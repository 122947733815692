import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListMerchants from "@lib/components/merchants/list";
import {
  initialNewMerchantState,
  ManageMerchant,
  ManageMerchantModal,
} from "@lib/components/modals/manage-merchant";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import Content from "@lib/layout/content";
import { IconButton } from "@lib/components/buttons/iconButton";
import { dateRange } from "@lib/components/query-builder/date-picker";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import MerchantsTable from "@lib/tables/merchants-table";

type Props = {};

const Merchants: FC<Props> = ({}: Props) => {
  const [refreshList, setRefreshList] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const { t } = useTranslation();
  const [newMerchant, setNewMerchant] = useState<ManageMerchant>(
    initialNewMerchantState
  );
  const [isCreateMerchantModalOpen, setIsCreateMerchantModalOpen] =
    useState(false);
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@merchantsQueryParams"
  );
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@merchantsDateRange"
  );
  const toggleCreateMerchantModal = () =>
    setIsCreateMerchantModalOpen((prevState: boolean) => !prevState);

  return (
    <>
      <Content
        title={t("merchants")}
        actionBtns={
          <IconButton
            onClick={toggleCreateMerchantModal}
            title="createMerchant"
            Icon={PencilSquareIcon}
          />
        }
        queryBuilder={
          <QueryBuilder
            type="merchants"
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setDateRange={
              setDateRange as React.Dispatch<SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
          />
        }
      >
        <MerchantsTable
          refresh={refreshList}
          setRefresh={setRefreshList}
          queryParams={queryParams}
        />

        <ManageMerchantModal
          isOpen={isCreateMerchantModalOpen}
          toggleModal={toggleCreateMerchantModal}
          newMerchant={newMerchant}
          setNewMerchant={setNewMerchant}
          refreshMerchant={setRefreshList}
        />
      </Content>
    </>
  );
};

export default Merchants;
