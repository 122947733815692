import { FC, useEffect, useLayoutEffect, useState } from "react";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import { dateRange } from "@lib/types/date";
import ListReconciliations from "@lib/components/reconciliations/list";
import ReconciliationsTable from "@lib/tables/reconciliations-table";
import Content from "@lib/layout/content";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import { useTranslation } from "react-i18next";
import DownloadReconciliationsButton from "@lib/components/shared/download-reconciliations-btn";

type Props = {};

const Reconciliations: FC<Props> = ({}: Props) => {
  const [refreshList, setRefreshList] = useState(false);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@reconciliationDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@reconciliationQueryParams"
  );

  return (
    <>
      <Content
        title={t("reconciliations")}
        queryBuilder={
          <QueryBuilder
            type={"reconciliations"}
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            setDateRange={
              setDateRange as React.Dispatch<React.SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        }
        actionBtns={<DownloadReconciliationsButton queryParams={queryParams} />}
      >
        <ReconciliationsTable
          refresh={refreshList}
          setRefresh={setRefreshList}
          queryParams={queryParams}
        />
      </Content>
    </>
  );
};

export default Reconciliations;
