import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Input, Search, Switch } from "@lib";
import { AppStore } from "@lib/icons/app-store";
import { Modal } from "@lib/modal";
import { App } from "../apps/view";
import { createAppApi, updateAppApi } from "@lib/utils/api/apps-api";
import { IError, NiceErrorComponent } from "@lib/nice-errors";
import UserContext, { UserContextType } from "src/contexts/UserContext";

type Props = {
  toggleModal: any;
  isOpen: any;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  sdks: any[];
  app: App;
  setApp: React.Dispatch<React.SetStateAction<App>>;
};

export const PackageTypes = [
  { value: "app", display: "Full App" },
  { value: "sdk", display: "SDK" },
];

const ManageAppModal = ({
  toggleModal,
  isOpen,
  setRefresh,
  app,
  setApp,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [createAppErrors, setCreateAppErrors] = useState<IError | null>(null);
  const [selectedMerchant, setSelectedMerchant] = useState<string>("");
  const [isMerchant, setIsMerchant] = useState<boolean>(false);
  const { auth } = useContext(UserContext) as UserContextType;

  const close = () => {
    setCreateAppErrors(null);
    setSelectedMerchant("");
    setIsMerchant(false);
    toggleModal();
  };

  const { t } = useTranslation();

  const handleCreateApp = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await createAppApi({
        name: app.name,
        app_name: app.app_name,
        merchant_id: isMerchant ? selectedMerchant : undefined,
      });
      setRefresh((prev) => !prev);
      setIsLoading(false);
      close();
    } catch (e: any) {
      e.error ? setCreateAppErrors(e) : void 0;
      e.message ? setCreateAppErrors(e.message) : void 0;
      setIsLoading(false);
    }
  };

  const handleUpdateApp = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await updateAppApi({
        ...app,
        merchant_id: isMerchant ? selectedMerchant : undefined,
      });
      if (!isMerchant) delete app["merchant"];
      setRefresh((prev) => !prev);
      setIsLoading(false);
      close();
    } catch (e: any) {
      setCreateAppErrors(e);
      setIsLoading(false);
    }
  };

  const updateValue = (val: string, key: string) => {
    setApp((prevState: App) => ({
      ...prevState,
      [key]: val,
    }));
  };

  useEffect(() => {
    if (app.merchant) {
      setIsMerchant(true);
      setSelectedMerchant(app.merchant.id);
    }
  }, [isOpen]);
  const { i18n } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={t("apps")}
      subTitle={app?.id ? t("editApp") : t("createNewApp")}
      close={close}
      icon={AppStore}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form className="flex flex-col gap-2">
        {createAppErrors?.error?.type === "niceError" ? (
          <div className="mb-4 mt-2">
            <NiceErrorComponent error={createAppErrors.error} />
          </div>
        ) : null}

        <Input
          type="text"
          label={t("packageName") as string}
          value={app?.name}
          name="name"
          onChange={(e) => updateValue(e, "name")}
          errors={createAppErrors}
          placeholder="e.g. example.package.io"
        />
        <Input
          type="text"
          label={t("appName") as string}
          value={app?.app_name}
          name="app_name"
          onChange={(e) => updateValue(e, "app_name")}
          errors={createAppErrors}
        />

        {!auth.merchant?.id && (
          <span className="flex gap-2 py-2 font-semibold">
            <h4 className={(!isMerchant ? "opacity-50" : undefined) as string}>
              {t("merchantApp")}
            </h4>
            <Switch
              className={isMerchant ? "!bg-sky-500" : "bg-gray-200"}
              isEnabled={isMerchant}
              setIsEnabled={() => setIsMerchant((prev) => !prev)}
            />
          </span>
        )}
        {isMerchant && (
          <Search
            key={"merchant"}
            // label={t("merchant")}
            initialSelected={
              app.merchant
                ? { value: app.merchant.id, display: app.merchant.name }
                : { value: "", display: "" }
            }
            placeholder={t("searchMerchantName")}
            searchKey={"merchants"}
            onClick={setSelectedMerchant}
            onRemove={() => setSelectedMerchant("")}
          />
        )}
        <Button
          colorScheme={"black"}
          isRounded={false}
          className={"px-2 py-1"}
          onClick={app.id ? handleUpdateApp : handleCreateApp}
          label={t("save") as string}
          isDisabled={isMerchant && !selectedMerchant ? true : false}
        />
      </form>
    </Modal>
  );
};

export default ManageAppModal;
