import { TableCell, TableRow, Text, Badge, Icon, Flex } from "@tremor/react";
import React from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { MerchantItem } from "@lib/list-item/merchant-list";
import { TerminalItem } from "@lib/types/terminal";
import ALBI from "../../icons/ALBI.png";
import ANBB from "../../icons/ANBB.jpeg";
import BSFB from "../../icons/BSFB.jpeg";
import BSHB from "../../icons/BSHB.png";
import FABM from "../../icons/FABM.png";
import GEID from "../../icons/GEID.png";
import GIBB from "../../icons/GIBB.jpeg";
import INMA from "../../icons/INMA.png";
import JAZB from "../../icons/JAZB.png";
import NBOB from "../../icons/NBOB.png";
import NBOK from "../../icons/NBOK.png";
import RAJB from "../../icons/RAJB.png";
import RYDB from "../../icons/RYDB.png";
import SABB from "../../icons/SABB.png";
import SAIB from "../../icons/SAIB.jpeg";
import STCP from "../../icons/STCP.jpeg";
const BankIcons: { [key: string]: string } = {
  ALBI: ALBI,
  ANBB: ANBB,
  BSFB: BSFB,
  BSHB: BSHB,
  FABM: FABM,
  GEID: GEID,
  GIBB: GIBB,
  INMA: INMA,
  JAZB: JAZB,
  NBOB: NBOB,
  NBOK: NBOK,
  RAJB: RAJB,
  RYDB: RYDB,
  SABB: SABB,
  SAIB: SAIB,
  STCP: STCP,
};
// id: string;
//   name: string;
//   name_ar?: string;
//   reference_id?: string;
//   status: "active" | "deactive";
//   created_at: string;

export const TerminalRow = ({ item }: { item: TerminalItem }) => {
  const navigate = useNavigate();
  const goToTerminal = () => navigate("./" + item.id);
  return (
    <TableRow
      onClick={goToTerminal}
      className="hover:bg-gray-100 cursor-pointer propagate-border"
    >
      <TableCell className="text-black p-1 rtl:text-right">
        <Flex flexDirection="col" alignItems="start">
          <span className="font-bold">{item.name}</span>
          <span className="font-light"> {item.tid}</span>
        </Flex>
      </TableCell>
      <TableCell className="text-black">{item.trsm}</TableCell>
      <TableCell className="text-black">
        <Flex flexDirection="col" alignItems="start">
          <span className="font-medium">{item.user?.name}</span>
          {/* <span className="font-light">{item.merchant?.name_ar}</span> */}
        </Flex>
      </TableCell>
      {/* <TableCell className="text-black py-0">Active</TableCell> */}
      <TableCell className="text-black py-0">
        <Flex
          flexDirection="row"
          alignItems="center"
          className="w-auto gap-2"
          justifyContent="start"
        >
          <Flex flexDirection="col" alignItems="start" className="w-auto">
            <span className="font-medium">{item.merchant.name}</span>
            <span className="font-light">{item.merchant_name_arabic}</span>
          </Flex>
          {/* <Badge
            color={"yellow"}
            className="!rounded-md border-2 border-yellow-500 "
          >
            {t("vIP")}
          </Badge> */}
        </Flex>
      </TableCell>
      <TableCell className="text-black py-0">
        <Flex justifyContent="start" className="gap-1">
          <img src={BankIcons[item?.bank?.code ?? ""]} className="w-5" />
          {item.bank?.code ?? "N/A"}
        </Flex>
      </TableCell>
      <TableCell className="text-black py-0 w-16 text-center">
        {item.is_connected ? (
          <Badge color={"gray"}>{t("deviceConnected")}</Badge>
        ) : (
          <Badge color={"green"}>{t("deviceNotConnected")}</Badge>
        )}
      </TableCell>

      {/* <TableCell className="text-black">
        <span className="font-medium">{item.reference_id}</span>
      </TableCell> */}
      {/* <TableCell className="text-black p-1">
        <span className="font-bold">43,4003</span>
        <span className="font-light"> SAR</span>
      </TableCell>
      <TableCell>
        <span className="text-black font-bold">40</span>{" "}
        <span className="text-black text-xs">Days</span>
      </TableCell>
      <TableCell>
        <span className="text-black font-bold">5</span>{" "}
        <span className="text-black text-xs">hours</span>
      </TableCell> */}
    </TableRow>
  );
};
export default TerminalRow;
