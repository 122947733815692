import React, { FC, useEffect, useState } from "react";
import { dateRange } from "@lib/types/date";
import { listTransactionsApi } from "@lib/utils/api/transactions-apis";
import { useTranslation } from "react-i18next";
import { Table } from "@tremor/react";
import TransactionRow from "@lib/tables/rows/transaction-row";
import { TransactionsHeader } from "@lib/tables/table-headers/transactions-header";

import { GenericTable } from "@lib/tables/generic-table";

type Props = {
  limit?: number;
  activeTab?: string;
  refresh: boolean;
  terminal_id?: string;
  reconciliation_id?: string;
  merchant_id?: string;
  setTransactionsForDownload?: any;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

export const TransactionsTable: FC<Props> = ({
  refresh,
  terminal_id,
  merchant_id,
  reconciliation_id,
  activeTab,
  limit = 30,
  setTransactionsForDownload,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [transactions, setTransactions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const { t } = useTranslation();

  const fetchTransactions = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTransactionsApi({
        page,
        merchant_id,
        limit,
        terminal_id,
        reconciliation_id,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setNumberOfPages(response.pages);
      setTransactions(response.transactions);
      setTransactionsForDownload?.(response.transactions);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(selectedPage);
  }, [refresh, activeTab, reconciliation_id, selectedPage]);

  return (
    <GenericTable
      data={transactions}
      isLoading={isLoading}
      component={TransactionRow}
      numberOfPages={numberOfPages}
      setSelectedPage={setSelectedPage}
      header={<TransactionsHeader />}
    />
  );
};

export default TransactionsTable;
