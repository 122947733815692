import { t } from "i18next";
import React, { ElementType, FC, PropsWithChildren, ReactNode } from "react";

import LoadingSpinner from "@lib/layout/loading-spinner";
import { EmptyState, Paginator } from "@lib";
import { Table, TableBody, TableRow } from "@tremor/react";
interface GroupedListType {
  data: { [key: string]: any }[];
  component: ElementType;
  isLoading: boolean;
  numberOfPages: number;
  setSelectedPage: (page: number) => void;
  header: ReactNode;
}

export const GenericTable: FC<PropsWithChildren<GroupedListType>> = ({
  data,
  component: Component,
  isLoading,
  numberOfPages,
  setSelectedPage,
  header,
}) => {
  return (
    <>
      <Table className="px-2 w-full max-w-full saparate-table relative">
        {!isLoading ? header : null}
        <TableBody>
          {isLoading ? (
            <TableRow>
              <td className="calculate-loader">
                <LoadingSpinner isLoading={isLoading} />
              </td>
            </TableRow>
          ) : data.length ? (
            <>
              {data.map((key, index) => {
                return <Component key={index} item={key} index={index} />;
              })}
            </>
          ) : (
            <TableRow className="h-20 relative">
              <td className="absolute h-20 w-full py-2">
                <EmptyState title={t("itemNotFound") as string} />
              </td>
            </TableRow>
          )}
        </TableBody>
        {/* <TableFoot className="sticky bottom-0"> */}

        {/* </TableFoot> */}
      </Table>
      {numberOfPages > 1 ? (
        // <TableRow className="h-16 relative">
        <div className="border-t p-3 pb-0">
          <Paginator pages={numberOfPages} selectPage={setSelectedPage} />
        </div>
      ) : // </TableRow>
      null}
    </>
  );
};
