export interface ThemeColors {
  "--theme-primary": string;
  "--theme-secondary": string;
  "--theme-text-base"?: string;
}

const baseTheme: ThemeColors = {
  "--theme-primary": "blue",
  "--theme-secondary": "red",
  "--theme-text-base": "white",
};
export default baseTheme;
