import React, { useEffect } from "react";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Errors, Input, Search, Select } from "@lib";
import { AppStore } from "@lib/icons/app-store";
import { Modal } from "@lib/modal";
import { policyType, times } from "@lib/types/policy";
import {
  createPolicyApi,
  PolicyResponse,
  updatePolicyApi,
} from "@lib/utils/api/policies-apis";
import { NiceError } from "@lib/nice-errors";
import { Feedback } from "@lib/forms/feedback";
import { PolicyType } from "../policies/policy-type";

type Props = {
  toggleModal: () => void;
  isOpen: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  policy?: Partial<PolicyResponse>;
};

const ManagePoliciesModal = ({
  toggleModal,
  isOpen,
  setRefresh,
  policy,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [policyFeedback, setPolicyFeedback] = useState<any>({});

  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedMerchant, setSelectedMerchant] = useState<string>("");
  const [selectedTerminal, setSelectedTerminal] = useState<string>("");

  const [type, setType] = useState<keyof typeof policyType>("client");

  const { t, i18n } = useTranslation();

  const close = () => {
    toggleModal();
    setPolicyFeedback({});
    setSelectedMerchant("");
    setSelectedTerminal("");
    setSelectedTime("");
  };

  const handleType = (type: string) => {
    setType(type as keyof typeof policyType);
    reset();
  };

  const handleCreatePolicy = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setPolicyFeedback({});
      await createPolicyApi({
        time: selectedTime,
        merchant_id: selectedMerchant,
        terminal_id: selectedTerminal,
      });
      setRefresh((prev) => !prev);
      setIsLoading(false);
      close();
    } catch (e: any) {
      e.error ? setPolicyFeedback(e) : void 0;
      e.message ? setPolicyFeedback(e.message) : void 0;
      setIsLoading(false);
    }
  };

  const hadnleUpdatePolicy = async () => {
    if (policy && policy.id)
      try {
        setIsLoading(true);
        setPolicyFeedback({});
        await updatePolicyApi(policy.id, selectedTime);
        setRefresh((prev) => !prev);
        setIsLoading(false);
        close();
      } catch (e: any) {
        e.error ? setPolicyFeedback(e) : void 0;
        e.message ? setPolicyFeedback(e.message) : void 0;
        setIsLoading(false);
      }
  };

  const reset = () => {
    setSelectedMerchant("");
    setSelectedTerminal("");
  };

  useEffect(() => {
    if (policy && policy.time) {
      setSelectedTime(policy.time);
      setType(policy.type as keyof typeof policyType);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={t("reconciliationPolicy")}
      subTitle={policy ? t("editPolicy") : t("createPolicy")}
      close={close}
      icon={AppStore}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />

      <div className="flex flex-col gap-2 capitalize" aria-disabled>
        <Feedback colorScheme="green" message={policyFeedback.message} />

        <NiceError error={policyFeedback.error} />

        <PolicyType
          onClick={handleType}
          type={type}
          disabled={policy ? true : false}
        />

        {type != "client" && !policy && (
          <Search
            key={type}
            label={t(type)}
            placeholder={
              type == "merchant"
                ? t("searchMerchantName")
                : t("searchTerminalId")
            }
            searchKey={type == "merchant" ? "merchants" : "terminals"}
            onClick={
              type == "merchant" ? setSelectedMerchant : setSelectedTerminal
            }
            onRemove={reset}
          />
        )}

        <div className="grid grid-cols-3 sm:grid-cols-5 gap-2 text-sm capitalize py-2">
          {Object.keys(times)
            .sort((a, b) => Number(a) - Number(b))
            .map((t) => {
              return Number(t) < 12 ? (
                <>
                  {t == "00" && (
                    <h3 key={t} className="col-span-3 sm:col-span-5 font-bold">
                      morning
                    </h3>
                  )}
                  <button
                    key={t}
                    onClick={() => setSelectedTime(t)}
                    className={`${
                      selectedTime == t && "bg-black text-white"
                    } rounded-md border p-2  font-semibold`}
                  >
                    {times[t as keyof typeof times]}
                  </button>
                </>
              ) : (
                <>
                  {t == "12" && (
                    <h3 className="col-span-3 sm:col-span-5  font-bold">
                      afternoon
                    </h3>
                  )}
                  <button
                    onClick={() => setSelectedTime(t)}
                    className={`${
                      selectedTime == t && "bg-black text-white"
                    } rounded-md border p-2  font-semibold`}
                  >
                    {times[t as keyof typeof times]}
                  </button>
                </>
              );
            })}
        </div>

        <div className="flex justify-between items-center gap-2">
          <Button
            classNames=""
            onClick={policy ? hadnleUpdatePolicy : handleCreatePolicy}
            label={(policy ? t("editPolicy") : t("createPolicy")) as string}
            colorScheme={"black"}
            isRounded={false}
            isDisabled={
              !selectedTime ||
              (type == "merchant" && !selectedMerchant && !policy) ||
              (type == "terminal" && !selectedTerminal && !policy)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default ManagePoliciesModal;
