import {
  FC,
  Context,
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";

interface ConfigContextType {
  allowedOnboarding: boolean;
}

type Props = {};

const ConfigContext: Context<ConfigContextType> =
  createContext<ConfigContextType>({
    allowedOnboarding: false,
  });

export const ConfigContextProvider: FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const [allowOnboarding, setAllowOnboarding] = useState(false);

  const setOnboarding = () =>
    setAllowOnboarding(process.env.REACT_APP_ONBOARDING_ON === "true");

  useEffect(() => {
    setOnboarding();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        allowedOnboarding: allowOnboarding,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
