import { FC, useContext, useState } from "react";
import { Input, Button } from "@lib/forms";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Errors } from "@lib";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../../assets/payment-schemes.svg";
import { verifyApi } from "@lib/utils/api/onboarding-apis";
import { usePromiseTracker } from "react-promise-tracker";

const Verify: FC<{}> = ({}) => {
  const [OTP, setOTP] = useState("");
  const [OTPErrors, setOTPErrors] = useState<any>({});
  const { promiseInProgress: isLoading } = usePromiseTracker();
  const { t, i18n } = useTranslation();
  const { addToken, auth } = useContext(UserContext) as UserContextType;
  const navigate = useNavigate();
  function logout() {
    navigate("/");
  }
  const verifyHandler = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    try {
      let response = await verifyApi({
        recaptchaToken: "",
        otp: OTP,
        admin_id: (state as { admin_id: string }).admin_id,
      }).then((response: any) => {
        addToken(response.accessToken, response.refreshToken);
        if (!auth.client) navigate("/create-orgnization-account");
        else navigate("/merchants");
      });
    } catch (error: any) {
      console.log({ error });

      if (error.message) setOTPErrors(error.message);
      else if (error?.error?.body) setOTPErrors(error.error.body);
      else setOTPErrors(error);
    }
  };

  const { state } = useLocation();

  return (
    <div
      style={{ padding: "20px" }}
      className="sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="flex flex-1 flex-col justify-center">
            <span className="text-5xl font-bold py-3">{t("verify")}</span>
            <div className="py-5">
              OTP sent to {(state as { receiver: string })?.receiver}
            </div>
            <div className="mb-4 mt-2">
              <Errors
                errors={
                  OTPErrors.error
                    ? [
                        i18n.language == "ar"
                          ? OTPErrors.error.arabic
                          : OTPErrors.error.english,
                      ]
                    : []
                }
              />
            </div>
            <Input
              value={OTP}
              label={t("OTP")}
              errors={OTPErrors?.otp}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e)) setOTP(e);
              }}
              name={""}
              // length={6}
              inputClasses="border border-2"
            />
            <div id="test-OTP-button">
              <Button
                label={t("verify") as string}
                onClick={verifyHandler}
                classNames="w-full py-2 px-4 mt-4"
                colorScheme="black"
                isRounded={false}
              />
            </div>
            <div className="pt-4 text-center flex justify-end">
              <div className="max-w-200px">
                <Button
                  label={t("logout") as string}
                  onClick={logout}
                  classNames="border border-black"
                  isRounded={false}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default Verify;
