export function mergeClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function capitalize(str: string): string {
  if (!str) return "";
  return str
    .split(" ")
    .map(
      (word: string) =>
        (word[0] || "").toUpperCase() + (word || "").substring(1)
    )
    .join(" ");
}

export const formatPrice = (
  amount: number | string,
  currency: string = "KES",
  currencyExponent: number = 2
) => {
  return (
    currency +
    " " +
    Number(amount).toLocaleString("en-US", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
  // const convertToNumber = Number(amount / Math.pow(10, currencyExponent));
  // return new Intl.NumberFormat("en", {
  //   style: "currency",
  //   currency,
  //   minimumFractionDigits: currencyExponent,
  // }).format(convertToNumber);
};

export function isObjectEmpty(object: { [key: string]: any }) {
  return Object.keys(object).every((i) => object[i] === undefined)
    ? true
    : false;
}
