import { useContext, useState } from "react";
import { dateRange } from "./date-picker";
import { Filter, Remove } from "@lib";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "@lib";
import { queryKeys } from "./query-keys";
import { listQueryParamsApi } from "@lib/utils/api/query-builder-apis";
import { Operator } from "@lib/types/operator";
import { FilterType } from "./filter-type";
import React from "react";
import UserContext, { UserContextType } from "src/contexts/UserContext";

type props = {
  type: keyof typeof queryKeys;
  refreshList: boolean;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
  setDateRange: React.Dispatch<React.SetStateAction<dateRange>>;
  dateRange: dateRange;
  setQueryParams: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >;
  queryParams: {
    [key: string]: any;
  };
};

const QueryBuilder = ({
  type,
  setRefreshList,
  setDateRange,
  dateRange,
  queryParams,
  setQueryParams,
}: props) => {
  const [amountRange, setAmountRange] = useState({ from: "", to: "" });
  const { auth } = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();
  const onChange = (dates: any) => {
    const [start, end] = dates;
    if (start === null && end === null) setDateRange({});
    else {
      setDateRange({
        from: start,
        to: end,
        fromTimestamp: (start as Date)?.getTime(),
        toTimestamp: (end as Date)?.getTime(),
      });
    }
    setRefreshList((prev) => !prev);
  };

  const resetParams = () => {
    setQueryParams({});
    setAmountRange({ from: "", to: "" });
    setDateRange({});
    setRefreshList((prev) => !prev);
  };

  const deleteQueryParam = (key: string, value: string) => {
    queryParams[key]["value"].length
      ? queryParams[key]["value"].splice(
          queryParams[key]["value"].indexOf(value),
          1
        )
      : delete queryParams[key];

    if (!queryParams[key]["value"].length) delete queryParams[key];
    setQueryParams(() => ({ ...queryParams }));
    setRefreshList((prev) => !prev);
  };

  const deleteQueryParams = (key: string) => {
    delete queryParams[key];
    if (key == "date") setDateRange({});
    setQueryParams(() => ({ ...queryParams }));
    setAmountRange({ from: "", to: "" });
    setRefreshList((prev) => !prev);
  };

  const addQueryParam = (
    key: string,
    value: any,
    operator?: string,
    valueDisplay?: string | any
  ) => {
    setQueryParams((prev) => {
      const params = { ...prev, [key]: { value, operator, valueDisplay } };

      if (operator == Operator.In || operator == Operator.NotNull) {
        params[key].value = [
          ...(prev[key]?.value.filter((i: string) => i != value) || []),
          value,
        ];
        params[key].valueDisplay = {
          ...prev[key]?.valueDisplay,
          [value]: valueDisplay,
        };
      } else if (operator == Operator.Between)
        params[key].value = [
          value.from ? value.from + "00" : "0",
          value.to + "00",
        ];

      return { ...params };
    });

    setRefreshList((prev) => !prev);
  };

  return (
    <>
      <div
        className={
          "flex flex-col  bg-white rounded-lg  sm:py-3 divide-y  capitalize h-full  overflow-y-scroll rounded-t-3xl sm:rounded-none  border sm:border-none hide-scrollbar max-h-[90vh]"
        }
      >
        <div className="flex justify-between items-center px-4 py-2 pt-4 ">
          <h5 className="font-bold text-md sm:text-md">{t("filters")}</h5>
          <button
            disabled={
              Object.keys(queryParams).length == 0 && dateRange.to == null
            }
            onClick={resetParams}
            className={
              " flex items-center justify-between bg-gray-400 hover:bg-gray-700 hover:-translate-y-px active:translate-y-0 ps-3  text-white rounded-xl capitalize  disabled:bg-gray-400 transition-all"
            }
          >
            <span className={"text-sm sm:text-xs whitespace-nowrap"}>
              {t("clearAll")}
            </span>{" "}
            <Remove
              color="text-white text-xs"
              size={18}
              containerClassNames="!p-1"
            ></Remove>
          </button>
        </div>
        {queryKeys[type].map((item, index) => {
          if (item?.searchKey == "merchants" && auth.merchant?.id) return;
          return (
            <div className="flex flex-col px-4 sm:py-px " key={index}>
              <details className="group text-xs" open={index == 0 ?? false}>
                <summary className="flex items-center justify-between cursor-pointer list-none ">
                  <h1 className="font-bold text-lg sm:text-xs py-4 sm:py-0">
                    {t(item.display)}
                  </h1>
                  <div className="flex items-center justify-between">
                    {queryParams[item.key]?.value?.length ||
                    (item?.key == "date" && dateRange.to) ? (
                      <li
                        onClick={() => deleteQueryParams(item.key)}
                        className="group/item flex  justify-between items-center px-2  m-1 text-sm text-white bg-black rounded-lg cursor-pointer"
                      >
                        {
                          <h6>
                            {item.type != "range"
                              ? queryParams[item.key]?.value.length
                              : ""}
                          </h6>
                        }

                        <Filter
                          color="text-white"
                          size={18}
                          containerClassNames="!p-0.5 group-hover/item:hidden transition-all"
                        ></Filter>
                        <Remove
                          color="text-white"
                          size={18}
                          containerClassNames="!p-0.5 hidden group-hover/item:block transition-all"
                        ></Remove>
                      </li>
                    ) : (
                      ""
                    )}

                    <ChevronDown
                      color="text-gray-400 group-open:text-gray-900"
                      containerClassNames="!pr-0"
                    />
                  </div>
                </summary>
                <hr className="hidden group-open:block py-2 -mx-4" />

                <FilterType
                  item={item}
                  queryParam={queryParams[item.key]}
                  addQueryParam={addQueryParam}
                  deleteQueryParam={deleteQueryParam}
                  amountRange={amountRange}
                  setAmountRange={setAmountRange}
                  onChangeDate={onChange}
                  dateRange={dateRange}
                />
              </details>
            </div>
          );
        })}
        {/* spacing at the bottom of the filters */}
        <div className="p-10 sm:hidden"></div>
      </div>
    </>
  );
};

export default QueryBuilder;
