export const cardVerification = (value: string) => {
  switch (value) {
    case "00":
      return "noVerification";
    case "20":
      return "pin";
    case "30":
      return "userDevice";
    default:
      return "--";
  }
};
