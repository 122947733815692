import i18n from "i18next"; // Ensure you import i18n instance

export const dateToRelativeTime = (date: Date) => {
  const prevDate = new Date(date).getTime();
  const diff = Number(new Date()) - prevDate;
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;

  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000);
      return `${seconds} ${
        seconds > 1 ? i18n.t("seconds") : i18n.t("second")
      } ${i18n.t("ago")}`;
    case diff < hour:
      return ` ${Math.round(diff / minute)} ${i18n.t("minutesAgo")}`;
    case diff < day:
      return ` ${Math.round(diff / hour)} ${i18n.t("hoursAgo")}`;
    case diff < month:
      return ` ${Math.round(diff / day)} ${i18n.t("daysAgo")}`;
    case diff < year:
      return ` ${Math.round(diff / month)} ${i18n.t("monthsAgo")}`;
    case diff > year:
      return ` ${Math.round(diff / year)} ${i18n.t("yearsAgo")}`;
    default:
      return "";
  }
};
