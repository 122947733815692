import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Content from "@lib/layout/content";
import { ProgressItemType } from "@lib/types/reports";
import { GenericBarChart } from "@lib/components/Reports/Charts";
import {
  PeriodicSalesCard,
  TerminalsStatusCard,
  TotalSalesCard,
  TotalTerminalsCard,
} from "@lib/components/Reports/Cards";
import {
  getSalesReportApi,
  getTerminalsReportApi,
} from "@lib/utils/api/reports-apis";
import { Text, Flex, Grid } from "@tremor/react";

import { t } from "i18next";

type Props = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};
// const dummyData: ProgressItemType[] = [
//   {
//     label: "New Terminals",
//     value: "15 Terminals",
//     progress: 70,
//     color: "green",
//     type: "progress",
//   },
//   {
//     label: "Slipaway Merchant",
//     value: "20 Merchants",
//     progress: 50,
//     color: "blue",
//     type: "progress",
//   },
//   {
//     label: "Internet Avg Latency",
//     value: "100ms",
//     progress: 80,
//     color: "amber",
//     type: "progress",
//   },
//   {
//     label: "Top Sales by Merchants",
//     value: "$5,000",
//     progress: 15,
//     color: "purple",
//     type: "category",
//     categoryValues: [40, 30, 20, 10],
//     categoryColors: ["rose", "yellow", "orange", "teal"],
//   },
//   {
//     label: "Merchants Terminal",
//     value: "30 Terminals",
//     progress: 40,
//     color: "red",
//     type: "progress",
//   },
//   {
//     label: "Top Sales by Terminals",
//     value: "$10,000",
//     progress: 19,
//     color: "teal",
//     type: "category",
//     categoryValues: [40, 30, 20, 10],
//     categoryColors: ["rose", "yellow", "orange", "teal"],
//   },
// ];

type TerminalReports = {
  total: string;
  connected: string;
  activated: string;
};
type SalesReports = {
  average: string;
  count: string;
  max: string;
  min: string;
  month: string;
  prevDay: string;
  prevMonth: string;
  prevWeek: string;
  today: string;
  total: string;
  totalRuning: string;
  week: string;
};

const Reports: FC<Props> = ({ setIsLoading }: Props) => {
  const [terminalReports, setTerminalReports] = useState<TerminalReports>({
    total: "0",
    connected: "0",
    activated: "0",
  });
  const [salesReports, setSalesReports] = useState<SalesReports>({
    average: "0",
    count: "0",
    max: "0",
    min: "0",
    month: "0",
    prevDay: "0",
    prevMonth: "0",
    prevWeek: "0",
    today: "0",
    total: "0",
    totalRuning: "0",
    week: "0",
  });

  const getReports = async () => {
    try {
      const terminals = await getTerminalsReportApi();
      const sales = await getSalesReportApi();

      setTerminalReports(terminals);
      setSalesReports(sales);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getReports();
  }, []);
  return (
    <>
      <Flex flexDirection="col" className="gap-12 p-4">
        {/* Terminal Section */}
        <Flex flexDirection="col" className="gap-4">
          <Text className=" lg:text-2xl text-xl w-full ltr:text-left rtl:text-right font-semibold text-gray-900">
            {t("terminals")}
          </Text>
          <Grid
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={3}
            className="gap-5 w-full"
          >
            <TotalTerminalsCard total={Number(terminalReports?.total) || 0} />
            <TerminalsStatusCard
              title={t("activeTerminals")}
              units={Number(terminalReports?.activated) || 0}
              total={Number(terminalReports?.total) || 0}
              tooltip={t("activeTerminalsExplanation")}
            />
            <TerminalsStatusCard
              title={t("connectedTerminals")}
              units={Number(terminalReports?.connected) || 0}
              total={Number(terminalReports?.total) || 0}
              tooltip={t("connectedTerminalsExplanation")}
            />
          </Grid>
          <GenericBarChart type="terminals" />
        </Flex>
        {/* ************************* */}

        {/* Operations Section */}
        {/* <Flex flexDirection="col" className="gap-4">
          <Text className="lg:text-2xl md:text-2xl sm:text-2xl w-full text-left font-semibold text-gray-900">
            Operations
          </Text>
          <Grid
            numItems={1}
            numItemsSm={2}
            numItemsLg={3}
            className="gap-5 w-full"
          >
            <ProgressPanel data={dummyData} />
            <ProgressPanel data={dummyData} />
            <ProgressPanel data={dummyData} />
          </Grid>
        </Flex> */}
        {/* ************************* */}

        {/* Sales Section */}
        <Flex flexDirection="col" className="gap-4">
          <Text className="ltr:text-left rtl:text-right lg:text-2xl md:text-2xl sm:text-2xl w-full text-left font-semibold text-gray-900">
            {t("sales")}
          </Text>
          <Grid
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={3}
            className="gap-5 w-full"
          >
            <TotalSalesCard
              title={t("total")}
              subTitle={t("transactions")}
              total={Number(salesReports?.total) / 100 || 0}
            />

            <TotalSalesCard
              title={t("runningTotals")}
              subTitle={t("notReconciled")}
              total={Number(salesReports?.totalRuning) / 100 || 0}
            />

            <TotalSalesCard
              title={t("average")}
              subTitle={t("transactionValue")}
              total={Number(salesReports?.average) / 100 || 0}
            />

            <PeriodicSalesCard
              title={t("todaySales")}
              current={Number(salesReports?.today) / 100 || 0}
              previous={Number(salesReports?.prevDay) / 100 || 0}
            />

            <PeriodicSalesCard
              title={t("weekSales")}
              current={Number(salesReports?.week) / 100 || 0}
              previous={Number(salesReports?.prevWeek) / 100 || 0}
            />

            <PeriodicSalesCard
              title={t("monthSales")}
              current={Number(salesReports?.month) / 100 || 0}
              previous={Number(salesReports?.prevMonth) / 100 || 0}
            />
          </Grid>
        </Flex>
        {/* ************************* */}
        <GenericBarChart type="sales" />
        {/* Merchants Section */}
        {/* <Flex flexDirection="col" className="gap-4">
          <Text className="lg:text-2xl md:text-2xl sm:text-2xl w-full text-left font-semibold text-gray-900">
            Merchants
          </Text>
          <Grid
            numItems={1}
            numItemsSm={2}
            numItemsLg={3}
            className="gap-5 w-full"
          >
            <ProgressPanel data={dummyData} />
            <ProgressPanel data={dummyData} />
            <ProgressPanel data={dummyData} />
          </Grid>
        </Flex> */}
        {/* ************************* */}
      </Flex>
    </>
  );
};

export default Reports;
