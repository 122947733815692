const APP_ID = process.env.REACT_APP_INTERCOM_KEY;
export const bootIntercom = () => {
  if (window.location.host === "sandbox.nearpay.io") {
    let Window: any = window;
    var Intercom = Window.Intercom;
    if (typeof Intercom === "function") {
      Intercom("reattach_activator");
      Intercom("update", Window.intercomSettings);
    } else {
      var _document = document;
      var IntercomFunctionBuilder: any = function () {
        IntercomFunctionBuilder.c(arguments);
      };
      IntercomFunctionBuilder.q = [];
      IntercomFunctionBuilder.c = function (args: any) {
        IntercomFunctionBuilder.q.push(args);
      };
      Window.Intercom = IntercomFunctionBuilder;
      var l = function () {
        var appScript = _document.createElement("script");
        appScript.type = "text/javascript";
        appScript.async = true;
        appScript.src = "https://widget.intercom.io/widget/" + APP_ID;
        var x = _document.getElementsByTagName("script")[0];
        x.parentNode?.insertBefore(appScript, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (Window.attachEvent) {
        Window.attachEvent("onload", l);
      } else {
        Window.addEventListener("load", l, false);
      }
    }
  } else {
    return;
  }
};
