import { FC, ReactElement } from "react";
import AccountMerchantsPage from "../../../account/pages/Merchants";
import AccountMerchantPage from "../../../account/pages/Merchant";
import AccountTerminalsPage from "../../../account/pages/Terminals";
import AccountTerminalPage from "../../../account/pages/Terminal";
import AccountTransactionsPage from "../../../account/pages/Transactions";
import AccountTransactionPage from "../../../account/pages/Transaction";
import AccountReconciliationsPage from "../../../account/pages/Reconciliations";
import AccountReconciliationPage from "../../../account/pages/Reconciliation";
import AccountTeamPage from "../../../account/pages/Team";
import AccountUsersPage from "../../../account/pages/Users";
import AccountSettingsPage from "../../../account/pages/SettingsPage";
import AccountWebhooksPage from "../../../account/pages/Webhooks";
import AccountWebhookPage from "@lib/components/webhooks/list-logs";
import AccountAppsPage from "../../../account/pages/Apps";
import AccountAppPage from "../../../account/pages/App";
import AccountPoliciesPage from "../../../account/pages/Policies";
import AccountPolicyPage from "../../../account/pages/Policy";
import AccountLoginPage from "../../../account/pages/Login";
import AccountResetPasswordpage from "../../../account/pages/ResetPassword";
import AccountUpdatePasswordpage from "../../../account/pages/UpdatePassword";
import AccountResetPasswordMessageSentPage from "../../../account/pages/ResetPasswordMessageSent";
import AccountUpdateMobilePage from "../../../account/pages/UpdateMobile";
import AccountVerifyPage from "../../../account/pages/Verify";
import AccountSingUpPage from "../../../account/pages/SignUp";
import AccountCreateOrgnizationAccountPage from "../../../account/pages/CreateOrgnizationAccount";
import AccountSelectPlatformPage from "../../../account/pages/SelectPlatform";
import AccountRedirectPage from "../../../account/pages/Redirect";
import AccountPublicLayout from "../../../account/pages/PublicLayout";
import AccountReportsPage from "../../../account/pages/Reports";
import MerchantReportsPage from "../../../merchant/pages/Reports";
import MerchantTerminalsPage from "../../../merchant/pages/Terminals";
import MerchantTerminalPage from "../../../merchant/pages/Terminal";
import MerchantTransactionsPage from "../../../merchant/pages/Transactions";
import MerchantTransactionPage from "../../../merchant/pages/Transaction";
import MerchantReconciliationsPage from "../../../merchant/pages/Reconciliations";
import MerchantReconciliationPage from "../../../merchant/pages/Reconciliation";
import MerchantemployeesPage from "../../../merchant/pages/Employees";
import MerchantemployeePage from "../../../merchant/pages/Employee";
import MerchantAppsPage from "../../../merchant/pages/Apps";
import MerchantAppPage from "../../../merchant/pages/App";
import MerchantSettingsPage from "../../../merchant/pages/SettingsPage";
import MerchantWebhooksPage from "../../../merchant/pages/Webhooks";
import MerchantWebhookPage from "@lib/components/webhooks/list-logs";

import MerchantPoliciesPage from "../../../merchant/pages/Policies";
import MerchantPolicyPage from "../../../merchant/pages/Policy";
import MerchantLoginPage from "../../../merchant/pages/Login";
import MerchantOnboardingPage from "../../../merchant/pages/InvitationLandingPage";
import MerchantRedirectPage from "../../../merchant/pages/Redirect";
import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  Cog8ToothIcon,
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  DeviceTabletIcon,
  DocumentTextIcon,
  ScaleIcon,
  SquaresPlusIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
export interface RouterNavLink {
  name: string;
  link: string;
  icon?: any;
}
export interface RoutesPages {
  path: string;
  component: FC<JSX.Element>;
  child: FC<JSX.Element> | undefined;
  isHome?: boolean;
}
export interface RoutesGroups {
  account: RoutesPages[];
  merchant: RoutesPages[];
}

const AccountLinks: RouterNavLink[] = [
  { name: "merchants", link: "/merchants", icon: BuildingStorefrontIcon },
  { name: "terminals", link: "/terminals", icon: DeviceTabletIcon },
  { name: "transactions", link: "/transactions", icon: BanknotesIcon },
  {
    name: "reconciliations",
    link: "/reconciliations",
    icon: ScaleIcon,
  },
  { name: "team", link: "/team", icon: UserGroupIcon },
  // { name: "users", link: "/users" },
  { name: "apps", link: "/apps", icon: SquaresPlusIcon },
  //{ name: "events", link: "/events", icon: CursorArrowRaysIcon },
  //{ name: "policies", link: "/policies", icon: DocumentTextIcon },
  { name: "settings", link: "/settings", icon: Cog8ToothIcon },
  { name: "reports", link: "/reports", icon: ChartBarIcon },
];

const MerchantLinks: RouterNavLink[] = [
  {
    link: "/terminals",
    name: "terminals",
    icon: DeviceTabletIcon,
  },
  {
    link: "/transactions",
    name: "transactions",
    icon: BanknotesIcon,
  },
  {
    link: "/reconciliations",
    name: "reconciliations",
    icon: ScaleIcon,
  },
  {
    link: "/employees",
    name: "employees",
    icon: UserGroupIcon,
  },
  {
    link: "/apps",
    name: "apps",
    icon: SquaresPlusIcon,
  },
  {
    link: "/events",
    name: "events",
    icon: CursorArrowRaysIcon,
  },
  { link: "/policies", name: "policies", icon: DocumentTextIcon },

  {
    link: "/settings",
    name: "settings",
    icon: Cog8ToothIcon,
  },
  { name: "reports", link: "/reports", icon: ChartBarIcon },
];

export const groupLinks = {
  account: AccountLinks,
  merchant: MerchantLinks,
  //   nsp: NspRouterGroupsLinks,
};

export const RoutesPages: RoutesGroups = {
  account: [
    {
      path: "/",
      component: AccountMerchantsPage,
      child: AccountMerchantPage,
    },
    {
      path: "/merchants",
      component: AccountMerchantsPage,
      child: AccountMerchantPage,
      isHome: true,
    },

    {
      path: "/terminals",
      component: AccountTerminalsPage,
      child: AccountTerminalPage,
    },
    {
      path: "/transactions",
      component: AccountTransactionsPage,
      child: AccountTransactionPage,
    },
    {
      path: "/reconciliations",
      component: AccountReconciliationsPage,
      child: AccountReconciliationPage,
    },
    {
      path: "/team",
      component: AccountTeamPage,
      child: undefined,
    },
    {
      path: "/apps",
      component: AccountAppsPage,
      child: AccountAppPage,
    },
    // {
    //   path: "/users",
    //   component: AccountUsersPage,
    //   child: undefined,
    // },
    {
      path: "/settings",
      component: AccountSettingsPage,
      child: undefined,
    },
    {
      path: "/events",
      component: AccountWebhooksPage,
      child: AccountWebhookPage as FC<JSX.Element>,
    },
    {
      path: "/policies",
      component: AccountPoliciesPage,
      child: AccountPolicyPage,
    },
    {
      path: "/reports",
      component: AccountReportsPage,
      child: undefined,
    },
  ],
  merchant: [
    {
      path: "/",
      component: MerchantTerminalsPage,
      child: MerchantTerminalPage,
    },
    {
      path: "/",
      component: MerchantTransactionsPage,
      child: MerchantTerminalPage,
    },
    {
      path: "/terminals",
      component: MerchantTerminalsPage,
      child: MerchantTerminalPage,
    },
    {
      path: "/transactions",
      component: MerchantTransactionsPage,
      child: MerchantTransactionPage,
    },
    {
      path: "/reconciliations",
      component: MerchantReconciliationsPage,
      child: MerchantReconciliationPage,
    },
    {
      path: "/employees",
      component: MerchantemployeesPage,
      child: MerchantemployeePage,
    },
    {
      path: "/apps",
      component: MerchantAppsPage,
      child: MerchantAppPage,
    },
    {
      path: "/settings",
      component: MerchantSettingsPage,
      child: undefined,
    },
    {
      path: "/events",
      component: MerchantWebhooksPage,
      child: MerchantWebhookPage as FC<JSX.Element>,
    },
    {
      path: "/policies",
      component: MerchantPoliciesPage,
      child: MerchantPolicyPage,
    },
    {
      path: "/reports",
      component: MerchantReportsPage,
      child: undefined,
    },
  ],

  //   nsp: [
  //     {
  //       path: "/",
  //       component: NSPReportsPage,
  //     },
  //     {
  //       path: "/terminals",
  //       component: NSPTerminalsPage,
  //     },
  //     {
  //       path: "/transactions",
  //       component: NSPTransactionsPage,
  //     },
  //     {
  //       path: "/messages",
  //       component: NSPMessagesPage,
  //     },
  //   ],
};

export interface PublicRoutes {
  path: string;
  element: FC<JSX.Element> | ReactElement<any, any>;
  is_allowed: boolean;
}

interface PublicRoutesGroup {
  account: PublicRoutes[];
  merchant: PublicRoutes[];
}
export const publicPages: PublicRoutesGroup = {
  account: [
    {
      path: "/",
      element: AccountLoginPage,
      is_allowed: true,
    },
    {
      path: "/reset-password",
      element: AccountResetPasswordpage,
      is_allowed: true,
    },
    {
      path: "/update-password",
      element: AccountUpdatePasswordpage,
      is_allowed: true,
    },
    {
      path: "/update-mobile",
      element: AccountUpdateMobilePage,
      is_allowed: true,
    },
    {
      path: "/verify",
      element: AccountVerifyPage,
      is_allowed: true,
    },
    {
      path: "/message-sent",
      element: AccountResetPasswordMessageSentPage,
      is_allowed: true,
    },
    {
      path: "/signup",
      element: AccountSingUpPage,
      is_allowed: false,
    },
    {
      path: "/create-orgnization-account",
      element: AccountCreateOrgnizationAccountPage,
      is_allowed: false,
    },
    {
      path: "/select-platform",
      element: AccountSelectPlatformPage,
      is_allowed: false,
    },
    {
      path: "*",
      element: AccountRedirectPage as FC<JSX.Element>,
      is_allowed: true,
    },
  ],
  merchant: [
    { path: "/", element: MerchantLoginPage, is_allowed: true },
    { path: "/login", element: MerchantLoginPage, is_allowed: true },
    { path: "/onboarding", element: MerchantOnboardingPage, is_allowed: true },
    {
      path: "*",
      element: MerchantRedirectPage as FC<JSX.Element>,
      is_allowed: true,
    },
  ],
};

// interface PublicLayout {
//   account: FC<JSX.Element> | undefined;
//   merchant: JSX.Element | undefined;
// }
// export const publicLayout: PublicLayout = {
//   account: AccountPublicLayout,
//   merchant: undefined,
// };
