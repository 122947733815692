import React, { FC, useEffect, useState } from "react";
import {
  TerminalIcon,
  Button,
  Input,
  SearchIcon,
  Errors,
  Remove,
  Filter,
} from "@lib";
import { Modal } from "@lib";
import {
  listMerchantsApi,
  MerchantResponse,
} from "../../utils/api/merchants-apis";
import {
  createTerminalApi,
  // updateTerminalApi,
} from "../../utils/api/terminals-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import { useTranslation } from "react-i18next";

import i18next from "i18next";
import { Feedback } from "@lib/forms/feedback";

type Merchant = {
  id: string;
  name: string;
  merchant_category_code: string;
  receipt_address: string;
  card_acceptor_id_code: string;
  host_related_data: {
    card_acceptor_name_and_location: string;
  };
};

const initialMerchant: Merchant = {
  id: "",
  name: "",
  merchant_category_code: "",
  receipt_address: "",
  card_acceptor_id_code: "",
  host_related_data: {
    card_acceptor_name_and_location: "",
  },
};
export type ManageTerminal = {
  id?: string;
  merchant: Merchant;
  card_acceptor_terminal_id: string;
};

export const initialNewTerminalState: ManageTerminal = {
  merchant: initialMerchant,
  card_acceptor_terminal_id: "",
};

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  terminal?: { [key: string]: any };
  refresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManageTerminals: FC<Props> = ({
  isOpen,
  toggleModal,
  terminal,
  refresh,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newTerminal, setNewTerminal] = useState<{ [key: string]: any }>(
    terminal?.id ? terminal : initialNewTerminalState
  );
  const [merchants, setMerchants] = useState<MerchantResponse[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>(
    terminal?.merchant ?? initialMerchant
  );
  const [search, setSearch] = useState("");
  const [terminalFeedback, setTerminalFeedback] = useState<any>({});

  const fetchMerchants = async () => {
    const { merchants } = await listMerchantsApi({ limit: 1000 });
    setMerchants(merchants);
    setIsLoading(false);
  };

  const merchantOnChange = (merchant: Merchant) => {
    setSelectedMerchant(merchant);
  };

  const submit = async () => {
    try {
      setTerminalFeedback({});
      setIsLoading(true);
      let response;
      if (terminal?.id) {
        // response = await updateTerminalApi({
        //   id: terminal?.id,
        //   reference_terminal_id: terminal.reference_terminal_id,
        //   merchant_category_code: newTerminal?.merchant_category_code,
        //   merchant_receipt_address: newTerminal?.receipt_address,
        //   card_acceptor_name_and_location:
        //     newTerminal?.card_acceptor_name_and_location,
        // });
      } else {
        response = await createTerminalApi({
          merchant_id: selectedMerchant?.id,
          merchant_name: selectedMerchant?.name,
          merchant_category_code: selectedMerchant?.merchant_category_code,
          card_acceptor_id_code: selectedMerchant?.card_acceptor_id_code,
          merchant_receipt_address: selectedMerchant?.receipt_address,
          card_acceptor_name_and_location:
            selectedMerchant?.host_related_data
              ?.card_acceptor_name_and_location,
          card_acceptor_terminal_id: newTerminal?.card_acceptor_terminal_id,
        });
      }

      setTerminalFeedback(response.message);
      setIsLoading(false);
      setTimeout(() => {
        refresh((prev) => !prev);
        close();
      }, 3000);
    } catch (e: any) {
      setIsLoading(false);
      console.log({ e });
      setTerminalFeedback(e);
    }
  };
  const close = () => {
    setTerminalFeedback({});
    setNewTerminal(initialNewTerminalState);
    setSelectedMerchant(initialMerchant);
    setIsLoading(false);
    toggleModal();
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (terminal) {
      setNewTerminal(terminal);
      console.log({ terminal });
    }
  }, [isOpen]);
  useEffect(() => {
    fetchMerchants();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      subTitle=""
      width="lg"
      icon={TerminalIcon}
      close={close}
      title={terminal?.id ? t("editTerminal") : t("addNewTerminal")}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={false} />
      <Errors errors={terminalFeedback?.general} />

      {!terminal?.id && (
        <div className={"group"}>
          {selectedMerchant.id ? (
            <>
              <h2 className="font-bold mb-1">{t("merchant")}</h2>
              <div className=" flex justify-between items-center px-2 py-1.5 mb-2 bg-black border border-gray-300 rounded-lg cursor-pointer text-white">
                <h6 className="text-xs">{selectedMerchant.name}</h6>
                <span onClick={() => merchantOnChange(initialMerchant)}>
                  <Remove
                    size={18}
                    color="text-white"
                    containerClassNames="!p-1"
                  ></Remove>
                </span>
              </div>
            </>
          ) : (
            <div className="relative flex items-center">
              <Input
                name="search"
                type="search"
                placeholder={t("select") + " " + t("merchant")}
                value={search}
                onChange={setSearch}
                icon={SearchIcon}
                label=""
                inputContainerClasses="w-full"
              />
            </div>
          )}

          <ul
            className={
              selectedMerchant.id
                ? "hidden "
                : " max-h-28 space-y-2 mb-2 text-center  overflow-x-hidden transition-all"
            }
          >
            {merchants
              .filter((i) =>
                i.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((el: any) => {
                return (
                  <li
                    key={el.id}
                    onClick={(e) => {
                      merchantOnChange(el);
                      setSearch("");
                    }}
                    className=" flex justify-between items-center px-2   m-2 bg-gray-200  hover:bg-gray-300 border border-gray-300 rounded-md cursor-pointer"
                  >
                    <h6 className="text-xs truncate">{el.name}</h6>
                    <Filter size={20} containerClassNames="!p-1"></Filter>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {/** search bar gose here ! */}

      {terminal?.id ? (
        <>
          {" "}
          <Input
            label={t("merchant_category_code")}
            name="merchant_category_code"
            errors={terminalFeedback?.merchant_category_code}
            onChange={(e) =>
              setNewTerminal((prevState: ManageTerminal) => ({
                ...prevState,
                merchant_category_code: e,
              }))
            }
            length={4}
            // pattern="[0-9]*"
            inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
            value={newTerminal?.merchant_category_code || ""}
          />
          <Input
            label={t("receiptAddress")}
            name="receipt_address"
            errors={terminalFeedback?.receipt_address}
            onChange={(e) =>
              setNewTerminal((prevState: ManageTerminal) => ({
                ...prevState,
                receipt_address: e,
              }))
            }
            value={newTerminal?.receipt_address || ""}
          />
          <Input
            label={t("Card Acceptor Name And Location")}
            name="card_acceptor_name_location"
            errors={terminalFeedback?.card_acceptor_name_location}
            onChange={(e) =>
              setNewTerminal((prevState: ManageTerminal) => ({
                ...prevState,
                card_acceptor_name_and_location: e,
              }))
            }
            length={40}
            inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
            value={newTerminal?.card_acceptor_name_and_location || ""}
          />
        </>
      ) : (
        <Input
          label={t("Card Acceptor Terminal Id")}
          name="card_acceptor_terminal_id"
          errors={terminalFeedback?.card_acceptor_terminal_id}
          onChange={(e) =>
            setNewTerminal((prevState: ManageTerminal) => ({
              ...prevState,
              card_acceptor_terminal_id: e,
            }))
          }
          length={16}
          //pattern="[0-9]*"
          inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
          value={newTerminal?.card_acceptor_terminal_id || ""}
        />
      )}

      <Feedback
        colorScheme="green"
        message={
          i18next.language == "ar"
            ? terminalFeedback?.arabic
            : terminalFeedback?.english
        }
      />

      <div className="my-4">
        <Button
          label={t("save") as string}
          onClick={submit}
          colorScheme="black"
          isRounded={false}
          isDisabled={
            terminal?.id
              ? newTerminal.card_acceptor_name_and_location?.length != 40
              : !selectedMerchant.id ||
                newTerminal.card_acceptor_terminal_id?.length != 8
          }
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"}
        />
      </div>
    </Modal>
  );
};

export default ManageTerminals;
