import React, {
  FC,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import { Button, Card, EmptyState } from "@lib";
import {
  generateApikey,
  getCredintialsApi,
  updateKey,
} from "@lib/utils/api/settings-apis";
import keypair from "keypair";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { IconButton } from "@lib/components/buttons/iconButton";
import TerminalPullOutModal from "@lib/components/modals/pullout-terminal";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

type Props = {};

const Settings: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({} as any);
  const { t } = useTranslation();
  const [fingerPrint, setFingerPrint] = useState("");
  const [apikey, setApikey] = useState("");
  const { auth } = useContext(UserContext) as UserContextType;

  const getCredintials = async () => {
    try {
      await getCredintialsApi().then((res) => {
        setResponse(res);
        setIsLoading(false);
        setFingerPrint(res.fingerPrint);
        setApikey(res.apikey);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCredintials();
  }, []);

  return (
    <Content isLoading={isLoading} title={t("credentials")}>
      <div className="flex flex-col">
        <div className="flex-1 flex flex-col justify-center">
          <div className="font-semibold flex-1 mt-3">
            {auth.merchant?.id ? t("merchantKey") : (t("clientKey") as string)}
          </div>
          {Object.keys(response).length ? (
            <Account uuid={auth.merchant?.id ?? auth.client.id} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="font-semibold flex-1 mt-3">
            {t("JWTKey") as string}
          </div>

          {Object.keys(response).length ? (
            <JWTKey
              fingerprint={fingerPrint}
              setFingerprint={setFingerPrint}
              role={auth?.role}
            />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="font-semibold flex-1 mt-3">
            {t("APIKey") as string}
          </div>
          {Object.keys(response).length ? (
            <Apikey apikey={apikey} setApikey={setApikey} role={auth?.role} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
      </div>
    </Content>
  );
};

export default Settings;

const JWTKey = ({
  fingerprint,
  setFingerprint,
  role,
}: {
  fingerprint: string;
  setFingerprint: any;
  role: "owner" | "accountant" | "cashier";
}) => {
  const { i18n, t } = useTranslation();
  const { allowKeygen } = useFlags();
  const rsaKeys = async () => {
    return await keypair();
  };

  const handleRegenerateButton = async (e: SyntheticEvent) => {
    if (window.confirm("Such Action Will Cause Payment To Temporarily Stop"))
      try {
        const keys = await rsaKeys();
        await updateKey({ public_key: keys.public }).then((result) => {
          setFingerprint(result.fingerPrint);
          if (!result?.error) {
            let data = [keys.private];
            let key_file = new File(
              data,
              new Date().getTime() + "-private-key.pem"
            );
            const link = document.createElement("a");
            const url = URL.createObjectURL(key_file);
            link.href = url;
            link.download = key_file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        });
      } catch (error) {
        console.log({ error });
      }
  };

  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full ">
      <div className="flex flex-row items-center flex-wrap ">
        <div className={`p-2 ${i18n.language == "ar" ? "pl-4" : "pr-4"}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.758 11.828l7.849-7.849 1.414 1.414-1.414 1.415 2.474 2.474-1.414 1.415-2.475-2.475-1.414 1.414 2.121 2.121-1.414 1.415-2.121-2.122-2.192 2.192a5.002 5.002 0 0 1-7.708 6.294 5 5 0 0 1 6.294-7.708zm-.637 6.293A3 3 0 1 0 5.88 13.88a3 3 0 0 0 4.242 4.242z" />
          </svg>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 ">
            <span className="font-bold text-sm lg:text-base">
              {t("fingerprint") as string}
            </span>
          </div>
          <span
            className={
              i18n.language == "ar"
                ? "pl-3 break-all number-place-holder text-gray-600 text-xs text-right"
                : "pr-3 break-all text-gray-600 text-xs "
            }
          >
            {fingerprint}
          </span>
        </div>

        <div className="flex-initial">
          {(!fingerprint || allowKeygen) && (
            <Button
              className={"px-2 py-0.5 text-sm"}
              // className="px-10 py-2"
              label={fingerprint ? "Regenerate" : "Generate"}
              isRounded={true}
              size="sm"
              onClick={handleRegenerateButton}
              colorScheme="black"
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const Apikey = ({
  apikey,
  setApikey,
  role,
}: {
  apikey: string;
  setApikey: React.Dispatch<SetStateAction<string>>;
  role: "owner" | "accountant" | "cashier";
}) => {
  const { i18n } = useTranslation();
  const { allowKeygen } = useFlags();

  const handleCopyButton = async () => {
    await navigator.clipboard.writeText(apikey);
    alert("api key copied to clipboard");
  };

  const handleRegenerateButton = async () => {
    try {
      await generateApikey().then((result) => setApikey(result.apikey));
    } catch (error) {
      console.log({ error });
    }
  };

  const CopyButton = ({
    handleCopyButton,
  }: {
    handleCopyButton: () => void;
  }) => {
    const { t } = useTranslation();
    return (
      <Button
        className={"px-2 py-0.5 text-sm"}
        onClick={() => handleCopyButton()}
        label={t("Copy") as string}
        size="sm"
        colorScheme={"black"}
      />
    );
  };

  const RegenerateButton = ({
    handleRegenerateButton,
  }: {
    handleRegenerateButton: () => void;
  }) => {
    const { t } = useTranslation();
    return (
      <Button
        className={"px-2 py-0.5 text-sm"}
        onClick={() => handleRegenerateButton()}
        label={apikey ? (t("Regenerate") as string) : (t("Generate") as string)}
        size="sm"
        colorScheme={"black"}
      />
    );
  };

  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full">
      <div className="flex flex-row items-center">
        <div className={`p-2 ${i18n.language == "ar" ? "pl-4" : "pr-4"}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M5 11h14V5H5v6zm16-7v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1zm-2 9H5v6h14v-6zM7 15h3v2H7v-2zm0-8h3v2H7V7z" />
          </svg>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 ">
            <span className="font-bold text-sm lg:text-base ">API Key</span>
          </div>

          <span
            className={
              i18n.language == "ar"
                ? "w-36 sm:w-full truncate sm:max-w-md number-place-holder text-gray-600 text-sm text-right font-bold "
                : "w-36 sm:w-full truncate sm:max-w-md text-gray-600 text-sm font-bold"
            }
          >
            {apikey}
          </span>
        </div>

        <div className="flex-initial flex justify-between items-center gap-2">
          <CopyButton handleCopyButton={handleCopyButton} />
          {role == "owner" && allowKeygen && (
            <>
              <RegenerateButton
                handleRegenerateButton={handleRegenerateButton}
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

const Account = ({ uuid }: { uuid: string }) => {
  const { i18n } = useTranslation();
  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full">
      <div className="flex flex-row items-center">
        <div className={`p-2 ${i18n.language == "ar" ? "pl-4" : "pr-4"}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M21 5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H16v2h2V5h3zm-3 8h-2v2h-2v3h4v-5zm-2-2h-2v2h2v-2zm2-2h-2v2h2V9zm-2-2h-2v2h2V7z" />
          </svg>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 lg:my-1">
            <span className="font-bold text-sm lg:text-base">UUID</span>
          </div>
          <span
            className={
              i18n.language == "ar"
                ? "number-place-holder text-gray-600 text-xs text-right"
                : "text-gray-600 text-xs "
            }
          >
            {uuid}
          </span>
        </div>
      </div>
    </Card>
  );
};
