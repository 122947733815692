import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AccountApp from "./account/App";
import MerchantApp from "./merchant/App";
import reportWebVitals from "./reportWebVitals";
import { bootIntercom } from "./account/boot-intercom";
import NotFound404 from "./merchant/pages/404";

bootIntercom();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// type AppType = "account" | "merchant";
const fetchConfig = async () => {
  return fetch(`${process.env.REACT_APP_BASEURL}get-config`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  }).then(async (response) => {
    console.log(response);
    if (response.ok) {
      (window as any).merchantConfiguration = await response.json();
    } else throw "notfound";
    (window as any).merchantHasTheme = true;
    return true;
  });
};

if (process.env.REACT_APP_DASHBOARD_TYPE == "merchant")
  fetchConfig()
    .then(() => {
      root.render(
        <React.StrictMode>
          <MerchantApp />
        </React.StrictMode>
      );
    })
    .catch(() => {
      root.render(
        <React.StrictMode>
          <NotFound404 />
        </React.StrictMode>
      );
    });
else
  root.render(
    <React.StrictMode>
      <AccountApp />
    </React.StrictMode>
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
