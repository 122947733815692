export const ExportTerminalType = {
  nearpay: {
    id: "ID",
    client_id: "Client ID",
    tname: "Terminal Name",
    tid: "TID",
    trsm: "TRSM",
    bank_en: "Bank-En",
    bank_ar: "Bank-Ar",
    merchant_id: "Merchant ID",
    merchant_name_en: "Merchant Name-en",
    merchant_name_ar: "Merchant Name-ar",
    merchant_catagory: "Merchant Catagory",
    merchant_currency: "Merchant Currency",
    address_en: "Address English",
    address_ar: "Address Arabic",
    mada: "Mada",
    visa: "Visa",
    amex: "AMEX",
    mc: "Master Card",
    assigned: "Assigned",
    user_name: "User Name",
    user_email: "User Email",
    user_mobile: "User Mobile",
    connected: "Connected",
    number_of_connections: "Number of Connected Devices",
    first_transaction: "First Transaction",
    last_transaction: "Last Transaction",
    created_at: "Created At",
  },
  tuma: {
    id: "ID",
    client_id: "Client ID",
    tname: "Terminal Name",
    tid: "TID",
    trsm: "TRSM",
    bank_en: "Bank-En",
    bank_ar: "Bank-Ar",
    merchant_id: "Merchant ID",
    merchant_name_en: "Merchant Name-en",
    merchant_name_ar: "Merchant Name-ar",
    merchant_catagory: "Merchant Catagory",
    merchant_currency: "Merchant Currency",
    address_en: "Address English",
    address_ar: "Address Arabic",
    mada: "Mada",
    visa: "Visa",
    amex: "AMEX",
    mc: "Master Card",
    assigned: "Assigned",
    user_name: "User Name",
    user_email: "User Email",
    user_mobile: "User Mobile",
    connected: "Connected",
    number_of_connections: "Number of Connected Devices",
    first_transaction: "First Transaction",
    last_transaction: "Last Transaction",
    created_at: "Created At",
  },
};
