import React, { FC } from "react";
import { CheckMarkIcon, XIcon } from "@lib";
import { default as Mada } from "@lib/icons/mada.png";
import { default as Visa } from "@lib/icons/visa.png";
import { default as Mastercard } from "@lib/icons/mastercard.png";
import { default as American_Express } from "@lib/icons/American_Express.png";

export const images = {
  mada: Mada,
  visa: Visa,
  mc: Mastercard,
  amex: American_Express,
};
export const imagesClasses = {
  mada: "w-7",
  visa: "w-10",
  mc: "w-10",
  amex: "w-10",
};
type Schemes = "visa" | "mc" | "amex" | "mada";
const SupportedSchemes: FC<{
  type: string;
  profile: any;
  index?: number;
}> = ({ type, profile, index }) => {
  const isSupported = (scheme: Schemes) => {
    if (!profile.schemes?.[scheme])
      return <div className="bg-gray-400 rounded-full h-full w-full"></div>;
    return profile.schemes?.[scheme][type] ? (
      <CheckMarkIcon color="text-green-600" />
    ) : (
      <XIcon
        color="!text-white"
        size={20}
        containerClassNames="bg-red-600 rounded-full"
      />
    );
  };

  return (
    <>
      {index == 0 && (
        <div className="flex-1 grid grid-cols-5 items-center place-items-center">
          <span className="font-bold text-xl text-left w-full">Schemes</span>
          {Object.keys(images).map((img) => {
            return (
              <img
                className={` ${img === "mada" && "w-7"} ${
                  !profile.schemes[img] && "grayscale"
                } object-contain `}
                src={images[img as keyof typeof images]}
                width={40}
                height={40}
                key={img}
              />
            );
          })}
        </div>
      )}
      <div className="flex-1 grid grid-cols-5 items-center place-items-center mt-1 ">
        <span className={`text-md mb-2 text-left capitalize w-full px-2`}>
          {type}
        </span>

        {Object.keys(images).map((scheme) => (
          <div
            key={scheme}
            className="text-md mb-2 w-5 h-5 rounded-full flex items-center justify-center"
          >
            {isSupported(scheme as Schemes)}
          </div>
        ))}
      </div>
    </>
  );
};

export default SupportedSchemes;
