import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Button } from "@lib";
import { testWebhookApi } from "@lib/utils/api/webhooks-apis";

type Props = {
  toggleModal?: any;
  webhook: any;
  setWebhook?: any;
};

export const EventPreview: FC<Props> = ({
  toggleModal,
  webhook,
  setWebhook,
}) => {
  const testWebhook = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    await testWebhookApi({ id: webhook?.id });
  };

  const toggleEditModal = () => {
    setWebhook({ ...webhook, event_id: webhook?.event?.id });
    toggleModal(true);
  };

  return webhook.id ? (
    <div className={"w-full flex flex-col justify-center text-xs"}>
      <div className="flex justify-between gap-2 items-center text-xs lg:text-base">
        <div className="text-gray-700 font-bold capitalize break-all">
          {webhook?.event ? webhook?.event?.name : "Event"} Preview{" "}
        </div>
        <div className="flex gap-2">
          <Button
            onClick={toggleEditModal}
            label={t("edit") as string}
            colorScheme="black"
            classNames="active:translate-y-px"
            size={"sm"}
            isRounded={false}
          />
          <Button
            onClick={testWebhook}
            label={t("test") as string}
            colorScheme="skyblue"
            classNames="active:translate-y-px"
            size={"sm"}
            isRounded={false}
          />
        </div>
      </div>

      <div className="">
        <SyntaxHighlighter
          language="jsx"
          customStyle={{
            backgroundColor: "#f7f7f7",
            padding: ".4rem",
            margin: 0,
            width: "100%",
          }}
          className="w-full max-h-screen overflow-x-auto  border-t border-gray-300"
        >
          {JSON.stringify(webhook?.event?.preview, null, 2)}
        </SyntaxHighlighter>
      </div>
    </div>
  ) : null;
};
