import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "../icons";
import { Card } from "../shared";
import { PolicyJobsListItemType } from "../types/policy-job";

export const PolicyJobsListItem: FC<PolicyJobsListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 w-full">
        <div className="flex justify-between px-2">
          <div>
            <div className="capitalize">
              <span className="text-sm">{t("reconciliationCreatedAt")}</span>
              <h3 className="font-bold">
                {" "}
                {new Date(item.created_at).toLocaleString("nl-NL")}
              </h3>
            </div>
            <div></div>
          </div>
          <div className="w-1/3 flex items-center justify-evenly gap-3 capitalize">
            <div className="">
              <span className="text-xs text-gray-600">{t("success")}</span>
              <h3 className="font-bold">{item.total_succeed}</h3>
            </div>
            <div className="">
              <span className="text-xs text-gray-600">{t("failed")}</span>
              <h3 className="font-bold">{item.total_failed}</h3>
            </div>
            <div className="">
              <span className="text-xs text-gray-600">{t("balanced")}</span>
              <h3 className="font-bold">{item.total_balanced}</h3>
            </div>
            <div className="">
              <span className="text-xs text-gray-600">{t("notBalanced")}</span>
              <h3 className="font-bold">{item.total_unbalanced}</h3>
            </div>
            <div className="">
              <span className="text-xs text-gray-600">{t("transactions")}</span>
              <h3 className="font-bold">{item.total_transactions ?? "-"}</h3>
            </div>
            <div className="">
              <span className="text-xs text-gray-600">{t("totalValue")}</span>
              <h3 className="font-bold">
                {typeof item.total_value === "number"
                  ? Number(item.total_value).toLocaleString("en-US", {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "-"}
              </h3>
            </div>
            <div></div>
          </div>
        </div>
      </Card>
    </div>
  );
};
