import React, { FC, ReactNode } from "react";
import { ArrowLeft, ArrowRight, Checked, Filter, XIcon } from "../icons";
import { Card } from "../shared";
import { ReconciliationListItemType } from "../types/reconciliation";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export const ReconciliationListItem: FC<ReconciliationListItemType> = ({
  role,
  onClick,
  item,
  isFull,
}) => {
  const { i18n, t } = useTranslation();
  const statusIcon: { [key: string]: ReactNode } = {
    1: (
      <div className="flex flex-row gap-1 items-center bg-green-200 text-green-600 rounded-full p-1 px-2 font-semibold text-xs">
        <Checked size={16} color="text-green-600" />
        <span>{t("balanced") as string}</span>
      </div>
    ),
    0: (
      <div className="flex flex-row gap-1 items-center bg-red-200 text-red-600 rounded-full p-1 px-2 font-semibold text-xs">
        <XIcon size={16} color="text-red-600" />
        <span>{t("notBalanced") as string}</span>
      </div>
    ),
  };

  return (
    <NavLink to={"/reconciliations/" + item?.id}>
      <Card classNames="flex flex-col items-center gap-y-2   divide-y-2 divide-dashed divide-gray-300 mt-3 lg:py-2 !px-0  text-[.5rem] lg:text-xs 2xl:text-sm w-full">
        <div className="flex items-center justify-between gap-5 py-1 sm:px-2 w-full">
          <div className="whitespace-nowrap mx-2">
            {statusIcon[Number(item?.is_balanced)]}
          </div>

          {/* total vavlue */}
          <div className="w-2/12 flex flex-col text-sm lg:text-base capitalize rtl:mr-auto ltr:ml-auto mx-2">
            <span className="font-bold whitespace-nowrap">
              {item?.total?.value}{" "}
              <small className="text-xs font-normal">
                {i18n.language === "ar"
                  ? item?.currency?.arabic
                  : item?.currency?.english}
              </small>
            </span>
            <span className="text-xs whitespace-nowrap">
              {t("count")} {item?.total?.count}
            </span>
          </div>
          {/* merchant  */}
          <div
            className={`${
              item?.merchant ?? "invisible"
            } w-2/12 hidden md:flex flex-col overflow-hidden group-[.merchant]:hidden group-[.terminal]:hidden`}
          >
            <small>{t("merchant")}</small>
            <span className=" font-bold text-xs whitespace-nowrap truncate">
              {item?.merchant?.name ?? ""}
            </span>
          </div>

          {/* terminal */}
          <div className="w-2/12 hidden sm:flex flex-col rtl:mr-auto ltr:ml-auto group-[.terminal]:hidden">
            <small>{t("terminal")}</small>
            <span className="font-bold text-xs">{item?.terminal?.tid}</span>
          </div>
          {/* stan */}
          <div className="hidden xs:flex flex-col rtl:mr-auto ltr:ml-auto">
            <span className="font-bold">{item?.time}</span>
            <span className="text-xs whitespace-nowrap">
              STAN : {item?.stan}
            </span>
          </div>

          {/* user show just inside terminal */}
          <div className="hidden sm:group-[.terminal]:flex flex-col rtl:mr-auto ltr:ml-auto ">
            <small className="text-[.65rem]">{t("user")}</small>
            <span className=" font-semibold text-xs truncate w-[8rem] lg:w-[11.5rem] [direction:ltr] rtl:text-end">
              {`${item?.user?.name ?? " "} (${item?.user?.mobile ?? " "})`}
            </span>
          </div>

          {/* device show just inside terminal*/}
          <div className="hidden md:group-[.terminal]:flex  flex-col rtl:mr-auto ltr:ml-auto">
            <small>{t("device")}</small>
            <span className=" font-semibold text-xs  truncate w-[6rem] [direction:ltr] rtl:text-end">
              {`${item?.device?.brand ?? ""} ${item?.device?.model ?? ""}`}
            </span>
          </div>
          <div
            className="flex-initial rtl:mr-auto ltr:ml-auto mx-2
        "
          >
            {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
          </div>
        </div>

        {/* //////////////// second filter //////////////////// */}
        {isFull && (
          <div
            className={
              " flex items-center justify-evenly gap-2 px-2 sm:px-10 pt-1.5   w-full "
            }
          >
            {/* user */}
            <div className="w-1/2 xs:w-2/6 sm:w-auto flex flex-col ">
              <small className="text-[.65rem]">{t("user")}</small>
              <span className=" font-semibold text-xs truncate ">
                {`${item?.user?.name ?? "test name"} (${
                  item?.user?.mobile ?? "+966558980328"
                })`}
              </span>
            </div>

            {/* device */}
            <div className="w-1/2 xs:w-2/6 sm:w-auto flex flex-col ">
              <small>{t("device")}</small>
              <span className=" font-semibold text-xs  truncate">
                {`${item?.device?.brand ?? ""} ${item?.device?.model ?? ""}`}
              </span>
            </div>
          </div>
        )}
      </Card>
    </NavLink>
  );
};
