import "../../types/globals";

import axios, { AxiosRequestConfig } from "axios";
import { IError } from "@lib/nice-errors";
import Storage from "../storage";
import { refreshAccessTokenApi } from "./auth-apis";

let baseURL = process.env.REACT_APP_BASEURL;
if (window.__RUNTIME_CONFIG__?.REACT_APP_BASEURL) {
  baseURL = window.__RUNTIME_CONFIG__.REACT_APP_BASEURL;
}
const baseApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const onboardingApi = axios.create({
  baseURL: baseURL?.replace("clients/", "") + "onboarding/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const refreshApi = axios.create({
  // baseURL:baseURL?.includes("clients") ? baseURL : baseURL + "clients/",
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

baseApi.interceptors.request.use(async (request: any) => {
  try {
    let token =
      (await Storage.get("accessToken")) ||
      (await Storage.get("access_token")) ||
      "";
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
      request.headers.client_id = Storage.get("clientId");
      request.headers.merchant_id = Storage.get("merchantId");
    }
    return request;
  } catch (e) {
    console.log({ e });
  }
});

baseApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (e: any) {
    console.log("from response ", { e });
    if (e?.response) throw e?.response?.data;
    throw e;
  }
);

onboardingApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (e: any) {
    if (e?.response) throw e?.response?.data;
    throw e;
  }
);

onboardingApi.interceptors.request.use(async (request: any) => {
  try {
    let token =
      (await Storage.get("accessToken")) ||
      (await Storage.get("access_token")) ||
      "";
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  } catch (e) {
    console.log({ e });
  }
});

const Api = async (config: AxiosRequestConfig) => {
  try {
    const response = await baseApi(config);
    return response;
  } catch (e: any) {
    if (e?.statusCode === 401) {
      let refreshResponses = await refreshAccessToken();
      Storage.set("accessToken", refreshResponses.accessToken);
      return await baseApi(config);
    }
    if (
      e?.statusCode === 503 &&
      (Storage.get("accessToken") || Storage.get("access_token"))
    ) {
      document.location = "/#/service-unavailable";
    }
    if ("message" in e)
      throw {
        error: { type: "validationError", body: e.message },
      } as IError;
    if ("solution" in e)
      throw { error: { type: "niceError", body: e } } as IError;
    throw e;
  }
};

export const OnboardingApi = async (config: AxiosRequestConfig) => {
  try {
    const response = await onboardingApi(config);
    return response;
  } catch (e: any) {
    if (e?.statusCode === 401 && e.message == "Unauthorized") {
      let refreshResponses = await refreshAccessToken();
      Storage.set("accessToken", refreshResponses.accessToken);
      const response = await onboardingApi(config);
      return response;
    }
    throw e;
  }
};

export default Api;
export interface List {
  page?: number;
  limit?: number;
  queryParams?: {
    [key: string]: any;
  };
}

export interface ApiParams {
  toggleLoading?: Function;
}
const refreshAccessToken = async () => {
  try {
    // remove after merge dashboards
    if (!Storage.get("auth")) throw { statusCode: 401 };

    let refreshResponse = await refreshAccessTokenApi(
      Storage.get("refreshToken") ?? Storage.get("refresh_token")
    );
    return refreshResponse;
  } catch (e: any) {
    if (e?.response?.status === 401 || e?.statusCode === 401) {
      window.location.href = `${window.location.origin}/`;
      Storage.remove("accessToken");
      Storage.remove("refreshToken");
      Storage.remove("client");
      Storage.remove("user");
      Storage.remove("auth");
      localStorage.clear();
      window.location.reload();
    }
    throw e;
  }
};
