type selectDate = {
  value: string;
  display: string;
};
type HttpStatus = {
  value: number;
  display: string;
};
export const banks: selectDate[] = [
  {
    value: "INMA",
    display: "banks:INMA",
  },
  {
    value: "RAJB",
    display: "banks:RAJB",
  },
  {
    value: "NCBB",
    display: "banks:NCBB",
  },
  {
    value: "ALBI",
    display: "banks:ALBI",
  },
  {
    value: "ANBB",
    display: "banks:ANBB",
  },
  {
    value: "BSFB",
    display: "banks:BSFB",
  },
  {
    value: "BSHB",
    display: "banks:BSHB",
  },
  {
    value: "EBIL",
    display: "banks:EBIL",
  },
  {
    value: "FABM",
    display: "banks:FABM",
  },
  {
    value: "GEID",
    display: "banks:GEID",
  },
  {
    value: "GIBB",
    display: "banks:GIBB",
  },
  {
    value: "JAZB",
    display: "banks:JAZB",
  },
  {
    value: "NBOB",
    display: "banks:NBOB",
  },
  {
    value: "NBOK",
    display: "banks:NBOK",
  },
  {
    value: "RYDB",
    display: "banks:RYDB",
  },
  {
    value: "SABB",
    display: "banks:SABB",
  },
  {
    value: "SAIB",
    display: "banks:SAIB",
  },
  {
    value: "SAMA",
    display: "banks:SAMA",
  },
  {
    value: "SAMB",
    display: "banks:SAMB",
  },
  {
    value: "STCP",
    display: "banks:STCP",
  },
];

export const actionCodes: selectDate[] = [
  {
    value: "000",
    display: "Approved (000)",
  },
  {
    value: "001",
    display: "Honour with identification (001)",
  },
  {
    value: "003",
    display: "Approved (VIP) (003)",
  },
  {
    value: "007",
    display: "Approved, update ICC (007)",
  },
  {
    value: "060",
    display: "Approved in Stand-In Processing (STIP) mode by mada switch (060)",
  },
  {
    value: "060",
    display: "Approved in Stand-In Processing (STIP) mode by mada POS (060)",
  },
  {
    value: "087",
    display: "Offline Approved (Chip only) (087)",
  },
  {
    value: "089",
    display: "Unable to go On-line. Off-line approved (Chip only) (089)",
  },
  {
    value: "100",
    display: "Do not honour (100)",
  },
  {
    value: "101",
    display: "Expired card (101)",
  },
  {
    value: "102",
    display: "Suspected fraud (To be used when ARQC validation fails) (102)",
  },
  {
    value: "103",
    display: "Card acceptor contact acquirer (103)",
  },
  {
    value: "104",
    display: "Restricted card (104)",
  },
  {
    value: "105",
    display: "Card acceptor call acquirer’s security department (105)",
  },
  {
    value: "106",
    display: "Allowable PIN tries exceeded (106)",
  },
  {
    value: "107",
    display: "Refer to card issuer (107)",
  },
  {
    value: "108",
    display: "Refer to card issuer’s special conditions (108)",
  },
  {
    value: "109",
    display: "Invalid merchant (109)",
  },
  {
    value: "110",
    display:
      "The specified amount is invalid or exceeds the maximum unitary amount for the given mada transaction service (110)",
  },
  {
    value: "111",
    display: "Invalid card number (111)",
  },
  {
    value: "112",
    display: "PIN data required (112)",
  },
  {
    value: "114",
    display: "No account of type requested (114)",
  },
  {
    value: "115",
    display: "Requested function not supported (115)",
  },
  {
    value: "116",
    display: "Not sufficient funds (116)",
  },
  {
    value: "117",
    display: "Incorrect PIN (117)",
  },
  {
    value: "118",
    display: "No card record (118)",
  },
  {
    value: "119",
    display: "Transaction not permitted to cardholder (119)",
  },
  {
    value: "120",
    display: "Transaction not permitted to terminal (120)",
  },
  {
    value: "121",
    display: "Exceeds Daily limit for the given mada transaction service (121)",
  },
  {
    value: "122",
    display: "Security violation (122)",
  },
  {
    value: "123",
    display:
      "Exceeds frequency limit i.e. number of transactions, for the given mada transaction service (123)",
  },
  {
    value: "125",
    display: "Card not effective (125)",
  },
  {
    value: "126",
    display: "Invalid PIN block (126)",
  },
  {
    value: "127",
    display: "PIN length error (127)",
  },
  {
    value: "128",
    display: "PIN key synch error (128)",
  },
  {
    value: "129",
    display: "Suspected counterfeit card (129)",
  },
  {
    value: "164",
    display:
      "Transaction not permitted for this Merchant Category value(MCC) (164)",
  },
  {
    value: "165",
    display:
      "Excessive pre-authorization Capture not allowed.The excessive Capture attempt violates the mada business rules in terms of the total amount being captured or the allowed transaction channels or presentment mode (165)",
  },
  {
    value: "166",
    display: "More than one pre-authorization extension is not allowed (166)",
  },
  {
    value: "167",
    display:
      "Issuer not certified for this transaction or service or Issuer does not support this service (167)",
  },
  {
    value: "182",
    display: "Invalid date (Visa 80) (182)",
  },

  {
    value: "183",
    display: "Cryptographic error found in PIN or CVV (Visa 81) (183)",
  },

  {
    value: "184",
    display: "Incorrect CVV (Visa 82) (184)",
  },

  {
    value: "185",
    display: "Unable to verify PIN (Visa 83) (185)",
  },

  {
    value: "187",
    display:
      "Original transaction for refund, preauthorization capture, preauthorization void or preauthorization extension not found based on original transaction data (187)",
  },

  {
    value: "188",
    display: "Offline declined (188)",
  },

  {
    value: "190",
    display: "Unable to go online – Offline declined (190)",
  },
  {
    value: "195",
    display: "Card insertion required to proceed the transaction (195)",
  },
  {
    value: "196",
    display: "Online PIN is required (196)",
  },
  {
    value: "197",
    display:
      "The bank account used in the original transaction does not match the bank account being used in the Refund, Preauthorization Capture, reauthorization Void or Preauthorization Extension transaction (197)",
  },
  {
    value: "198",
    display:
      "The refund, preauthorization void amount or cumulative amount exceeds the original transaction amount (198)",
  },
  {
    value: "199",
    display:
      "The refund, pre-authorization capture, preauthorization void or preauthorization extension transaction period exceeds the maximum time limit allowed by the mada business rules (199)",
  },
  {
    value: "200",
    display: "Do not honour (200)",
  },
  {
    value: "201",
    display: "Expired card (201)",
  },
  {
    value: "202",
    display: "Suspected fraud (To be used when ARQC validation fails) (202)",
  },
  {
    value: "203",
    display: "Card acceptor contact acquirer (203)",
  },
  {
    value: "204",
    display: "Restricted card (204)",
  },
  {
    value: "206",
    display: "Allowable PIN tries exceeded (206)",
  },
  {
    value: "207",
    display: "Special conditions (207)",
  },
  {
    value: "208",
    display: "Lost card (208)",
  },
  {
    value: "209",
    display: "Stolen card (209)",
  },
  {
    value: "210",
    display: "Suspected counterfeit card (210)",
  },
  {
    value: "300",
    display: "Successful (300)",
  },
  {
    value: "301",
    display: "Not supported by receiver (301)",
  },
  {
    value: "302",
    display: "Unable to locate record on file (302)",
  },
  {
    value: "306",
    display: "Not successful (306)",
  },
  {
    value: "307",
    display: "Format error (307)",
  },
  {
    value: "400",
    display: "Accepted (400)",
  },
  {
    value: "480",
    display: "Original transaction not found (480)",
  },
  {
    value: "481",
    display: "Original transaction was found but declined (481)",
  },
  {
    value: "500",
    display: "Reconciled, in balance (500)",
  },
  {
    value: "501",
    display: "Reconciled, out of balance (501)",
  },
  {
    value: "690",
    display: "Unable to parse display (690)",
  },
  {
    value: "800",
    display: "Accepted (800)",
  },
  {
    value: "888",
    display: "Unknown error (888)",
  },
  {
    value: "893",
    display: "Signature Error (893)",
  },
  {
    value: "902",
    display: "Invalid transaction (902)",
  },
  {
    value: "903",
    display: "Re-enter transaction (903)",
  },
  {
    value: "904",
    display: "Format error (904)",
  },
  {
    value: "906",
    display: "Cutover in process (906)",
  },
  {
    value: "907",
    display: "Card issuer or switch inoperative (907)",
  },
  {
    value: "909",
    display: "System malfunction (909)",
  },
  {
    value: "910",
    display: "Card issuer signed off (910)",
  },
  {
    value: "911",
    display: "Card issuer timed out (911)",
  },
  {
    value: "912",
    display: "Card issuer unavailable (912)",
  },
  {
    value: "913",
    display: "Duplicate transmission (913)",
  },
  {
    value: "914",
    display: "Not able to trace back to original transaction (914)",
  },
  {
    value: "915",
    display: "Reconciliation cutover or checkpoint error (915)",
  },
  {
    value: "916",
    display: "MAC incorrect (permissible in 1644) (916)",
  },
  {
    value: "917",
    display: "MAC key sync (917)",
  },
  {
    value: "918",
    display: "No communication keys available for use (918)",
  },
  {
    value: "919",
    display: "Encryption key sync error (919)",
  },
  {
    value: "920",
    display: "Security software/hardware error – try again (920)",
  },
  {
    value: "921",
    display: "Security software/hardware error – no action (921)",
  },
  {
    value: "922",
    display: "display number out of sequence (922)",
  },
  {
    value: "923",
    display: "Request in progress (923)",
  },
  {
    value: "940",
    display: "Unknown terminal (940)",
  },
  {
    value: "942",
    display: "Invalid Reconciliation Date (942)",
  },
  {
    value: "943",
    display: "Transaction service is not supported for GCCNet cards (943)",
  },
  {
    value: "944",
    display:
      "The requested DCC Currency is invalid or no longer supported (944)",
  },
  {
    value: "945",
    display:
      "The Acquirer ID, Merchant ID, Store ID or Department ID specified in the DCC Rate Inquiry is not enabled for the DCC service (945)",
  },
  {
    value: "946",
    display: "Acquirer timed out (946)",
  },
];

export const statusCodes: HttpStatus[] = [
  { display: "CONTINUE (100)", value: 100 },
  { display: "SWITCHING_PROTOCOLS (101)", value: 101 },
  { display: "PROCESSING (102)", value: 102 },
  { display: "EARLYHINTS (103)", value: 103 },
  { display: "OK (200)", value: 200 },
  { display: "CREATED (201)", value: 201 },
  { display: "ACCEPTED (202)", value: 202 },
  { display: "NON_AUTHORITATIVE_INFORMATION (203)", value: 203 },
  { display: "NO_CONTENT (204)", value: 204 },
  { display: "RESET_CONTENT (205)", value: 205 },
  { display: "PARTIAL_CONTENT (206)", value: 206 },
  { display: "AMBIGUOUS (300)", value: 300 },
  { display: "MOVED_PERMANENTLY (301)", value: 301 },
  { display: "FOUND (302)", value: 302 },
  { display: "SEE_OTHER (303)", value: 303 },
  { display: "NOT_MODIFIED (304)", value: 304 },
  { display: "TEMPORARY_REDIRECT (307)", value: 307 },
  { display: "PERMANENT_REDIRECT (308)", value: 308 },
  { display: "BAD_REQUEST (400)", value: 400 },
  { display: "UNAUTHORIZED (401)", value: 401 },
  { display: "PAYMENT_REQUIRED (402)", value: 402 },
  { display: "FORBIDDEN (403)", value: 403 },
  { display: "NOT_FOUND (404)", value: 404 },
  { display: "METHOD_NOT_ALLOWED (405)", value: 405 },
  { display: "NOT_ACCEPTABLE (406)", value: 406 },
  { display: "PROXY_AUTHENTICATION_REQUIRED (407)", value: 407 },
  { display: "REQUEST_TIMEOUT (408)", value: 408 },
  { display: "CONFLICT (409)", value: 409 },
  { display: "GONE (410)", value: 410 },
  { display: "LENGTH_REQUIRED (411)", value: 411 },
  { display: "PRECONDITION_FAILED (412)", value: 412 },
  { display: "PAYLOAD_TOO_LARGE (413)", value: 413 },
  { display: "URI_TOO_LONG (414)", value: 414 },
  { display: "UNSUPPORTED_MEDIA_TYPE (415)", value: 415 },
  { display: "REQUESTED_RANGE_NOT_SATISFIABLE (416)", value: 416 },
  { display: "EXPECTATION_FAILED (417)", value: 417 },
  { display: "I_AM_A_TEAPOT (418)", value: 418 },
  { display: "MISDIRECTED (421)", value: 421 },
  { display: "UNPROCESSABLE_ENTITY (422)", value: 422 },
  { display: "FAILED_DEPENDENCY (424)", value: 424 },
  { display: "PRECONDITION_REQUIRED (428)", value: 428 },
  { display: "TOO_MANY_REQUESTS (429)", value: 429 },
  { display: "INTERNAL_SERVER_ERROR (500)", value: 500 },
  { display: "NOT_IMPLEMENTED (501)", value: 501 },
  { display: "BAD_GATEWAY (502)", value: 502 },
  { display: "SERVICE_UNAVAILABLE (503)", value: 503 },
  { display: "GATEWAY_TIMEOUT (504)", value: 504 },
  { display: "HTTP_VERSION_NOT_SUPPORTED (505)", value: 505 },
];

export const webhookEvents: selectDate[] = [
  {
    value: "app.supported.sdk.removed",
    display: "app supported sdk removed",
  },
  {
    value: "terminal.reconciled",
    display: "terminal reconciled",
  },
  {
    value: "terminal.user.unassigned",
    display: "terminal user unassigned",
  },
  {
    value: "terminal.user.assigned",
    display: "terminal user assigned",
  },
  {
    value: "sdk.created",
    display: "sdk created",
  },
  {
    value: "app.supported.sdk.release.upgraded",
    display: "app supported sdk release upgraded",
  },
  {
    value: "client.admin.created",
    display: "client admin created",
  },
  {
    value: "merchant.created",
    display: "merchant created",
  },

  {
    value: "terminal.device.connected",
    display: "terminal device connected",
  },
  {
    value: "terminal.registered",
    display: "terminal registered",
  },
  {
    value: "merchant.assigned.admin",
    display: "merchant assigned admin",
  },
  {
    value: "device.created",
    display: "device created",
  },
  {
    value: "transaction.approved",
    display: "transaction approved",
  },
  {
    value: "app.supported.sdk.added",
    display: "app supported sdk added",
  },
  {
    value: "merchant.revoked.admin",
    display: "merchant revoked admin",
  },
  {
    value: "terminal.device.disconnected",
    display: "terminal device disconnected",
  },
  {
    value: "terminal.updated.configurations",
    display: "terminal updated configurations",
  },
  {
    value: "app.created",
    display: "app created",
  },
  {
    value: "transaction.reversed",
    display: "transaction reversed",
  },
  {
    value: "transaction.confirmed",
    display: "transaction confirmed",
  },
  {
    value: "transaction.rejected",
    display: "transaction rejected",
  },
];
