import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  listType?: string;
  setIsFull: React.Dispatch<React.SetStateAction<boolean>>;
  isFull: boolean;
};

const IsFullBtn: FC<Props> = ({ listType, isFull, setIsFull }) => {
  const { t } = useTranslation();
  return listType ? (
    <div
      className={
        "flex self-start" +
        (listType == "reconciliations" ? " group-[.terminal]:hidden" : "")
      }
    >
      <button
        onClick={() => setIsFull(true)}
        className={
          (isFull && "bg-gray-300 ltr:-mr-3 rtl:-ml-3") +
          " border-2 border-gray-300  rounded-lg px-6 py-1 text-sm"
        }
      >
        {t("full")}
      </button>
      <button
        onClick={() => setIsFull(false)}
        className={
          (!isFull && "bg-gray-300 ltr:-ml-3 rtl:-mr-3") +
          " border-2 border-gray-300  rounded-lg  px-4 py-1 text-sm"
        }
      >
        {t("compact")}
      </button>
    </div>
  ) : null;
};

export default IsFullBtn;
