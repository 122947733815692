import {
  Context,
  FC,
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
} from "react";
import { applyTheme } from "../themes";
import baseTheme from "../themes/base";
import {
  fetchClientConfigurationApi,
  fetchConfig,
} from "@lib/utils/api/configuration-apis";

export type ThemeContextType = {
  client: {
    id: string;
    name: string;
    logo: string;
    export_data_type: string;
  };
  landingPageTheme: {
    invitation_link: string;
    logo: string;
    primary_color: string;
    secondary_color: string;
    theme: string;
  };
};

const ThemeContext: Context<ThemeContextType | null> =
  createContext<ThemeContextType | null>(null);

export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState(baseTheme);
  const [client, setClient] = useState({
    id: "",
    name: "",
    logo: "",
    export_data_type: "",
  });
  const [landingPageTheme, setLandingPageTheme] = useState({
    invitation_link: "",
    logo: "",
    primary_color: "",
    secondary_color: "",
    theme: "",
  });

  const getClientConfig = async () => {
    const { primary_color, secondary_color, name, logo, id, export_data_type } =
      (window as any)?.merchantConfiguration;
    // await fetchClientConfigurationApi();
    setTheme({
      "--theme-primary": primary_color,
      "--theme-secondary": secondary_color,
    });
    applyTheme({
      "--theme-primary": primary_color,
      "--theme-secondary": secondary_color,
    });
    setClient({ id, name, logo, export_data_type });
  };

  const fetchConfigCall = async () => {
    const response = await fetchConfig();
    setLandingPageTheme(response);
  };

  useEffect(() => {
    getClientConfig();
  }, []);

  useEffect(() => {
    if (client.id) fetchConfigCall();
  }, [client.id]);

  return (
    <ThemeContext.Provider value={{ client, landingPageTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
