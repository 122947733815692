import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext, { UserContextType } from "../../../contexts/UserContext";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { MerchantIcon, Modal, SearchIcon } from "@lib";
import { MerchantsSwitchItem } from "@lib/list-item/merchants-switch";
import { GroupedListV2 } from "@lib/list/grouped-list";
import {
  getTokenApi,
  listAccessableMerchantsApi,
} from "@lib/utils/api/merchants-apis";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  selectMerchant: (switchMerchant: boolean) => void;
  adminId: string;
  addToken?: (accessToken: string, refreshToken: string) => void;
};

const SwitchMerchant: FC<Props> = ({
  isOpen = false,
  toggleModal,
  selectMerchant,
  adminId,
  addToken,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState<any[]>([]);
  const [pages, setPages] = useState(1);
  const { t } = useTranslation();
  const { auth } = useContext(UserContext) as UserContextType;
  const listMerchants = async ({
    page = 1,
    name,
  }: {
    page?: number;
    name?: string;
  }) => {
    try {
      setIsLoading(true);
      const response = await listAccessableMerchantsApi({
        admin_id: adminId,
        page,
        name,
      });
      setMerchants(response.merchants);
      setPages(response.pages);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getToken = async (merchant_id: string) => {
    try {
      setIsLoading(true);
      await getTokenApi({
        merchant_id,
        admin_id: adminId,
      }).then(({ access_token, refresh_token }) => {
        if (access_token && refresh_token) {
          addToken?.(access_token, refresh_token);
        }
      });

      selectMerchant(true);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  let debounceFn: number | any;
  useEffect(() => {
    listMerchants({});
  }, []);

  return (
    <Modal
      close={toggleModal}
      isOpen={isOpen}
      icon={MerchantIcon}
      title={t("selectMerchant")}
      subTitle={t("merchant")}
    >
      <>
        <LoadingSpinner isLoading={isLoading} isTransparent />
        <div className="relative flex items-center my-2">
          <input
            type="search"
            onChange={(e) => {
              clearTimeout(debounceFn);
              debounceFn = setTimeout(() => {
                if (e.target.value)
                  listMerchants({ name: e.target.value.trim() });
              }, 1000);
            }}
            placeholder={t("search by name")}
            className="peer w-full flex items-center  placeholder:flex placeholder:items-center placeholder:text-gray-400 placeholder:text-xs rounded-lg py-1 mb-1 border-gray-300 invalid:border-red-500 invalid:ring-red-500"
          />
          <SearchIcon
            size={18}
            containerClassNames="absolute rtl:left-0 ltr:right-0 top-0 hidden peer-placeholder-shown:inline"
            color="text-gray-400"
          />
        </div>
        <GroupedListV2
          pages={{
            total: pages,
            selectPage: (page: number) => listMerchants({ page }),
          }}
          role={auth?.role}
          data={merchants}
          component={MerchantsSwitchItem}
          onItemClick={(item) => getToken(item?.id)}
          groupBy={{
            key: "created_at",
            isDate: true,
          }}
          hideKey={true}
        />
      </>
    </Modal>
  );
};

export default SwitchMerchant;
