import React, { FC, useState } from "react";
import Content from "@lib/layout/content";
import ViewReports from "@lib/components/Reports/View";
import { t } from "i18next";

type Props = {};

const Reports: FC<Props> = ({}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Content title={t("reports")} isLoading={isLoading}>
      <ViewReports isLoading={isLoading} setIsLoading={setIsLoading} />
    </Content>
  );
};

export default Reports;
