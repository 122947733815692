import { ElementType, FC, Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  close: () => void;
  title: string;
  subTitle: string;
  icon: ElementType;
  width?: "sm" | "md" | "lg" | "xl" | "2xl" | "4xl" | "6xl";
  contentNoPadding?: boolean;
  ContainerClassNames?: string;
};

export const Modal: FC<PropsWithChildren<Props>> = ({
  isOpen,
  close,
  title,
  subTitle,
  icon: Icon,
  width = "xl",
  children,
  contentNoPadding,
  ContainerClassNames,
}) => {
  const { i18n } = useTranslation();
  const size: { [key: string]: string } = {
    sm: "max-w-sm",
    lg: "max-w-md",
    md: "max-w-lg",
    xl: "max-w-xl",
    "2xl": "max-w-2xl",
    "4xl": "max-w-4xl",
    "6xl": "max-w-6xl",
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-30 inset-0 overflow-y-auto transition-all ease-linear delay-100 py-[20px] ${ContainerClassNames}`}
        onClose={() => {}}
      >
        <div className=" inset-0  backdrop-blur-sm fixed"></div>

        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={
                i18n.language == "ar"
                  ? `relative inline-block align-bottom bg-white rounded-lg text-right shadow-xl transform transition-all sm:align-middle sm:max-w-2xl sm:w-full lg:max-w-xl  group-[.create-terminal]:max-w-6xl z-50`
                  : `relative inline-block align-bottom  bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle sm:max-w-2xl sm:w-full lg:max-w-xl  group-[.create-terminal]:max-w-6xl z-50`
              }
            >
              <div className="flex flex-row items-center p-4 gap-2 border-b-2">
                <div className="hidden lg:flex flex-initial text-lg">
                  <Icon size={32} />
                </div>
                <div className="flex flex-col flex-1">
                  <span className="text-gray-500 font-medium text-xs">
                    {subTitle}
                  </span>
                  <span className="text-black font-bold text-md lg:text-lg">
                    {title}
                  </span>
                </div>
                <div className="flex-initial pr-2 z-50">
                  <button
                    onClick={close}
                    className="bg-gray-300 text-black lg:p-2 p-1 rounded-full"
                  >
                    <XMarkIcon className="h-3 lg:h-4" />
                  </button>
                </div>
              </div>
              {/* px-4 pt-5 pb-4 sm:my-8 sm:align-middle sm:w-full sm:p-6 */}
              <div
                className={`${
                  contentNoPadding ? "" : "p-6"
                } bg-white rounded-lg`}
              >
                {children}
              </div>
              {/* <div className="mt-5 flex justify-end space-x-2">{footer}</div> */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
