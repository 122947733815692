import { TableHead, TableHeaderCell, TableRow } from "@tremor/react";
import { t } from "i18next";
import React from "react";

export const TransactionsHeader = () => {
  return (
    <TableHead className="border-b !sticky top-0">
      <TableRow className="propagate-header bg-white">
        <TableHeaderCell className=" rtl:text-right ">
          {t("type")}
        </TableHeaderCell>
        <TableHeaderCell className="text-right">{t("amount")}</TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">
          {t("status")}
        </TableHeaderCell>
        <TableHeaderCell className="text-center px-2">
          {t("reconciled")}
        </TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">RRN & STAN</TableHeaderCell>
        <TableHeaderCell className="rtl:text-right ">
          {t("card")}
        </TableHeaderCell>
        <TableHeaderCell className=" px-6 rtl:text-right">
          {t("bank")}
        </TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">
          {t("date")}
        </TableHeaderCell>
        <TableHeaderCell className="p-1 rtl:text-right">
          {t("merchant")}
        </TableHeaderCell>
        {/* <TableHeaderCell className="rtl:text-right">
          {t("terminal")}
        </TableHeaderCell> */}
        <TableHeaderCell className="rtl:text-right">
          {t("user")}
        </TableHeaderCell>
      </TableRow>
    </TableHead>
  );
};
