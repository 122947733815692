import { ReactComponent as PaymentSchemeLogos } from "../../assets/payment-schemes.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import Storage from "@lib/utils/storage";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { useContext, useEffect, useState } from "react";
import { Button, EmptyState, Errors } from "@lib";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { refreshAccessTokenApi } from "@lib/utils/api/auth-apis";
import storage from "@lib/utils/storage";
import { listPlatformsApi } from "@lib/utils/api/onboarding-apis";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

const SelectPlatform = () => {
  const { promiseInProgress: isLoading } = usePromiseTracker();
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { addToken, auth } = useContext(UserContext) as UserContextType;
  const [platFormList, setPlatformList] = useState([]);
  const [errors, setErrors] = useState();

  useEffect(() => {
    getPlatformList();
    return () => {};
  }, []);

  const refreshToken = async () => {
    await trackPromise(refreshAccessTokenApi(storage.get("refreshToken"))).then(
      (result) => {
        addToken(result.accessToken, storage.get("refreshToken"));
      }
    );
  };

  const onClickHandler = async (e: any) => {
    e?.preventDefault();
    await refreshToken();
  };
  const getPlatformList = async () => {
    const admin = auth.user;
    await trackPromise(listPlatformsApi({ admin_id: admin.id })).then((res) => {
      try {
        setPlatformList(res);
      } catch (error: any) {
        setErrors(error);
      }
    });
  };
  function logout() {
    Storage.remove("accessToken");
    Storage.remove("refreshToken");
    Storage.remove("clientId");
    Storage.remove("admin");
    (window as any).Intercom?.("shutdown");
    (window as any).Intercom?.("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_KEY as string,
    });
    navigate("/");
  }

  return (
    <div
      style={{ padding: "20px" }}
      className="sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form
            className=" flex flex-1 flex-col justify-center "
            style={{ maxHeight: "700px", flex: 9 }}
          >
            <>
              <span className="text-5xl font-bold py-8">{t("platforms")}</span>
              {platFormList.map((item: any) => {
                return (
                  <Button
                    isRounded={false}
                    key={item.id}
                    colorScheme={`${item.isSelected ? "blue" : "default"}`}
                    onClick={onClickHandler}
                    className="px-8 py-2"
                    label={item.name}
                  />
                );
              })}
            </>
          </form>
          <div className="pt-4 text-center flex justify-end w-full">
            <div className="max-w-200px">
              <Button
                label={t("logout") as string}
                onClick={logout}
                classNames="w-full py-2 px-4 mt-4"
                colorScheme="black"
                isRounded={false}
              />
            </div>
          </div>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>

      {/* <div className="flex flex-col flex-1 max-w-2xl m-4 p-4">
    <div className="logo flex-initial">
      <Logo />
    </div>
    
    <div className="flex-initial p-4">
      <span>Logos</span>
    </div>
  </div> */}
    </div>
  );
};

export default SelectPlatform;
