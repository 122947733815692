import { trackPromise } from "react-promise-tracker";
import Api from ".";

interface MapSDKPackage {
  package_id: string;
  sdk_version_id: string;
}

export interface App {
  id?: string;
  name: string;
  app_name: string;
  merchant_id?: string;
}
export const listAppsApi = async ({ page, limit }: any) => {
  try {
    const response = await Api({
      method: "get",
      url: `/apps?limit=${limit}&page=${page}`,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const unassignSDKApi = async ({
  package_id,
  sdk_version_id,
}: MapSDKPackage) => {
  try {
    const response = await Api({
      method: "DELETE",
      url: `/apps/${package_id}/${sdk_version_id}`,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const assignSDKApi = async ({
  package_id,
  sdk_version_id,
}: MapSDKPackage) => {
  try {
    const response = await Api({
      method: "PATCH",
      url: `/apps/${package_id}/${sdk_version_id}`,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const findAppApi = async ({ id }: { id: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: `/apps/${id}`,
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getReleasesAppPageApi = async ({
  page = 1,
  limit = 5,
}: {
  page: number;
  limit: number;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/apps/releases",
        params: {
          page,
          limit,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getPackageReleasesApi = async ({ id, packageReleases }: any) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: `/releases/${id}`,
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getAllPackageReleasesApi = async ({ id }: any) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: `/app/releases`,
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateAppApi = async ({
  app_name,
  name,
  id,
  merchant_id,
}: App) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: `/apps`,
        data: {
          id,
          name,
          package_name: app_name,
          merchant_id,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const createAppApi = async ({ name, app_name, merchant_id }: App) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: `/apps`,
        data: {
          name,
          app_name,
          merchant_id,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const changeCurrentReleaseApi = async ({
  release_id,
  sdk_version_id,
  package_id,
}: {
  release_id: string;
  sdk_version_id: string;
  package_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: `/app/current/release`,
        data: {
          release_id,
          sdk_version_id,
          package_id,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const listSDKsApi = async () => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: `/sdk-versions`,
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
