import { Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React, { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, ExclamationIcon } from "../../lib";

type ErrorSource = { arabic: string; english: string; color: "#000000" };

enum ErrorLevels {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export interface IValidationError {
  [key: string]: string[];
}

export interface INiceError {
  code_text: string;
  code: number;
  error: {
    arabic: string;
    english: string;
  };
  solution: {
    arabic: string;
    english: string;
  };
  level: ErrorLevels;
  source: ErrorSource;
}

export interface IError {
  errorKey?: string;
  error: IErrorBody | null | undefined;
}

interface IErrorBody {
  type: "niceError" | "validationError";
  body: IValidationError | INiceError;
}

export const NiceError: FC<IError> = ({ error, errorKey }) => {
  if (!error) return null;

  if (error.type == "niceError") return <NiceErrorComponent error={error} />;
  if (error.type == "validationError")
    return <ValidationErrorComponent error={error} errorKey={errorKey} />;

  return null;
};

export const NiceErrorComponent: FC<IError> = ({ error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  if (!error) return null;

  const niceError: INiceError = error.body as INiceError;
  const language = i18n.language === "ar" ? "arabic" : "english";

  const toggleDetails = () => setIsOpen((prevState: boolean) => !prevState);

  return (
    <>
      <button
        onClick={toggleDetails}
        className="flex items-center text-left rounded-md bg-red-50 p-2 list-disc gap-y-1 text-sm text-red-700 gap-x-2 w-full"
      >
        <ExclamationIcon color="text-red-700" size={18} />
        <span>{niceError.error[language]}</span>
      </button>
      {isOpen && (
        <div className="-mt-1 pt-3 flex flex-col border-l border-r border-b rounded-b-md p-2 text-xs text-gray-600 border-gray-300">
          <div className="flex items-center gap-x-2 flex-1">
            <span className="font-extrabold text-sm">Solution</span>
            <span className="bg-black text-white rounded-lg px-2 text-xs">
              {niceError.code.toString()}
            </span>
          </div>
          <span>{niceError.solution[language]}</span>
        </div>
      )}
    </>
  );
};

export const ValidationErrorComponent: FC<IError> = ({ error, errorKey }) => {
  if (!error || !errorKey) return null;
  const validationError: IValidationError = error.body as IValidationError;

  return (
    <>
      {(validationError[errorKey] || []).map((error, i) => (
        <div
          key={i}
          className="rounded-md bg-red-50 p-2 mt-2 list-disc gap-y-1 text-sm text-red-700"
        >
          <span>{error}</span>
        </div>
      ))}
    </>
  );
};
