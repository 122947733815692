import { trackPromise } from "react-promise-tracker";
import Api, { List } from ".";

interface Admins extends List {
  merchant_id?: number;
  queryParams: { [key: string]: any };
}
interface Admin {
  id: string;
}
interface AdminResponse {
  name: string;
  mobile: string;
  is_active: boolean;
  created_at: string;
}
type admin = {
  name: string;
  mobile: string;
  email: string;
  type: string;
  bank_id: string;
};
export type NewAdmin = {
  name: string;
  mobile: string;
  email: string;
  type: string;
  bank_id: string;
};
export const initialAdminState: NewAdmin = {
  name: "",
  mobile: "",
  email: "",
  type: "",
  bank_id: "",
};

export const listAdminsApi = async ({
  limit = 30,
  page = 1,
  merchant_id,
  queryParams,
}: Admins) => {
  let encodedQueryParams = btoa(JSON.stringify(queryParams));

  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "admins",
        params: {
          limit,
          page,
          merchant_id,
          queryParams: encodedQueryParams,
        },
      })
    );
    return response.data as { pages: any; admins: AdminResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findAdminApi = async ({ id }: Admin) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "admins/" + id,
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const createAdminApi = async (admin: admin) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "admins/",
        data: {
          ...admin,
          mobile: admin.mobile,
          type: "client",
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const revokeAdminsAccessApi = async (admin_id: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "admin/revoke",
        data: {
          admin_id,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
