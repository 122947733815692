import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Input, Invite, Modal, Switch, UserIcon } from "@lib";
import {
  countriesOptions,
  defaultCountriesOption,
} from "../../utils/countriesOptions";
import { assginTerminalApi } from "@lib/utils/api/terminals-apis";
import {
  createTerminalUserApi,
  createUserApi,
  findUserByMobileApi,
  UserResponse,
} from "@lib/utils/api/users-apis";
import { usePromiseTracker } from "react-promise-tracker";

type Props = {
  isOpen: boolean;
  toggleModal: any;
  terminalId: string;
  refresh: () => void;
  merchant_id: string;
};
const AssignTerminalToUserModal = ({
  isOpen,
  toggleModal,
  terminalId,
  refresh,
  merchant_id,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [createUserErrors, setCreateUserErrors] = useState<any>({});
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const [name, setName] = useState<string>("");
  const [sendSMSMessage, setSendSMSMessage] = useState(false);
  const { t } = useTranslation();
  const findUser = async () => {
    setIsLoading(true);
    const { users } = await findUserByMobileApi({ mobile });
    setUsers(users);
    setIsSearchTriggered(true);
    setIsLoading(false);
  };

  const { promiseInProgress } = usePromiseTracker();

  const assignToUser = async (id: string, mobile: string) => {
    try {
      setIsLoading(true);
      await assginTerminalApi({
        id: terminalId,
        user_id: id,
        mobile,
        sendSMS: sendSMSMessage,
      });
      refresh();
      setIsLoading(false);
      close();
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const close = () => {
    setIsLoading(false);
    setUsers([]);
    setName("");
    setMobile("");
    setEmail("");
    setSendSMSMessage(false);
    setIsSearchTriggered(false);
    toggleModal();
  };
  const updateSendSMSMessage = (e: boolean) => {
    setSendSMSMessage((prev) => !prev);
  };

  const inviteUser = async () => {
    const user = await createTerminalUserApi({
      name,
      email,
      mobile,
      merchant_id,
    }).catch((error) => {
      setCreateUserErrors(error);
      return;
    });

    await assginTerminalApi({
      user_id: user.id,
      mobile,
      id: terminalId,
      sendSMS: sendSMSMessage,
    });
    close();
    refresh();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t("assignUser")}
      subTitle={t("user")}
      icon={UserIcon}
    >
      <LoadingSpinner
        isLoading={isLoading || promiseInProgress}
        isTransparent={true}
      />
      <div className="flex flex-col lg:flex-row lg:gap-2 items-center">
        <div className="flex-1">
          <Input
            type="phone"
            label={t("searchByMobile")}
            defaultDropDownOption={defaultCountriesOption}
            dropDownOptions={countriesOptions}
            dropDownValueKey="dial_code"
            dropDownDisplayKey="display_name"
            errors={createUserErrors?.mobile}
            name="User Mobile"
            value={mobile}
            onChange={(e) => setMobile(e)}
          />
        </div>
        <div className="flex-1 lg:flex-initial lg:w-32 flex justify-end items-end pt-4">
          <Button
            onClick={findUser}
            isRounded={false}
            isDisabled={mobile.length < 4}
            colorScheme="skyblue"
            className={"px-2 py-1 text-blue-500"}
            label={t("search") as string}
          />
        </div>
      </div>
      {isSearchTriggered ? (
        <>
          <div className="flex my-2 mb-4 items-center">
            <Switch
              isEnabled={sendSMSMessage}
              setIsEnabled={updateSendSMSMessage}
            />
            <label className="min-w-max mx-4" htmlFor="sendMessageCheckbox">
              {t("sendSMSInvitationMessage")}
            </label>
          </div>
          <div className="flex flex-col gap-2">
            <UserCard
              users={users}
              mobile={mobile}
              email={email}
              userErrors={createUserErrors}
              setEmail={setEmail}
              assignToUser={assignToUser}
              inviteUser={inviteUser}
              name={name}
              setName={setName}
            />
          </div>
        </>
      ) : null}
    </Modal>
  );
};

type UserCardType = {
  users: UserResponse[];
  assignToUser?: (id: string, mobile: string) => void;
  mobile?: string;
  userErrors: any;
  inviteUser: any;
  name: string;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
};

const UserCard: FC<UserCardType> = ({
  users,
  assignToUser,
  mobile = "",
  userErrors,
  inviteUser,
  name,
  setName,
  email,
  setEmail,
}) => {
  const { t, i18n } = useTranslation();
  if (!users.length) {
    return (
      <div className="flex flex-col flex-1">
        <Input
          label={t("name")}
          type="text"
          name="name"
          errors={userErrors?.name}
          onChange={(e) => {
            setName(e);
          }}
          value={name as string}
        />
        <Input
          label={t("email")}
          name="email"
          errors={userErrors?.email}
          onChange={(e) => {
            setEmail(e);
          }}
          value={email as string}
        />
        <div className="flex flex-1 items-end justify-end">
          <div className="w-60">
            <Button
              onClick={inviteUser}
              size="sm"
              isDisabled={mobile.length != 13}
              colorScheme="black"
              label={
                <div className="flex flex-initial items-center justify-center ">
                  <Invite color="fill-white" size={20} />
                  {i18n.language == "ar" ? (
                    <span className={"text-xs ltr"}>
                      {mobile ? mobile : "User"} {t("invite")}
                    </span>
                  ) : (
                    <span className={"text-xs"}>
                      {t("invite")} {mobile ? mobile : "User"}
                    </span>
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {users.map((user: UserResponse, index: number) => (
        <div
          key={index}
          className="border-2 border-gray-300 shadow-sm rounded-md py-2 px-2 flex-1 flex flex-row justify-between"
        >
          <div className="flex flex-col">
            <div className="font-bold text-sm">{user?.name}</div>
            <div className="text-xs text-gray-600">{user?.mobile}</div>
          </div>
          <button
            onClick={() =>
              assignToUser ? assignToUser(user.id, user.mobile) : {}
            }
            className="bg-blue-300 hover:bg-blue-500 rounded-full px-4 py-0"
          >
            <span className="text-xs text-white hover:text-blue-100">
              Select
            </span>
          </button>
        </div>
      ))}
    </>
  );
};

export default AssignTerminalToUserModal;
