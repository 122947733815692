import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListApps from "@lib/components/apps/list";
import { App, initialAppState } from "@lib/components/apps/view";
import ManageAppModal from "@lib/components/modals/manage-app";
import UserContext, { UserContextType } from "../../contexts/UserContext";

import Content from "@lib/layout/content";
import { Button } from "@lib";
import { listSDKsApi } from "@lib/utils/api/apps-api";
import { IconButton } from "@lib/components/buttons/iconButton";
import { PlusSmallIcon } from "@heroicons/react/24/outline";

interface Props {}

const Apps = ({}: Props) => {
  const { t } = useTranslation();
  const [refreshList, setRefreshList] = useState(false);
  const [newApp, setNewApp] = useState<App>(initialAppState);
  const [sdks, setSdks] = useState<any>([]);
  const [isCreateNewAppModalOpen, setIsCreateNewAppModalOpen] = useState(false);
  const toggleModal = () => {
    setIsCreateNewAppModalOpen((prev) => !prev);
  };
  const { auth } = useContext(UserContext) as UserContextType;

  const handleCreateNewAppButtonClick = (
    _?: MouseEventHandler<HTMLButtonElement>
  ) => {
    setIsCreateNewAppModalOpen(true);
  };

  // const fetchSdks = async () => {
  //   try {
  //     const response = await listSDKsApi();
  //     setSdks(response);
  //   } catch (e) {
  //     console.log({ e });
  //   }
  // };

  // useEffect(() => {
  //   fetchSdks();
  // }, []);

  return (
    <Content
      title={t("apps")}
      actionBtns={
        <IconButton
          title="addNewApp"
          Icon={PlusSmallIcon}
          onClick={() => handleCreateNewAppButtonClick()}
        />
      }
    >
      <ListApps
        refresh={refreshList}
        setRefresh={setRefreshList}
       
      />
      <ManageAppModal
        toggleModal={toggleModal}
        isOpen={isCreateNewAppModalOpen}
        setRefresh={setRefreshList}
        app={newApp}
        setApp={setNewApp}
        sdks={sdks}
      />
    </Content>
  );
};

export default Apps;
