import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { EmptyState, GroupedList, PoliciesListItem } from "@lib";
import { PolicyJobsListItem } from "@lib/list-item/policy-job-list";
import { listPolicyJobsApi } from "@lib/utils/api/policies-apis";

type Props = {
  refresh: boolean;
  limit?: number;
};

export const ListPolicyJobs: FC<Props> = ({ refresh, limit }: Props) => {
  const { id } = useParams();

  const [policyJobs, setPolicyJobs] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const fetchPolicies = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listPolicyJobsApi(id as string, page);
      setPages(response.pages);
      setPolicyJobs(response.policyJobs);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPolicies();
  }, [id]);
  return policyJobs?.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchPolicies(page),
      }}
      role={"client"}
      data={policyJobs}
      component={PolicyJobsListItem}
      onItemClick={(item) => item}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      isLoading={isLoading}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};
