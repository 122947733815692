import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList } from "@lib";
import { UserListItem } from "@lib/list-item/user-list";
import { listUsersApi } from "@lib/utils/api/users-apis";
import { createFilterObject, UsersFilters } from "../filters";

type Props = {
  limit?: number;
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  merchant_id?: string;
  activeTab?: string;
  revokeAccess?: (user: any) => void;
  queryParams?: {
    [key: string]: any;
  };
};

const ListUsers: FC<Props> = ({
  refresh,
  merchant_id,
  activeTab,
  limit = 10,
  revokeAccess,
  queryParams = {},
}: Props) => {
  const [users, setUsers] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const fetchUsers = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listUsersApi({
        page,
        filter: createFilterObject(activeTab ? activeTab : "all", UsersFilters),
        merchant_id,
        limit,
        queryParams: queryParams as {
          [key: string]: any;
        },
      });
      setPages(response.pages);
      setUsers(response.users);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [refresh, activeTab]);

  return users.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchUsers(page),
      }}
      role={"client"}
      data={users}
      component={UserListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
        order: "desc",
      }}
      actionButtonOnClick={
        revokeAccess ? (e, user) => revokeAccess(user) : undefined
      }
      isLoading={isLoading}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListUsers;
