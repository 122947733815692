import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Filters } from "../components/filters";
import BackButton from "../components/buttons/back-button";
import LoadingSpinner from "./loading-spinner";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Filter } from "@lib/icons";
import { IconButton } from "@lib/components/buttons/iconButton";

type Props = {
  children: ReactNode;
  actionBtns?: ReactElement;
  isLoading?: boolean;
  isLoadingContainerTransparent?: boolean;
  activeTab?: string;
  tabs?: Filters[];
  setActiveTab?: any;
  queryBuilder?: ReactNode;
  title?: string;
  subTitle?: string;
  hasBackButton?: boolean;
};

const Content: FC<Props> = ({
  children,
  title,
  subTitle,
  actionBtns,
  isLoading = false,
  isLoadingContainerTransparent = false,
  tabs,
  setActiveTab,
  queryBuilder,
  activeTab = "All",
  hasBackButton = false,
}: Props) => {
  const { t } = useTranslation();
  function camelCase(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // useEffect(() => {
  //   if (value) {
  //     // Add class to body when 'value' is true
  //     document.body.classList.add("no-scrollbar");
  //   } else {
  //     // Remove class from body when 'value' is false
  //     document.body.classList.remove("no-scrollbar");
  //   }

  //   // Cleanup function to remove the class when the component unmounts
  //   return () => {
  //     document.body.classList.remove("no-scrollbar");
  //   };
  // }, [value]);
  const queryBuilderElement = queryBuilder as ReactElement;
  const query = queryBuilderElement?.props?.queryParams;
  const queryLength = query ? Object.keys(query)?.length : 0;

  const dateRange = queryBuilderElement?.props?.dateRange;
  const isDateFiltered =
    dateRange &&
    Object.keys(dateRange).length > 0 &&
    dateRange.to !== undefined &&
    dateRange.from !== undefined;

  return (
    <div className={"flex flex-col !w-full"}>
      <div>
        {hasBackButton ? (
          <div className="flex gap-3 items-center sm:ps-6 h-[8vh] sm:px-0 px-16 ">
            <BackButton />
            <div className="text-sm font-bold">{t("goBack") as string} </div>
          </div>
        ) : null}

        <div className="flex flex-col relative sm:!ps-4 sm:pt-2 calculate-content ">
          <div>
            {title?.length ? (
              <>
                <div
                  className={
                    tabs
                      ? "px-4 sm:px-6 border-b h-[12vh]"
                      : "px-4 sm:px-6 border-b h-[8vh] "
                  }
                >
                  <div
                    className={
                      tabs
                        ? "flex flex-row items-end h-full justify-between"
                        : "flex flex-row items-center h-full justify-between"
                    }
                  >
                    <div className="flex gap-2 flex-col w-full">
                      <div className="flex items-center justify-between w-full gap-2 text-2xl  font-semibold text-gray-900">
                        <div className="justify-between flex gap-2 items-center">
                          <span className="rtl:pr-10 ltr:pl-10 sm:!pl-0 sm:!pr-0 ">
                            {title}
                          </span>
                        </div>
                      </div>
                      <div className="pl-4 flex gap-4 flex-wrap">
                        {tabs?.map((tab, index) => (
                          <div
                            key={index}
                            className={
                              activeTab == tab.header
                                ? "border-b-4 border-primary"
                                : ""
                            }
                          >
                            <button
                              value={tab.header}
                              onClick={() => setActiveTab(tab.header as string)}
                              className={"px-4 py-2 font-semibold "}
                            >
                              {t(camelCase(tab.header)) as string}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {/* <div className="sm:hidden">{queryBuilder}</div> */}

          {/* <div className="4 sm:px-6 relative"></div> */}
          <div
            className={
              tabs
                ? "flex flex-row justify-between h-[88vh]"
                : "flex flex-row justify-between calculate-height"
            }
          >
            <div
              onClick={() => setIsFilterOpen(false)}
              className={
                (!isFilterOpen ? "hidden " : "") +
                "backdrop-blur-sm  bg-[#00000070] absolute w-full h-full z-40 inset-0 sm:hidden "
              }
            />
            <div
              className={
                (isFilterOpen
                  ? "sm:!w-3/5 md:!w-2/5 xl:!w-1/5 static border-l border-b"
                  : "translate-y-[110%] sm:translate-y-0 sm:!w-0 invisible") +
                " absolute sm:static w-screen sm:w-auto hide-scrollbar max-h-full h-full inset-0 mt-[30%] sm:mt-0   z-50 sm:z-0 duration-500 transition-all order-1"
              }
            >
              {queryBuilder}
            </div>
            <div
              className={
                hasBackButton
                  ? "flex flex-col w-full overflow-y-auto p-2 sm:py-4 relative "
                  : "flex flex-col w-full overflow-y-auto overflow-x-hidden relative"
              }
            >
              <div className="p-2 flex gap-2 w-full justify-between z-[25] ">
                <div className=" flex items-center w-auto">{actionBtns}</div>

                {queryBuilder && (
                  <div className="relative">
                    <IconButton
                      Icon={Filter}
                      title="filter"
                      onClick={() => {
                        setIsFilterOpen((prev) => !prev);
                      }}
                    />
                    {queryLength || isDateFiltered ? (
                      <div className="absolute -top-1.5 -right-1.5 text-xs bg-black text-white text-center flex h-[18px] w-[18px] items-center justify-center rounded-full ">
                        {queryLength + (isDateFiltered ? 1 : 0)}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              <LoadingSpinner
                zIndex={hasBackButton ? "z-50" : "z-20"}
                isLoading={isLoading}
                isTransparent={isLoadingContainerTransparent}
              />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
