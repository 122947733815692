import { trackPromise } from "react-promise-tracker";
import Api, { List } from ".";
import { dateRange } from "@lib/types/date";
interface Clients extends List {
  queryParams: { [key: string]: any };
  dateRange?: dateRange;
}

interface Client {
  id: string;
}

interface ClientUsers extends List {
  id: string;
}

interface ClientConfig {
  logo: string;
  secondary_color?: string;
  primary_color?: string;
}

interface CreateClient {
  name: string;
  vendor_id: string;
  logo?: string;
  domain?: string;
  primary_color?: string;
  secondary_color?: string;
}

export const listClientsApi = async ({
  admin_id,
  page = 1,
  limit = 10,
  name,
}: {
  admin_id?: string;
  page?: number;
  limit?: number;
  name?: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/clients/list",
        headers: { admin_id: admin_id as string },
        params: {
          page,
          limit,
          name,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const getTokenApi = async ({
  client_id,
  admin_id,
}: {
  client_id?: string;
  admin_id?: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/admin/token",
        headers: {
          admin_id: admin_id as string,
          new_client_id: client_id as string,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const findClientApi = async ({ id }: Client) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "clients/" + id,
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listClientUsersApi = async ({
  id,
  page = 1,
  limit = 30,
}: ClientUsers) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/clients/" + id + "/users",
        params: {
          page,
          limit,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const listClientMerchantsApi = async ({
  id,
  page = 1,
  limit = 30,
}: ClientUsers) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/clients/" + id + "/merchants",
        params: {
          page,
          limit,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const GetClientConfigApi = async ({
  client_id,
}: {
  client_id: string;
}): Promise<ClientConfig> => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/config/" + client_id,
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
