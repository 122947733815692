import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Switch } from "@lib";
import { MerchantIcon } from "@lib/icons";
import {
  findMerchantApi,
  MerchantResponse,
  updateManualReconcileApi,
} from "@lib/utils/api/merchants-apis";
import { revokeUserAccess } from "@lib/utils/api/users-apis";
import {
  initialUserState,
  ManageMerchant,
  ManageMerchantModal,
  ManageUser,
} from "../modals";
import ManageUserModal from "../modals/manage-user";
import ListReconciliations from "../reconciliations/list";
import ListTerminals from "../terminals/list";
import ListTransactions from "../transactions/list";
import ListUsers from "../users/list";

import MergeMerchantModal from "../../components/modals/merchant-merge-modal";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

type Props = {
  id: string;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  merchant: Partial<MerchantResponse>;
  setMerchant: React.Dispatch<React.SetStateAction<Partial<MerchantResponse>>>;
};

const ViewMerchant: FC<Props> = ({
  id,
  setIsLoading,
  isLoading,
  merchant,
  setMerchant,
}: Props) => {
  const [isManageUserModalOpen, setIsManageUserModalOpen] = useState(false);
  const [isCreateMerchantModalOpen, setIsCreateMerchantModalOpen] =
    useState(false);
  const [refreshMerchant, setRefreshMerchant] = useState(false);
  const [manualReconcile, setManualReconcile] = useState(
    merchant.manual_reconcile
  );

  const toggleEditMerchantModal = () => {
    setIsCreateMerchantModalOpen((prev) => !prev);
  };
  const [selectedUser, setSelectedUser] =
    useState<ManageUser>(initialUserState);
  const [refreshAdmins, setRefreshAdmins] = useState(false);
  const [refreshTerminals, setRefreshTerminals] = useState(false);
  const [refreshReconciliations, setRefreshReconciliations] = useState(false);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const { t } = useTranslation();
  const [isMergeMerchantModalOpen, setIsMergeMerchantModalOpen] =
    useState(false);
  const toggleMergeMerchantModal = () => {
    setIsMergeMerchantModalOpen((prev: boolean) => !prev);
  };
  const refresh = () => {
    setRefreshMerchant((prev) => !prev);
    setRefreshTerminals((prev) => !prev);
    setRefreshReconciliations((prev) => !prev);
    setRefreshTransactions((prev) => !prev);
  };
  let { moveTerminalsToNewMerchant } = useFlags();

  const revokeAccess = async (user: any) => {
    await revokeUserAccess({
      user_id: user.id,
      merchant_id: merchant.id as string,
    });
    setRefreshAdmins((prev) => !prev);
  };

  const findMerchant = async () => {
    try {
      setIsLoading(true);
      const merchant = await findMerchantApi({ id: id as string });
      setMerchant(merchant);
      setManualReconcile(merchant.manual_reconcile);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
    }
  };

  const toggleManageUserModal = () => {
    setIsManageUserModalOpen((prev) => !prev);
  };

  const updateManualReconcile = async () => {
    try {
      if (merchant.id) {
        const { manual_reconcile } = await updateManualReconcileApi({
          id: merchant.id,
          manual_reconcile: !manualReconcile,
        });

        setManualReconcile(manual_reconcile);
      }
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    findMerchant();
    setSelectedUser((prevState: ManageUser) => ({
      ...prevState,
      merchantId: String(merchant?.id),
    }));
  }, [id, refreshMerchant]);

  useEffect(() => {
    if (!isCreateMerchantModalOpen) setRefreshMerchant((prev) => !prev);
  }, [isCreateMerchantModalOpen]);

  return (
    <div>
      {/* header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="lg:p-4">
            <MerchantIcon size={28} />
          </div>
          <div className="flex flex-col items-start">
            <div className="font-bold text-md lg:text-xl">
              {merchant?.name}{" "}
              <span className="text-gray-600 text-xs before:content-['#'] empty:hidden">
                {merchant?.reference_id}
              </span>
            </div>
            <div className="text-xs lg:text-sm ltr">{"#" + merchant?.id} </div>
          </div>
        </div>
        <div className=" items-center justify-end flex flex-grow max-w-lg gap-4 ">
          <div
            className="flex justify-between items-center gap-x-2 transition-all tooltip "
            data-tip="Manual reconcile will ignore all terminal policy (Auto Reconcile) and exclude this merchant from the policy."
          >
            <Switch
              className={
                manualReconcile ? "!bg-green-700 mt-1" : "bg-gray-200 mt-1"
              }
              isEnabled={manualReconcile ? manualReconcile : false}
              // setIsEnabled={() => setManualReconcile((prev) => !prev)}
              setIsEnabled={() => updateManualReconcile()}
            />
            <label className="font-bold text-xs">Manual Reconcile</label>
          </div>
          <div className="min-w-[50px] sm:min-w-[70px] md:min-w-[120px]   flex ">
            <Button
              onClick={() => {
                toggleEditMerchantModal();
              }}
              colorScheme="black"
              label={t("edit") as string}
              className={"px-4 py-1 text-xs "}
            />
          </div>
          {moveTerminalsToNewMerchant ? (
            <div>
              <Button
                isRounded={false}
                colorScheme="red"
                size="sm"
                className="px-2 py-1 text-xs"
                label={t("mergeMerchant") as string}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleMergeMerchantModal();
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {/** Main content  */}
      <div className="flex flex-col gap-2 mt-4 lg:mt-0 group merchant">
        {/** Terminals starts here !!  */}
        <div className="flex flex-col ">
          <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold">
            {t("terminals")}
          </div>
          <ListTerminals
            limit={5}
            refresh={refreshTerminals}
            setRefresh={setRefreshTerminals}
            // setIsLoading={setIsLoading}
            merchant_id={id}
          />
        </div>
        <div className="grid grid-cols-1 lg:flex flex-col justify-between gap-4">
          <div className="flex flex-col flex-1">
            <div className="text-lg lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
              {t("transactions")}
            </div>
            <ListTransactions
              merchant_id={id}
              limit={5}
              refresh={refreshTransactions}
              // setIsLoading={setIsLoading}
            />
          </div>
          <div className="flex flex-col flex-1">
            <div className="text-lg lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
              {t("reconciliations")}
            </div>
            <ListReconciliations
              merchant_id={id}
              limit={5}
              refresh={refreshReconciliations}
              setRefresh={setRefreshReconciliations}
              // setIsLoading={setIsLoading}
            />
          </div>
        </div>
        {/* admin section !! */}
        <div className="flex flex-col ">
          <div className="flex justify-between">
            <div className="text-lg lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
              {t("owners")}
            </div>
            <div className="w-30 h-30">
              <Button
                label={t("addNewUser") as string}
                onClick={() => {
                  toggleManageUserModal();
                }}
                className={"px-2 py-1"}
                colorScheme={"skyblue"}
              />
            </div>
          </div>
          <ListUsers
            refresh={refreshAdmins}
            setRefresh={setRefreshAdmins}
            activeTab={"all"}
            // setIsLoading={setIsLoading}
            merchant_id={id}
            limit={5}
            revokeAccess={(user) => revokeAccess(user)}
          />
        </div>
      </div>
      <ManageUserModal
        isOpen={isManageUserModalOpen}
        toggleModal={toggleManageUserModal}
        merchant_id={merchant?.id}
        user={selectedUser}
        setUser={setSelectedUser}
        setRefreshUsers={setRefreshAdmins}
      />
      {merchant.id && (
        <ManageMerchantModal
          isOpen={isCreateMerchantModalOpen}
          toggleModal={toggleEditMerchantModal}
          newMerchant={merchant as ManageMerchant}
          setNewMerchant={
            setMerchant as React.Dispatch<SetStateAction<ManageMerchant>>
          }
          refreshMerchant={setRefreshMerchant}
        />
      )}

      {/* <MergeMerchantModal
        isOpen={isMergeMerchantModalOpen}
        toggleModal={toggleMergeMerchantModal}
        refresh={refresh}
        merchant={merchant}
      /> */}
    </div>
  );
};

export default ViewMerchant;
