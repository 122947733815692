import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listTerminalsApi } from "@lib/utils/api/terminals-apis";
import { EmptyState, GroupedList, TerminalListItem } from "@lib";
import { useTranslation } from "react-i18next";
import { dateRange } from "@lib/types/date";
import { GenericTable } from "./generic-table";
import TerminalHeader from "./table-headers/terminals-header";
import { TerminalRow } from "./rows/terminal-row";
type Props = {
  refresh?: boolean;
  setRefresh?: (refresh: boolean) => any;
  merchant_id?: string;
  user_id?: string;
  onRowClick?: (payload: any) => void;
  activeTab?: string;
  limit?: number;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const TerminalsTable: FC<Props> = ({
  refresh,
  merchant_id,
  user_id,
  activeTab = "all",
  limit = 30,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [terminals, setTerminals] = useState<any>([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchTerminals = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTerminalsApi({
        page,
        merchant_id,
        user_id,
        limit,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setNumberOfPages(response.pages);
      setTerminals(response.terminals);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToTerminal = (terminal: any) => navigate("/terminals/" + terminal.id);

  useEffect(() => {
    fetchTerminals(selectedPage);
  }, [refresh, activeTab, selectedPage]);

  return (
    <GenericTable
      data={terminals}
      isLoading={isLoading}
      component={TerminalRow}
      numberOfPages={numberOfPages}
      setSelectedPage={setSelectedPage}
      header={<TerminalHeader />}
    />
  );
};

export default TerminalsTable;
