import jsonexport from "jsonexport/dist";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@lib";
import { downloadTerminalsApi } from "@lib/utils/api/terminals-apis";
import ReceiptsDownloadModal from "../modals/receipts-download";
import { useContext, useState } from "react";
import UserContext, { UserContextType } from "../../../contexts/UserContext";
import { ExportTerminalType } from "../../types/export-terminals";
import { flatObject } from "@lib/utils/flat-object";
import React from "react";
import ArrowUpOnSquareIcon from "@heroicons/react/24/outline/ArrowUpOnSquareIcon";
import { IconButton } from "../buttons/iconButton";

const DownloadTerminalsButton = ({ queryParams }: any) => {
  const { t, i18n } = useTranslation();
  const [showDatemodal, setShowDateModel] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: Date.now() - 1000 * 60 * 60 * 24 * 30,
    endDate: Date.now(),
  });
  const [feedback, setFeedBack] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [withFilters, setWithFilters] = useState<boolean>(true);
  const { auth } = useContext(UserContext) as UserContextType;

  const toggleModal = () => {
    setShowDateModel((prev) => !prev);
    setFeedBack("");
  };

  const download = async () => {
    try {
      setIsLoading(true);
      setFeedBack("");

      const response = await downloadTerminalsApi({
        queryParams: withFilters ? queryParams : {},
        dateRange: {
          fromTimestamp: dateRange.startDate,
          toTimestamp: dateRange.endDate,
        },
      }).then((terminals) => {
        if (!terminals?.length) {
          setFeedBack("no terminals in this period");
          return;
        }

        terminals = terminals.map((t: any) => {
          t = {
            id: t.id,
            client_id: t.client ? t.client.id : "",
            tname: t.name,
            tid: t.tid,
            trsm: `"${t.trsm}"`,
            bank_en: t.bank ? t.bank.name.english : "",
            bank_ar: t.bank ? t.bank.name.arabic : "",
            merchant_id: t.merchant ? t.merchant.id : "",
            merchant_name_en: t.merchant ? t.merchant.name : "",
            merchant_name_ar: t.merchant ? t.merchant.name_ar : "",
            merchant_catagory: t.merchant ? t.merchant_category : "",
            merchant_currency: t.merchant ? t.currency_symbol_english : "",

            mada: t.schemes?.mada?.status ? "True" : "False",
            visa: t.schemes?.visa?.status ? "True" : "False",
            amex: t.schemes?.amex?.status ? "True" : "False",
            mc: t.schemes?.mc?.status ? "True" : "False",
            assigned: t.user ? "Assigned" : "Not Assigned",
            user_name: t.user?.name,
            user_email: t.user?.email,
            user_mobile: t.user?.mobile,
            number_of_connections: t.connections?.length,
            connected: t.connections?.length ? "True" : "False",
            address_en: t.merchant_address_1_english,
            address_ar: t.merchant_address_1_arabic,
            first_transaction: t.first_transaction ?? "No Transactions yet",
            last_transaction: t.last_transaction ?? "No Transactions yet",
            created_at: t.created_at,
          };

          let exportType = ExportTerminalType["tuma"];
          return Object.keys(exportType).reduce((acc: any, curr: string) => {
            if (curr.includes(".")) {
              const arr = curr.split(".");
              acc[exportType[curr as keyof typeof exportType]] = t[arr[0]]
                ? flatObject(arr, t[arr[0]])
                : null;
            } else {
              acc[exportType[curr as keyof typeof exportType]] = t[curr];
            }
            return acc;
          }, {});
        });

        let output: string[] = [];
        jsonexport(
          terminals,
          {
            includeHeaders: true,
            rowDelimiter: ",",
            endOfLine: "\n",
          },
          function (err: Error, csv: string) {
            if (err) return console.log(err);
            output.push(csv);
          }
        );

        output[0] = "\ufeff" + " " + output[0];

        const outFile = new File(
          output,
          "Terminals " + new Date().toDateString() + ".csv"
        );

        const link = document.createElement("a");
        const url = URL.createObjectURL(outFile);
        link.href = url;
        link.download = outFile.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toggleModal();
      });
      setIsLoading(false);
    } catch (downloadTerminalError: any) {
      setIsLoading(false);
      setFeedBack(() => {
        return i18n.language === "ar"
          ? downloadTerminalError?.error?.body?.error?.arabic
          : downloadTerminalError?.error?.body?.error?.english;
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => toggleModal()}
        Icon={ArrowUpOnSquareIcon}
        title="export"
      />

      {showDatemodal && (
        <ReceiptsDownloadModal
          feedback={feedback}
          // withFilters={withFilters}
          // setWithFilters={setWithFilters}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isModalOpen={showDatemodal}
          toggleModal={toggleModal}
          submit={download}
          isLoading={isLoading}
          title={t("downloadTerminals")}
        ></ReceiptsDownloadModal>
      )}
    </>
  );
};

export default DownloadTerminalsButton;
