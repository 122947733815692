import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import React from "react";
import ViewPolicy from "@lib/components/policies/view";

type Props = {};
const Policy: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Content isLoading={isLoading} hasBackButton>
      <ViewPolicy
        id={id as string}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    </Content>
  );
};

export default Policy;
