import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getWebhookLogApi } from "@lib/utils/api/webhooks-apis";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { WebhookMethod, WebhookStatus, WebhookURL } from ".";
import moment from "moment";

export interface WebhookLog {
  id: string;
  status_code: string;
  method: string;
  url: string;
  body: {};
  param: {};
  response: {};
  header: {};
  created_at: string;
}

type Props = {
  id: string;
};

export const LogView: FC<Props> = ({ id }: Props) => {
  // const { id } = useParams();
  const [log, setLog] = useState<WebhookLog>();

  const getWebhookLog = async (id: string | any) => {
    try {
      let response = await getWebhookLogApi(id);
      setLog(response);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    getWebhookLog(id);
  }, [id]);

  return (
    <>
      {log && (
        <div className="text-sm">
          <div className="flex flex-row items-center space-x-4 mb-2">
            <span className="text-gray-500 font-bold flex-initial">
              Method:{" "}
            </span>
            <div className="flex-initial">
              <WebhookMethod method={log.method} />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 mb-2">
            <span className="text-gray-500 font-bold flex-initial">
              Status:{" "}
            </span>
            <div className="flex-initial">
              <WebhookStatus status={log.status_code} />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 mb-2">
            <span className="text-gray-500 font-bold flex-initial">URL: </span>
            <div className="max-w-full flex-initial break-all">
              <WebhookURL url={log.url} />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 mb-2">
            <span className="text-gray-500 font-bold flex-initial">Date: </span>
            <div className="flex-initial font-mono">
              <span>
                {moment(log.created_at).format("DD-MM-YYYY")} at{" "}
                {moment(log.created_at).format("hh:mm:ss A")}
              </span>
            </div>
          </div>
          <div className="pt-2">
            <View
              log={log}
              attr="header"
              title="Headers"
              className="max-h-48"
            />
          </div>
          <div className="pt-2 ">
            <View log={log} attr="body" title="Body" className="max-h-80" />
          </div>
          <div className="pt-2">
            <View
              log={log}
              attr="response"
              title="Response"
              className="max-h-56"
            />
          </div>
        </div>
      )}
    </>
  );
};

const View = ({
  log,
  title,
  className,
  attr,
}: {
  log: WebhookLog;
  attr: "header" | "body" | "response";
  title: string;
  className: string;
}) => {
  return (
    <div className={"flex flex-col  pb-3 " + className}>
      <h1 className="pb-1">{title}</h1>
      <SyntaxHighlighter
        language="jsx"
        customStyle={{
          backgroundColor: "#f7f7f7",
          padding: ".4rem",
          margin: 0,
          width: "",
          overflowWrap: "break-word",
        }}
        className="break-words whitespace-pre-wrap border-t border-gray-300"
      >
        {JSON.stringify(log[attr], null, 2)}
      </SyntaxHighlighter>
    </div>
  );
};
