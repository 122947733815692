import { useTranslation } from "react-i18next";
import { Operator } from "@lib/types/operator";
import { actionCodes, banks, statusCodes, webhookEvents } from "./data";
import { t } from "i18next";
//  const {t} = useTranslation()
export const queryKeys = {
  transactions: [
    {
      key: "is_approved",
      display: "status",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "approved", value: "1", display: "Approved" },
        {
          key: "declined",
          value: "0",
          display: "Declined",
        },
      ],
    },
    {
      key: "transaction_type",
      display: "type",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "refund", value: "20", display: "Refund" },
        { key: "purchase", value: "00", display: "Purchase" },
      ],
    },
    {
      key: "is_reconcilied",
      display: "reconcileState",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "reconciled", value: "1", display: "Reconciled" },
        {
          key: "notReconciled",
          value: "0",
          display: "Not Reconciled",
        },
      ],
    },
    {
      key: "is_reversed",
      display: "reversal",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "reversed", value: "1", display: "Reversed" },
        {
          key: "notReversed",
          value: "0",
          display: "Not Reversed",
        },
      ],
    },
    {
      key: "card_scheme_id",
      display: "cardScheme",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "Mada", value: "P1", display: "Mada" },
        { key: "Mastercard", value: "MC", display: "Mastercard" },
        { key: "Visa Credit", value: "VC", display: "Visa Credit" },
        { key: "Discover", value: "DC", display: "Discover" },
        { key: "JCB", value: "JC", display: "JCB" },
        { key: "UnionPay", value: "UP", display: "UnionPay" },
        { key: "GCCNET", value: "GN", display: "GCCNET" },
        {
          key: "American Express",
          value: "AX",
          display: "American Express",
        },
        { key: "Maestro", value: "DM", display: "Maestro" },
      ],
    },
    {
      key: "cardholder_verification_result",
      display: "cardVerification",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "pin", value: "20", display: "PIN" },
        { key: "userDevice", value: "30", display: "Device" },
        { key: "noVerification", value: "00", display: "No Verification" },
      ],
    },
    {
      key: "system_trace_audit_number",
      display: "STAN",
      operator: Operator.In,
      type: "input",
      pattern: "[0-9]{6}",
      length: 6,
      placeholder: "000064",
      options: [],
    },
    {
      key: "customer_reference_number",
      display: "Customer Reference Number",
      operator: Operator.In,
      type: "input",
      // pattern: "[0-9]{6}",
      //  length: 12,
      // placeholder: "000064",
      options: [],
    },
    {
      key: "retrieval_reference_number",
      display: "RRN",
      operator: Operator.In,
      type: "input",
      pattern: "[0-9]{12}",
      length: 12,
      placeholder: "000023793485",
      options: [],
    },
    {
      key: "amount_authorized",
      display: "amount",
      operator: Operator.Between,
      type: "range",
      pattern: "[0-9]+",
      options: [],
    },
    {
      key: "receipts.action_code",
      display: "actionResult",
      operator: Operator.In,
      type: "select",
      options: actionCodes,
      placeholder: "search",
    },

    {
      key: "merchant_id",
      display: "merchant",
      searchKey: "merchants",
      operator: Operator.In,
      type: "search",
      placeholder: "searchMerchantName",
      options: [],
    },
    {
      key: "terminal.id",
      display: "tid&name",
      searchKey: "terminals",
      operator: Operator.In,
      type: "search",
      placeholder: "searchTidOrName",
      // pattern: "[0-9]+",
      options: [],
    },
    {
      key: "card_scheme_sponsor_id",
      display: "bank",
      operator: Operator.In,
      type: "select",
      options: banks,
      placeholder: "search",
    },
    {
      key: "date",
      display: "date",
      operator: "",
      type: "date",
      options: [],
    },
  ],

  reconciliations: [
    {
      key: "is_balanced",
      display: "status",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "balanced", value: "1", display: "Balanced" },
        {
          key: "notBalanced",
          value: "0",
          display: "Not Balanced",
        },
      ],
    },

    {
      key: "system_trace_audit_number",
      display: "STAN",
      operator: Operator.In,
      type: "input",
      pattern: "[0-9]{6}",
      length: 6,
      placeholder: "000064",
      options: [],
    },

    {
      key: "total_value",
      display: "amount",
      operator: Operator.Between,
      type: "range",
      pattern: "[0-9]+",
      options: [],
    },

    {
      key: "merchant_id",
      display: "merchant",
      searchKey: "merchants",
      operator: Operator.In,
      type: "search",
      placeholder: "searchMerchantName",
      options: [],
    },
    {
      key: "terminal.id",
      display: "tid&name",
      searchKey: "terminals",
      operator: Operator.In,
      type: "search",
      placeholder: "searchTidOrName",
      // pattern: "[0-9]+",
      options: [],
    },
    {
      key: "transactions.card_scheme_sponsor_id",
      display: "bank",
      operator: Operator.In,
      type: "select",
      options: banks,
      placeholder: "search",
    },
    {
      key: "date",
      display: "date",
      operator: "",
      type: "date",
      options: [],
    },
  ],
  terminals: [
    {
      key: "user",
      display: "assignedUser",
      operator: Operator.NotNull,
      type: "checkbox",
      options: [
        { key: "assigned", value: "1", display: "Assigned" },
        {
          key: "notAssigned",
          value: "0",
          display: "Not Assigned",
        },
      ],
    },

    {
      key: "reference_terminal_id",
      display: "tid&name",
      searchKey: "terminals",
      operator: Operator.In,
      type: "search",
      placeholder: "searchTidOrName",
      // pattern: "[0-9]+",
      options: [],
    },

    {
      key: "date",
      display: "date",
      operator: "",
      type: "date",
      options: [],
    },

    {
      key: "merchant.id",
      display: "merchant",
      searchKey: "merchants",
      operator: Operator.In,
      type: "search",
      placeholder: "searchMerchantName",
      options: [],
    },
    {
      key: "user.id",
      display: "name&mobile",
      searchKey: "terminal-users",
      operator: Operator.In,
      type: "search",
      placeholder: "searchUserNameOrMobile",
      options: [],
    },
  ],
  merchants: [
    {
      key: "status",
      display: "status",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "active", value: "active", display: "Active" },
        {
          key: "notActive",
          value: "deactive",
          display: "Not Active",
        },
      ],
    },
    {
      key: "id",
      display: "name",
      searchKey: "merchants",
      operator: Operator.In,
      type: "search",
      placeholder: "searchMerchantName",
      options: [],
    },
  ],
  admins: [
    {
      key: "admin.is_active",
      display: "status",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "active", value: "1", display: "Active" },
        {
          key: "notActive",
          value: "0",
          display: "Not Active",
        },
      ],
    },
    {
      key: "admin.id",
      display: "name&mobile",
      searchKey: "admins",
      operator: Operator.In,
      type: "search",
      placeholder: "searchAdminNameOrMobile",
      options: [],
    },
  ],
  users: [
    {
      key: "user.is_active",
      display: "status",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "active", value: "1", display: "Active" },
        {
          key: "notActive",
          value: "0",
          display: "Not Active",
        },
      ],
    },
    {
      key: "user.id",
      display: "name&mobile",
      searchKey: "users",
      operator: Operator.In,
      type: "search",
      placeholder: "searchUserNameOrMobile",
      options: [],
    },
  ],
  trsms: [
    {
      key: "terminal",
      display: "status",
      operator: Operator.NotNull,
      type: "checkbox",
      options: [
        { key: "registered", value: "1", display: "Active" },
        {
          key: "notRegistered",
          value: "0",
          display: "Not Active",
        },
      ],
    },
    {
      key: "id",
      display: "code",
      searchKey: "trsm",
      operator: Operator.In,
      type: "search",
      placeholder: "searchTrsmCode",
      options: [],
    },
  ],
  webhooks: [
    {
      key: "status",
      display: "status",
      operator: Operator.In,
      type: "checkbox",
      options: [
        { key: "success", value: "success", display: "Success" },
        {
          key: "failed",
          value: "failed",
          display: "Success",
        },
      ],
    },
    {
      key: "event",
      display: "Event Name",
      operator: Operator.In,
      type: "select",
      options: webhookEvents,
      placeholder: "search",
    },
    {
      key: "status_code",
      display: "Status Code",
      operator: Operator.In,
      type: "select",
      options: statusCodes,
      placeholder: "search",
      searchKey: "",
    },
    {
      key: "date",
      display: "date",
      operator: "",
      type: "date",
      options: [],
    },
  ],
};
