import React, { ChangeEvent, FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { IError, NiceError } from "../../lib/nice-errors";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Filter, Remove, SearchIcon } from "../icons";
import { listQueryParamsApi } from "@lib/utils/api/query-builder-apis";

interface SearchType {
  label?: string;
  // onChange: (e: string, event: ChangeEvent<HTMLSelectElement>) => void;
  isDisabled?: boolean;
  displayKey?: string;
  valueKey?: string;
  placeholder?: string;
  selectContainerClasses?: string;
  selectLabelClasses?: string;
  selectClasses?: string;
  pattern?: string;
  initialSelected?: { value: string; display: string };
  searchKey: string;
  onClick: (e: string, event?: React.MouseEventHandler) => void;
  onRemove?: () => void;
}

export const Search: FC<SearchType> = ({
  label,
  placeholder,
  isDisabled = false,
  searchKey,
  onClick,
  onRemove,
  pattern,
  initialSelected = { value: "", display: "" },
}) => {
  const { t } = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData] = useState<{ value: string; display: string }[]>([]);
  const [selected, setSelected] = useState<{ value: string; display: string }>(
    initialSelected
  );
  const listData = async (key: string, value: string) => {
    try {
      let response = await listQueryParamsApi(key, value);
      return response;
    } catch (e) {}
  };
  let debounceFn: number | any;

  return (
    <div className="group mb-2">
      <label htmlFor={searchKey} className="font-bold capitalize mb-2">
        {label}
      </label>
      <div className="relative flex items-center">
        {selected.value && (
          <div className="absolute bottom-0 left-1 flex justify-between items-center [padding-inline-start:1rem] px-2  mb-2 bg-black border border-gray-300 rounded-full cursor-pointer text-white text-xs">
            <h5>{selected.display}</h5>
            <span
              onClick={() => {
                setSelected({ value: "", display: "" });
                onRemove && onRemove();
              }}
            >
              <Remove
                size={18}
                color="text-white"
                containerClassNames="!p-1"
              ></Remove>
            </span>
          </div>
        )}

        <input
          id={searchKey}
          type="search"
          pattern={pattern}
          onChange={(e) => {
            clearTimeout(debounceFn);
            if (
              !e.target.checkValidity() ||
              e.target.value.trim().length == 0
            ) {
              setData([]);
              return;
            } else {
              debounceFn = setTimeout(() => {
                setLoadingSearch(true);
                listData(searchKey, e.target.value.trim()).then((res: any) => {
                  setData(res);
                  setLoadingSearch(false);
                });
              }, 1000);
            }
          }}
          disabled={selected.value ? true : false || label == "terminal"}
          placeholder={selected.value ? "" : placeholder && t(placeholder)}
          className="peer w-full flex items-center  placeholder:flex placeholder:items-center placeholder:text-gray-400 placeholder:text-xs rounded-lg py-1.5 mb-1 border-gray-300 invalid:border-red-500 invalid:ring-red-500"
        />
        <SearchIcon
          size={18}
          containerClassNames="py-1.5 absolute rtl:left-0 ltr:right-0 top-1  hidden peer-placeholder-shown:inline"
          color="text-gray-400"
        />
      </div>

      <ul
        className={
          "max-h-28 space-y-2 mb-2 text-center  overflow-x-hidden transition-all relative"
        }
      >
        <LoadingSpinner isLoading={loadingSearch} />
        {!data?.length ? (
          <li className=" hidden group-focus-within:block mt-2 text-xs text-gray-400">
            {t("noData")}
          </li>
        ) : (
          ""
        )}

        {!selected.value &&
          data.map((el: any, index: number) => {
            return (
              <li
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelected(el);
                  onClick(el.value);
                }}
                className=" flex justify-between items-center px-2  m-2 bg-gray-200  hover:bg-gray-300 border border-gray-300 rounded-lg cursor-pointer"
              >
                <h6 className="text-xs truncate [direction:ltr] rtl:text-end">
                  {el.display}
                </h6>
                <Filter size={20} containerClassNames="!p-1"></Filter>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
