import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, CheckMarkIcon, ReportsIcon } from "@lib";
import { Modal } from "@lib/modal";

import React from "react";
import { fileDownloadApi } from "@lib/utils/api/terminals-apis";
import { NiceError } from "@lib/nice-errors";

type Props = {
  terminal_id: string;
  toggleModal: any;
  isOpen: any;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const FileDownloadModal = ({
  terminal_id,
  toggleModal,
  isOpen,
  setRefresh,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [error, setError] = useState<any>({});

  const { t } = useTranslation();

  const fileDownload = async (isFull: boolean = true) => {
    try {
      setIsLoading(true);
      const response = await fileDownloadApi({ id: terminal_id, isFull });
      if (response?.success) setSuccessDownload(true);
    } catch (fileDownloadError: any) {
      setSuccessDownload(false);
      setError(fileDownloadError?.error);
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => {
    toggleModal();
    if (successDownload) setRefresh((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) fileDownload(true);
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      title={t("File Download")}
      subTitle={""}
      close={close}
      icon={ReportsIcon}
    >
      <form className="flex items-center ">
        <LoadingSpinner isLoading={isLoading} isTransparent={true} />

        {!isLoading &&
          (successDownload ? (
            <span className="flex justify-center items-center gap-1 bg-green-100 text-green-600 rounded p-2 text-center w-full">
              <CheckMarkIcon
                color="text-green-600"
                containerClassNames="!p-0.5"
              />
              file download succesfully
            </span>
          ) : (
            <div className="flex flex-col items-center gap-2 w-full text-center">
              <NiceError error={error} />
              <Button
                colorScheme={"black"}
                isRounded={false}
                className={"px-2 py-1"}
                onClick={() => fileDownload()}
                label={t("retry") as string}
              />
            </div>
          ))}
      </form>
    </Modal>
  );
};

export default FileDownloadModal;
