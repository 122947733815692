import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import ViewTerminal from "@lib/components/terminals/view";
type Props = {};

const Terminal: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Content hasBackButton isLoading={isLoading}>
      <ViewTerminal
        id={id as string}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </Content>
  );
};

export default Terminal;

// import { FC, useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useNavigate, useParams } from "react-router-dom";
// import AssignTerminalToUserModal from "@lib/components/modals/assign-terminal-to-user";
// import Content from "@lib/layout/content";
// import {
//   Button,
//   EmptyState,
//   GroupedList,
//   Invite,
//   ReconciliationListItem,
//   TerminalIcon,
//   TransactionListItem,
//   UserListItem,
// } from "@lib";
// import { Badge, BadgeType } from "@lib/shared/badge";
// import {
//   disconnectTerminalApi,
//   findTerminalApi,
//   unAssginTerminalApi,
//   UnAssignEnum,
// } from "@lib/utils/api/terminals-apis";
// import {
//   listReconciliationsApi,
//   reconcileApi,
// } from "@lib/utils/api/reconciliations-apis";
// import {
//   listTransactionsApi,
//   TransactionResponse,
//   TransactionsResponse,
// } from "@lib/utils/api/transactions-apis";
// import ListTerminalConnections from "@lib/components/terminal-connections/list";
// import { ProfileSection } from "@lib/components/terminals/profile";
// import UserContext, { UserContextType } from "../../contexts/UserContext";
// import { ReconcileIcon } from "@lib/icons/reconcile";
// import EditTerminalModal from "@lib/components/modals/edit-terminal";
// import { ReconciliationResponse } from "@lib/types/reconciliations";

// type Props = {};
// const initialDeviceState = {
//   brand: "",
//   model: "",
//   is_active: false,
//   id: "",
//   created_at: "",
// };

// const Terminal: FC<Props> = ({}) => {
//   const { id } = useParams();
//   const { auth } = useContext(UserContext) as UserContextType;

//   const [isLoading, setIsLoading] = useState(true);
//   const [terminal, setTerminal] = useState<any>({});
//   const [transactions, setTransactions] = useState<TransactionsResponse[]>([]);
//   const [transactionsTotal, setTransactionsTotal] = useState(0);
//   const [reconciliations, setReconciliations] = useState<any>([]);
//   const [reconciliationsTotal, setReconciliationsTotal] = useState(0);
//   const [refreshTerminal, setRefreshTerminal] = useState(false);
//   const [isAssignToUserOpen, setIsAssignToUserOpen] = useState(false);
//   const [refreshReconciliations, setRefreshReconciliations] = useState(false);
//   const [refreshTransactions, setRefreshTransactions] = useState(false);
//   const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);

//   const [device, setDevice] = useState(initialDeviceState);
//   const navigate = useNavigate();

//   const toggleTerminalAssignModal = () =>
//     setIsAssignToUserOpen((prevState: boolean) => !prevState);

//   const toggleEditNameModal = () => {
//     setIsEditNameModalOpen((prevState: boolean) => !prevState);
//   };

//   const disconnectDevice = async (device_id: string) => {
//     if (window.confirm("Are you sure, you want to remove this connection?")) {
//       await disconnectTerminalApi({
//         terminal_id: terminal.id,
//         device_id,
//       });
//       return true;
//     }
//     return false;
//   };

//   const fetchTerminal = async () => {
//     try {
//       setIsLoading(true);
//       const terminal = await findTerminalApi({ id: id as string });
//       setDevice((prevState: any) => terminal?.device);
//       setTerminal(terminal);
//       setIsLoading(false);
//     } catch (e) {
//       console.log({ e });
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (terminal?.reference_terminal_id) {
//       fetchTransactions();
//       fetchReconciliations();
//     }
//   }, [terminal]);

//   const fetchTransactions = async (page: number = 1) => {
//     try {
//       const { transactions, pages } = await listTransactionsApi({
//         terminal_id: terminal.reference_terminal_id,
//         page,
//         limit: 7,
//       });
//       setTransactions(transactions);
//       setTransactionsTotal(pages);
//     } catch (e) {
//       console.log(e);
//       setIsLoading(false);
//     }
//   };

//   const goToReconciliation = (reconcile: any) =>
//     navigate("/reconciliations/" + reconcile.id);
//   const goToTransaction = (transaction: any) =>
//     navigate("/transactions/" + transaction.uuid);
//   const fetchReconciliations = async (page: number = 1) => {
//     try {
//       const { receipts, pages } = await listReconciliationsApi({
//         terminal_id: terminal.reference_terminal_id,
//         page,
//         limit: 7,
//       });
//       setReconciliations(receipts);
//       setReconciliationsTotal(pages);
//     } catch (e) {
//       console.log(e);
//       setIsLoading(false);
//     }
//   };

//   const UnAssignTerminal = async (type: UnAssignEnum) => {
//     if (window.confirm("Are you sure, you want to remove access ?")) {
//       await unAssginTerminalApi({
//         id: terminal.id,
//         type,
//       });
//       setRefreshTerminal((prevState) => !prevState);
//     }
//   };
//   const reconcile = async ({ terminal_id }: { terminal_id: string }) => {
//     try {
//       await reconcileApi({ terminal_id });
//       setRefreshReconciliations((prev) => !prev);
//       setRefreshTransactions((prev) => !prev);
//     } catch (error) {}
//   };

//   const { t } = useTranslation();

//   const isConnectedBadge: BadgeType = terminal?.is_connected
//     ? { label: t("deviceConnected"), color: "gray" }
//     : { label: t("deviceNotConnected"), color: "green" };
//   const isRegisteredBadge: BadgeType = terminal?.is_assigned_to_user
//     ? { label: t("terminalAssinged"), color: "gray" }
//     : { label: t("terminalNotAssinged"), color: "green" };

//   useEffect(() => {
//     fetchTerminal();
//   }, [refreshTerminal]);
//   useEffect(() => {
//     fetchReconciliations();
//   }, [refreshReconciliations]);
//   useEffect(() => {
//     fetchTransactions();
//   }, [refreshTransactions]);

//   return terminal ? (
//     <Content
//       isLoading={isLoading}
//       isLoadingContainerTransparent={false}
//       hasBackButton={true}
//     >
//       <div className="flex flex-col">
//         <div className="lg:text-3xl text-xl font-semibold">
//           {t("info") as string}
//         </div>
//         <div className="flex justify-between my-4 ">
//           {/* left  */}
//           <div className="flex ">
//             <div className="">
//               <TerminalIcon />
//             </div>
//             <div className="flex flex-col">
//               <div className="text-sm">
//                 {terminal?.name
//                   ? terminal?.tid
//                   : (t("terminalsIdentifier") as string)}
//               </div>
//               <div className="font-bold lg:text-lg text-base">
//                 {terminal?.name ? terminal.name : "#" + terminal?.tid}
//               </div>
//             </div>
//           </div>
//           {/* right  */}
//           <div className="flex flex-col lg:flex-row items-center gap-4 justify-around lg:flex-initial lg:mt-0 ">
//             <Badge {...isConnectedBadge} isRounded={true} />
//             <Badge {...isRegisteredBadge} isRounded={true} />
//           </div>
//         </div>
//         {/* profile start  */}
//         <ProfileSection
//           setRefreshTerminal={setRefreshTerminal}
//           terminal={terminal}
//           setIsLoading={setIsLoading}
//           toggleEditNameModal={toggleEditNameModal}
//         />
//         <div className="text-xl lg:text-3xl font-semibold mt-4">
//           {t("access") as string}
//         </div>
//         <div className="flex">
//           <div className="lg:flex grid grid-cols-1 gap-4 w-full">
//             <div className="flex flex-col flex-1 mt-3">
//               {terminal.user?.name ? (
//                 <UserListItem
//                   title="User"
//                   role={auth?.role}
//                   item={terminal.user}
//                   actionButtonOnClick={function UnAssignTerminalWrapper() {
//                     UnAssignTerminal(UnAssignEnum.user);
//                   }}
//                 />
//               ) : (
//                 <EmptyState
//                   componentTitle={t("user")}
//                   component={
//                     auth?.role == "owner" ? (
//                       <div className="">
//                         <Button
//                           colorScheme="black"
//                           size="sm"
//                           label={
//                             <div className="flex flex-initial items-center justify-center">
//                               <Invite color="fill-white" size={18} />
//                               <span className="ml-2 lg:ml-0 text-xs">
//                                 {t("inviteUser") as string}
//                               </span>
//                             </div>
//                           }
//                           onClick={toggleTerminalAssignModal}
//                         />
//                       </div>
//                     ) : (
//                       <span className="block font-bold text-sm lg:text-md mt-2">
//                         {t("itemNotFound")}
//                       </span>
//                     )
//                   }
//                 />
//               )}
//             </div>

//             <div className="flex flex-col flex-1 mt-3">
//               <span className="text-lg font-bold text-gray-600">
//                 {t("device") as string}
//               </span>
//               {terminal.id ? (
//                 <ListTerminalConnections
//                   terminal_id={terminal.id}
//                   setIsLoading={setIsLoading}
//                   limit={5}
//                   disconnectTerminal={disconnectDevice}
//                 />
//               ) : (
//                 <EmptyState title={t("itemNotFound")} />
//               )}
//             </div>
//           </div>
//         </div>
//         <div className="grid grid-cols-1 lg:flex justify-between gap-4 mt-4">
//           <div className="flex flex-col flex-1">
//             <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
//               {t("transactions") as string}
//             </div>
//             {transactions.length ? (
//               <GroupedList
//                 data={transactions}
//                 groupBy={{
//                   key: "created_at",
//                   isDate: true,
//                 }}
//                 pages={{
//                   selectPage: (page) => fetchTransactions(page),
//                   total: transactionsTotal,
//                 }}
//                 onItemClick={(transaction) => {
//                   goToTransaction(transaction);
//                 }}
//                 component={TransactionListItem}
//                 role="client"
//               />
//             ) : (
//               <EmptyState title={t("itemNotFound")} />
//             )}
//           </div>
//           <div className="flex flex-col flex-1">
//             <div className="flex items-center justify-between text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
//               <h4>{t("reconciliations") as string}</h4>
//               <div className="max-w-fit">
//                 <Button
//                   colorScheme={"default"}
//                   size={"default"}
//                   className={"px-4 py-1 text-xs lg:text-sm w-full"}
//                   label={
//                     <div className="flex items-center gap-1">
//                       <div>{<ReconcileIcon color={"black"} />}</div>
//                       <span className="text-sm block">{t("reconcile")}</span>
//                     </div>
//                   }
//                   onClick={() =>
//                     reconcile({
//                       terminal_id: terminal?.reference_terminal_id,
//                     })
//                   }
//                 />
//               </div>
//             </div>
//             {reconciliations?.length ? (
//               <GroupedList
//                 data={reconciliations}
//                 groupBy={{
//                   key: "created_at",
//                   isDate: true,
//                 }}
//                 pages={{
//                   selectPage: (page) => fetchReconciliations(page),
//                   total: reconciliationsTotal,
//                 }}
//                 onItemClick={(item) => goToReconciliation(item)}
//                 component={ReconciliationListItem}
//                 role="client"
//               />
//             ) : (
//               <EmptyState title={t("itemNotFound")} />
//             )}
//           </div>
//         </div>
//       </div>

//       {terminal.id ? (
//         <>
//           <AssignTerminalToUserModal
//             toggleModal={toggleTerminalAssignModal}
//             isOpen={isAssignToUserOpen}
//             terminalId={terminal.id}
//             merchant_id={terminal.merchant.id}
//             refresh={() =>
//               setRefreshTerminal((prevState: boolean) => !prevState)
//             }
//           />
//           <EditTerminalModal
//             isOpen={isEditNameModalOpen}
//             toggleModal={toggleEditNameModal}
//             terminal={terminal}
//             refresh={() => setRefreshTerminal(!refreshTerminal)}
//           ></EditTerminalModal>
//         </>
//       ) : null}
//     </Content>
//   ) : (
//     <EmptyState title={t("itemNotFound")} />
//   );
// };

// export default Terminal;
