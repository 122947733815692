import { trackPromise } from "react-promise-tracker";
import Api, { OnboardingApi } from ".";

export const SingUpApi = async ({
  email = "",
  password,
  recaptchaToken, // not now
  name,
  mobile,
}: any) => {
  try {
    const response = await trackPromise(
      OnboardingApi({
        headers: { recaptchaToken },
        method: "post",
        url: "/admin",
        data: {
          email: email.toLowerCase(),
          password,
          name,
          mobile,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const verifyApi = async ({
  recaptchaToken, // not now
  admin_id,
  otp,
}: any) => {
  try {
    const response = await trackPromise(
      Api({
        headers: { recaptchaToken },
        method: "post",
        url: "/verify",
        data: {
          otp,
          admin_id,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const createClientApi = async ({
  recaptchaToken,
  admin_id,
  name,
  size,
  country,
  type,
}: any) => {
  try {
    const response = await trackPromise(
      OnboardingApi({
        headers: { recaptchaToken },
        method: "post",
        url: "/client",
        data: {
          admin_id,
          name,
          size,
          country,
          type,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listPlatformsApi = async ({ admin_id }: any) => {
  try {
    const response = await trackPromise(
      OnboardingApi({
        headers: { recaptchaToken: "" },
        method: "get",
        url: "/platforms/" + admin_id,
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};
