import React, { FC, useState } from "react";
import { ArrowLeft, ArrowRight } from "../icons";
import { mergeClassNames } from "../utils";

interface PaginatorType {
  pages?: number;
  selectPage: (page: number) => void;
}

export const Paginator: FC<PaginatorType> = ({ pages = 1, selectPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentInputPage, setCurrentInputPage] = useState(1);

  const handleChange = (newPage: number) => {
    setCurrentPage((prev: number) => newPage);
    setCurrentInputPage((prev: number) => newPage);
    selectPage(newPage);
  };

  return (
    <div className="w-full flex justify-end items-center pb-4">
      <div className="flex flex-row gap-2 paginator items-center">
        <button
          className={mergeClassNames(
            "border-gray-400 flex items-center justify-center border w-9 h-9 rounded-md disabled:opacity-40 disabled:bg-[#F2F2F7] disabled:cursor-not-allowed"
          )}
          disabled={currentPage === 1}
          onClick={() => handleChange(currentPage - 1)}
        >
          <ArrowLeft size={25} />
        </button>
        <input
          className={
            " border-gray-400 border rounded-md font-bold bg-none text-center w-16 h-9 invalid:bg-red-400"
          }
          value={currentInputPage}
          onChange={(e) => {
            if (e.target.checkValidity() && Number(e.target.value) <= pages) {
              setCurrentInputPage(Number(e.target.value));
            }
          }}
          onKeyUp={(e) => {
            if (
              e.key === "Enter" &&
              currentInputPage <= pages &&
              currentInputPage > 0
            )
              handleChange(currentInputPage);
          }}
          onBlur={() =>
            currentInputPage <= pages &&
            currentInputPage > 0 &&
            handleChange(currentInputPage)
          }
          pattern="[0-9]+"
          type="text"
        />
        <span className="text-gray-400 font-medium sm:block hidden">
          of {pages}
        </span>
        <button
          className=" border-gray-400 flex items-center justify-center border w-9 h-9 rounded-md disabled:opacity-40 disabled:bg-[#F2F2F7]  disabled:cursor-not-allowed"
          disabled={currentPage === pages}
          onClick={() => handleChange(currentPage + 1)}
        >
          <ArrowRight size={25} />
        </button>
      </div>
    </div>
  );
};
