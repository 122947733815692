import React from "react";

export const WebhookMethod = ({ method }: { method: string }) => {
  const methodStyle: any = {
    get: "bg-green-200 text-green-700 ",
    put: "bg-yellow-200 text-yellow-700 ",
    post: "bg-blue-200 text-blue-700 ",
    patch: "bg-gray-200 text-gray-700 ",
    delete: "bg-red-200 text-red-700 ",
  };
  return (
    <div
      className={`font-mono rounded w-full text-center py-0.5 px-1 lg:px-2 ${
        methodStyle[method.toLowerCase()]
      }`}
    >
      {method}
    </div>
  );
};

export const WebhookURL = ({ url }: { url: string }) => (
  <div className="w-full font-mono break-words">{url}</div>
);

export const WebhookEvent = ({ event }: { event: string }) => (
  <div className="font-mono break-words">{event}</div>
);

export const WebhookStatus = ({ status }: { status: string }) => {
  const statusStyle: any = {
    2: "bg-green-200 text-green-700  ",
    4: "bg-yellow-200 text-yellow-700",
    5: "bg-red-200 text-red-700",
  };
  return (
    <div
      className={`font-mono rounded px-2 xl:px-4 py-0.5 text-center ${
        statusStyle[status.slice(0, 1)] || "bg-red-200 text-red-700"
      }`}
    >
      {status}
    </div>
  );
};


export const eventReadableName = (name: string) =>
  name
    .split(".")
    .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
    .join(" ");
