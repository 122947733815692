import { Filters } from ".";

export const WebhooksFilters: Filters[] = [
  {
    header: "All",
    key: "all",
    value: "all",
  },
  {
    header: "Logs",
    key: "logs",
    value: "logs",
  },
];
