export enum LoginState {
  MOBILE_NOT_FOUND = "mobile_not_found",
  VERIFY = "verify",
  CREATE_ORGNIZATION = "create_orgnization",
}

interface LoginResponse {
  loginAction: LoginState;
  accessToken: string;
  refreshToken: string;
  admin_id: string;
  mobile: string;
  receiver?: string;
}

export interface LoginCheckerResult {
  link: string;
  state?: { admin_id?: string; mobile?: string; receiver?: string };
  action?: boolean;
}

const loginChecker = ({
  response: {
    loginAction,
    accessToken,
    refreshToken,
    admin_id,
    mobile,
    receiver,
  },
  addToken,
}: {
  response: LoginResponse;
  addToken?: (accessToken: string, refreshToken: string) => void;
}) => {
  let result: LoginCheckerResult;
  switch (loginAction) {
    // case LoginState.MOBILE_NOT_FOUND:
    //   result = { link: "/update-mobile", state: { admin_id } };
    //   break;
    case LoginState.VERIFY:
      result = {
        link: "/verify",
        state: {
          receiver, //: mobile.substring(4, mobile.length),
          admin_id,
        },
      };
      break;
    // case LoginState.CREATE_ORGNIZATION:
    //   addToken?.(accessToken, refreshToken);
    //   result = { link: "/create-orgnization-account", state: undefined };
    //   break;
    default:
      addToken?.(accessToken, refreshToken);
      result = { link: "/#/merchants", action: false };
      break;
  }
  return result;
};

export default loginChecker;
