import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WebhooksFilters } from "@lib/components/filters/webhooks-filters";
import ManageWebhookModal from "@lib/components/modals/manage-webhook";
import ListWebhooks from "@lib/components/webhooks/list";
import ListLogs from "@lib/components/webhooks/list-logs";
import Content from "@lib/layout/content";
import { Button } from "@lib";
import { listEventsApi } from "@lib/utils/api/webhooks-apis";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import {
  usePreservedDateRange,
  usePreservedState,
} from "src/custom-hooks/UsePreservedState";
import { dateRange } from "@lib/types/date";
import { IconButton } from "@lib/components/buttons/iconButton";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
type Props = {};

const Webhooks: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<"All" | "Logs">("All");
  const [toggleModal, setToggleModal] = useState(false);
  const [webhook, setWebhook] = useState<{ [key: string]: any }>({});
  const [refreshWebhook, setRefreshWebhook] = useState(false);
  const [refreshLogs, setRefreshLogs] = useState(false);
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@webhooksDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@webhooksQueryParams"
  );
  const navigate = useNavigate();

  const toggleManageWebhookModal = () => setToggleModal((prev) => !prev);

  const changeTab = (tab: "All" | "Logs") => {
    if (tab === "All") navigate("/events");
    setActiveTab(tab);
  };

  const listEvents = async () => {
    try {
      let response = await listEventsApi();
      setEvents(response);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    listEvents();
  }, [refreshWebhook]);

  return (
    <Content
      tabs={WebhooksFilters}
      title={t("events")}
      activeTab={activeTab}
      setActiveTab={changeTab}
      isLoading={isLoading}
      queryBuilder={
        activeTab == "Logs" && (
          <QueryBuilder
            type="webhooks"
            refreshList={refreshLogs}
            setRefreshList={setRefreshLogs}
            setDateRange={
              setDateRange as React.Dispatch<SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        )
      }
      actionBtns={
        <IconButton
          onClick={toggleManageWebhookModal}
          title="add"
          Icon={PlusSmallIcon}
        />
      }
    >
      {activeTab === "All" && (
        <ListWebhooks
          setIsLoading={setIsLoading}
          refresh={refreshWebhook}
          toggleModal={setToggleModal}
          setWebhook={setWebhook}
        />
      )}
      {activeTab === "Logs" && (
        <ListLogs
          refresh={refreshLogs}
          setIsLoading={setIsLoading}
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
        />
      )}
      <ManageWebhookModal
        toggleModal={toggleManageWebhookModal}
        isOpen={toggleModal}
        setRefresh={setRefreshWebhook}
        webhook={webhook as any}
        setWebhook={setWebhook}
        events={events}
      />
    </Content>
  );
};

export default Webhooks;
