import React, { FC } from "react";
import { Button } from "../forms";
import { Card } from "../shared";

type ReleaseType = {
  role: "client" | "admin";
  onClick: (item: any) => void;
  actionButtonOnClick?: (e: any, item: any) => void;
  releases?: any[];
  item?: {
    id: string;
    target_sdk: number;
    version: string;
    file_name: string;
    url: string;
    sdk_version_id: string;
    current_release_id: string;
    status: string;
    type: string;
    is_force_update: false;
  };
};

const buttonProps = (
  onClick: any,
  buttonType: "upgrade" | "current" | "disabled"
) => {
  switch (buttonType) {
    case "upgrade":
      return (
        <Button
          colorScheme="black"
          label="Upgrade"
          onClick={onClick}
          isDisabled={false}
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
        />
      );
    case "current":
      return (
        <Button
          colorScheme="blue"
          label="Current"
          onClick={() => {}}
          isDisabled={true}
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
        />
      );
    default:
      return (
        <Button
          colorScheme="disabled"
          label="Not Supported"
          onClick={() => {}}
          isDisabled={false}
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
        />
      );
  }
};

export const ReleaseListItem: FC<ReleaseType> = ({
  role,
  onClick,
  item,
  actionButtonOnClick,
  releases = [],
}) => {
  if (!item) return null;

  const setCurrentRelease = (e: any) => {
    actionButtonOnClick &&
      actionButtonOnClick(e, {
        releaseId: item.id,
        sdkVersionId: item.sdk_version_id,
      });
  };

  const renderButton = () => {
    if (item.id == item.current_release_id)
      return buttonProps(setCurrentRelease, "current");
    const sdkReleases = releases.filter(
      (i) => i.sdk_version_id == item.sdk_version_id
    );
    const currentRelease = sdkReleases.find(
      (i) => i.current_release_id == i.id
    );

    if (!currentRelease) return buttonProps(setCurrentRelease, "upgrade");
    if (currentRelease.version < item.version)
      return buttonProps(setCurrentRelease, "upgrade");
    else return buttonProps(setCurrentRelease, "disabled");
  };

  return (
    <Card
      classNames="lg:py-4 lg:px-4 p-2 w-full mt-3 grid grid-cols-10"
      onClick={onClick ? () => onClick(item) : undefined}
    >
      <div className="flex flex-col col-span-4 sm:col-span-7">
        <span>Payment Plugin</span>
        <span className="font-bold">{item?.version}</span>
      </div>
      <div className="flex flex-col px-3"></div>
      <div className="flex flex-col px-3 col-span-3 sm:col-span-1">
        <span>Type</span>
        <span className="font-bold">
          {item?.is_force_update ? "Force" : "Optional"}
        </span>
      </div>
      <div className="flex flex-col px-1 col-span-2 text-xs sm:col-span-1 justify-center">
        {actionButtonOnClick && renderButton()}
      </div>
    </Card>
  );
};
