import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyState, GroupedList } from "@lib";
import { DeviceListItem } from "@lib/list-item/device-list";
import { listTerminalConnectionsApi } from "@lib/utils/api/terminals-apis";

type Props = {
  limit?: number;
  terminal_id?: string;
  disconnectTerminal?: (id: string) => Promise<boolean>;
  refreshTerminal?: boolean;
};

export interface TerminalConnection {
  brand: string;
  model: string;
}

const ListTerminalConnections: FC<Props> = ({
  terminal_id,
  limit = 30,
  disconnectTerminal,
  refreshTerminal,
}: Props) => {
  const [connections, setConnections] = useState<TerminalConnection[]>([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchConnections = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTerminalConnectionsApi({
        page,
        limit,
        terminal_id,
      });
      setPages(response.pages);
      setConnections(response.connections);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const disconnectApi = async (device_id: string) => {
    const updated = disconnectTerminal && (await disconnectTerminal(device_id));
    updated && setRefresh((prevState: boolean) => !prevState);
  };

  useEffect(() => {
    fetchConnections();
  }, [terminal_id, refresh, refreshTerminal]);

  return connections?.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchConnections(page),
      }}
      hideKey={true}
      onItemClick={(item: any) => {}}
      role={"client"}
      data={connections}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      isLoading={isLoading}
    >
      <DeviceListItem role="client" disconnectDevice={disconnectApi} />
    </GroupedList>
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListTerminalConnections;
