import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReleaseListItem, EmptyState, GroupedList } from "@lib";
import { getReleasesAppPageApi, listAppsApi } from "@lib/utils/api/apps-api";
import ListApps from "./list";
import ListAllReleases from "../all-releases/list";

type Props = {
  refreshList: boolean;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
};

const SplitAppsPage = ({ refreshList, setRefreshList }: Props) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="w-[49%]">
        <ListApps refresh={refreshList} setRefresh={setRefreshList} />
      </div>

      <div className="w-px bg-gray-300 h-[98%]" />

      <div className="w-[49%]">{/* <ListAllReleases /> */}</div>
    </div>
  );
};
export default SplitAppsPage;
