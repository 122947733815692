import { useState } from "react";
import { Button, Input, Errors } from "@lib/forms";
import { resetPasswordApi } from "@lib/utils/api/auth-apis";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../../assets/payment-schemes.svg";
import { usePromiseTracker } from "react-promise-tracker";
import { IError, INiceError, NiceError } from "@lib/nice-errors";

type Props = {};
const ResetPassword = ({}: Props) => {
  const [email, setEmail] = useState(process.env.REACT_APP_TESTING_EMAIL || "");
  const [resetPasswordErrors, setResetPasswordErrors] = useState<IError | null>(
    null
  );
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { promiseInProgress: isLoading } = usePromiseTracker();

  const loginHandler = async () => {
    navigate("/");
  };

  const resetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      setResetPasswordErrors(null);
      await resetPasswordApi({ email, recaptchaToken });
      navigate("/message-sent");
    } catch (e: any) {
      console.log(e);
      setResetPasswordErrors(e);
    }
  };

  return (
    <div
      style={{ padding: "20px" }}
      className=" sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center  flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="sm:p-5 flex flex-1 flex-col justify-center">
            <span className="text-5xl font-bold">
              {t("resetPasswordLabel")}
            </span>
            <div className="mb-4 mt-2">
              <NiceError error={resetPasswordErrors?.error} />
              {/* <Errors
                errors={
                  resetPasswordErrors.error
                    ? [
                        i18n.language == "ar"
                          ? resetPasswordErrors.error.arabic
                          : resetPasswordErrors.error.english,
                      ]
                    : []
                }
              /> */}
            </div>
            <Input
              name="email"
              value={email}
              type="email"
              label={t("email")}
              onChange={setEmail}
              errors={resetPasswordErrors}
            />
            <Button
              label={t("sendEmail") as string}
              colorScheme="black"
              isRounded={false}
              onClick={resetPassword}
              className={"px-2 py-1 my-2"}
              // classNames="w-full py-2 px-4 mt-4"
            />
            <div className="w-1/2">
              <Button
                label={t("login") as string}
                colorScheme="default"
                isRounded={false}
                onClick={loginHandler}
                className={"px-2 py-1"}
                // classNames="w-full py-2 px-4 "
              />
            </div>
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
