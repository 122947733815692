import React, { FC, useEffect } from "react";
import { ILookupTerminalProfile } from "./types";
import { default as Mada } from "@lib/icons/mada.png";
import { default as Visa } from "@lib/icons/visa.png";
import { default as Mastercard } from "@lib/icons/mastercard.png";
import { default as American_Express } from "@lib/icons/American_Express.png";
import SupportedSchemes from "./supported-schemes";
import { IError, NiceError } from "@lib/nice-errors";
import { t } from "i18next";

const allowedTransactionsMap = [
  "purchase",
  "refund",
  "authorization",
  "reversal",
];

interface IProps {
  profile: ILookupTerminalProfile | IError | null;
}

export const Profile: FC<IProps> = ({ profile }) => {
  if (!profile) return null;

  if ("error" in profile) {
    profile = profile as IError;
    return (
      <div className="w-full mt-4">
        <span className="font-bold text-bold text-xl mb-2">Profile</span>
        <NiceError error={profile.error} />
      </div>
    );
  }

  profile = profile as ILookupTerminalProfile;

  return (
    <>
      <div className="grid grid-cols-2 my-4 gap-y-4">
        <div className="flex flex-col">
          <span className="font-light text-sm">{t("nameArabic")}</span>
          <span className="font-semibold">{profile.merchant.name.arabic}</span>
        </div>
        <div className="flex flex-col">
          <span className="font-light text-sm">{t("nameEnglish")}</span>
          <span className="font-semibold">{profile.merchant.name.english}</span>
        </div>
        <div className="flex flex-col">
          <span className="font-light text-sm">{t("addressArabic")}</span>
          <span className="font-semibold">
            {profile.merchant.address.arabic}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="font-light text-sm">{t("addressEnglish")}</span>
          <span className="font-semibold">
            {profile.merchant.address.english}
          </span>
        </div>
      </div>
      <br />
      <hr />
      <div className="py-4">
        {allowedTransactionsMap.map((type, index: number) => (
          <SupportedSchemes
            profile={profile}
            type={type}
            key={index}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export const SchemesOnlyProfile: FC<IProps> = ({ profile }) => {
  if (!profile) return null;
  if ("error" in profile) {
    profile = profile as IError;
    return (
      <div className="w-full mt-4">
        <span className="font-bold text-bold text-xl mb-2">Profile</span>
        <NiceError error={profile.error} />
      </div>
    );
  }
  profile = profile as ILookupTerminalProfile;
  return (
    <>
      <div className="flex-1 grid grid-cols-5 items-center place-items-center"></div>
      {allowedTransactionsMap.map((type, index: number) => (
        <SupportedSchemes
          profile={profile}
          type={type}
          key={index}
          index={index}
        />
      ))}
    </>
  );
};
