import { ChartItem, TransformedData } from "../types/reports";

export function toArabianStandardTimeMilliseconds(
  inputDate: Date | undefined
): number | undefined {
  if (!inputDate) {
    console.log("Invalid date provided");
    return;
  }

  const arabianOffsetMinutes = -180;
  const offsetDifference =
    (arabianOffsetMinutes - inputDate.getTimezoneOffset()) * 60000;
  return inputDate.getTime() + offsetDifference;
}
export function calculateMonthlyTotals(
  data: TransformedData[],
  type: string
): TransformedData[] {
  const monthlyTotals: { [key: string]: number } = {};
  data.forEach((value) => {
    const month = value.name.slice(0, 3);
    monthlyTotals[month] = (monthlyTotals[month] ?? 0) + Number(value[type]);
  });

  const monthlyArray: TransformedData[] = [];
  for (const [month, total] of Object.entries(monthlyTotals)) {
    monthlyArray.push({ name: month, [type]: total });
  }

  return monthlyArray;
}

export function transformData(data: ChartItem[], type: string) {
  const transformedData = data.map((item) => {
    let category = 0;
    if (type === "Terminal Registrations" || type === "Transaction Count") {
      category = Math.round(parseInt(item.count));
    } else if (type === "Transaction Value") {
      category = Math.round(parseInt(item.amount ?? "0") / 100);
    } else if (type === "Transaction Average") {
      category = Math.round(
        parseInt(item.amount ?? "0") / 100 / parseInt(item.count)
      );
    }
    return {
      name: new Date(item.date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
      [type]: category,
    };
  });

  transformedData.sort((a, b) => {
    const timestampA = new Date(a.name).getTime();
    const timestampB = new Date(b.name).getTime();

    return timestampA - timestampB;
  });

  return transformedData;
}

export function calculateTotal(data: ChartItem[], type: string) {
  return data.reduce((total, entry: any) => {
    return total + parseInt(entry[type], 10);
  }, 0);
}
