import { useState, FC, SetStateAction } from "react";
import Content from "@lib/layout/content";
import ListAdmins from "@lib/components/admins/list";
import CreateAdminModal from "@lib/components/modals/manage-admins";
import { useTranslation } from "react-i18next";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import { dateRange } from "@lib/types/date";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import { initialAdminState } from "@lib/utils/api/admins-apis";
import { IconButton } from "@lib/components/buttons/iconButton";
import { PlusSmallIcon } from "@heroicons/react/24/outline";

type Props = {};

const Team: FC<Props> = ({}: Props) => {
  const [newAdmin, setNewAdmin] = useState(initialAdminState);
  const [refreshList, setRefreshList] = useState(false);
  const [isCreateAdminModalOpen, setIsCreateAdminModalOpen] = useState(false);
  const { t } = useTranslation();
  const toggleCreateAdminModal = () => {
    setIsCreateAdminModalOpen((prev) => !prev);
  };
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@adminsQueryParams"
  );
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@adminsDateRange"
  );
  return (
    <>
      <Content
        title={t("team")}
        actionBtns={
          <IconButton
            Icon={PlusSmallIcon}
            onClick={() => toggleCreateAdminModal()}
            title="addNewMember"
          />
        }
        queryBuilder={
          <QueryBuilder
            type="admins"
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setDateRange={
              setDateRange as React.Dispatch<SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
          />
        }
      >
        <ListAdmins
          limit={5}
          // setIsLoading={setIsLoading}
          refresh={refreshList}
          queryParams={queryParams}
        />
        <CreateAdminModal
          toggleModal={toggleCreateAdminModal}
          isOpen={isCreateAdminModalOpen}
          newAdmin={newAdmin}
          setNewAdmin={setNewAdmin}
          setRefresh={setRefreshList}
        />
      </Content>
    </>
  );
};

export default Team;
