import { trackPromise } from "react-promise-tracker";
import Api, { List } from ".";

export const getTerminalsReportApi = async () => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reports/terminals",
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSalesReportApi = async () => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reports/sales",
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const getTerminalRegisterationsApi = async ({
  from,
  to,
}: {
  from?: number;
  to?: number;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reports/terminals/range",
        params: { from, to },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSalesChartData = async ({
  from,
  to,
}: {
  from?: number;
  to?: number;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reports/sales/range",
        params: { from, to },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
