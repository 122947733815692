import { FC } from "react";
import {
  ArrowDown,
  ArrowDownUp,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpDown,
  Checked,
  ReconcileIcon,
  XIcon,
} from "../icons";
import { Card } from "../shared";
import { default as Mada } from "../icons/mada.png";
import { default as Visa } from "../icons/visa.png";
import { default as Mastercard } from "../icons/mastercard.png";
import { default as American_Express } from "../icons/American_Express.png";
import { default as GCCNET } from "../icons/GCCNET.png";
import { default as Discover } from "../icons/Discover.png";
import { default as UnionPay } from "../icons/UnionPay.png";
import { default as Maestro } from "../icons/Maestro.png";
import { default as JCB } from "../icons/JCB.png";
import { TransactionListItemType } from "../types/transaction";
import { useTranslation } from "react-i18next";
import { cardVerification } from "../utils/card-verification";
import { getBank } from "../utils/get-bank";
import { NavLink } from "react-router-dom";
import { banks } from "@lib/components/query-builder/data";
import { Flex } from "@tremor/react";

const PaymentSchemeIcons: { [key: string]: string } = {
  P1: Mada,
  VC: Visa,
  MC: Mastercard,
  DM: Maestro,
  AX: American_Express,
  JC: JCB,
  DC: Discover,
  UP: UnionPay,
  GN: GCCNET,
};

export const TransactionListItem: FC<TransactionListItemType> = ({
  role,
  title,
  onClick,
  item,
  isFull,
}) => {
  //default is xs - to sm -> we reach 600
  const { i18n, t } = useTranslation();
  return (
    <NavLink to={"/transactions/" + item?.id} className="mt-3 overflow-hidden">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="flex flex-col items-center gap-y-2   divide-y-2 divide-dashed divide-gray-300  lg:py-2 !px-0  text-[.65rem] lg:text-xs 2xl:text-sm w-full ">
        <div className="flex items-center justify-between gap-3 xs:gap- py-1 px-2 w-full">
          <Flex
            flexDirection="row"
            className="gap-4 justify-between sm:justify-start"
          >
            <div className="flex font-bold  md:w-24 lg:w-28">
              <TransactionType
                type={item?.transaction_type}
                is_reversed={item?.is_reversed}
              />
            </div>
            <div className=" flex items-center gap-2">
              <span
                className={
                  (item?.is_approved
                    ? "bg-green-200 p-1 text-green-600"
                    : "bg-red-200 text-red-600 px-1") +
                  " flex items-center gap-1 sm:px-3 md:px-1 rounded-full "
                }
              >
                {item?.is_approved ? (
                  <Checked size={18} color={"text-current"} />
                ) : (
                  <XIcon
                    size={18}
                    color={"text-current"}
                    containerClassNames={"py-1"}
                  />
                )}
                <span className="hidden sm:block md:hidden  sm:max-w-[11rem] truncate px-1">
                  {i18n.language == "ar"
                    ? item.receipts?.[0]?.status_message?.arabic
                    : item.receipts?.[0]?.status_message?.english}
                </span>
              </span>
              <FormatPrice item={item} />
            </div>
            <div
              className={
                item?.is_reconciled ? "sm:hidden" : "sm:hidden invisible"
              }
            >
              <ReconcileIcon color="text-current" containerClassNames="!p-0" />
            </div>
            {/* {!item?.is_approved && (
              <div className="hidden 2xl:block truncate  rounded-full bg-red-100 2xl:mx-2  py-1 px-1 2xl:px-2 text-[.6rem] text-center font-medium text-red-800">
                {i18n.language == "ar"
                  ? item.receipts?.[0]?.status_message?.arabic
                  : item.receipts?.[0]?.status_message?.english}
              </div>
            )}{" "} */}
          </Flex>
          <Flex className="gap-1 sm:gap-8 w-auto">
            <div className="hidden md:flex items-center gap-2 2xl:ltr:mr-4 2xl:rtl:ml-4">
              <img
                src={PaymentSchemeIcons[item?.card_scheme_id]}
                className="w-5 lg:w-7"
              />

              <div className="flex flex-col lg:leading-5">
                <span className="font-bold whitespace-nowrap">{item.pan}</span>
                <span>{item?.receipts?.[0]?.start_time}</span>
              </div>
            </div>

            <div className="hidden xl:flex flex-col">
              <span className="font-bold whitespace-nowrap">
                RRN: {item?.retrieval_reference_number}
              </span>
              <span>STAN: {item?.system_trace_audit_number}</span>
            </div>

            <div
              className={`-mr-8 w-[132px] min-h-7 max-h-7 h-7 hidden sm:flex justify-center items-center gap-1 group-[.reconciliation]:hidden rounded-full bg-gray-900 py-1 lg:py-1 px-2 text-white text-xs whitespace-nowrap ${
                item?.is_reconciled ? "" : "invisible"
              }`}
            >
              <span>
                <ReconcileIcon
                  size={16}
                  color="text-current"
                  containerClassNames="!p-0"
                />
              </span>

              <span>
                {item?.is_reconciled ? t("reconciled") : t("notReconciled")}
              </span>
            </div>

            <div className="">
              {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
            </div>
          </Flex>
        </div>

        {/* //////////////// second filter //////////////////// */}
        {isFull && (
          <div
            className={
              " flex items-center justify-between xs:gap-2 pt-1.5 xs:px-2  w-full     [&_.filter]:items-center [&_.filter]:justify-between [&_.filter]:gap-1  [&_.filter]:px-2 "
            }
          >
            {/* merchant */}
            <div className="filter group flex   group-[.merchant]:hidden group-[.terminal]:hidden">
              <div className="flex flex-col">
                <small className="text-[.65rem]">{t("merchant")}</small>
                <span className="font-semibold text-xs w-[8.5rem] sm:w-[7rem] truncate [direction:ltr] rtl:text-end">
                  {item?.merchant?.name}
                </span>
              </div>
            </div>

            {/* user */}
            <div
              className="filter group hidden sm:flex"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex flex-col ">
                <small className="text-[.65rem]">{t("user")}</small>
                <span
                  dir="ltr"
                  className=" font-semibold text-xs truncate w-[11rem] [direction:ltr] rtl:text-end"
                >
                  {`${item?.user?.name} (${item?.user?.mobile})`}
                </span>
              </div>
            </div>
            {/* terminal */}
            <div className="filter group flex  group-[.terminal]:hidden">
              <div className="flex flex-col">
                <small className="text-[.65rem]">{t("terminal")}</small>
                <span className=" font-semibold text-xs  truncate w-[8rem]">
                  {item?.tid}
                </span>
              </div>
            </div>

            {/* device */}
            <div className="hidden 2xl:flex flex-col ">
              <small>{t("userDevice")}</small>
              <span
                dir="ltr"
                className=" font-semibold text-xs w-[6rem] truncate [direction:ltr] rtl:text-end"
              >
                {`${item?.device?.brand ?? ""} ${item?.device?.model ?? ""}`}
              </span>
            </div>

            {/* bank */}
            <div className="filter group hidden xl:flex">
              <div className="flex flex-col">
                <small className="text-[.65rem]">{t("bank")}</small>
                <span className="sm:w-[7rem] font-semibold text-xs whitespace-nowrap truncate">
                  {t(
                    banks.find((b) => b.value == item?.card_scheme_sponsor_id)
                      ?.display as string
                  )}
                </span>
              </div>
            </div>

            {/* verification */}
            <div className="filter group hidden lg:flex">
              <div className="flex flex-col">
                <small className="text-[.65rem]">{t("verification")}</small>
                <span className=" font-semibold text-xs w-[5.5rem] truncate">
                  {t(cardVerification(item?.cardholder_verification_result))}
                </span>
              </div>
            </div>

            {/* speed */}
            <div className="hidden xl:flex flex-col">
              <small>{t("speed")}</small>
              <span className=" font-semibold text-xs whitespace-nowrap truncate">
                {`${item?.speed} ${t("second")}`}
              </span>
            </div>
          </div>
        )}
      </Card>
    </NavLink>
  );
};

export const TransactionType = ({
  type,
  is_reversed,
}: {
  type: any;
  is_reversed: boolean;
}) => {
  const { i18n, t } = useTranslation();
  const name = i18n.language == "ar" ? type?.name.arabic : type?.name.english;
  const color =
    type?.name.english == "PURCHASE" ? "text-violet-600" : "text-orange-600";

  const handleType = () => {
    switch (type?.name.english) {
      case "PURCHASE":
        return (
          <div className="flex items-center text-violet-600">
            {is_reversed ? (
              <ArrowDownUp color="text-current" />
            ) : (
              <ArrowUp color="text-current" />
            )}
          </div>
        );
      case "REFUND":
        return (
          <div className="flex items-center text-orange-600">
            {is_reversed ? (
              <ArrowUpDown color="text-current" />
            ) : (
              <ArrowDown color="text-current" />
            )}
          </div>
        );
    }
  };
  return (
    <div className={"flex items-center " + color}>
      {handleType()}
      <span className="flex flex-col leading-3 ">
        {name}
        <span className="text-black">
          {is_reversed && (t("reversed") as string)}
        </span>
      </span>
    </div>
  );
};

export const FormatPrice = ({ item }: { item: any }) => {
  const { i18n, t } = useTranslation();

  return (
    <span className=" flex items-baseline gap-1 font-bold">
      {" "}
      <small className="lg:text-[.7rem] font-normal">KES</small>{" "}
      {(Number(item?.amount_authorized.toString()) / Math.pow(10, 2)).toFixed(
        2
      )}
    </span>
  );
};
