import { FC, useContext, useEffect } from "react";
import { Routes as RoutesContainer, Route, Navigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../../contexts/UserContext";
import Container from "@lib/layout/container";
import PublicContainer from "@lib/layout/public-container";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ScrollToTop } from "./scroll-to-top";
import { bootIntercom } from "../../../account/boot-intercom";
import PublicLayoutContainer from "../../../account/pages/PublicLayout";
import MerchantPublicLayout from "../../../merchant/pages/PublicLayout";
import { groupLinks, publicPages, RoutesPages } from "./routes";
import Redirect from "src/account/pages/Redirect";

type Dashboard = "account" | "merchant";

const Routes: FC = ({}) => {
  const { isLoggedIn } = useContext(UserContext) as UserContextType;
  useEffect(() => {
    bootIntercom();
  }, []);

  const getDashboardType = (): Dashboard => {
    switch (process.env.REACT_APP_DASHBOARD_TYPE) {
      case "account":
        return "account";
      case "merchant":
        return "merchant";
      default:
        return "account";
    }
  };
  return (
    <ScrollToTop>
      {isLoggedIn() ? (
        <Container navlinks={groupLinks[getDashboardType()]}>
          <RoutesContainer>
            {RoutesPages[getDashboardType()].map(
              (route: any, index: number) => {
                return (
                  <>
                    <Route path={route.path} key={route.path}>
                      <Route index element={<route.component />}></Route>
                      <Route
                        path=":id"
                        element={route.child && <route.child />}
                      ></Route>
                    </Route>
                  </>
                );
              }
            )}
            {/* redirect anonymus */}
            <Route path="*">
              <Route
                index
                element={
                  <Redirect
                    redirectPath={
                      RoutesPages[getDashboardType()].find(
                        (routeObject) => routeObject.isHome
                      )?.path
                    }
                  />
                }
              />
            </Route>
          </RoutesContainer>
        </Container>
      ) : (
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfETr0hAAAAAGhTpXh9z0SiBBe2Qcm3VUawtVCq"
          language="en"
          scriptProps={{
            async: true,
            defer: false,
            appendTo: "head",
          }}
        >
          <PublicContainer>
            <RoutesContainer>
              <Route
                path="/"
                element={
                  getDashboardType() === "merchant" ? (
                    <MerchantPublicLayout />
                  ) : (
                    <PublicLayoutContainer />
                  )
                }
              >
                {publicPages[getDashboardType()]
                  .filter(
                    (p) => p.is_allowed || process.env.REACT_APP_ONBOARDING_ON
                  )
                  .map((page: any) => {
                    return (
                      <Route
                        key={page.path}
                        path={page.path}
                        element={<page.element />}
                      />
                    );
                  })}
              </Route>
            </RoutesContainer>
          </PublicContainer>
        </GoogleReCaptchaProvider>
      )}
    </ScrollToTop>
  );
};

export default Routes;
