import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import ThemeContext, { ThemeContextType } from "../contexts/ThemeContext";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { Button, Errors, Input } from "@lib";
import { loginMobileApi, verifyLoginMobileApi } from "@lib/utils/api/auth-apis";
import { default as Mada } from "@lib/icons/mada.png";
import { default as Visa } from "@lib/icons/visa.png";
import { default as Mastercard } from "@lib/icons/mastercard.png";
import { default as American_Express } from "@lib/icons/American_Express.png";
import {
  countriesOptions,
  defaultCountriesOption,
} from "../utils/countriesOptions";

type Props = {};

const Login: FC<Props> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [loginErrors, setLoginErrors] = useState<any>({});
  const { addToken } = useContext(UserContext) as UserContextType;
  const { t, i18n } = useTranslation();
  const { client } = useContext(ThemeContext) as ThemeContextType;

  const login = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      setLoginErrors({});
      e?.preventDefault();
      const { isOtpSent } = await loginMobileApi({ mobile });
      setShowOTPInput(isOtpSent);
      setIsLoading(false);
    } catch (e: any) {
      if (e.code_text) setLoginErrors(e.error);
      else setLoginErrors(e);
      setIsLoading(false);
    }
  };

  const submitOtp = (e?: any) => {
    loginVerify(e);
  };

  const loginVerify = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      setLoginErrors({});
      e?.preventDefault();
      const { access_token, refresh_token } = await verifyLoginMobileApi({
        mobile,
        otp,
        client_id: client.id,
      });
      addToken(access_token, refresh_token);
      setIsLoading(false);
    } catch (e: any) {
      console.log({ e });
      setLoginErrors(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (otp.length == 6) submitOtp();
  }, [otp]);

  return (
    <div className="lg:grid lg:grid-cols-4 flex  h-full">
      <div className="bg-white flex flex-col items-center justify-center relative flex-1 ">
        <div className="flex-auto flex justify-center items-center">
          {client?.logo ? (
            <div className="flex-col">
              <img src={client.logo} className="client-logo-homepage" />
            </div>
          ) : (
            <div className="text-3xl font-semibold flex flex-col">
              {client.name}
            </div>
          )}
        </div>
        <LoadingSpinner isLoading={isLoading} isTransparent={true} />
        <form className="w-full sm:w-2/3 flex-auto" style={{ padding: "20px" }}>
          <span className=" text-2xl font-bold text-center block w-full mb-4 text-primary ">
            {t("login") as string}
          </span>
          <Errors
            errors={
              loginErrors.error?.body.error
                ? [
                    i18n.language == "ar"
                      ? loginErrors.error?.body.error.arabic
                      : loginErrors.error?.body.error.english,
                  ]
                : []
            }
          />

          <Input
            label=""
            inputContainerClasses="number-place-holder"
            value={mobile}
            onChange={(e) => setMobile(e)}
            name="mobile"
            errors={loginErrors}
            type={"phone"}
            defaultDropDownOption={defaultCountriesOption}
            dropDownOptions={countriesOptions}
            dropDownValueKey="dial_code"
            dropDownDisplayKey="display_name"
          />
          {showOTPInput ? (
            <>
              <Input
                label={t("OTP") as string}
                value={otp}
                onChange={(e) => (e?.length <= 6 ? setOTP(e) : {})}
                name="OTP"
                errors={loginErrors}
                inputLabelClasses=" text-2xl font-bold text-center block w-full mb-2 text-primary "
              />
              <Button
                classNames=" mt-4 py-2 "
                isRounded={false}
                colorScheme="primary"
                onClick={loginVerify}
                label={t("login") as string}
              />
            </>
          ) : (
            <Button
              classNames="mt-4 py-2 "
              isRounded={false}
              colorScheme="primary"
              onClick={otp.length > 0 ? loginVerify : login}
              label={t("login") as string}
            />
          )}
        </form>

        <div className="flex flex-col w-full flex-auto justify-end pb-4 gap-2 items-center">
          <div className="flex ">
            <div className="border-b-2 flex border-primary p-1 gap-1 ">
              <img src={Mada} className="payment-scheme" />
              <img src={Mastercard} className="payment-scheme" />
              <img src={Visa} className="payment-scheme" />
              <img src={American_Express} className="payment-scheme" />
            </div>
          </div>
          <span className="text-gray-300 px-2 flex  justify-start ">
            {t("poweredByNearpay") as string}
          </span>
        </div>
      </div>
      <div className="bg-primary col-span-3 relative lg:visible invisible">
        <img
          className="opacity-10 w-full absolute top-0 right-0 h-full object-cover "
          src="https://images.unsplash.com/photo-1586724237569-f3d0c1dee8c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          alt="Riyadh City"
        />
      </div>
    </div>
  );
};

export default Login;
