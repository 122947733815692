import { FC, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ManageWebhookModal from "@lib/components/modals/manage-webhook";
import ListWebhooks from "@lib/components/webhooks/list";
import ListLogs from "@lib/components/webhooks/list-logs";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import Content from "@lib/layout/content";
import { Button } from "@lib";
import { Tabs } from "@lib/types/tabs";
import { listEventsApi } from "@lib/utils/api/webhooks-apis";
import {
  usePreservedDateRange,
  usePreservedState,
} from "src/custom-hooks/UsePreservedState";
import { dateRange } from "@lib/components/query-builder/date-picker";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import { IconButton } from "@lib/components/buttons/iconButton";
import { PlusSmallIcon } from "@heroicons/react/24/outline";

type Props = {};
export const WebhooksFilters: Tabs[] = [
  {
    header: "All",
    key: "all",
    value: "all",
  },
  {
    header: "Logs",
    key: "logs",
    value: "logs",
  },
];
export const eventReadableName = (name: string) =>
  name
    .split(".")
    .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
    .join(" ");

const Webhooks: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<"All" | "Logs">("All");
  const [toggleModal, setToggleModal] = useState(false);
  const [webhook, setWebhook] = useState<{ [key: string]: any }>({});
  const [refreshWebhook, setRefreshWebhook] = useState(false);
  const [refreshLogs, setRefreshLogs] = useState(false);

  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@webhooksDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@webhooksQueryParams"
  );
  const { auth } = useContext(UserContext) as UserContextType;

  const navigate = useNavigate();

  const toggleManageWebhookModal = () => setToggleModal((prev) => !prev);

  const changeTab = (tab: "All" | "Logs") => {
    if (tab === "All") navigate("/events");
    setActiveTab(tab);
  };

  const listEvents = async () => {
    try {
      let response = await listEventsApi();
      setEvents(response);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    listEvents();
  }, [refreshWebhook]);

  return (
    <Content
      tabs={WebhooksFilters}
      title={t("events")}
      activeTab={activeTab}
      setActiveTab={changeTab}
      isLoading={isLoading}
      queryBuilder={
        activeTab == "Logs" && (
          <QueryBuilder
            type="webhooks"
            refreshList={refreshLogs}
            setRefreshList={setRefreshLogs}
            setDateRange={
              setDateRange as React.Dispatch<SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        )
      }
      actionBtns={
        <IconButton
          onClick={toggleManageWebhookModal}
          title="add"
          Icon={PlusSmallIcon}
        />
      }
    >
      {activeTab === "All" && (
        <ListWebhooks
          setIsLoading={setIsLoading}
          refresh={refreshWebhook}
          toggleModal={setToggleModal}
          setWebhook={setWebhook}
        />
      )}{" "}
      {activeTab === "Logs" && (
        <ListLogs
          refresh={refreshLogs}
          setIsLoading={setIsLoading}
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
        />
      )}
      <ManageWebhookModal
        toggleModal={toggleManageWebhookModal}
        isOpen={toggleModal}
        setRefresh={setRefreshWebhook}
        webhook={webhook as any}
        setWebhook={setWebhook}
        events={events}
      />
    </Content>
  );
};

export const WebhookMethod = ({ method }: { method: string }) => {
  const methodStyle: any = {
    get: "bg-green-200 text-green-700 ",
    put: "bg-yellow-200 text-yellow-700 ",
    post: "bg-blue-200 text-blue-700 ",
    patch: "bg-gray-200 text-gray-700 ",
    delete: "bg-red-200 text-red-700 ",
  };
  return (
    <div
      className={`font-mono rounded w-full text-center py-0.5 px-1 lg:px-2 ${
        methodStyle[method.toLowerCase()]
      }`}
    >
      {method}
    </div>
  );
};

export const WebhookURL = ({ url }: { url: string }) => (
  <div className="w-full font-mono break-words">{url}</div>
);

export const WebhookEvent = ({ event }: { event: string }) => (
  <div className="font-mono break-words">{event}</div>
);

export const WebhookStatus = ({ status }: { status: string }) => {
  const statusStyle: any = {
    2: "bg-green-200 text-green-700  ",
    4: "bg-yellow-200 text-yellow-700",
    5: "bg-red-200 text-red-700",
  };
  return (
    <div
      className={`font-mono rounded px-2 xl:px-4 py-0.5 text-center ${
        statusStyle[status.slice(0, 1)] || "bg-red-200 text-red-700"
      }`}
    >
      {status}
    </div>
  );
};

export default Webhooks;
