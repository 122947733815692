import Api from ".";
import Storage from "../storage";

interface ClientConfiguration {
  name: string;
  logo: string;
  id: string;
  primary_color: string;
  secondary_color: string;
}

export const fetchClientConfigurationApi =
  async (): Promise<ClientConfiguration> => {
    try {
      const response = await Api({
        method: "GET",
        url: "/get-config",
      });
      return response.data as ClientConfiguration;
    } catch (e) {
      console.log({ e });
      throw e;
    }
  };

export const fetchConfig = async () => {
  try {
    const response = await Api({
      method: "GET",
      url: "/configuration/download",
    });
    return response.data;
  } catch (e) {
    console.log({ e });
    throw e;
  }
};
