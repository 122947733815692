import React, { useContext } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { Button, Card, EmptyState } from "@lib";
import { terminalUpgradeApi } from "@lib/utils/api/terminals-apis";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ReactComponent as FileDownloadIcon } from "../../../assets/file-download.svg";
import { SchemesOnlyProfile } from "../modals/terminal-lookup/profile";
import FileDownloadModal from "../modals/file-download";
import UserContext, { UserContextType } from "src/contexts/UserContext";

export const ProfileSection = ({
  terminal,
  setIsLoading,
  setRefreshTerminal,
  togglePreferencesModal,
  toggleEditNameModal,
}: {
  terminal: {
    [key: string]: any;
  };
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  setRefreshTerminal: Dispatch<SetStateAction<boolean>>;
  togglePreferencesModal: () => void;
  toggleEditNameModal: () => void;
}) => {
  const { auth } = useContext(UserContext) as UserContextType;

  const [isFileDownloadOpen, setIsFileDownloadOpen] = useState(false);
  const toggleFileDownload = () => {
    setIsFileDownloadOpen((prev) => !prev);
  };

  const upgradeTerminal = async () => {
    setIsLoading(true);
    await terminalUpgradeApi({ id: terminal.id });
    setRefreshTerminal((prev) => !prev);
  };

  let { schemeOverrideConfiguration, v2TerminalsUpgrades } = useFlags();

  const { t } = useTranslation();
  return (
    <div className="flex flex-col flex-1 mt-3">
      <div className="flex justify-between items-center gap-2 mb-2">
        <div className="flex flex-col text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
          <h3> {t("profile")}</h3>
          <small className="text-xs">
            {moment(terminal?.profile_updated_at).format(
              "YYYY-MM-DD hh:mm:ss A"
            )}
          </small>
        </div>
        <div>
          <div className="flex gap-2 my-2">
            {v2TerminalsUpgrades && terminal.version === "v1" && (
              <div>
                <Button
                  colorScheme={"indigo"}
                  label={
                    <div className="flex items-center gap-2">
                      <div className="flex-auto">upgrade terminal</div>
                    </div>
                  }
                  className={
                    "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"
                  }
                  onClick={() => {
                    upgradeTerminal();
                  }}
                ></Button>
              </div>
            )}

            <div className={"hidden"}>
              <Button
                colorScheme={"lightYellow"}
                label={
                  <div className="flex items-center gap-2">
                    <FileDownloadIcon />
                    <div className="flex-auto">{t("fullFileDownload")}</div>
                  </div>
                }
                className={
                  "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"
                }
                onClick={() => toggleFileDownload()}
              ></Button>
            </div>
            {schemeOverrideConfiguration && !auth.merchant?.id && (
              <div>
                <Button
                  colorScheme={"black"}
                  label={t("preferences") as string}
                  className={
                    "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"
                  }
                  onClick={() => togglePreferencesModal()}
                ></Button>
              </div>
            )}
            <div className="hidden">
              <Button
                colorScheme={"black"}
                label={t("Edit") as string}
                className={
                  "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"
                }
                onClick={() => toggleEditNameModal()}
              ></Button>
            </div>
          </div>
        </div>
      </div>

      {terminal?.profile &&
      Object.keys(terminal?.profile?.schemes || {}).length ? (
        <Card>
          <div className="flex flex-col lg:flex-row flex-wrap justify-between gap-2 md:gap-0">
            <div className="flex justify-between  gap-2 w-full lg:basis-1/2">
              <div className="flex flex-col basis-1/2 gap-2">
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("nameArabic")}</div>
                  <div className="font-bold">
                    {terminal?.profile?.merchant?.name?.arabic}
                  </div>
                </div>
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("retailerCategory")}</div>
                  <div className="font-bold">
                    {terminal?.profile?.merchant?.category?.english}
                  </div>
                </div>
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("addressEnglish")}</div>
                  <div className="font-bold">
                    {terminal?.profile?.merchant?.address?.english}
                  </div>
                </div>
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("acquirer")}</div>
                  <div className="font-bold">{terminal?.bank?.code}</div>
                </div>
              </div>
              <div className="flex flex-col md:basis-1/2 gap-2">
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("nameEnglish")}</div>
                  <div className="font-bold">
                    {terminal?.profile?.merchant?.name?.english}
                  </div>
                </div>
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("retailerCurrency")}</div>
                  <div className="font-bold">
                    {terminal?.currency_symbol_english}
                  </div>
                </div>
                <div className="flex flex-col basis-1/2">
                  <div className="text-sm">{t("addressArabic")}</div>
                  <div className="font-bold">
                    {terminal?.profile?.merchant?.address?.arabic}
                  </div>
                </div>
              </div>
            </div>
            <div className="  w-full lg:basis-1/2 p-2 bg-gray-100 rounded flex flex-col">
              <SchemesOnlyProfile profile={terminal?.profile} />
            </div>
          </div>
        </Card>
      ) : (
        <EmptyState
          title={
            "Profile not found, Please try full filedownload or contact the bank"
          }
        />
      )}
      <FileDownloadModal
        terminal_id={terminal.id}
        isOpen={isFileDownloadOpen}
        toggleModal={toggleFileDownload}
        setRefresh={setRefreshTerminal}
      />
    </div>
  );
};
