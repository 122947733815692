import React, { useContext } from "react";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, MerchantsListItem, TerminalListItem, TrashIcon } from "@lib";
import { MerchantItem } from "@lib/list-item/merchant-list";
import { Badge, BadgeType } from "@lib/shared/badge";
import { times } from "@lib/types/policy";
import { TerminalItem } from "@lib/types/terminal";
import {
  findMerchantApi,
  MerchantResponse,
} from "@lib/utils/api/merchants-apis";
import {
  findPolicyApi,
  PolicyResponse,
  removePolicyApi,
} from "@lib/utils/api/policies-apis";
import {
  findTerminalApi,
  TerminalResponse,
} from "@lib/utils/api/terminals-apis";
import ManagePoliciesModal from "@lib/components/modals/manage-policies";
import { ListPolicyJobs } from "./list-jobs";

import { PolicyType } from "./policy-type";
import UserContext, { UserContextType } from "src/contexts/UserContext";

type Props = {
  id: string;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

const ViewPolicy: FC<Props> = ({
  id,
  setIsLoading,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState<Partial<PolicyResponse>>({});
  const [merchant, setMerchant] = useState<Partial<MerchantResponse>>();
  const [terminal, setTerminal] = useState<Partial<TerminalResponse>>();
  const [refresh, setRefresh] = useState(false);
  const { auth } = useContext(UserContext) as UserContextType;

  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal((prev) => !prev);
  const findPolicy = async () => {
    try {
      setIsLoading(true);
      const policy = await findPolicyApi(id);
      console.log({ policy });

      if (policy.type === "merchant" && !auth.merchant?.id) {
        const merchant = await findMerchantApi({ id: policy.relation_id });
        setMerchant(merchant);
      } else if (policy.type === "terminal") {
        const terminal = await findTerminalApi({ id: policy.relation_id });
        setTerminal(terminal);
      }

      setPolicy(policy);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
    }
  };

  const removePolicy = async () => {
    if (window.confirm("Are you sure to remove this policy"))
      try {
        setIsLoading(true);
        const resposne = await removePolicyApi(id);
        setIsLoading(false);
        if (resposne) navigate("/policies");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
  };

  useEffect(() => {
    findPolicy();
  }, [id, refresh]);

  return (
    <div className="group policy">
      {/* header */}
      <div className="flex justify-between items-center">
        <div className="flex items-end gap-x-3 text-xl lg:text-3xl font-bold">
          <h2 className="ps-4 capitalize">{t("reconciliationPolicy")}</h2>
          <span className="[direction:ltr]">
            {times[policy.time as keyof typeof times]}
          </span>
        </div>
        <div className="flex justify-between items-center gap-2">
          <Button
            label={""}
            colorScheme="red"
            isRounded={false}
            icon={<TrashIcon />}
            className={"!p-1.5 px-2"}
            onClick={removePolicy}
          />
          {/* <span>
            <TrashIcon />
          </span> */}
          <Button
            label={t("edit") as string}
            colorScheme="black"
            isRounded={false}
            onClick={handleToggleModal}
          />
        </div>
      </div>
      {/** Main content  */}
      <div className="flex flex-col gap-2 lg:p-4 mt-4 lg:mt-0 group merchant ">
        {/** Terminals starts here !!  */}
        <div className="flex flex-col ">
          <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold">
            {t("type")}
          </div>
          <PolicyType type={policy["type"]} disabled={true} />
        </div>
        {merchant && (
          <MerchantsListItem role={"client"} item={merchant as MerchantItem} />
        )}

        {terminal && (
          <TerminalListItem role="client" item={terminal as TerminalItem} />
        )}

        <div>
          <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold">
            {t("report")}
          </div>
          <ListPolicyJobs refresh={false} />
        </div>
      </div>
      <ManagePoliciesModal
        toggleModal={handleToggleModal}
        isOpen={toggleModal}
        setRefresh={setRefresh}
        policy={policy}
      />
    </div>
  );
};

export default ViewPolicy;
