import { t } from "i18next";
import React, { ReactNode } from "react";
type props = {
  className?: string;
  title: string;
  onClick: () => void;
  Icon: any;
  hidden?: boolean;
};
export const IconButton = ({
  onClick,
  className,
  Icon,
  title,
  hidden,
}: props) => {
  return (
    <>
      {hidden ? null : (
        <button
          onClick={onClick}
          className={
            " px-2 pr-4 gap-1 h-9 flex rtl:flex-row-reverse items-center bg-[#F2F2F7] border border-[#E5E5E6] text-xs sm:text-sm font-medium rounded-md hover:bg-gray-100 focus:outline-none" +
            className
          }
        >
          {<Icon height={20} width={20} />} {t(title)}
        </button>
      )}
    </>
  );
};
