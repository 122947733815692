import { FC, SetStateAction, useEffect, useState } from "react";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import DownloadTransactionsButton from "@lib/components/shared/download-transactions-btn";
import ListTransactions from "@lib/components/transactions/list";
import TransactionsTable from "@lib/tables/transactions-table";
import Content from "@lib/layout/content";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import { dateRange } from "@lib/components/query-builder/date-picker";
import { useTranslation } from "react-i18next";

type Props = {};

const Transactions: FC<Props> = ({}: Props) => {
  const [refreshList, setRefreshList] = useState(false);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("All");
  const [transactions, setTransactions] = useState<any[]>([]);
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@transactionsDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@transactionsQueryParams"
  );

  return (
    <Content
      title={t("transactions")}
      queryBuilder={
        <QueryBuilder
          type="transactions"
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          setDateRange={
            setDateRange as React.Dispatch<SetStateAction<dateRange>>
          }
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      }
      actionBtns={
        <DownloadTransactionsButton
          transactions={transactions}
          queryParams={queryParams}
        />
      }
    >
      <TransactionsTable
        refresh={refreshList}
        setTransactionsForDownload={setTransactions}
        dateRange={dateRange as dateRange}
        queryParams={queryParams}
      />
      {/* <ListTransactions
        refresh={refreshList}
        setIsLoading={setIsLoading}
        setTransactionsForDownload={setTransactions}
        dateRange={dateRange as dateRange}
        queryParams={queryParams}
      /> */}
    </Content>
  );
};

export default Transactions;
