import { FC } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  to?: string;
};

const Redirect: FC<Props> = ({ to = "/login" }) => {
  return <Navigate to={to} />;
};

export default Redirect;
