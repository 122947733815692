import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Input, Modal, Select, UserIcon } from "@lib";
import { MerchantResponse } from "@lib/utils/api/merchants-apis";
import { createUserApi } from "@lib/utils/api/users-apis";
import {
  countriesOptions,
  defaultCountriesOption,
} from "../../utils/countriesOptions";
import { IError } from "@lib/nice-errors";

type Props = {
  isOpen: boolean;
  toggleModal: any;
  merchant_id?: string;
  user: ManageUser;
  setUser: React.Dispatch<SetStateAction<ManageUser>>;
  merchants?: MerchantResponse[];
  setRefreshUsers: React.Dispatch<SetStateAction<boolean>>;
};

export type ManageUser = {
  name: string;
  mobile: string;
  merchant_id: string;
  email: string;
};

export const initialUserState: ManageUser = {
  name: "",
  mobile: "",
  merchant_id: "",
  email: "",
};

const ManageUserModal = ({
  isOpen,
  merchant_id,
  toggleModal,
  setRefreshUsers,
  user,
  setUser,
  merchants = [],
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newAdminErrors, setNewAdminErrors] = useState<IError | null>(null);
  const { t } = useTranslation();
  const saveUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      setNewAdminErrors(null);
      e.preventDefault();
      merchant_id
        ? await createUserApi({ ...user, merchant_id: merchant_id as string })
        : await createUserApi({ ...user });
      setRefreshUsers((prevState) => !prevState);
      setIsLoading(false);
      close();
    } catch (e: any) {
      console.log({ e });
      setNewAdminErrors(e);
      setIsLoading(false);
    }
  };

  const close = () => {
    setUser(() => initialUserState);
    setNewAdminErrors(null);
    setIsLoading(() => false);
    toggleModal();
  };

  const { i18n } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t("newUser")}
      subTitle={t("user")}
      icon={UserIcon}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <div className="flex flex-col">
        <form>
          <Input
            type="text"
            name="name"
            onChange={(e) =>
              setUser((prevState: ManageUser) => ({ ...prevState, name: e }))
            }
            value={user.name}
            label={t("name") as string}
            errors={newAdminErrors}
          />
          <Input
            label={t("email") as string}
            type="email"
            onChange={(e) =>
              setUser((prevState: ManageUser) => ({
                ...prevState,
                email: e,
              }))
            }
            value={user.email}
            name="email"
            errors={newAdminErrors}
          />

          <Input
            type="phone"
            defaultDropDownOption={defaultCountriesOption}
            label={t("mobile") as string}
            value={user.mobile}
            name="mobile"
            onChange={(e) =>
              setUser((prevState: ManageUser) => ({
                ...prevState,
                mobile: e,
              }))
            }
            dropDownOptions={countriesOptions}
            dropDownValueKey="dial_code"
            dropDownDisplayKey="display_name"
            errors={newAdminErrors}
          />

          <div
            className={
              i18n.language == "ar" ? "text-right my-2" : "text-left my-2"
            }
          >
            {!merchant_id ? (
              <Select
                isDisabled={!merchants.length}
                data={merchants as { [key: string]: any }[]}
                valueKey="id"
                displayKey="name"
                onChange={(merchantId) => {
                  setUser((prevState: ManageUser) => ({
                    ...prevState,
                    merchant_id: merchantId,
                  }));
                }}
                label={t("merchant")}
                placeholder={t("selectMerchant")}
                name="merchant_id"
                value={user.merchant_id}
                errors={newAdminErrors}
              />
            ) : null}
          </div>
          <Button
            onClick={saveUser}
            label={t("save") as string}
            colorScheme="black"
            isRounded={false}
            className={"my-4 px-2 py-1"}
            // classNames="my-4 "
          />
        </form>
      </div>
    </Modal>
  );
};

export default ManageUserModal;
