import { TableHead, TableHeaderCell, TableRow } from "@tremor/react";
import { t } from "i18next";
import React from "react";

export const MerchantsHeader = () => {
  return (
    <TableHead className="border-b !sticky top-0">
      <TableRow className="propagate-header bg-white">
        <TableHeaderCell className="rtl:text-right">
          {t("name")}
        </TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">
          {t("arabicName")}
        </TableHeaderCell>
        {/* <TableHeaderCell className="text-center">{t("class")}</TableHeaderCell> */}
        {/* <TableHeaderCell className="rtl:text-right">
          {t("activity")}
        </TableHeaderCell> */}
        <TableHeaderCell className="rtl:text-right">
          {t("referenceId")}
        </TableHeaderCell>
        {/* <TableHeaderCell className="rtl:text-right">
          {t("sales")}
        </TableHeaderCell> */}
        {/* <TableHeaderCell className="rtl:text-right">
          {t("terminals")}
        </TableHeaderCell> */}
        {/* <TableHeaderCell className="rtl:text-right">
          {t("life")}
        </TableHeaderCell> */}
        {/* <TableHeaderCell className="rtl:text-right">
          {t("lastSeen")}
        </TableHeaderCell> */}
      </TableRow>
    </TableHead>
  );
};
