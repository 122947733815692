import { FC, useEffect, useMemo, useState } from "react";
import { Modal, Badge, GroupedList, SearchIcon } from "@lib";
import { ClientIcon } from "@lib/icons/client";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { getTokenApi, listClientsApi } from "@lib/utils/api/clients-api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientsSwitchItem } from "@lib/list-item/clients-switch";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  selectClient: (client_id: boolean) => void;
  admin_id?: string;
  curr_client_id?: string;
  addToken?: (accessToken: string, refreshToken: string) => void;
};

const SwitchClient: FC<Props> = ({
  isOpen,
  toggleModal,
  selectClient,
  admin_id,
  curr_client_id,
  addToken,
}: Props) => {
  const close = () => {
    toggleModal();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(curr_client_id);
  const [pages, setPages] = useState(1);
  const trigger = useMemo(() => {
    return curr_client_id === selectedClient;
  }, [curr_client_id, selectedClient]);
  let [clientsList, setClientsList] = useState<any[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let clientsListApi = async ({
    page = 1,
    name,
  }: {
    page?: number;
    name?: string;
  }) => {
    try {
      setIsLoading(true);
      await listClientsApi({
        admin_id,
        page,
        name,
      }).then((res) => {
        let { pages, clients } = res;
        let newClients = [
          ...clients?.filter?.((client: any) => client.id === curr_client_id),
          ...clients?.filter?.((client: any) => client.id !== curr_client_id),
        ];
        setClientsList(newClients);
        setPages(pages);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
      // check if version 0 you should toggle modal here
    }
  };

  let getToken = async (client_id: string) => {
    try {
      setIsLoading(true);
      setSelectedClient(client_id);
      await getTokenApi({ client_id, admin_id }).then(
        ({ accessToken, refreshToken }) => {
          addToken?.(accessToken, refreshToken);
        }
      );
      selectClient(true);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);

      // close();
    }
  };

  let debounceFn: number | any;
  useEffect(() => {
    if (!trigger) navigate("/");
  }, [selectedClient]);

  useEffect(() => {
    if (admin_id) clientsListApi({});
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t("selectAccount")}
      subTitle={t("account")}
      icon={ClientIcon}
    >
      {/* <LoadingSpinner isLoading={isLoading} isTransparent /> */}
      <div className="relative flex items-center my-2">
        <input
          type="search"
          onChange={(e) => {
            clearTimeout(debounceFn);
            debounceFn = setTimeout(() => {
              if (e.target.value)
                clientsListApi({ name: e.target.value.trim() });
            }, 1000);
          }}
          placeholder={t("search by name")}
          className="peer w-full flex items-center  placeholder:flex placeholder:items-center placeholder:text-gray-400 placeholder:text-xs rounded-lg py-1 mb-1 border-gray-300 invalid:border-red-500 invalid:ring-red-500"
        />
        <SearchIcon
          size={18}
          containerClassNames="absolute rtl:left-0 ltr:right-0 top-0 hidden peer-placeholder-shown:inline"
          color="text-gray-400"
        />
      </div>
      <div>
        <GroupedList
          pages={{
            total: pages,
            selectPage: (page: number) => clientsListApi({ page }),
          }}
          role={"client"}
          data={clientsList}
          component={ClientsSwitchItem}
          onItemClick={(item) => getToken(item?.id)}
          groupBy={{
            key: "created_at",
            isDate: true,
          }}
          hideKey
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default SwitchClient;
