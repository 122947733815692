import { HashRouter } from "react-router-dom";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import { UserContextProvider } from "../contexts/UserContext";
import Router from "@lib/utils/router";

import { useTranslation } from "react-i18next";
import { withLDProvider } from "launchdarkly-react-client-sdk";

const App = ({}) => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <HashRouter>
      <UserContextProvider>
        <ThemeContextProvider>
          <Router />
        </ThemeContextProvider>
      </UserContextProvider>
    </HashRouter>
  );
};

const WrappedApp = process.env.REACT_APP_LAUNCHDARKLY
  ? withLDProvider({
      clientSideID: process.env.REACT_APP_LAUNCHDARKLY,
      context: {
        key: "anonymous",
        anonymous: true,
      },
    })(App)
  : App;

export default WrappedApp;
