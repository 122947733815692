import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  EmptyState,
  Invite,
  LockClosedIcon,
  LockOpenIcon,
  MerchantsListItem,
  TerminalIcon,
  UserListItem,
} from "@lib";
import ListTerminalConnections from "../terminal-connections/list";
import { Badge, BadgeType } from "@lib/shared/badge";
import {
  disconnectTerminalApi,
  fileDownloadApi,
  findTerminalApi,
  toggleTerminalIsLockedApi,
  unAssginTerminalApi,
} from "@lib/utils/api/terminals-apis";
import AssignTerminalToUserModal from "../modals/assign-terminal-to-user";
import ChangeTerminalMerchant from "../modals/terminal-change-merchant-modal";
import ListReconciliations from "../reconciliations/list";
import ListTransactions from "../transactions/list";
import moment from "moment";
import { reconcileApi } from "@lib/utils/api/reconciliations-apis";
import { ReactComponent as ReconcileIcon } from "../../../assets/reconcile.svg";
import SchemesPreferencesModal from "../modals/schemes-preferences";
import { useFlags } from "launchdarkly-react-client-sdk";
import { AppItem } from "../apps/item";
import EditTerminalModal from "../modals/edit-terminal";
import { ProfileSection } from "./profile";
import UserContext, { UserContextType } from "src/contexts/UserContext";
import DownloadTransactionsButton from "../shared/download-transactions-btn";
import { usePreservedState } from "src/custom-hooks/UsePreservedState";

type Props = {
  id: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const ViewTerminal = ({ id, setIsLoading, isLoading }: Props) => {
  const [terminal, setTerminal] = useState<{ [key: string]: any }>({});
  const [refreshTerminal, setRefreshTerminal] = useState(false);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const [refreshReconciliations, setRefreshReconciliations] = useState(false);
  const [reconcileButtonState, setReconcileButtonState] = useState(true);
  const [isAssignToUserModalOpen, setIsAssignToUserModal] = useState(false);
  const [isTerminalChangeMerchantModalOpen, setIsTerminalChangeMerchantModal] =
    useState(false);
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);

  const [reconciliationsIsLoading, setReconciliationsIsLoading] =
    useState(true);
  const [transactionsIsLoading, setTransactionsIsLoading] = useState(true);

  const [isLocked, setIsLocked] = useState<boolean>(terminal.is_locked);
  const { auth } = useContext(UserContext) as UserContextType;

  const { t } = useTranslation();
  let { v2TerminalsUpgrades } = useFlags();

  let { terminalLock } = useFlags();

  const toggleAssignUserModal = () => {
    setIsAssignToUserModal((prevState: boolean) => !prevState);
  };
  const toggleChangeTerminalModal = () => {
    setIsTerminalChangeMerchantModal((prev: boolean) => !prev);
  };
  const togglePreferencesModal = () => {
    setIsPreferencesModalOpen((prevState: boolean) => !prevState);
  };
  const toggleEditNameModal = () => {
    setIsEditNameModalOpen((prevState: boolean) => !prevState);
  };
  const disconnectDevice = async (device_id: string) => {
    if (window.confirm("Are you sure, you want to remove this connection?")) {
      await disconnectTerminalApi({
        terminal_id: terminal.id,
        device_id,
      });
      return true;
    }
    return false;
  };

  const UnAssignTerminal = async () => {
    if (window.confirm("Are you sure, you want to remove access ?")) {
      await unAssginTerminalApi({
        id: terminal.id,
      });
      setRefreshTerminal((prevState) => !prevState);
    }
  };

  const reconcile = async ({
    terminal_id,
    merchant_id,
  }: {
    terminal_id: string;
    merchant_id: string;
  }) => {
    try {
      setReconcileButtonState(false);
      await reconcileApi({ terminal_id, merchant_id });
      setRefreshReconciliations((prev) => !prev);
      setRefreshTransactions((prev) => !prev);
    } catch (error) {
    } finally {
      setReconcileButtonState(true);
    }
  };

  const fileDownload = async (isFull: boolean) => {
    setIsLoading(true);
    await fileDownloadApi({ id: terminal.id, isFull });
    setRefreshTerminal((prev) => !prev);
  };

  const findTerminal = async () => {
    try {
      setIsLoading(true);
      const terminal = await findTerminalApi({ id: id as string });
      setIsLocked(terminal.is_locked);
      setTerminal(terminal);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const toggleTerminalIsLocked = async () => {
    try {
      setIsLoading(true);
      const response = await toggleTerminalIsLockedApi({
        terminal_id: terminal.id,
        is_locked: !isLocked,
      });
      setIsLocked((prev) => (response ? !prev : prev));
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(() => false);
  }, [terminal]);

  useEffect(() => {
    findTerminal();
  }, [refreshTerminal]);

  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@transactionsQueryParams"
  );

  const isConnectedBadge: BadgeType = terminal.is_connected
    ? { label: t("deviceConnected"), color: "gray" }
    : { label: t("deviceNotConnected"), color: "green" };
  const isRegisteredBadge: BadgeType = terminal.is_assigned_to_user
    ? { label: t("terminalAssinged"), color: "gray" }
    : { label: t("terminalNotAssinged"), color: "green" };
  const versionBadge: BadgeType =
    terminal.version === "v1"
      ? { label: "Version 1", color: "indigo", isRounded: true }
      : { label: "Version 2", color: "yellow", isRounded: true };

  return terminal.id ? (
    <>
      {/* this is container */}
      <div className="flex flex-col group terminal">
        <div className="lg:text-3xl text-xl font-semibold">{t("info")}</div>
        <div className="flex flex-col xs:flex-row items-center justify-between my-4 gap-3">
          {/* left  */}
          <div className="flex items-center gap-1">
            <div className="">
              <TerminalIcon />
            </div>
            <div className="flex flex-col">
              <div className="text-sm">
                {terminal?.name ? terminal?.tid : t("terminalIdentifier")}
              </div>
              <div className="font-bold lg:text-lg text-base">
                {terminal?.name ? terminal.name : "#" + terminal?.tid}
              </div>
              <small>
                {moment(terminal?.created_at).format("YYYY-MM-DD hh:mm:ss A")}{" "}
              </small>
            </div>
          </div>

          {/* right  */}
          <div className="flex flex-col sm:flex-row items-center gap-4 justify-around lg:flex-initial lg:mt-0 ">
            <button
              onClick={toggleTerminalIsLocked}
              className="transition-all tooltip p-0"
              data-tip="when terminal is locked you can't connect another device"
            >
              {terminalLock &&
                (isLocked ? (
                  <LockClosedIcon
                    color="text-teal-700"
                    containerClassNames="lg:p-0"
                  />
                ) : (
                  <LockOpenIcon
                    color="text-teal-700"
                    containerClassNames="lg:p-0"
                  />
                ))}
            </button>

            {true && <Badge {...versionBadge} />}
            {/* <Badge isRounded label={`TRSM: ${terminal?.trsm}`} /> */}
            <Badge {...isRegisteredBadge} isRounded={true} />
            <Badge {...isConnectedBadge} isRounded={true} />
          </div>
        </div>
        {/* profile start  */}
        <ProfileSection
          setRefreshTerminal={setRefreshTerminal}
          terminal={terminal}
          setIsLoading={setIsLoading}
          togglePreferencesModal={togglePreferencesModal}
          toggleEditNameModal={toggleEditNameModal}
        />
        {/* profile end */}
        <div className="flex flex-col">
          <div
            className={auth.merchant?.id ? "hidden" : "w-full flex flex-col"}
          >
            <div className="text-xl lg:text-3xl font-semibold mt-4">
              {t("merchant")}
            </div>
            {terminal?.merchant?.id ? (
              <MerchantsListItem
                role={"client"}
                item={terminal?.merchant}
                actionButtons={[
                  <Button
                    isRounded={false}
                    colorScheme="red"
                    size="sm"
                    className="px-4 py-1 text-xs"
                    label={t("changeMerchant") as string}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleChangeTerminalModal();
                    }}
                  />,
                ]}
              />
            ) : (
              <EmptyState title={t("itemNotFound")} />
            )}
          </div>
          <div className="text-xl lg:text-3xl font-semibold mt-4">
            {t("access")}
          </div>
          <div className="lg:flex grid grid-cols-1 gap-4 ">
            <div className="flex flex-col flex-1 mt-3">
              {terminal.user?.name ? (
                <UserListItem
                  title={t("user")}
                  role={"client"}
                  item={terminal.user}
                  assignToUser={toggleAssignUserModal}
                  actionButtonOnClick={UnAssignTerminal}
                />
              ) : (
                <EmptyState
                  componentTitle={t("user")}
                  component={
                    <div className="">
                      <Button
                        colorScheme={"black"}
                        size={"sm"}
                        className={"px-4 text-xs lg:px-4 lg:text-sm w-full"}
                        label={
                          <div className="flex items-center">
                            <div>
                              <Invite color="fill-white" size={18} />
                            </div>
                            <span className="text-sm block">
                              {t("inviteUser")}
                            </span>
                          </div>
                        }
                        onClick={toggleAssignUserModal}
                      />
                    </div>
                  }
                />
              )}
            </div>

            <div className="flex flex-col flex-1 mt-3">
              <span className="text-lg font-bold text-gray-600">
                {t("device")}
              </span>
              {terminal.id ? (
                <ListTerminalConnections
                  terminal_id={terminal.id}
                  refreshTerminal={refreshTerminal}
                  limit={5}
                  disconnectTerminal={disconnectDevice}
                />
              ) : (
                <EmptyState title={t("itemNotFound")} />
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 lg:flex flex-col justify-between gap-4 mt-4">
            <div className="flex flex-col flex-1 details">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold mb-2">
                <div className="flex justify-between">
                  <div>{t("transactions")}</div>
                  <div>
                    <DownloadTransactionsButton
                      queryParams={{
                        "terminal.id": {
                          value: terminal.reference_terminal_id,
                          operator: "Equal",
                          valueDisplay: {
                            [terminal.reference_terminal_id]: "",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              {terminal?.reference_terminal_id ? (
                <ListTransactions
                  terminal_id={terminal?.reference_terminal_id}
                  limit={5}
                  refresh={refreshTransactions}
                  // setIsLoading={setTransactionsIsLoading}
                />
              ) : (
                <EmptyState title={t("itemNotFound")} />
              )}
            </div>
            <div className="flex flex-col flex-1 reconciliation">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                <div className="flex justify-between ">
                  <div>{t("reconciliations")}</div>
                  <div className="max-w-fit">
                    <Button
                      colorScheme={"default"}
                      size={"default"}
                      className={"px-4 py-1 text-xs lg:text-sm w-full"}
                      label={
                        <div className="flex items-center gap-1">
                          <div>{<ReconcileIcon color={"black"} />}</div>
                          <span className="text-sm block">
                            {t("reconcile")}
                          </span>
                        </div>
                      }
                      onClick={() =>
                        reconcile({
                          terminal_id: terminal?.reference_terminal_id,
                          merchant_id: terminal?.merchant?.id,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {terminal?.reference_terminal_id ? (
                <ListReconciliations
                  terminal_id={terminal?.reference_terminal_id}
                  limit={5}
                  refresh={refreshReconciliations}
                  setRefresh={setRefreshReconciliations}
                  // setIsLoading={setReconciliationsIsLoading}
                />
              ) : (
                <EmptyState title={t("itemNotFound")} />
              )}
            </div>

            <div className="">
              {terminal?.client_package?.id ? (
                <AppItem client_package={terminal.client_package} />
              ) : (
                <>
                  <h2 className="text-lg font-bold text-gray-600">
                    {t("App")}
                  </h2>
                  <EmptyState title={t("itemNotFound")} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {terminal?.id ? (
        <>
          <AssignTerminalToUserModal
            isOpen={isAssignToUserModalOpen}
            toggleModal={toggleAssignUserModal}
            terminalId={terminal?.id}
            refresh={() => setRefreshTerminal(!refreshTerminal)}
            merchant_id={terminal.merchant.id}
          />
          {/* {!auth.merchant?.id && (
            <>
              <ChangeTerminalMerchant
                isOpen={isTerminalChangeMerchantModalOpen}
                toggleModal={toggleChangeTerminalModal}
                terminal_id={terminal?.id}
                refresh={() => setRefreshTerminal(!refreshTerminal)}
                merchant={terminal.merchant}
              />
              <SchemesPreferencesModal
                isOpen={isPreferencesModalOpen}
                toggleModal={togglePreferencesModal}
                terminal={terminal}
                refresh={() => setRefreshTerminal(!refreshTerminal)}
              ></SchemesPreferencesModal>
            </>
          )} */}
          <EditTerminalModal
            isOpen={isEditNameModalOpen}
            toggleModal={toggleEditNameModal}
            terminal={terminal}
            refresh={() => setRefreshTerminal(!refreshTerminal)}
          ></EditTerminalModal>
        </>
      ) : null}
    </>
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ViewTerminal;
