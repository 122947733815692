import React, {
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import { useTranslation } from "react-i18next";
import { ReconiliationItem } from "@lib/types/reconciliations";
import { findReconiliationApi } from "@lib/utils/api/reconciliations-apis";
import LoadingSpinner from "@lib/layout/loading-spinner";
import {
  Button,
  Card,
  formatPrice,
  MerchantsListItem,
  Remove,
  TerminalListItem,
  Tick,
  UserListItem,
} from "@lib";
import { PaymentSchemeReconciliation } from "./payment-scheme-details";
import ListTransactions from "@lib/components/transactions/list";
import { DeviceListItem } from "@lib/list-item/device-list";
import NotFound404 from "@lib/shared/404";
import UserContext, { UserContextType } from "src/contexts/UserContext";

type Props = {
  id: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const ViewReconciliation: FC<Props> = ({
  id,
  isLoading,
  setIsLoading,
}: Props) => {
  const { auth } = useContext(UserContext) as UserContextType;
  const [reconciliation, setReconiliation] = useState<ReconiliationItem>(
    {} as ReconiliationItem
  );
  const [isReceiptFound, setIsReceiptFound] = useState(true);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const findReconiliation = async () => {
    try {
      const response = await findReconiliationApi({
        id: id as string,
      });
      if (response.reconciliation) setReconiliation(response.reconciliation);
      else setReconiliation(response);
      setIsLoading(false);
    } catch (e: any) {
      console.log({ e: e.error.body.statusCode });
      if (e.error.body.statusCode === 404) setIsReceiptFound(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    findReconiliation();
  }, []);
  return isReceiptFound ? (
    <div className="rounded group reconciliation">
      <div className="flex justify-between items-center mb-10 pb-3 capitalize border-b">
        <h4 className="font-bold text-3xl">{t("reconciliation")}</h4>
        <a href={reconciliation?.qr_code} target="_blank">
          <Button
            onClick={() => {}}
            label={t("seeReceipt") as string}
            colorScheme="default"
            isRounded={true}
          />
        </a>
      </div>
      <div>
        <LoadingSpinner isLoading={isLoading} />
        <div className="flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row justify-between items-center mb-4 ">
          <div className="flex flex-row items-center lg:jusitfy-start gap-4 flex-1">
            <span className="font-medium text-2xl lg:text-3xl">
              <span className="font-bold">
                {formatPrice(Number(reconciliation?.details?.total?.total))}
              </span>
            </span>
            <div className="flex flex-row gap-x-2 items-center">
              {reconciliation?.is_balanced?.value ? (
                <>
                  <div className="rounded-full bg-green-700">
                    <Tick color="text-white" size={18} />
                  </div>
                  <span className="font-bold">
                    {i18n.language == "ar"
                      ? reconciliation.is_balanced?.label?.arabic
                      : reconciliation.is_balanced?.label?.english}
                  </span>
                </>
              ) : (
                <>
                  <div className="rounded-full bg-red-600">
                    <Remove color="text-white" size={18} />
                  </div>
                  <span className="font-bold">{t("notBalanced")}</span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col items-end flex-1">
            <div className="flex flex-row gap-x-2 lg:gap-x-0 items-center lg:flex-col justify-between">
              <span className="text-md float-left">{t("date&time")}</span>
              <span className="font-bold text-lg">
                {reconciliation?.date} {reconciliation?.time}
              </span>
            </div>
          </div>
        </div>
        <span className="block text-lg lg:text-2xl font-bold mb-2">
          {t("terminalDetails")}
        </span>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 mb-8">
          {Object.keys(reconciliation?.details || []).map(
            (key: any, index: number) => {
              if (key == "total") return;
              const item =
                reconciliation?.details[
                  key as keyof typeof reconciliation.details
                ];
              return (
                <Card key={index} classNames="px-4 py-4">
                  <div className="flex flex-col">
                    <span className="font-bold text-md capitalize">
                      {t(item?.label?.english)} ({item?.count})
                    </span>
                    <span className="text-2xl font-bold">
                      {formatPrice(item?.total)}
                    </span>
                  </div>
                </Card>
              );
            }
          )}
        </div>
        <br />
        <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-3 gap-2">
          {reconciliation?.schemes?.map((item: any, index: number) => (
            <PaymentSchemeReconciliation key={index} item={item} />
          ))}
        </div>
      </div>

      {reconciliation.id && (
        <>
          <div className="py-2 mt-2 border-t details">
            <h1 className="lg:text-2xl text-lg font-semibold text-gray-900 mt-2">
              Transactions
            </h1>
            <ListTransactions
              refresh={true}
              // setIsLoading={setIsLoading}
              reconciliation_id={reconciliation.id}
              limit={10}
            />
          </div>
          <div className="py-2 border-t grid grid-cols-1 lg:grid-cols-2 gap-2 mt-6">
            {!auth.merchant?.id && (
              <>
                <div className="lg:col-span-2">
                  <TerminalListItem
                    title={t("terminal")}
                    role="client"
                    item={reconciliation.terminal}
                    onClick={(terminal) =>
                      navigate("/terminals/" + terminal.id)
                    }
                  />
                </div>
                <div className="col-span-2">
                  <MerchantsListItem
                    title={t("merchant")}
                    role="client"
                    item={reconciliation.merchant}
                    onClick={(merchant) =>
                      navigate("/merchants/" + merchant.id)
                    }
                  />
                </div>
              </>
            )}
            {reconciliation.user?.id && (
              <div className="">
                <UserListItem
                  title={t("user")}
                  role="client"
                  item={reconciliation.user}
                />
              </div>
            )}
            <div className="">
              <DeviceListItem
                title={t("device")}
                role="client"
                item={reconciliation?.device}
              />
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <NotFound404 message="Reconciliation cannot be displayed due to a technical issue" />
  );
};

export default ViewReconciliation;
