import { t } from "i18next";
import React, { FC, SetStateAction, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { EmptyState, Paginator } from "@lib";
import { WebhookEvent, WebhookMethod, WebhookStatus, WebhookURL } from ".";
import { listWebhookLogApi } from "@lib/utils/api/webhooks-apis";
import { LogView } from "./log-view";
import { dateRange } from "@lib/types/date";

interface WebhooksLogs {
  id: string;
  method: string;
  status_code: string;
  url: string;
  created_at: string;
  event: string;
}

type Props = {
  refresh?: boolean;

  setIsLoading?: React.Dispatch<SetStateAction<boolean>>;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const ListLogs: FC<Props> = ({
  refresh,
  setIsLoading,
  queryParams,
  dateRange,
}: Props) => {
  const navigate = useNavigate();
  const [listLogs, setListLogs] = useState<WebhooksLogs[]>([]);
  const [pages, setPages] = useState(1);
  const [id, setId] = useState("");
  const handleLoading = (value: boolean) => setIsLoading && setIsLoading(value);
  const listWebhookLogs = async (page = 1) => {
    try {
      handleLoading(true);
      const response = await listWebhookLogApi({
        page,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setListLogs(response.logs);
      setPages(response.pages);
      handleLoading(false);
    } catch (e) {
      handleLoading(false);
    }
  };

  useEffect(() => {
    listWebhookLogs();
  }, [refresh]);
  return listLogs.length ? (
    <div className="flex flex-col md:flex-row items-start gap-4">
      <div className="md:w-1/2 h-full flex flex-col min-h-screen justify-between">
        <div>
          <div className="grid grid-cols-8 gap-2 pb-1 px-2 capitalize text-xs lg:text-base lg:font-bold">
            <span className="col-span-1 2xl:px-2">Status</span>
            <span className="col-span-2 xl:px-4">event</span>
            <span className="col-span-1  3xl:px-4">method</span>
            <span className="col-span-4 px-4">url</span>
          </div>
          {listLogs.map((log, index) => {
            return (
              <div
                key={index}
                className={
                  "w-full  items-center align-center py-2 px-1 mb-2 grid grid-cols-8 gap-2 text-xs border rounded-md hover:bg-gray-100 cursor-pointer transition-all  " +
                  (log.id === id && "bg-gray-100")
                }
                onClick={() => {
                  setId(log.id);
                }}
              >
                <div className="col-span-1 2xl:px-2">
                  <WebhookStatus status={log.status_code} />
                </div>
                <div className="col-span-2  xl:px-4">
                  <WebhookEvent event={log.event} />
                </div>
                <div className="col-span-1  3xl:px-4">
                  <WebhookMethod method={log.method} />
                </div>
                <div className="col-span-4  px-4 ">
                  <WebhookURL url={log.url} />
                </div>
              </div>
            );
          })}
        </div>
        {pages && pages > 1 ? (
          <div className="w-full  flex justify-end items-end ">
            <Paginator
              pages={pages}
              selectPage={(page: number) => listWebhookLogs(page)}
            />
          </div>
        ) : null}
      </div>
      <div className="max-w-full md:w-1/2">
        {id && <LogView id={id} />}
        {/* <Outlet /> */}
      </div>
    </div>
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListLogs;
