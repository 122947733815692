import { TableCell, TableRow, Text, Badge, Icon, Flex } from "@tremor/react";
import React from "react";

import { banks } from "@lib/components/query-builder/data";
import { t } from "i18next";
import { TransactionItem } from "@lib/types/transaction";
import { useNavigate } from "react-router-dom";
import { MerchantItem } from "@lib/list-item/merchant-list";

// id: string;
//   name: string;
//   name_ar?: string;
//   reference_id?: string;
//   status: "active" | "deactive";
//   created_at: string;

export const MerchantRow = ({ item }: { item: MerchantItem }) => {
  const navigate = useNavigate();
  const goToMerchant = (merchant: any) => navigate("./" + item.id);
  return (
    <TableRow
      onClick={goToMerchant}
      className="hover:bg-gray-100 cursor-pointer propagate-border"
    >
      <TableCell className="text-black rtl:text-right">
        <span className="font-bold">{item.name}</span>{" "}
      </TableCell>
      <TableCell className="text-black rtl:text-right">
        <span className="font-light"> {item.name_ar}</span>
      </TableCell>
      {/* <TableCell className="p-3 text-center">
        <Badge
          color={"yellow"}
          className="!rounded-md border-2 border-yellow-500"
        >
          {t("vIP")}
        </Badge>
      </TableCell> */}
      {/* <TableCell className="rtl:text-right">Active</TableCell> */}
      <TableCell className="text-black rtl:text-right">
        {item.reference_id}
      </TableCell>
      {/* <TableCell className="text-black rtl:text-right">
        <span className="font-bold">43,4003</span> <span>SAR</span>
      </TableCell>
      <TableCell className="text-black font-bold rtl:text-right">
        1530
      </TableCell>
      <TableCell className="rtl:text-right">
        <span className="text-black font-bold">40</span>{" "}
        <span className="text-black text-xs">Days</span>
      </TableCell>
      <TableCell className="rtl:text-right">
        <span className="text-black font-bold">5</span>{" "}
        <span className="text-black text-xs">hours</span>
      </TableCell> */}
    </TableRow>
  );
};
export default MerchantRow;
