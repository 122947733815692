import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList, ReconciliationListItem } from "@lib";
import { listReconciliationsApi } from "@lib/utils/api/reconciliations-apis";
import { dateRange } from "@lib/types/date";
import React from "react";
import { GenericTable } from "./generic-table";
import { ReconciliationsHeader } from "@lib/tables/table-headers/reconciliations-header";
import { ReconciliationRow } from "@lib/tables/rows/reconciliation-row";

type Props = {
  refresh?: boolean;
  setRefresh?: (refresh: boolean) => any;
  merchant_id?: string;
  terminal_id?: string;
  user_id?: string;
  onRowClick?: (payload: any) => void;
  activeTab?: string;
  limit?: number;
  setReconiliationsForDownload?: any;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const ReconciliationsTable: FC<Props> = ({
  limit = 30,
  refresh,
  terminal_id,
  user_id,
  merchant_id,
  activeTab = "all",
  setReconiliationsForDownload,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [reconciliations, setReconcile] = useState<any>([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchReconciliations = async (page = 1) => {
    try {
      setIsLoading(true);
      const { receipts, pages } = await listReconciliationsApi({
        page,
        merchant_id,
        user_id,
        limit,
        terminal_id,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setNumberOfPages(pages);
      setReconcile(receipts);
      setReconiliationsForDownload?.(receipts);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToReconcile = (reconcile: any) => {
    navigate("/reconciliations/" + reconcile?.id);
  };

  useEffect(() => {
    fetchReconciliations(selectedPage);
  }, [refresh, activeTab, selectedPage]);

  return (
    <GenericTable
      data={reconciliations}
      isLoading={isLoading}
      component={ReconciliationRow}
      numberOfPages={numberOfPages}
      setSelectedPage={setSelectedPage}
      header={<ReconciliationsHeader />}
    />
  );
};

export default ReconciliationsTable;
