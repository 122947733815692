import { FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TerminalLookupModal from "@lib/components/modals/terminal-lookup";
import { dateRange } from "@lib/types/date";
import DownloadTerminalsButton from "@lib/components/shared/download-terminals-btn";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import TerminalsTable from "@lib/tables/terminals-table";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import Content from "@lib/layout/content";
import { TerminalItem } from "@lib/types/terminal";
import {
  ArrowLeftOnRectangleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { IconButton } from "@lib/components/buttons/iconButton";
import TerminalPullOutModal from "@lib/components/modals/pullout-terminal";
import ManageTerminals from "@lib/components/modals/manage-terminals";

type Props = {};

const Terminals: FC<Props> = ({}: Props) => {
  const [showTerminalsModal, setShowTerminalsModal] = useState(false);
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [terminals, setTerminals] = useState<TerminalItem[]>([]);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@terminalsDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@terminalsQueryParams"
  );
  const [isPullOutTerminalsModalOpen, setIsPullOutTerminalsModalOpen] =
    useState(false);

  const navigate = useNavigate();

  const togglePullOutTerminalsModal = () =>
    setIsPullOutTerminalsModalOpen((prevState: boolean) => !prevState);

  const toggleLookupModal = () =>
    setIsLookupModalOpen((prevState: boolean) => !prevState);

  const toggleModal = () =>
    setShowTerminalsModal((prevState: boolean) => !prevState);

  return (
    <Content
      title={t("terminals")}
      actionBtns={
        <div className="flex justify-between w-full gap-2">
          <IconButton
            onClick={toggleModal}
            Icon={PencilSquareIcon}
            title="createTerminal"
          />
          {/* <div className="flex gap-2">
            <IconButton
              onClick={togglePullOutTerminalsModal}
              Icon={ArrowLeftOnRectangleIcon}
              title="pullOut"
            />
            <DownloadTerminalsButton
              terminals={terminals}
              queryParams={queryParams}
            />
          </div> */}
        </div>
      }
      queryBuilder={
        <QueryBuilder
          type="terminals"
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          setDateRange={
            setDateRange as React.Dispatch<SetStateAction<dateRange>>
          }
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      }
    >
      <TerminalsTable
        refresh={refreshList}
        setRefresh={setRefreshList}
        dateRange={dateRange as dateRange}
        queryParams={queryParams}
      />

      <ManageTerminals
        isOpen={showTerminalsModal}
        toggleModal={toggleModal}
        refresh={() => setRefreshList((prevState: boolean) => !prevState)}
      />
      <TerminalPullOutModal
        isOpen={isPullOutTerminalsModalOpen}
        toggleModal={togglePullOutTerminalsModal}
      />
    </Content>
  );
};

export default Terminals;
