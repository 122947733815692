import jsonexport from "jsonexport/dist";
import { useTranslation } from "react-i18next";
import { Button } from "@lib";
import { downloadReconciliationsApi } from "@lib/utils/api/reconciliations-apis";
import ReceiptsDownloadModal from "../modals/receipts-download";
import { useContext, useState } from "react";
import { ExportReconcilationType } from "../../types/export-receipts";
import { flatObject } from "@lib/utils/flat-object";
import UserContext, { UserContextType } from "../../../contexts/UserContext";
import React from "react";
import ArrowUpOnSquareIcon from "@heroicons/react/24/outline/ArrowUpOnSquareIcon";
import { IconButton } from "../buttons/iconButton";

const DownloadReconciliationsButton = ({
  queryParams,
}: {
  queryParams?: { [key: string]: any };
}) => {
  const { t, i18n } = useTranslation();
  const [showDatemodal, setShowDateModel] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: Date.now() - 1000 * 60 * 60 * 24 * 30,
    endDate: Date.now(),
  });
  const [feedback, setFeedBack] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [withFilters, setWithFilters] = useState<boolean>(true);

  const { auth } = useContext(UserContext) as UserContextType;
  const toggleModal = () => {
    setShowDateModel((prev) => !prev);
    setFeedBack("");
  };
  const download = async () => {
    try {
      setFeedBack("");
      setIsLoading(true);
      const reconciliations = await downloadReconciliationsApi({
        queryParams: withFilters ? queryParams : {},
        dateRange: {
          fromTimestamp: dateRange.startDate,
          toTimestamp: dateRange.endDate,
        },
      }).then((reconciliations: any) => {
        if (!reconciliations.length) {
          setFeedBack("no reconciliations in this period");
          return;
        }
        let proccessedReconciliations: any[] = [];

        reconciliations.forEach((r: any) => {
          r = {
            ...r.reconciliation_receipt,
            system_trace_audit_number:
              "S" + r.reconciliation_receipt?.system_trace_audit_number,
            id: r.id,
            action_code: r.action_code,
            status: r.status,
            card_acceptor_terminal_id:
              "T" + r.reconciliation_receipt?.card_acceptor_terminal_id,
          };

          const exportType =
            ExportReconcilationType[
              (auth?.client
                ?.export_data_type as keyof typeof ExportReconcilationType) ||
                "nearpay"
            ];

          r.schemes?.forEach((scheme: any, i: number) => {
            const reconciliationObj = Object.keys(exportType).reduce(
              (acc: any, curr: string) => {
                const currentKey: string = curr;
                const defaultValue =
                  exportType[currentKey as keyof typeof exportType]
                    .defaultValue;
                const accKey =
                  exportType[currentKey as keyof typeof exportType].key;

                if (r.schemes.length && curr.split(".")[0] === "schemes") {
                  curr = curr.replace("schemes.", "schemes." + i + ".");
                }
                if (curr.includes(".")) {
                  const arr = curr.split(".");
                  acc[accKey] = flatObject(arr, r[arr[0]]) ?? defaultValue;
                } else {
                  acc[accKey] = r[curr] ?? defaultValue;
                }

                return acc;
              },
              {}
            );

            proccessedReconciliations.push(reconciliationObj);
          });
          proccessedReconciliations.push({});
        });

        let output: string[] = [];
        jsonexport(
          proccessedReconciliations,
          { includeHeaders: true, rowDelimiter: ",", endOfLine: "\n" },
          function (err: Error, csv: string) {
            if (err) return console.log(err);
            output.push(csv);
          }
        );

        output[0] = "\ufeff" + output[0];
        const outFile = new File(
          output,
          "Reconciliations-Receipts" + new Date().toDateString() + ".csv"
        );
        const link = document.createElement("a");
        const url = URL.createObjectURL(outFile);
        link.href = url;
        link.download = outFile.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toggleModal();
      });
      setIsLoading(false);
    } catch (downloadTransactionError: any) {
      setIsLoading(false);
      setFeedBack(() => {
        return i18n.language === "ar"
          ? downloadTransactionError?.error?.body?.error?.arabic
          : downloadTransactionError?.error?.body?.error?.english;
      });
      console.log({ downloadTransactionError });
    }
  };

  return (
    <>
      <IconButton
        onClick={() => toggleModal()}
        Icon={ArrowUpOnSquareIcon}
        title="export"
      />

      {showDatemodal && (
        <ReceiptsDownloadModal
          feedback={feedback}
          // withFilters={withFilters}
          // setWithFilters={setWithFilters}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isModalOpen={showDatemodal}
          toggleModal={toggleModal}
          submit={download}
          isLoading={isLoading}
          title="Reconciliations"
        ></ReceiptsDownloadModal>
      )}
    </>
  );
};

export default DownloadReconciliationsButton;
