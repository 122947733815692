import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ArrowLeft, ArrowRight, TerminalIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";
import { PoliciesListItemType, times } from "../types/policy";

enum PolicyType {
  client = "allTerminals",
  merchant = "allMerchantTerminal",
  terminal = "oneTerminal",
}

export const PoliciesListItem: FC<PoliciesListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <NavLink to={"/policies/" + item?.id} className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 w-full">
        <div className="flex justify-between px-2">
          <div>
            <div className="capitalize">
              <span className="text-sm">{t("type")}</span>
              <h3 className="font-bold"> {t(PolicyType[item.type])}</h3>
            </div>
            <div></div>
          </div>
          <div className=" flex items-center gap-3">
            <div className="capitalize">
              <span className="text-xs text-gray-600">{t("time")}</span>
              <h3 className="font-bold [direction:ltr]">{times[item.time]}</h3>
            </div>
            <div>
              <div className="flex-initial">
                {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </NavLink>
  );
};
