import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { availableLanguages } from "../../i18n";
import { RouterNavLink } from "../utils/router/routes";
import { Menu, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { Icon } from "@tremor/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  BuildingStorefrontIcon,
  LanguageIcon,
} from "@heroicons/react/24/outline";

const navigateToAlternative = () => {
  if (process.env.REACT_APP_GO_TO_URL)
    window.location.href = process.env.REACT_APP_GO_TO_URL;
};

type Props = {
  navlinks: RouterNavLink[];
  isOpen: boolean;
  setIsOpen: (value: boolean | ((prevState: boolean) => boolean)) => void;
};
const metaTagContent =
  document
    .querySelector('meta[name="build-version"]')
    ?.getAttribute("content") === "%REACT_APP_BUILD_VERSION%"
    ? ""
    : document
        .querySelector('meta[name="build-version"]')
        ?.getAttribute("content");

const NavBar = ({ navlinks, isOpen, setIsOpen }: Props) => {
  const { logout, toggleSwitchAccount, auth } = useContext(
    UserContext
  ) as UserContextType;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isNavbarHovered, setIsNavbarHovered] = useState(false);
  const closeMenuRef = useRef<() => void>();
  const switchLanguage = () =>
    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
  const navigateToMerchant = () => {
    if (auth.client?.merchant_hub_link) {
      window.location.href = auth.client?.merchant_hub_link;
    }
  };
  const logoutAndRedirect = () => {
    setIsOpen(false);
    logout();
    navigate("/");
  };
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  const userNavigation = [
    {
      name: auth.merchant?.id ? t("switchMerchant") : t("switchAccount"),
      onClick: toggleSwitchAccount,
      icon: ArrowsRightLeftIcon,
    },
    {
      name: auth.client?.merchant_hub_link ? t("goToMerchant") : null,
      onClick: navigateToMerchant,
      icon: BuildingStorefrontIcon,
    },
    {
      name: t("changeLanguage"),
      onClick: switchLanguage,
      icon: LanguageIcon,
    },
    {
      name: t("logout"),
      onClick: logoutAndRedirect,
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  useEffect(() => {
    if (!isNavbarHovered && closeMenuRef.current) {
      closeMenuRef.current();
    }
  }, [isNavbarHovered]);
  return (
    <>
      <>
        {isOpen && (
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="w-screen h-full backdrop-blur-sm fixed top-0 z-30 sm:hidden"
          />
        )}
        <div
          className={classNames(
            isOpen
              ? "fixed rtl:animate-slide-right-left ltr:animate-slide-left-right"
              : "hidden",
            classNames(
              isNavbarHovered ? "!min-w-[15rem]" : "",
              " sm:block sm:!static sm:py-4 top-0 z-30 min-w-[4rem] w-[17rem] sm:w-[4rem] h-full ltr:sm:ml-3 rtl:sm:mr-3 transition-all duration-500 overflow-hidden"
            )
          )}
        >
          <div
            onMouseEnter={() => setIsNavbarHovered(true)}
            onMouseLeave={() => setIsNavbarHovered(false)}
            className="w-full flex flex-col bg-menu font-medium text-primary h-full justify-between sm:rounded-md select-none overflow-visible"
          >
            <select
              className=" hidden"
              defaultValue={t("changeLanguage")}
              onChange={(e) => {
                i18n.changeLanguage(
                  e.target.value === "English"
                    ? "en"
                    : "العربية" === e.target.value
                    ? "ar"
                    : ""
                );
              }}
            >
              <option>{t("changeLanguage")}</option>
              {availableLanguages.map((language) => (
                <option key={language}>
                  {language === "en" ? "English" : "العربية"}
                </option>
              ))}
            </select>
            <div>
              {auth?.client?.logo ? (
                <div
                  className={classNames(
                    isNavbarHovered ? "" : "sm:overflow-hidden",
                    "text-2xl font-semibold flex flex-col p-4  relative"
                  )}
                >
                  <div
                    className={classNames(
                      isNavbarHovered
                        ? "ltr:sm:-translate-x-14 rtl:sm:translate-x-14"
                        : "ltr:sm:-translate-x-0",

                      "ltr:left-6 rtl:right-6 top-4 hidden absolute text-2xl font-semibold sm:flex flex-col  overflow-hidden transition-all duration-500"
                    )}
                  >
                    {auth?.client?.name.slice(0, 1)}
                  </div>
                  <div
                    className={classNames(
                      isNavbarHovered
                        ? "ltr:sm:translate-x-0"
                        : "ltr:sm:translate-x-20 rtl:sm:-translate-x-20",
                      "my-1 transition-all duration-500 !overflow-visible whitespace-nowrap"
                    )}
                  >
                    <img
                      src={auth?.client?.logo}
                      className="!min-h-[1.5rem] h-6 max-h-[1.5rem] w-28 "
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={classNames(
                    isNavbarHovered ? "" : "sm:overflow-hidden",
                    "text-2xl font-semibold flex flex-col p-4  relative"
                  )}
                >
                  <div
                    className={classNames(
                      isNavbarHovered
                        ? "ltr:sm:-translate-x-14 rtl:sm:translate-x-14"
                        : "ltr:sm:-translate-x-0",

                      "ltr:left-6 rtl:right-6 top-4 hidden absolute text-2xl font-semibold sm:flex flex-col  overflow-hidden transition-all duration-500"
                    )}
                  >
                    {auth?.client?.name.slice(0, 1)}
                  </div>
                  <div
                    className={classNames(
                      isNavbarHovered
                        ? "ltr:sm:translate-x-0"
                        : "ltr:sm:translate-x-20 rtl:sm:-translate-x-20",
                      "transition-all duration-500 overflow-hidden whitespace-nowrap"
                    )}
                  >
                    {auth?.client?.name}
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-1 tall:gap-1.5 w-full text-sm  px-2">
                {navlinks.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.link}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? "!bg-secondary" : "",
                        "p-1.5 px-4 tall:px-3 tall:py-2.5 whitespace-nowrap w-full rounded-md hover:opacity-90 hover:bg-secondary flex gap-2 items-center overflow-hidden"
                      )
                    }
                  >
                    {item.icon ? (
                      <item.icon className="tall:h-6 h-4 overflow-visible" />
                    ) : (
                      ""
                    )}
                    <span
                      className={classNames(
                        isNavbarHovered ? "sm:max-w-xs" : "sm:max-w-0",
                        "transition-all duration-500  overflow-hidden font-normal tall:font-bold"
                      )}
                    >
                      {t(item.name)}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>

            <div>
              <div
                className={`gap-2 flex flex-col overflow-hidden text-center whitespace-nowrap text-primary text-base p-1 transition-all duration-300
                 ${
                   isNavbarHovered ? "opacity-100" : "opacity-100 sm:opacity-0"
                 }`}
              >
                {process.env.REACT_APP_GO_TO_URL ? (
                  <div
                    onClick={navigateToAlternative}
                    className="hover:opacity-80 opacity-50 transition-opacity duration-200 text-xs tall:text-sm flex rtl:flex-row-reverse w-full items-center justify-center gap-1 cursor-pointer hover:text-black"
                  >
                    <span> Go To {process.env.REACT_APP_GO_TO_TITLE}</span>

                    <Icon
                      size="sm"
                      icon={ArrowTopRightOnSquareIcon}
                      className="p-0"
                    ></Icon>
                  </div>
                ) : null}

                <div className="flex flex-col opacity-50">
                  <span className="text-xs ">Powered By Nearpay</span>
                  <span className="text-xs"> v{metaTagContent}</span>
                </div>
              </div>

              <Menu
                as="div"
                className="relative self-center text-primary py-5 p-5 border-t border-secondary"
              >
                <div className="flex">
                  <Menu.Button className="max-w-xs flex focus:outline-none ">
                    <div className="flex rounded-xl sm:rounded-full border-3 border-gray-200  gap-2 items-center ">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="26"
                          height="26"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            className="fill-primary"
                            d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
                          />
                        </svg>
                      </div>
                      <div>
                        <div className="ltr:text-left rtl:text-right flex flex-col text-ellipsis whitespace-nowrap overflow-hidden w-32 text-sm">
                          <span
                            className={classNames(
                              isNavbarHovered
                                ? "text-ellipsis max-w-xs"
                                : "sm:max-w-0",
                              "whitespace-nowrap overflow-hidden font-normal tall:font-bold transition-all duration-500 text-primary"
                            )}
                          >
                            {auth?.user?.name}
                          </span>
                          <span
                            className={classNames(
                              isNavbarHovered
                                ? "text-ellipsis max-w-xs "
                                : "sm:invisible sm:max-w-0 ",
                              "whitespace-nowrap overflow-hidden text-primary font-thin text-xs transition-all duration-500 "
                            )}
                          >
                            {auth?.user?.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className={
                      "z-50 overflow-visible origin-top-right absolute -mt-36 bottom-16 left-4 w-48 p-1 rounded-md shadow-lg bg-menu ring-1 ring-black ring-opacity-5  focus:outline-none " +
                      t("alignMenu")
                    }
                  >
                    {userNavigation.map((item: any) =>
                      item.name ? (
                        <Menu.Item key={item.name}>
                          {({ close }) => {
                            // Store the close function in the ref
                            closeMenuRef.current = close;
                            return (
                              <button
                                onClick={item.onClick}
                                className={
                                  " p-2 flex items-center gap-1.5 justify-between text-sm text-primary opacity-80 w-full text-left rounded-lg hover:bg-secondary rtl:text-right"
                                }
                              >
                                <span>{item.name}</span>
                                {item.icon ? (
                                  <Icon className="p-0" icon={item.icon} />
                                ) : null}
                              </button>
                            );
                          }}
                        </Menu.Item>
                      ) : null
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default NavBar;
