import React, { FC } from "react";
import {
  Metric,
  Card,
  Text,
  Icon,
  Flex,
  Bold,
  ProgressCircle,
} from "@tremor/react";
import { DevicesIcon } from "@lib/components/Reports/Icons";
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { t } from "i18next";

type TotalTerminalsCardProps = {
  total: number;
};
const formatPrice = (num: number) => {
  return Math.round(num).toLocaleString();
};
export const TotalTerminalsCard = ({ total }: TotalTerminalsCardProps) => {
  return (
    <Card className=" h-20 px-4 flex w-full flex-row justify-between rounded-sm">
      <Flex flexDirection="col" justifyContent="center" alignItems="start">
        <Text className="flex">{t("totalTerminals")}</Text>
        <Bold className="text-[20px]">{total.toLocaleString()}</Bold>
      </Flex>
      <DevicesIcon size={32} containerClassNames="hidden md:flex" />
    </Card>
  );
};
type TotalSalesCard = {
  total: number;
  title: string;
  subTitle: string;
};
export const TotalSalesCard = ({
  total = 0,
  title,
  subTitle,
}: TotalSalesCard) => {
  return (
    <Card className=" h-20 px-4 flex w-full flex-row justify-between rounded-sm">
      <Flex
        className="gap-2"
        flexDirection="row"
        justifyContent="between"
        alignItems="center"
      >
        <Flex
          className="w-auto whitespace-nowrap"
          flexDirection="col"
          alignItems="start"
        >
          <Text className="text-[14px]">{title}</Text>
          <Bold className="text-[14px]">{subTitle}</Bold>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="baseline"
          className="w-auto gap-1 overflow-hidden"
        >
          <Metric className="!text-[20px] !whitespace-nowrap !text-ellipsis overflow-hidden">
            {formatPrice(total)}
          </Metric>
          <Text className="!text-[14px]">{t("KES")}</Text>
        </Flex>
      </Flex>
    </Card>
  );
};
type PeriodicSalesCard = {
  previous: number;
  current: number;
  title: string;
};
export const PeriodicSalesCard = ({
  previous = 0,
  current = 0,
  title,
}: PeriodicSalesCard) => {
  let percentageChange = 0;

  if (previous === 0 && current === 0) {
    // Both previous and current sales are zero
    percentageChange = 0;
  } else if (previous === 0) {
    // Previous sales are zero, but current sales are not
    percentageChange = 100; // Represents a new start or infinite increase
  } else {
    percentageChange = ((current - previous) / previous) * 100;
  }

  percentageChange = parseFloat(percentageChange.toFixed(0));

  return (
    <Card className="h-20 px-4 flex w-full flex-row justify-between rounded-sm">
      <Flex flexDirection="col" justifyContent="center" alignItems="start">
        <Text>{title}</Text>
        <div className="flex items-baseline justify-between w-full flex-wrap">
          <Flex
            alignItems="baseline"
            className="w-auto gap-1 mr-2 ltr:flex-row rtl:flex-row-reverse "
          >
            <Text className="!text-[20px] ">{t("KES")}</Text>
            <Metric className="!text-[20px]">{formatPrice(current)}</Metric>
          </Flex>
          <Text className="text-sm">
            {t("from") + " " + formatPrice(previous) + " " + t("KES")}
          </Text>
        </div>
      </Flex>
      {previous ? (
        <div className="flex items-start absolute rtl:left-0 ltr:right-0 top-2.5">
          <Text color={current > previous ? "emerald" : "rose"}>
            {current > previous ? "+" : ""}
            {percentageChange}%
          </Text>
          <Icon
            color={current > previous ? "emerald" : "rose"}
            icon={
              current > previous ? ArrowTrendingUpIcon : ArrowTrendingDownIcon
            }
            className="p-0 px-2"
          />
        </div>
      ) : null}
    </Card>
  );
};
type TerminalsStatusCardProps = {
  title: string;
  units: number;
  total: number;
  tooltip?: string;
};

export const TerminalsStatusCard = ({
  title,
  units = 0,
  total = 0,
  tooltip,
}: TerminalsStatusCardProps) => {
  let percentage;
  if (total === 0) {
    // No terminals, or no data available
    percentage = 0;
  } else if (units < 0 || total < 0) {
    // Negative values, which might indicate a data error
    percentage = 0;
  } else {
    // Normal case: calculate the percentage of active terminals
    percentage = Math.round((units / total) * 100);
  }
  return (
    <Card className=" h-20 px-4 flex flex-row justify-between rounded-sm">
      <Flex flexDirection="col" justifyContent="center" alignItems="start">
        <Text className=" items-center flex gap-1">
          {title}
          {tooltip && (
            <Icon
              size="sm"
              className="p-0"
              icon={InformationCircleIcon}
              color="gray"
              tooltip={tooltip}
            />
          )}
        </Text>
        <div className="text-[20px]">
          <Bold>{formatPrice(units)} /</Bold> {formatPrice(total)}
        </div>
      </Flex>

      <ProgressCircle
        value={percentage}
        radius={26}
        strokeWidth={4}
        className="hidden absolute rtl:left-0 ltr:right-0 top-4 rtl:pl-4 ltr:pr-4 md:flex"
      >
        <Bold className="text-xs">{percentage}%</Bold>
      </ProgressCircle>
    </Card>
  );
};
