import React, { FC } from "react";

interface IconType {
  containerClassNames?: string;
  size?: number;
  color?: string;
}

export const DevicesIcon: FC<IconType> = ({
  containerClassNames = "",
  size = 42, // Adjusted default size to match the original width and height
  color = "text-black",
}) => {
  return (
    <div className={"p-1 " + containerClassNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 42 43"
        width={size}
        height={size}
        className={color}
      >
        <mask id="a" fill="#fff">
          <rect width="36" height="39" rx="1" />
        </mask>
        <rect
          width="36"
          height="39"
          fill="#fff"
          stroke="currentColor"
          strokeWidth="8"
          mask="url(#a)"
          rx="1"
        />
        <mask id="b" fill="#fff">
          <rect width="21" height="29" x="21" y="14" rx="1" />
        </mask>
        <rect
          width="21"
          height="29"
          x="21"
          y="14"
          fill="#fff"
          stroke="currentColor"
          strokeWidth="8"
          mask="url(#b)"
          rx="1"
        />
      </svg>
    </div>
  );
};
