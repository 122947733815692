import React, { FC } from "react";
import { MerchantIcon } from "../icons";
import { Badge, Card } from "../shared";
import { ClientListItemType } from "../types/client";
import { useTranslation } from "react-i18next";
import Storage from "@lib/utils/storage";

export const ClientsSwitchItem: FC<any> = ({
  role,
  title,
  onClick,
  item,
  index,
}) => {
  const { t } = useTranslation();
  return (
    <li
      onClick={() => onClick(item?.id)}
      className={`list-none p-4 border border-gray-300 rounded-md hover:bg-gray-200 cursor-pointer shadow-sm flex flex-row items-center mb-4 justify-between min-h-[60px] max-h-[60px] `}
    >
      <div className="flex flex-row gap-2 items-center ">
        {item?.logo ? (
          <img
            src={item.logo}
            className="switch-client-logo mix-blend-multiply"
          />
        ) : (
          <span className="font-bold text-gray-700 text-sm lg:text-base">
            {item?.name}
          </span>
        )}
      </div>
      <div className="flex gap-2">
        {item?.vendor_name ? (
          <div>
            <Badge label={item?.vendor_name} color="black" isRounded />
          </div>
        ) : null}
        {item?.id === Storage.get("clientId") ? (
          <Badge color="blue" label={t("current")} isRounded={true} />
        ) : null}
      </div>
    </li>
  );
};
