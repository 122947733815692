import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { MerchantIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";

export interface AppListItemType {
  role: "admin" | "client";
  onClick?: (item?: any) => void;
  item: {
    id: string;
    name: string;
    type: "APP" | "SDK";
    app_name?: string;
    merchant?: {
      id: string;
      name: string;
      name_ar: string;
    };
  };
}

export const AppListItem: FC<AppListItemType> = ({
  role,
  item: { id, name, type, app_name, merchant },
  onClick,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <NavLink to={"/apps/" + id}>
      <Card classNames="py-4 px-4 w-full mt-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          <div className="flex items-center justify-start ">
            <svg
              className="pr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="34"
              height="34"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM8.823 15.343l-.79 1.37a.823.823 0 1 1-1.428-.822l.589-1.016c.66-.206 1.201-.048 1.629.468zM13.21 8.66l2.423 4.194h2.141a.82.82 0 0 1 .823.822.82.82 0 0 1-.823.823h-1.19l.803 1.391a.824.824 0 0 1-1.427.823l-3.04-5.266c-.69-1.19-.198-2.383.29-2.787zm.278-3.044c.395.226.528.73.302 1.125l-3.528 6.109h2.553c.826 0 1.29.972.931 1.645h-7.48a.82.82 0 0 1-.822-.823.82.82 0 0 1 .822-.822h2.097l2.685-4.653-.838-1.456a.824.824 0 0 1 1.427-.823l.359.633.367-.633a.823.823 0 0 1 1.125-.302z" />
            </svg>
            <span className="font-bold max-w-xs truncate">{app_name}</span>
          </div>
          {/* trick the grid to render elements far from bigening  */}

          <div className="hidden  xl:flex "></div>
          <div className="flex justify-between items-center flex-wrap">
            <div className="flex flex-col justify-start max-w-xs  3xl:max-w-sm">
              <div>{t("packageName") as string}</div>
              <div className="font-bold truncate">{name}</div>
            </div>
            <div className="flex flex-col justify-start max-w-xs  3xl:max-w-sm">
              {/* <div>{t("merchant") as string}</div> */}
              <div className="font-semibold truncate bg-sky-100 text-sky-700 text-sm rounded-full px-2">
                {merchant?.name}
              </div>
            </div>
            <div className="flex items-center justify-end">
              {i18n.language == "ar" ? (
                <ChevronLeftIcon height={34} width={34} />
              ) : (
                <ChevronRightIcon height={34} width={34} />
              )}
            </div>
          </div>
        </div>
      </Card>
    </NavLink>
  );
};
