import { TableCell, TableRow, Text, Badge, Icon, Flex } from "@tremor/react";
import React, { ReactNode } from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { MerchantItem } from "@lib/list-item/merchant-list";
import { ReconiliationItem } from "@lib/types/reconciliation";
import { Checked, XIcon } from "@lib/icons";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { dateToRelativeTime } from "@lib/utils/date-to-relative-time";

// id: string;
//   name: string;
//   name_ar?: string;
//   reference_id?: string;
//   status: "active" | "deactive";
//   created_at: string;

export const ReconciliationRow = ({ item }: { item: ReconiliationItem }) => {
  const navigate = useNavigate();
  const goToTerminal = () => navigate("./" + item.id);
  const { i18n } = useTranslation();
  return (
    <TableRow
      onClick={goToTerminal}
      className="hover:bg-gray-100 cursor-pointer propagate-border"
    >
      <TableCell className="w-32">
        {item?.is_balanced ? (
          <Badge
            color="teal"
            className="flex rtl:flex-row-reverse"
            icon={CheckCircleIcon}
          >
            {t("balanced")}
          </Badge>
        ) : (
          <Badge
            className="flex rtl:flex-row-reverse "
            color="rose"
            icon={XCircleIcon}
          >
            {t("notBalanced")}
          </Badge>
        )}
      </TableCell>
      <TableCell className="max-w-[120px] min-w-[120px] p-3 text-black">
        <FormatPrice item={item} />
      </TableCell>
      <TableCell className="text-center text-black">
        {item?.total?.count}
      </TableCell>
      <TableCell className="py-0 font-bold rtl:text-right text-black">
        <span className="text-sm  whitespace-nowrap">{item?.stan}</span>
      </TableCell>
      <TableCell className="p-0 min-w-[90px] rtl:text-right text-black">
        <Text className="text-black">
          {dateToRelativeTime(item?.created_at)}
        </Text>
        <Text className="text-black">{formatDate(item?.created_at)}</Text>
      </TableCell>
      <TableCell className="rtl:text-right text-black">
        <Text className="truncate font-semibold text-black">
          {item?.merchant?.name}
        </Text>
        {/* <Text className="truncate font-light text-xs">
          {item?.merchant?.name_ar}
        </Text> */}
      </TableCell>
      <TableCell className="w-48 rtl:text-right text-black">
        <Text className="text-black"> {item?.terminal?.tid}</Text>
      </TableCell>
      <TableCell className="p-0 w-44 rtl:text-right text-black">
        <Text className="font-semibold text-black">{item?.user?.name} </Text>
        <Text className="text-xs text-black">{item?.user?.mobile}</Text>
      </TableCell>
    </TableRow>
  );
};
export const FormatPrice = ({ item }: { item: ReconiliationItem }) => {
  const { i18n, t } = useTranslation();

  return (
    <Text className="trucnate text-right font-medium flex gap-1 justify-end rtl:flex-row-reverse text-black">
      {item?.total?.value}
      <small className="lg:text-[.7rem] font-normal ">
        {i18n.language == "ar"
          ? item?.currency?.arabic
          : item?.currency?.english}
      </small>
    </Text>
  );
};
const formatDate = (date: Date) =>
  new Date(date).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
export default ReconciliationRow;
