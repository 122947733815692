import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Errors } from "@lib";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../../assets/payment-schemes.svg";

const ResetPasswordMessageSent = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{ padding: "20px" }}
      className="flex flex-row h-full w-full sm:justify-center relative py-10"
    >
      <div className="flex flex-col w-full h-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="flex flex-1 flex-col justify-center ">
            <div className="pb-4 pt-2"></div>
            <div className="text-gray-900 mt-3 p-1 font-bold">
              {t("resetPasswordMessage")}
            </div>
            <Button
              onClick={() => navigate("/")}
              label={t("login") as string}
              colorScheme="black"
              isRounded={false}
            />{" "}
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordMessageSent;
