import React, { useContext } from "react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "@lib/layout/content";
import { ListPolicies } from "@lib/components/policies/list";
import { Button } from "@lib";
import ManagePoliciesModal from "@lib/components/modals/manage-policies";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@lib/components/buttons/iconButton";

type Props = {};

const Policies: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  const [refreshList, setRefreshList] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const { auth } = useContext(UserContext) as UserContextType;

  const handleToggleModal = () => setToggleModal((prev) => !prev);

  return (
    <Content
      title={t("reconciliationsPolicies")}
      actionBtns={
        <IconButton
          onClick={handleToggleModal}
          title="add"
          Icon={PlusSmallIcon}
          hidden={auth?.role !== "owner" ? true : false}
        />
      }
    >
      <ListPolicies refresh={refreshList} />
      <ManagePoliciesModal
        toggleModal={handleToggleModal}
        isOpen={toggleModal}
        setRefresh={setRefreshList}
      />
    </Content>
  );
};

export default Policies;
