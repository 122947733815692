import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Card, Tick, formatPrice } from "@lib";

export const PaymentSchemeReconciliation: FC<{ item: any }> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Card
      classNames={`px-4 py-4 ${
        !Number(item?.pos?.total?.total) && !Number(item?.host?.total?.total)
          ? "opacity-40"
          : ""
      }`}
    >
      {}
      <div className="flex-initial flex flex-row items-center gap-x-6 mb-2 ">
        <div className="flex flex-col gap-y-2 items-center">
          <div
            className={
              (item.is_balanced ? "bg-green-700" : "bg-gray-500") +
              " rounded-full"
            }
          >
            <Tick color="text-white" size={18} />
          </div>
          <span className="font-bold">
            {item.is_balanced ? t("balanced") : t("notBalanced")}
          </span>
        </div>
        <div className="flex-1 flex flex-col">
          <span className="text-xl">{t("type")}</span>
          <span className="text-2xl font-bold">
            {item?.name?.label?.english}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-4">
        <span></span>
        <span className="font-bold text-md">{t("POS")}</span>
        <span className="font-bold text-md">{t("HOST")}</span>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-2">
        <span className="text-md">{t("debitCard")}</span>
        <span className="font-bold text-md">
          {formatPrice(item?.pos?.debit?.total)}
        </span>
        <span className="font-bold text-md">
          {formatPrice(item?.host?.debit?.total)}
        </span>
      </div>
      <div
        className={`grid grid-cols-3 border-b border-gray-300 py-2 ${
          item?.pos?.debit?.count != item?.pos?.debit?.count
            ? " bg-red-100"
            : ""
        }`}
      >
        <span className="text-md">{t("debitCount")}</span>
        <span className="font-bold text-md">{item?.pos?.debit?.count}</span>
        <span className="font-bold text-md">{item?.host?.debit?.count}</span>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-2">
        <span className="text-md">{t("creditCard")}</span>
        <span className="font-bold text-md">
          {formatPrice(item?.pos?.credit?.total)}
        </span>
        <span className="font-bold text-md">
          {formatPrice(item?.host?.credit?.total)}
        </span>
      </div>
      <div
        className={`grid grid-cols-3 border-b border-gray-300 py-2 ${
          item?.pos?.credit?.count != item?.pos?.credit?.count
            ? " bg-red-100"
            : ""
        }`}
      >
        <span className="text-md">{t("creditCount")}</span>
        <span className="font-bold text-md">{item?.pos?.credit?.count}</span>
        <span className="font-bold text-md">{item?.host?.credit?.count}</span>
      </div>
      <div className="grid grid-cols-3 py-4">
        <span className="text-md font-bold">{t("theTotal")}</span>
        <span className="font-bold text-md">
          {formatPrice(item?.pos?.total?.total)}
        </span>
        <span className="font-bold text-md">
          {formatPrice(item?.host?.total?.total)}
        </span>
      </div>
      <div className="black h-10 w-10"></div>
    </Card>
  );
};
