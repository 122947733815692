import Api, { List } from ".";
import { trackPromise } from "react-promise-tracker";
import { dateRange } from "@lib/types/date";
import {
  TerminalConnection,
  LookupDto,
  MerchantDto,
} from "@lib/types/terminal";

interface Terminals extends List {
  user_id?: string;
  merchant_id?: string;
  queryParams?: { [key: string]: any };
  dateRange?: dateRange;
}

interface AssignTerminal {
  id: string;
  user_id?: string;
  mobile: string;
  sendSMS: boolean;
}
interface UnassignTerminal {
  id: string;
  type?: UnAssignEnum;
}

interface DisconnectTerminal {
  terminal_id: string;
  device_id: string;
}
interface EditTerminal {
  terminal_id: string;
  name: string;
}

interface Terminal {
  id?: string;
  reference_terminal_id?: string;
  card_acceptor_terminal_id?: string;
  merchant_id?: string;
  merchant_name?: string;
  card_acceptor_id_code?: string;
  merchant_category_code: string;
  merchant_receipt_address: string;
  card_acceptor_name_and_location: string;
}

interface TerminalConnectionResponse {
  connections: TerminalConnection[];
  pages: number;
}

interface NewTerminal {
  merchant_id: string;
  terminal_ids: string[];
}

interface TerminalDownload {
  dateRange?: dateRange;
  queryParams?: { [key: string]: any };
}

interface ShortenedTerminalsApi extends List {
  search?: string | null;
}

export interface VasMerchant {
  id: string;
  name: string;
  logo: string;
}
export interface TerminalResponse {
  id: string;
  name?: string;
  tid?: string;
  user: {
    name: string;
  };
  client?: {
    name: string;
  };
  merchant: {
    name: string;
  };
  device: {
    id: string;
    model: string;
    brand: string;
    is_active: boolean;
    created_at: string;
  };
  is_assigned_to_user: boolean;
  is_connected: boolean;
  is_locked: boolean;

  profile: any;
}

export interface ShortenedTerminalResponse {
  id: string;
  name?: string;
  tid?: string;
  card_acceptor_terminal_id: string;
  trsm: string;
}

export interface OverrideConfig {
  terminal_id: string;
  schemes: { [key: string]: boolean };
  supported_operations: { [key: string]: boolean };
  vas_id?: string;
}
export interface UpdateTerminalState {
  terminal_id: string;
  is_locked: boolean;
}

export interface TerminalStatistics {
  transactions_count?: number;
  reconciliations_count?: number;
}

export enum UnAssignEnum {
  user = "user",
  device = "device",
}
export const listTerminalsApi = async ({
  limit = 30,
  page = 1,
  merchant_id,
  user_id,
  queryParams = {},
  dateRange = {},
}: Terminals) => {
  // let encodedQueryParams = btoa(JSON.stringify(queryParams));
  //to encode arabic charachter
  let encodedQueryParams = btoa(
    unescape(encodeURIComponent(JSON.stringify(queryParams)))
  );
  let encodeddateRange = btoa(JSON.stringify(dateRange));
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals",
        params: {
          limit,
          page,
          merchant_id,
          user_id,
          queryParams: encodedQueryParams,
          dateRange: encodeddateRange,
        },
      })
    );
    return response.data as { pages: any; terminals: TerminalResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findTerminalApi = async ({ id }: any) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/" + id,
      })
    );

    return response.data as TerminalResponse;
  } catch (e: any) {
    throw e;
  }
};

export const terminalStatisticsApi = async (id: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/statistics/" + id,
      })
    );
    return response.data as TerminalStatistics;
  } catch (e: any) {
    throw e;
  }
};

export const createTerminalApi = async ({
  merchant_id,
  merchant_name,
  merchant_category_code,
  card_acceptor_id_code,
  merchant_receipt_address,
  card_acceptor_name_and_location,
  card_acceptor_terminal_id,
}: Terminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "terminals",
        data: {
          merchant_id,
          merchant_name,
          merchant_category_code,
          card_acceptor_id_code,
          merchant_receipt_address,
          card_acceptor_name_and_location,
          card_acceptor_terminal_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};
export const listLookupsApi = async (page: number, type: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/lookups",
        params: { page, type },
      })
    );

    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const getLookupsIdsApi = async () => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/lookup/ids",
      })
    );

    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const assginTerminalApi = async ({
  id,
  user_id,
  mobile,
  sendSMS,
}: AssignTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/connect",
        data: {
          id,
          user_id,
          mobile,
          sendSMS,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const unAssginTerminalApi = async ({ id, type }: UnassignTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/unassign",
        data: {
          terminal_id: id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const disconnectTerminalApi = async ({
  terminal_id,
  device_id,
}: DisconnectTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/disconnect",
        data: {
          terminal_id,
          device_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const fileDownloadApi = async ({
  id,
  isFull,
}: {
  id: string;
  isFull: boolean;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: "terminal/configuration",
        data: {
          terminalId: id,
          isFull,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const terminalUpgradeApi = async ({ id }: { id: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "terminal/upgrade/v2/",
        data: {
          terminal_id: id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const terminalLookupApi = async ({ trsmCode }: { trsmCode: string }) => {
  try {
    const response = await Api({
      url: "terminals/lookup/register/" + trsmCode,
      method: "POST",
    });
    return response.data as LookupDto;
  } catch (e) {
    throw e;
  }
};

export const deleteTerminalLookupApi = async ({ id }: { id: string }) => {
  try {
    const response = await Api({
      url: "terminals/lookup/" + id,
      method: "DELETE",
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const setTerminalLookupMerchantApi = async ({
  id,
  merchant,
}: {
  id: string;
  merchant: MerchantDto;
}) => {
  try {
    const response = await Api({
      method: "POST",
      url: "terminals/lookup/merchants",
      data: {
        id,
        merchant,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const commitTerminalLookupApi = async ({ id }: { id: string }) => {
  try {
    const response = await Api({
      method: "POST",
      url: "terminals/lookup/commit",
      data: {
        id,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const listTerminalConnectionsApi = async ({
  terminal_id,
  page = 1,
  limit = 30,
}: {
  terminal_id?: string;
  page: number;
  limit: number;
}) => {
  try {
    const response = await Api({
      method: "GET",
      url: "terminals-connections",
      params: {
        terminal_id,
        page,
        limit,
      },
    });
    return response.data as TerminalConnectionResponse;
  } catch (e) {
    throw e;
  }
};

export const changeMerchantApi = async ({
  id,
  merchant_id,
}: {
  id: string;
  merchant_id: string;
}) => {
  try {
    const response = await Api({
      method: "Put",
      url: "terminals/move",
      data: {
        terminal_id: id,
        dest_merchant_id: merchant_id,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const overrideConfigApi = async ({
  terminal_id,
  schemes,
  supported_operations,
  vas_id,
}: OverrideConfig) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: "terminal/configuration/override",
        data: {
          terminal_id,
          schemes,
          supported_operations,
          vas_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const toggleTerminalIsLockedApi = async ({
  terminal_id,
  is_locked,
}: UpdateTerminalState) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: "terminals/toggle-is-locked",
        data: {
          terminal_id,
          is_locked,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listVasMerchantsApi = async () => {
  try {
    const response = await trackPromise(
      Api({ method: "get", url: "vas_merchants" })
    );
    return response.data as VasMerchant[];
  } catch (listVasMerchantsError) {
    throw listVasMerchantsError;
  }
};

export const editTerminalApi = async ({ terminal_id, name }: EditTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/change-name",
        data: {
          name,
          terminal_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const downloadTerminalsApi = async ({
  dateRange,
  queryParams = {},
}: TerminalDownload) => {
  let encodedQueryParams = btoa(
    unescape(encodeURIComponent(JSON.stringify(queryParams)))
  );
  let encodeddateRange = btoa(JSON.stringify(dateRange));
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/export/download",
        params: {
          queryParams: encodedQueryParams,
          dateRange: encodeddateRange,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const getShortenedTerminalsApi = async ({
  limit = 5,
  page = 1,
  search = null,
}: ShortenedTerminalsApi) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/pullout",
        params: {
          limit,
          page,
          search,
        },
      })
    );
    return response.data as {
      pages: any;
      terminals: ShortenedTerminalResponse[];
    };
  } catch (e: any) {
    throw e;
  }
};

export const submitPullRequestApi = async ({
  terminalIds,
  reason,
  merchant_id,
}: {
  terminalIds: string[];
  reason: string;
  merchant_id?: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "terminals/pullout",
        data: {
          terminal_ids: terminalIds,
          reason: reason,
          merchant_id,
        },
      })
    );
    return response.data as {
      pages: any;
      terminals: ShortenedTerminalResponse[];
    };
  } catch (e: any) {
    throw e;
  }
};
