import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Input, UserIcon } from "@lib";
import { Modal } from "@lib/modal";
import { initialAdminState, NewAdmin } from "../../utils/api/admins-apis";
import { createAdminApi } from "@lib/utils/api/admins-apis";
import { IError } from "@lib/nice-errors";
import {
  countriesOptions,
  defaultCountriesOption,
} from "../../utils/countriesOptions";

type Props = {
  toggleModal: any;
  isOpen: any;
  newAdmin: NewAdmin;
  setNewAdmin: React.Dispatch<React.SetStateAction<NewAdmin>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateAdminModal = ({
  toggleModal,
  isOpen,
  newAdmin,
  setNewAdmin,
  setRefresh,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [createAdminErrors, setCreateAdminErrors] = useState<IError | null>(
    null
  );

  const saveAdmin = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      await createAdminApi(newAdmin);
      setIsLoading(false);
      close();
    } catch (e: any) {
      setCreateAdminErrors(e);
      setIsLoading(false);
    }
  };

  const close = () => {
    setCreateAdminErrors(null);
    setNewAdmin(initialAdminState);
    toggleModal();
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={t("addNewMember")}
      subTitle={t("admin")}
      close={close}
      icon={UserIcon}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form>
        <Input
          name="name"
          onChange={(e) =>
            setNewAdmin((prevState: NewAdmin) => ({ ...prevState, name: e }))
          }
          value={newAdmin.name}
          errors={createAdminErrors}
          label={t("name")}
        />
        <Input
          name="email"
          onChange={(e) =>
            setNewAdmin((prevState: NewAdmin) => ({
              ...prevState,
              email: e.toLowerCase(),
            }))
          }
          errors={createAdminErrors}
          value={newAdmin.email}
          label={t("email")}
        />
        <Input
          name="mobile"
          onChange={(e) =>
            setNewAdmin((prevState: NewAdmin) => ({ ...prevState, mobile: e }))
          }
          defaultDropDownOption={defaultCountriesOption}
          errors={createAdminErrors}
          value={newAdmin.mobile}
          label={t("mobile")}
          type={"phone"}
          dropDownOptions={countriesOptions}
          dropDownValueKey="dial_code"
          dropDownDisplayKey="display_name"
        />
        <div className="mt-6">
          <Button
            className={"px-2 py-1"}
            label={t("invite") as string}
            onClick={saveAdmin}
            colorScheme="black"
            isRounded={false}
          />
        </div>
      </form>
    </Modal>
  );
};

export default CreateAdminModal;
