import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { AppListItem, EmptyState, GroupedList } from "@lib";
import { listAppsApi } from "@lib/utils/api/apps-api";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  limit?: number;
};

type App = {
  pages: number;
  clientPackages: ClientPackage[];
};

type ClientPackage = {
  app_name: string;
  id: string;
  name: string;
  type: string;
};

const ListApps = ({ refresh, limit = 10 }: Props) => {
  const [apps, setApps] = useState<App[]>([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToApp = (item_id: string) => {
    navigate(item_id);
  };

  const fetchApps = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listAppsApi({
        page,
        limit,
      });

      setPages(response.pages);
      // TODO: remove after test
      if (response.clientPackages) setApps(response.clientPackages);
      else if (response.merchantPackages) setApps(response.merchantPackages);
      // end remove
      else setApps(response.packages);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, [refresh]);
  return (
    <GroupedList
      pages={{
        selectPage: (page) => fetchApps(page),
        total: pages,
      }}
      data={apps}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      component={AppListItem}
      onItemClick={(item) => goToApp(item.id)}
      role={"client"}
      isLoading={isLoading}
    />
  );
};
export default ListApps;
