import { FC } from "react";

import { Navigate } from "react-router-dom";

type Props = {
  redirectPath?: string;
};

const Redirect: FC<Props> = ({ redirectPath = "/" }: Props) => {
  return <Navigate to={redirectPath} />;
};

export default Redirect;
