import React from "react";
import { FC, ReactNode } from "react";
import { usePromiseTracker } from "react-promise-tracker";
// import LoadingSpinner from "./loading-spinner";

type Props = {
  children: ReactNode;
};

const PublicContainer: FC<Props> = ({ children }: Props) => {
  return (
    <>{children}</>
    // <div className="flex flex-col flex-1 h-full bg-gray-100">
    //   <main className="flex flex-1 h-full bg-opacity-75">{children}</main>
    // </div>
  );
};

export default PublicContainer;
