import React, { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight, TerminalIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";
import { TerminalListItemType } from "../types/terminal";
import { Flex, Grid, Icon } from "@tremor/react";
import { t, use } from "i18next";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

type LabelProps = {
  code: string | undefined;
  label: string | string[] | undefined;
  labelCode?: string;
  containerClassName: string;
};
const Label = ({ code, label, labelCode, containerClassName }: LabelProps) => {
  return (
    <Flex flexDirection="col" alignItems="start" className={containerClassName}>
      <Flex
        alignItems="center"
        justifyContent="start"
        className="text-gray-400  text-sm"
      >
        {t(label ?? "") as string}
        {labelCode && (
          <Icon
            className="p-0 opacity-50 hover:opacity-100 scale-75 tooltip"
            data-tip={labelCode}
            color="gray"
            size="sm"
            icon={InformationCircleIcon}
          />
        )}
      </Flex>
      <span className="flex-1 font-bold w-full whitespace-wrap overflow-hidden text-ellipsis ">
        {code}
      </span>
    </Flex>
  );
};
export const TerminalListItem: FC<TerminalListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  const { t, i18n } = useTranslation();
  // const isRegisteredBadge: BadgeType = item?.is_assigned_to_user
  //   ? { label: t("terminalAssinged"), color: "gray" }
  //   : { label: t("terminalNotAssinged"), color: "green" };
  const isConnectedBadge: BadgeType = item?.is_connected
    ? { label: t("deviceConnected"), color: "gray" }
    : { label: t("deviceNotConnected"), color: "green" };

  return (
    <NavLink to={"/terminals/" + item?.id}>
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="xl:py-1 px-4 my-1">
        <div className="flex justify-start sm:justify-center gap-3 sm:gap-16 flex-col  sm:flex-row">
          <Flex
            justifyContent="start"
            className="gap-3 w-auto min-w-full max-w-full sm:min-w-[35%] sm:max-w-[35%] overflow-hidden"
          >
            <TerminalIcon containerClassNames="!p-0" />
            <div
              className={
                i18n.language == "ar"
                  ? "flex flex-col flex-1 xl:flex-initial "
                  : "flex flex-col flex-1 xl:flex-initial "
              }
            >
              <Label
                containerClassName=" text-base text-ellipsis whitespace-nowrap"
                code={item?.name ? item?.name : "#" + item?.tid}
                label={
                  item?.name ? item?.tid : (t("terminalIdentifier") as string)
                }
              />
            </div>
          </Flex>

          <Flex
            alignItems="start"
            className="w-full  flex-wrap  group-[.policy]:hidden gap-3 "
          >
            <Label
              containerClassName="text-xs xl:text-base flex-1 xl:text-left"
              code={item?.bank?.code}
              label="bank"
            />
            <Label
              containerClassName="text-xs xl:text-base flex-1 xl:text-left"
              code={item?.merchant_name_english}
              label="retailer"
            />
            <Label
              containerClassName="text-xs xl:text-base flex-1 xl:text-left hidden md:flex"
              code={item?.merchant_category}
              label="category"
              labelCode={item?.merchant_category_code}
            />
            <Label
              containerClassName="text-xs xl:text-base flex-1 xl:text-left"
              code={item?.trsm}
              label="TRSM"
            />
            <Label
              containerClassName="text-xs xl:text-base flex-1 xl:text-left hidden lg:block"
              code={item?.user?.name}
              label="user"
            />
          </Flex>

          <div
            className={`${onclick} " hidden group-[.policy]:flex flex-initial h-full items-center"`}
          >
            {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
          </div>

          <Badge
            className="min-w-[100px] xl:flex justify-center align-middle hidden !text-xs !py-1 h-7 self-center"
            isRounded={true}
            {...isConnectedBadge}
          />
        </div>
      </Card>
    </NavLink>
  );
};

type ShortenedTerminalProps = {
  item: any;
  setTerminalIds: React.Dispatch<SetStateAction<string[]>>;
  terminalIds: string[];
};

export const ShortenedTerminalListItem: FC<ShortenedTerminalProps> = ({
  item,
  setTerminalIds,
  terminalIds,
}) => {
  const { t, i18n } = useTranslation();

  const isConnectedBadge: BadgeType = {
    label: t("alreadyRequestedPullOut"),
    color: "gray",
  };

  const handleItemClick = () => {
    setTerminalIds((prevTerminals: string[]) => {
      if (prevTerminals.includes(item?.reference_terminal_id)) {
        return prevTerminals.filter(
          (selectedId) => selectedId !== item?.reference_terminal_id
        );
      } else {
        return [...prevTerminals, item?.reference_terminal_id];
      }
    });
  };

  return (
    <>
      <Card
        classNames={
          "flex justify-between sm:p-5 justify-between gap-3 sm:gap-16 flex-col sm:flex-row xl:py-1 px-4 my-1 "
        }
      >
        <Flex justifyContent="start" className="gap-3 w-auto">
          <TerminalIcon containerClassNames="!p-0" />
          <div className="flex flex-col flex-1 xl:flex-initial my-2">
            <span className="text-gray-600 text-base">
              {item?.name ? item?.tid : (t("terminalIdentifier") as string)}
            </span>
            <span className="flex-1 font-bold text-4xl xl:text-sm">
              {item?.name ? item?.name : "#" + item?.tid}
            </span>
          </div>
        </Flex>
        {item?.is_requested ? (
          <Badge
            className="min-w-[100px] xl:flex justify-center align-middle hidden !text-xs !py-1 h-7 self-center"
            isRounded={true}
            {...isConnectedBadge}
          />
        ) : (
          <input
            className="w-6 h-6 mt-5 flex items-center text-gray-800 bg-gray-300 border-gray-300 rounded !ring-transparent checked:!ring-gray-800  cursor-pointer"
            type="checkbox"
            checked={
              terminalIds.find((id) => item.reference_terminal_id == id)
                ? true
                : false
            }
            color="black"
            onChange={() => handleItemClick()}
          />
        )}
      </Card>
    </>
  );
};
