import { dateToRelativeTime } from "@lib/utils/date-to-relative-time";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  ArrowsUpDownIcon,
  CheckCircleIcon,
  CheckIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import ALBI from "../../icons/ALBI.png";
import ANBB from "../../icons/ANBB.jpeg";
import BSFB from "../../icons/BSFB.jpeg";
import BSHB from "../../icons/BSHB.png";
import FABM from "../../icons/FABM.png";
import GEID from "../../icons/GEID.png";
import GIBB from "../../icons/GIBB.jpeg";
import INMA from "../../icons/INMA.png";
import JAZB from "../../icons/JAZB.png";
import NBOB from "../../icons/NBOB.png";
import NBOK from "../../icons/NBOK.png";
import RAJB from "../../icons/RAJB.png";
import RYDB from "../../icons/RYDB.png";
import SABB from "../../icons/SABB.png";
import SAIB from "../../icons/SAIB.jpeg";
import STCP from "../../icons/STCP.jpeg";

import { default as Mada } from "../../icons/mada.png";
import { default as Visa } from "../../icons/visa.png";
import { default as Mastercard } from "../../icons/mastercard.png";
import { default as American_Express } from "../../icons/American_Express.png";
import { default as GCCNET } from "../../icons/GCCNET.png";
import { default as Discover } from "../../icons/Discover.png";
import { default as UnionPay } from "../../icons/UnionPay.png";
import { default as Maestro } from "../../icons/Maestro.png";
import { default as JCB } from "../../icons/JCB.png";
import { TableCell, TableRow, Text, Badge, Icon, Flex } from "@tremor/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { banks } from "@lib/components/query-builder/data";
import { t } from "i18next";
import { TransactionItem } from "@lib/types/transaction";
const PaymentSchemeIcons: { [key: string]: string } = {
  P1: Mada,
  VC: Visa,
  MC: Mastercard,
  DM: Maestro,
  AX: American_Express,
  JC: JCB,
  DC: Discover,
  UP: UnionPay,
  GN: GCCNET,
};
const BankIcons: { [key: string]: string } = {
  ALBI: ALBI,
  ANBB: ANBB,
  BSFB: BSFB,
  BSHB: BSHB,
  FABM: FABM,
  GEID: GEID,
  GIBB: GIBB,
  INMA: INMA,
  JAZB: JAZB,
  NBOB: NBOB,
  NBOK: NBOK,
  RAJB: RAJB,
  RYDB: RYDB,
  SABB: SABB,
  SAIB: SAIB,
  STCP: STCP,
};

const currencies = {
  "404": "KES",
};
export const TransactionRow = ({ item }: { item: TransactionItem }) => {
  console.log(item);
  const navigate = useNavigate();
  const goToTransaction = () => navigate("/transactions/" + item.id);
  return (
    <TableRow
      onClick={goToTransaction}
      className="hover:bg-gray-100 cursor-pointer propagate-border"
    >
      <TableCell className="p-0 text-black ">
        <TransactionType
          type={item?.transaction_type}
          is_reversed={item?.is_reversed}
        />
      </TableCell>
      <TableCell className="max-w-[120px] min-w-[120px] p-3 text-black">
        <FormatPrice item={item} />
      </TableCell>
      <TableCell className="rtl:text-right text-black">
        {item?.is_approved ? (
          <Badge
            color="teal"
            className="flex rtl:flex-row-reverse"
            icon={CheckCircleIcon}
          >
            {t("approved")}
          </Badge>
        ) : (
          <Badge
            className="flex rtl:flex-row-reverse "
            color="rose"
            icon={XCircleIcon}
          >
            {t("declined")}
          </Badge>
        )}
      </TableCell>
      <TableCell className="text-center p-0 text-black">
        {item.is_reconciled ? (
          <Icon size="lg" className="p-0 " icon={CheckCircleIcon} />
        ) : null}
      </TableCell>
      <TableCell className="py-0 rtl:text-right text-black">
        <div className="flex flex-col">
          <span className="text-sm font-bold whitespace-nowrap">
            {item?.retrieval_reference_number}
          </span>
          <span className="text-xs">{item?.system_trace_audit_number}</span>
        </div>
      </TableCell>
      <TableCell className="py-0 text-black">
        <Flex justifyContent="start" className="p-0 m-0 gap-1.5">
          <img src={PaymentSchemeIcons[item?.card_scheme_id]} className="w-7" />
          <span className="font-bold whitespace-nowrap">
            {item?.pan.slice(-4)}
          </span>
        </Flex>
      </TableCell>
      <TableCell className="py-0 px-6 rtl:text-right text-black">
        <Flex justifyContent="start" className="gap-1">
          <img src={BankIcons[item?.card_scheme_sponsor_id]} className="w-5" />
          <span className=" font-semibold text-xs">
            {item?.card_scheme_sponsor_id}
          </span>
        </Flex>
      </TableCell>
      <TableCell className="p-0 min-w-[90px] rtl:text-right text-black">
        <Text className="text-black">
          {dateToRelativeTime(item?.created_at)}
        </Text>
        <Text className="text-black">{item?.receipts?.[0]?.start_date}</Text>
      </TableCell>
      <TableCell className="p-1 rtl:text-right text-black">
        <Text className="truncate font-semibold text-black">
          {item?.merchant?.name}
        </Text>
        {/* <Text className="truncate font-light text-xs">
          {item?.merchant?.name_ar}
        </Text> */}
      </TableCell>
      {/* <TableCell className="w-48 rtl:text-right text-black">
        <Text className="text-black"> {item?.car}</Text>
      </TableCell> */}
      <TableCell className="p-0 w-44 rtl:text-right text-black">
        <Text className="font-semibold text-black">{item?.user?.name} </Text>
        <Text className="text-xs text-black">{item?.user?.mobile}</Text>
      </TableCell>
    </TableRow>
  );
};
export default TransactionRow;

export const TransactionType = ({
  type,
  is_reversed,
}: {
  type: any;
  is_reversed: boolean;
}) => {
  const { i18n, t } = useTranslation();
  const name = i18n.language == "ar" ? type?.name.arabic : type?.name.english;

  const handleType = () => {
    switch (type?.name.english) {
      case "PURCHASE":
        return (
          <>
            {is_reversed ? (
              <div className="bg-black rounded-full m-1.5 p-1">
                <ArrowsUpDownIcon color="white" height={16} width={16} />
              </div>
            ) : (
              <div className="bg-violet-600 rounded-full m-1.5 p-0.5">
                <ArrowSmallUpIcon color="white" height={20} width={20} />
              </div>
            )}
          </>
        );
      case "REFUND":
        return (
          <>
            {is_reversed ? (
              <div className="bg-gray-400 rounded-full m-1.5 p-1">
                <ArrowsUpDownIcon color="white" height={16} width={16} />
              </div>
            ) : (
              <div className="bg-orange-600 rounded-full m-1.5 p-0.5">
                <ArrowSmallDownIcon color="white" height={20} width={20} />
              </div>
            )}
          </>
        );
    }
  };
  return (
    <div className={"flex items-center "}>
      {handleType()}
      <span className="flex flex-col leading-3 !text-xs ">
        {name}
        <span className="text-black !text-xs">
          {is_reversed && (t("reversed") as string)}
        </span>
      </span>
    </div>
  );
};
export const FormatPrice = ({ item }: { item: TransactionItem }) => {
  const { i18n, t } = useTranslation();

  return (
    <Text className="trucnate text-right font-medium flex gap-1 justify-end rtl:flex-row-reverse text-black">
      {(Number(item?.amount_authorized.toString()) / Math.pow(10, 2)).toFixed(
        2
      )}
      <small className="lg:text-[.7rem] font-normal ">KES</small>
    </Text>
  );
};
