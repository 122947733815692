import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList, TransactionListItem } from "@lib";
import { listTransactionsApi } from "@lib/utils/api/transactions-apis";
import { dateRange } from "@lib/types/date";
import React from "react";

type Props = {
  limit?: number;
  activeTab?: string;
  refresh?: boolean;
  terminal_id?: string;
  user_id?: string;
  reconciliation_id?: string;
  merchant_id?: string;
  setTransactionsForDownload?: any;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const ListTransactions: FC<Props> = ({
  refresh,
  terminal_id,
  user_id,
  merchant_id,
  reconciliation_id,
  activeTab,
  limit = 30,
  setTransactionsForDownload,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [transactions, setTransactions] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchTransactions = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTransactionsApi({
        page,
        merchant_id,
        limit,
        terminal_id,
        user_id,
        reconciliation_id,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setPages(response.pages);
      setTransactions(response.transactions);
      setTransactionsForDownload?.(response.transactions);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToTransaction = (transaction: any) =>
    navigate("/transactions/" + transaction.uuid);

  useEffect(() => {
    fetchTransactions();
  }, [refresh, activeTab, reconciliation_id]);

  return (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchTransactions(page),
      }}
      onItemClick={(item) => goToTransaction(item)}
      role={"client"}
      data={transactions}
      component={TransactionListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      listType="transactions"
      isLoading={isLoading}
    />
  );
};

export default ListTransactions;
