import React, { FC } from "react";
import { Switch as TailwindSwitch } from "@headlessui/react";
import { mergeClassNames } from "../utils";
import { CheckIcon } from "@heroicons/react/20/solid";

interface SwitchType extends React.HTMLAttributes<HTMLBodyElement> {
  isEnabled: boolean;
  setIsEnabled: (enabled: boolean) => void;
}

export const Switch: FC<SwitchType> = ({
  className,
  isEnabled,
  setIsEnabled,
}) => {
  return (
    <TailwindSwitch
      checked={isEnabled}
      onChange={setIsEnabled}
      className={mergeClassNames(
        isEnabled ? "bg-blue-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        className as string
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={mergeClassNames(
          isEnabled
            ? "ltr:translate-x-5 rtl:translate-x-0"
            : "ltr:translate-x-0 rtl:-translate-x-5",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </TailwindSwitch>
  );
};

interface ISwitchv2 {
  options: {
    displayName: string;
    value?: string;
  }[];
  selected: string;
  onChange: any;
}

export const Switchv2: FC<ISwitchv2> = ({
  options = [],
  selected,
  onChange,
}) => {
  const isSelected = (option: any) =>
    selected == (option.value ?? option.displayName);
  return (
    <div onChange={onChange} className="flex flex-row gap-x-2 w-full">
      {options.map((option, index) => (
        <button
          key={index}
          className="flex flex-row items-center gap-x-2 font-bold border-2 border-black p-4 rounded-md w-full text-left"
          onClick={() => onChange(option.value ?? option.displayName)}
        >
          <div
            className={`flex-initial rounded-full border-2 border-black w-5 h-5 flex items-center justify-center ${
              isSelected(option) ? "bg-black" : ""
            }`}
          >
            {isSelected(option) && <CheckIcon className="w-4 h-4 text-white" />}
          </div>
          <label className="capitalize flex-1 cursor-pointer rtl:text-right">
            {option.displayName}
          </label>
        </button>
      ))}
    </div>
  );
};
