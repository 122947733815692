import React from "react";
import { FC } from "react";
import { BarLoader } from "react-spinners";

type Props = {
  isLoading: boolean;
  isTransparent?: boolean;
  zIndex?: "z-10" | "z-20" | "z-30" | "z-40" | "z-50";
};

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

const LoadingSpinner: FC<Props> = ({
  isLoading,
  isTransparent = false,
  zIndex,
}: Props) => {
  return isLoading ? (
    <div
      className={classNames(
        "bg-white flex items-center justify-center rounded-lg absolute top-0 left-0 right-0 bottom-0 ",
        isTransparent ? " bg-opacity-50" : "",
        zIndex ?? "z-20"
      )}
    >
      <BarLoader
        height={4}
        width={140}
        loading={true}
        color="black"
        // color="var(--theme-primary)"
        className="block"
      />
    </div>
  ) : null;
};

export default LoadingSpinner;
