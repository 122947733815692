import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList, MerchantsListItem } from "@lib";
import { listMerchantsApi } from "@lib/utils/api/merchants-apis";
import { GenericTable } from "@lib/tables/generic-table";
import React from "react";
import { MerchantsHeader } from "./table-headers/merchants-header";
import { MerchantRow } from "./rows/merchant-row";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  activeTab?: string;
  limit?: number;
  queryParams?: {
    [key: string]: any;
  };
};

const MerchantsTable: FC<Props> = ({
  refresh,
  setRefresh,
  activeTab,
  queryParams = {},
}: Props) => {
  const [merchants, setMerchants] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);

  const fetchMerchants = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listMerchantsApi({
        page,
        queryParams: queryParams as {
          [key: string]: any;
        },
      });
      setNumberOfPages(response.pages);
      setMerchants(response.merchants);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMerchants(selectedPage);
  }, [refresh, activeTab, selectedPage]);

  return (
    <GenericTable
      data={merchants}
      isLoading={isLoading}
      component={MerchantRow}
      numberOfPages={numberOfPages}
      setSelectedPage={setSelectedPage}
      header={<MerchantsHeader />}
    />
  );
};

export default MerchantsTable;
