import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Errors, Input, Select } from "@lib";
import { ReactComponent as PaymentSchemeLogos } from "../../assets/payment-schemes.svg";
import { createClientApi } from "@lib/utils/api/onboarding-apis";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { usePromiseTracker } from "react-promise-tracker";

const CreateOrgnizationAccount = () => {
  const { promiseInProgress: isLoading } = usePromiseTracker();
  const [orgnizationName, setOrgnizationName] = useState("");
  const [createOrgnizationAccountErrors, setCreateOrgnizationAccountErrors] =
    useState<{
      [key: string]: any;
    }>({});
  const [orgnizationType, setOrgnizationType] = useState("");
  const [orgnizationSize, setOrgnizationSize] = useState("");
  const [orgnizationCountry, setOrgnizationCountry] = useState("");
  const { i18n, t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { auth } = useContext(UserContext) as UserContextType;

  const handleCreateClient = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();

    try {
      const admin = auth.user;
      let response = await createClientApi({
        recaptchaToken: "",
        name: orgnizationName,
        country: orgnizationCountry,
        type: orgnizationType,
        size: orgnizationSize,
        admin_id: admin.id,
      }).then((response: any) => {
        navigate("/select-platform");
      });
    } catch (error: any) {
      error.message
        ? setCreateOrgnizationAccountErrors(error.message)
        : setCreateOrgnizationAccountErrors(error);
    }
  };

  return (
    <div
      style={{ padding: "20px" }}
      className="sm:justify-center flex flex-row h-full w-full relative py-10 "
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="flex flex-1 flex-col justify-center">
            <span className="text-xl sm:text-3xl font-bold">
              {t("createOrgnizationAccount")}
            </span>
            <div className="mb-4 mt-2">
              <Errors
                errors={
                  createOrgnizationAccountErrors.error
                    ? [
                        i18n.language == "ar"
                          ? createOrgnizationAccountErrors.error.arabic
                          : createOrgnizationAccountErrors.error.english,
                      ]
                    : []
                }
              />
            </div>
            <div className="flex flex-col max-w-sm xl:max-w-full">
              <Input
                value={orgnizationName}
                label={t("orgnizationName")}
                errors={createOrgnizationAccountErrors?.name}
                onChange={setOrgnizationName}
                name={"orgnizationName"}
                inputClasses="border border-2 "
              />
              <Select
                value={orgnizationType}
                label={t("type")}
                errors={createOrgnizationAccountErrors?.type}
                onChange={setOrgnizationType}
                name={"orgnizationType"}
                data={type}
                displayKey="code"
              />
              <Select
                value={orgnizationSize}
                label={t("size")}
                errors={createOrgnizationAccountErrors?.size}
                onChange={setOrgnizationSize}
                name={"orgnizationSize"}
                data={size}
                displayKey="size"
              />
              <Select
                value={orgnizationCountry}
                label={t("country")}
                errors={createOrgnizationAccountErrors?.country}
                onChange={setOrgnizationCountry}
                name={"orgnizationCountry"}
                data={countryList}
                displayKey="code"
              />
              <Button
                label={t("create") as string}
                onClick={handleCreateClient}
                classNames="w-full py-2 px-4 mt-4 border border-black react-select "
                colorScheme="black"
                isRounded={false}
              />
            </div>
            {/* <GoogleReCaptcha onVerify={onVerify} /> */}
          </form>
        </div>
        <div className="flex items-start justify-center ">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default CreateOrgnizationAccount;

const countryList = [
  { code: "Afghanistan" },
  { code: "Albania" },
  { code: "Algeria" },
  { code: "American Samoa" },
  { code: "Andorra" },
  { code: "Angola" },
  { code: "Anguilla" },
  { code: "Antarctica" },
  { code: "Antigua and Barbuda" },
  { code: "Argentina" },
  { code: "Armenia" },
  { code: "Aruba" },
  { code: "Australia" },
  { code: "Austria" },
  { code: "Azerbaijan" },
  { code: "Bahamas (the)" },
  { code: "Bahrain" },
  { code: "Bangladesh" },
  { code: "Barbados" },
  { code: "Belarus" },
  { code: "Belgium" },
  { code: "Belize" },
  { code: "Benin" },
  { code: "Bermuda" },
  { code: "Bhutan" },
  { code: "Bolivia (Plurinational State of)" },
  { code: "Bonaire, Sint Eustatius and Saba" },
  { code: "Bosnia and Herzegovina" },
  { code: "Botswana" },
  { code: "Bouvet Island" },
  { code: "Brazil" },
  { code: "British Indian Ocean Territory (the)" },
  { code: "Brunei Darussalam" },
  { code: "Bulgaria" },
  { code: "Burkina Faso" },
  { code: "Burundi" },
  { code: "Cabo Verde" },
  { code: "Cambodia" },
  { code: "Cameroon" },
  { code: "Canada" },
  { code: "Cayman Islands (the)" },
  { code: "Central African Republic (the)" },
  { code: "Chad" },
  { code: "Chile" },
  { code: "China" },
  { code: "Christmas Island" },
  { code: "Cocos (Keeling) Islands (the)" },
  { code: "Colombia" },
  { code: "Comoros (the)" },
  { code: "Congo (the Democratic Republic of the)" },
  { code: "Congo (the)" },
  { code: "Cook Islands (the)" },
  { code: "Costa Rica" },
  { code: "Croatia" },
  { code: "Cuba" },
  { code: "Curaçao" },
  { code: "Cyprus" },
  { code: "Czechia" },
  { code: "Côte d'Ivoire" },
  { code: "Denmark" },
  { code: "Djibouti" },
  { code: "Dominica" },
  { code: "Dominican Republic (the)" },
  { code: "Ecuador" },
  { code: "Egypt" },
  { code: "El Salvador" },
  { code: "Equatorial Guinea" },
  { code: "Eritrea" },
  { code: "Estonia" },
  { code: "Eswatini" },
  { code: "Ethiopia" },
  { code: "Falkland Islands (the) [Malvinas]" },
  { code: "Faroe Islands (the)" },
  { code: "Fiji" },
  { code: "Finland" },
  { code: "France" },
  { code: "French Guiana" },
  { code: "French Polynesia" },
  { code: "French Southern Territories (the)" },
  { code: "Gabon" },
  { code: "Gambia (the)" },
  { code: "Georgia" },
  { code: "Germany" },
  { code: "Ghana" },
  { code: "Gibraltar" },
  { code: "Greece" },
  { code: "Greenland" },
  { code: "Grenada" },
  { code: "Guadeloupe" },
  { code: "Guam" },
  { code: "Guatemala" },
  { code: "Guernsey" },
  { code: "Guinea" },
  { code: "Guinea-Bissau" },
  { code: "Guyana" },
  { code: "Haiti" },
  { code: "Heard Island and McDonald Islands" },
  { code: "Holy See (the)" },
  { code: "Honduras" },
  { code: "Hong Kong" },
  { code: "Hungary" },
  { code: "Iceland" },
  { code: "India" },
  { code: "Indonesia" },
  { code: "Iran (Islamic Republic of)" },
  { code: "Iraq" },
  { code: "Ireland" },
  { code: "Isle of Man" },
  { code: "Israel" },
  { code: "Italy" },
  { code: "Jamaica" },
  { code: "Japan" },
  { code: "Jersey" },
  { code: "Jordan" },
  { code: "Kazakhstan" },
  { code: "Kenya" },
  { code: "Kiribati" },
  { code: "Korea (the Democratic People's Republic of)" },
  { code: "Korea (the Republic of)" },
  { code: "Kuwait" },
  { code: "Kyrgyzstan" },
  { code: "Lao People's Democratic Republic (the)" },
  { code: "Latvia" },
  { code: "Lebanon" },
  { code: "Lesotho" },
  { code: "Liberia" },
  { code: "Libya" },
  { code: "Liechtenstein" },
  { code: "Lithuania" },
  { code: "Luxembourg" },
  { code: "Macao" },
  { code: "Madagascar" },
  { code: "Malawi" },
  { code: "Malaysia" },
  { code: "Maldives" },
  { code: "Mali" },
  { code: "Malta" },
  { code: "Marshall Islands (the)" },
  { code: "Martinique" },
  { code: "Mauritania" },
  { code: "Mauritius" },
  { code: "Mayotte" },
  { code: "Mexico" },
  { code: "Micronesia (Federated States of)" },
  { code: "Moldova (the Republic of)" },
  { code: "Monaco" },
  { code: "Mongolia" },
  { code: "Montenegro" },
  { code: "Montserrat" },
  { code: "Morocco" },
  { code: "Mozambique" },
  { code: "Myanmar" },
  { code: "Namibia" },
  { code: "Nauru" },
  { code: "Nepal" },
  { code: "Netherlands (the)" },
  { code: "New Caledonia" },
  { code: "New Zealand" },
  { code: "Nicaragua" },
  { code: "Niger (the)" },
  { code: "Nigeria" },
  { code: "Niue" },
  { code: "Norfolk Island" },
  { code: "Northern Mariana Islands (the)" },
  { code: "Norway" },
  { code: "Oman" },
  { code: "Pakistan" },
  { code: "Palau" },
  { code: "Palestine, State of" },
  { code: "Panama" },
  { code: "Papua New Guinea" },
  { code: "Paraguay" },
  { code: "Peru" },
  { code: "Philippines (the)" },
  { code: "Pitcairn" },
  { code: "Poland" },
  { code: "Portugal" },
  { code: "Puerto Rico" },
  { code: "Qatar" },
  { code: "Republic of North Macedonia" },
  { code: "Romania" },
  { code: "Russian Federation (the)" },
  { code: "Rwanda" },
  { code: "Réunion" },
  { code: "Saint Barthélemy" },
  { code: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "Saint Kitts and Nevis" },
  { code: "Saint Lucia" },
  { code: "Saint Martin (French part)" },
  { code: "Saint Pierre and Miquelon" },
  { code: "Saint Vincent and the Grenadines" },
  { code: "Samoa" },
  { code: "San Marino" },
  { code: "Sao Tome and Principe" },
  { code: "Saudi Arabia" },
  { code: "Senegal" },
  { code: "Serbia" },
  { code: "Seychelles" },
  { code: "Sierra Leone" },
  { code: "Singapore" },
  { code: "Sint Maarten (Dutch part)" },
  { code: "Slovakia" },
  { code: "Slovenia" },
  { code: "Solomon Islands" },
  { code: "Somalia" },
  { code: "South Africa" },
  { code: "South Georgia and the South Sandwich Islands" },
  { code: "South Sudan" },
  { code: "Spain" },
  { code: "Sri Lanka" },
  { code: "Sudan (the)" },
  { code: "Suriname" },
  { code: "Svalbard and Jan Mayen" },
  { code: "Sweden" },
  { code: "Switzerland" },
  { code: "Syrian Arab Republic" },
  { code: "Taiwan" },
  { code: "Tajikistan" },
  { code: "Tanzania, United Republic of" },
  { code: "Thailand" },
  { code: "Timor-Leste" },
  { code: "Togo" },
  { code: "Tokelau" },
  { code: "Tonga" },
  { code: "Trinidad and Tobago" },
  { code: "Tunisia" },
  { code: "Turkey" },
  { code: "Turkmenistan" },
  { code: "Turks and Caicos Islands (the)" },
  { code: "Tuvalu" },
  { code: "Uganda" },
  { code: "Ukraine" },
  { code: "United Arab Emirates (the)" },
  { code: "United Kingdom of Great Britain and Northern Ireland (the)" },
  { code: "United States Minor Outlying Islands (the)" },
  { code: "United States of America (the)" },
  { code: "Uruguay" },
  { code: "Uzbekistan" },
  { code: "Vanuatu" },
  { code: "Venezuela (Bolivarian Republic of)" },
  { code: "Viet Nam" },
  { code: "Virgin Islands (British)" },
  { code: "Virgin Islands (U.S.)" },
  { code: "Wallis and Futuna" },
  { code: "Western Sahara" },
  { code: "Yemen" },
  { code: "Zambia" },
  { code: "Zimbabwe" },
  { code: "Åland Islands" },
];

const size: { [key: string]: string }[] = [
  { size: "1-10" },
  { size: "11-50" },
  { size: "51-100" },
  { size: "> 100" },
];

const type: { [key: string]: string }[] = [
  { code: "MSP" },
  { code: "PSP" },
  { code: "Bank" },
  { code: "Acquirer" },
  { code: "Developer" },
];
