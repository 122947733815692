import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import ViewReconciliation from "@lib/components/reconciliations/view"
type Props = {};

const Reconciliation: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Content hasBackButton isLoading={isLoading}>
      <ViewReconciliation
        id={id as string}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </Content>
  );
};

export default Reconciliation;