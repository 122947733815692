import React, { FC, useState } from "react";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Modal, TerminalIcon } from "@lib";
import { useTranslation } from "react-i18next";

import PullTerminalsList from "@lib/components/terminals/pull-terminals-list";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  merchant_id?: string;
  setIsLoading?: any;
  limit?: number;
};

const TerminalPullOutModal: FC<Props> = ({ isOpen, toggleModal }) => {
  const { t } = useTranslation();

  const close = async () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("pullOutTerminals") as string}
      subTitle=""
      width="lg"
      icon={TerminalIcon}
      close={close}
    >
      <>
        <PullTerminalsList close={close} />
      </>
    </Modal>
  );
};

export default TerminalPullOutModal;
