import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import ViewApp from "@lib/components/apps/view";
type Props = {};

const App: FC<Props> = ({}: Props) => {
  const { id } = useParams();

  return (
    <Content hasBackButton>
      <ViewApp id={id as string} />
    </Content>
  );
};

export default App;
