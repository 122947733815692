import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList, PoliciesListItem } from "@lib";
import { listPoliciesApi } from "@lib/utils/api/policies-apis";

type Props = {
  refresh: boolean;
  limit?: number;
};

export const ListPolicies: FC<Props> = ({ refresh, limit }: Props) => {
  const [policies, setPolicies] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchPolicies = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listPoliciesApi({ page });
      setPages(response.pages);
      setPolicies(response.policies);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToPolicy = (policy: any) => {
    navigate("./" + policy.id);
  };

  useEffect(() => {
    fetchPolicies();
  }, [refresh]);
  return (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchPolicies(page),
      }}
      role={"client"}
      data={policies}
      component={PoliciesListItem}
      onItemClick={(item) => goToPolicy(item)}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      isLoading={isLoading}
    />
  );
};
