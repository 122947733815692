import Api from ".";

export  const listQueryParamsApi = async (key: string, value: string) => {
  try {
    const response = await Api({
      method: "post",
      url: `find/${key}`,
      data: { value },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
