import Api, { List } from ".";
import { trackPromise } from "react-promise-tracker";

export enum MerchantStatus {
  active = "active",
  deactive = "deactive",
}

export interface MerchantResponse {
  id: string;
  name: string;
  name_ar: string;
}

interface policies {
  merchant_id?: string;
  terminal_id?: string;
  page?: number;
  limit?: number;
}

export interface PolicyResponse {
  id: string;
  time: string;
  type: string;
  relation_id: string;
  policy_jobs: PolicyJob[];
}
interface PolicyJob {
  id: string;
  total_succeed: number;
  total_failed: number;
  total_balanced: number;
  total_unbalanced: number;
}
interface CreatePlicy {
  time: string;
  merchant_id?: string;
  terminal_id?: string;
}

export const listPoliciesApi = async ({ page = 1, limit = 20 }: policies) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "policies/",
        params: {
          page,
          limit,
        },
      })
    );
    return response.data as { pages: number; policies: PolicyResponse[] };
  } catch (e: any) {
    throw e;
  }
};
export const listPolicyJobsApi = async (id: string, page = 1, limit = 20) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "policies-jobs/" + id,
        params: { page, limit },
      })
    );
    return response.data as { pages: number; policyJobs: PolicyJob[] };
  } catch (e: any) {
    throw e;
  }
};

export const findPolicyApi = async (id: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "policies/" + id,
      })
    );
    return response.data as PolicyResponse;
  } catch (e: any) {
    throw e;
  }
};

export const createPolicyApi = async ({
  time,
  merchant_id,
  terminal_id,
}: CreatePlicy) => {
  let data: any = { time };
  if (terminal_id) data = { ...data, terminal_id };
  if (merchant_id) data = { ...data, merchant_id };
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "policies/",
        data,
      })
    );
    return response.data as PolicyResponse;
  } catch (e: any) {
    throw e;
  }
};
export const removePolicyApi = async (id: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "delete",
        url: "policies/" + id,
      })
    );
    return response.data as PolicyResponse;
  } catch (e: any) {
    throw e;
  }
};
export const updatePolicyApi = async (id: string, time: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "Patch",
        url: "policies/" + id,
        data: {
          time,
        },
      })
    );
    return response.data as PolicyResponse;
  } catch (e: any) {
    throw e;
  }
};
