import jsonexport from "jsonexport/dist";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@lib";
import { downloadTransactionApi } from "../../utils/api/transactions-apis";
import { ExportTransactionType } from "../../types/export-receipts";
import { useContext, useState } from "react";
import ReceiptsDownloadModal from "../modals/receipts-download";
import { flatObject } from "@lib/utils/flat-object";

const DownloadTransactionsButton = ({ queryParams }: any) => {
  const { t, i18n } = useTranslation();
  const [showDatemodal, setShowDateModel] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: Date.now() - 1000 * 60 * 60 * 24 * 30,
    endDate: Date.now(),
  });
  const [feedback, setFeedBack] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleModal = () => {
    setShowDateModel((prev) => !prev);
    setFeedBack("");
  };

  const download = async () => {
    try {
      setFeedBack("");
      setIsLoading(true);
      const response = await downloadTransactionApi({
        from: dateRange.startDate,
        to: dateRange.endDate,
        queryParams,
      }).then((transactions) => {
        if (!transactions.length) {
          setFeedBack("no transactions in this period");
          return;
        }
        transactions = transactions.map((t: any) => {
          t = {
            ...t,
            terminal_location: `${t.terminal.lat},${t.terminal.lon}`,
            acquirer: "TUMA",
            acq_country: "KENYA",
            country_code: t.terminal.currency_code,
            amount_authorized: (
              t.amount_authorized /
              Math.pow(10, t.transaction_currency_exponent)
            ).toFixed(t.transaction_currency_exponent),
            acquirer_institution_identification_code:
              t.host_related_data.acquiring_bin,
          };

          let exportType = ExportTransactionType["tuma"];
          return Object.keys(exportType).reduce((acc: any, curr: string) => {
            if (curr.includes(".")) {
              const arr = curr.split(".");
              acc[exportType[curr as keyof typeof exportType]] = t[arr[0]]
                ? flatObject(arr, t[arr[0]])
                : null;
            } else {
              acc[exportType[curr as keyof typeof exportType]] = t[curr];
            }
            return acc;
          }, {});
        });

        let output: string[] = [];
        jsonexport(
          transactions,
          {
            includeHeaders: true,
            rowDelimiter: ",",
            endOfLine: "\n",
          },
          function (err: Error, csv: string) {
            if (err) return console.log(err);
            output.push(csv);
          }
        );

        output[0] = "\ufeff" + " " + output[0];

        const outFile = new File(
          output,
          "Transactions-Receipts" + new Date().toDateString() + ".csv"
        );

        const link = document.createElement("a");
        const url = URL.createObjectURL(outFile);
        link.href = url;
        link.download = outFile.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toggleModal();
      });
      setIsLoading(false);
    } catch (downloadTransactionError: any) {
      setIsLoading(false);
      setFeedBack(() => {
        return i18n.language === "ar"
          ? downloadTransactionError?.error?.body?.error?.arabic
          : downloadTransactionError?.error?.body?.error?.english;
      });
      console.log({ downloadTransactionError });
    }
  };

  return (
    <>
      <Button
        onClick={() => toggleModal()}
        colorScheme={"black"}
        className={"px-2 py-1 w-40"}
        label={t("downloadTransactions") as string}
        // classNames={classNames}
      />
      {showDatemodal && (
        <ReceiptsDownloadModal
          feedback={feedback}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isModalOpen={showDatemodal}
          toggleModal={toggleModal}
          submit={download}
          isLoading={isLoading}
          title="Transactions"
        ></ReceiptsDownloadModal>
      )}
    </>
  );
};

export default DownloadTransactionsButton;
