import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listTerminalsApi } from "@lib/utils/api/terminals-apis";
import { createFilterObject } from "../filters";
import { TerminalsFilters } from "../filters/terminals-filters";
import { EmptyState, GroupedList, TerminalListItem } from "@lib";
import { useTranslation } from "react-i18next";
import { dateRange } from "@lib/types/date";

type Props = {
  refresh?: boolean;
  setRefresh?: (refresh: boolean) => any;
  merchant_id?: string;
  user_id?: string;
  onRowClick?: (payload: any) => void;
  activeTab?: string;
  limit?: number;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const ListTerminals: FC<Props> = ({
  refresh,
  setRefresh,
  merchant_id,
  user_id,
  onRowClick,
  activeTab = "all",
  limit = 30,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [terminals, setTerminals] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchTerminals = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTerminalsApi({
        page,
        merchant_id,
        user_id,
        limit,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setPages(response.pages);
      setTerminals(response.terminals);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToTerminal = (terminal: any) => navigate("/terminals/" + terminal.id);

  useEffect(() => {
    fetchTerminals();
  }, [refresh, activeTab]);

  return (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchTerminals(page),
      }}
      role={"client"}
      onItemClick={(terminal) => goToTerminal(terminal)}
      data={terminals}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      component={TerminalListItem}
      isLoading={isLoading}
    />
  );
};

export default ListTerminals;
