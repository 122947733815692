import React from "react";
import { FC } from "react";

type Props = { message?: string };

const NotFound404: FC<Props> = ({ message }) => {
  return (
    <section className="flex items-center h-full p-16 ">
      <div className="container flex flex-col items-center justify-center p-16 mx-auto my-8 bg-white rounded-md break-words">
        <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
          <span className="sr-only">Error</span>404
        </h2>
        <div className="text-xl font-semibold md:text-2xl   h-full ">
          {message ? message : "Sorry, we couldn't find this page."}
        </div>
      </div>
    </section>
  );
};

export default NotFound404;
