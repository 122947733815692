import { FC, ReactNode, useEffect } from "react";
import Routes from "@lib/utils/router";
import { HashRouter } from "react-router-dom";

import { UserContextProvider } from "../contexts/UserContext";

import { useTranslation } from "react-i18next";
import { ConfigContextProvider } from "./contexts/ConfigContext";
import { withLDProvider } from "launchdarkly-react-client-sdk";

const App: FC = ({}) => {
  const { i18n } = useTranslation();
  (window as any).Intercom?.("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: process.env.REACT_APP_INTERCOM_KEY as string,
  });
  document.body.dir = i18n.dir();

  useEffect(() => {
    window.onstorage = (event) => {
      if (event.key === "@clientId" && event.oldValue)
        document.location.reload();
    };
  }, []);

  return (
    <>
      <ConfigContextProvider>
        <HashRouter>
          <UserContextProvider>
            <Routes />
          </UserContextProvider>
        </HashRouter>
      </ConfigContextProvider>
    </>
  );
};

const WrappedApp = process.env.REACT_APP_LAUNCHDARKLY
  ? withLDProvider({
      clientSideID: process.env.REACT_APP_LAUNCHDARKLY,
      context: {
        key: "anonymous",
        anonymous: true,
      },
    })(App)
  : App;

export default WrappedApp;
