import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, Input, Modal, TerminalIcon } from "@lib";
import { usePromiseTracker } from "react-promise-tracker";

import React from "react";
import { editTerminalApi } from "@lib/utils/api/terminals-apis";

type Props = {
  isOpen: boolean;
  toggleModal: any;
  terminal: any;
  refresh: () => void;
};
const EditTerminalModal = ({
  isOpen,
  toggleModal,
  terminal,
  refresh,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>(terminal?.name ?? "");
  const { t } = useTranslation();

  // const { promiseInProgress } = usePromiseTracker();

  const submit = async () => {
    try {
      setIsLoading(true);
      await editTerminalApi({ terminal_id: terminal.id, name });
      close();
      refresh();
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const close = () => {
    setIsLoading(false);
    toggleModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t("Edit Name")}
      subTitle={t("terminal")}
      icon={TerminalIcon}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />

      <div className="flex flex-col gap-2">
        <Input
          type="text"
          label={t("name") as string}
          value={name}
          name="name"
          onChange={(e) => {
            setName(e);
          }}
          // errors={createAppErrors}
          // placeholder="e.g. example.package.io"
        />

        <Button
          colorScheme={"black"}
          isRounded={false}
          className={"px-2 py-1"}
          onClick={submit}
          label={t("save") as string}
        />
      </div>
    </Modal>
  );
};

export default EditTerminalModal;
