import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  Filter,
  MerchantIcon,
  SearchIcon,
  XIcon,
} from "../icons";
import { Badge, CardV2 } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";
import { Button } from "../forms";
import { ButtonType } from "../forms/button";
import { CardV3 } from "../shared/card";
import { listQueryParamsApi } from "@lib/utils/api/query-builder-apis";
import {
  MerchantResponse,
  findMerchantApi,
} from "@lib/utils/api/merchants-apis";

import { useFlags } from "launchdarkly-react-client-sdk";
import LoadingSpinner from "@lib/layout/loading-spinner";

export interface MerchantItem {
  id: string;
  name: string;
  name_ar?: string;
  reference_id?: string;
  status: "active" | "deactive";
  created_at: string;
}

export interface MerchantListItemType {
  role: "admin" | "client";
  title?: string;
  onClick?: (item?: any) => void;
  item: MerchantItem;
  version?: string;
  actionButtons?: any[];
}

export const MerchantsListItem: FC<MerchantListItemType> = ({
  role,
  title,
  onClick,
  item,
  version,
  actionButtons,
}) => {
  const { i18n, t } = useTranslation();
  let { moveTerminalsToNewMerchant } = useFlags();

  const merchantBadge: BadgeType =
    item?.status == "active"
      ? { label: t("active"), color: "sky" }
      : { label: t("notActive"), color: "red" };

  return version === "batch-upload" ? (
    <MerchantsListItemBatchUploadVersion item={item} role={"client"} />
  ) : (
    <NavLink to={"/merchants/" + item?.id} className="mt-3 w-full">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 p-2 w-full">
        <div className="flex flex-row items-center gap-2">
          <MerchantIcon containerClassNames="pr-4" />
          <div className="flex flex-col flex-1">
            <span className="font-bold text-sm lg:text-base">
              {(i18n.language == "ar"
                ? item?.name_ar ?? item?.name
                : item?.name) ?? ""}
            </span>
            <span className="text-gray-600 text-xs">
              {(i18n.language != "ar"
                ? item?.name_ar ?? item?.name
                : item?.name) ?? ""}
            </span>
          </div>
          {moveTerminalsToNewMerchant ? (
            <div className="text-sm lg:text-base ">
              {actionButtons?.length ? (
                <div>
                  {actionButtons.map((actionButton, index) => {
                    return <span key={index}>{actionButton}</span>;
                  })}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="flex-initial">
            <Badge {...merchantBadge} isRounded={true} />
          </div>
          <div className="flex-initial h-full items-center">
            {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
          </div>
        </div>
      </Card>
    </NavLink>
  );
};

export const MerchantsListItemBatchUploadVersion: FC<MerchantListItemType> = ({
  item,
}) => {
  const { t } = useTranslation();
  return (
    <div className=" w-full">
      <div className="flex w-full items-start flex-col flex-1">
        <span className="font-bold text-sm lg:text-base">
          {item?.name || "--"} -- {item?.name_ar}
        </span>
        <span className="text-gray-600 text-xs">#{item?.id}</span>
      </div>
    </div>
  );
};

export const MerchantsListItemChangeMerchantVersion: FC<{
  merchant: MerchantItem | any;
  selectedMerchantId: string;
  setSelectedMerchantId: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  merchant: { name, status, name_ar, id },
  selectedMerchantId,
  setSelectedMerchantId,
}: {
  merchant: MerchantItem;
  selectedMerchantId: string;
  setSelectedMerchantId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t, i18n } = useTranslation();
  const merchantBadge: BadgeType =
    status == "active"
      ? { label: t("active"), color: "sky" }
      : { label: t("notActive"), color: "red" };
  const [merchants, setMerchants] = useState<MerchantItem[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<
    MerchantResponse | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const fetchMerchants = async () => {
    try {
      setIsLoading(true);
      let merchants = await listQueryParamsApi("merchants", searchQuery);
      merchants = merchants.filter?.((merchant: any) => merchant?.value !== id);
      setMerchants(merchants);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handelSelectMerchant = async () => {
    let merchant = await findMerchantApi({
      id: selectedMerchantId as string,
    });
    setSelectedMerchant(merchant);
  };

  const removeSelectedMerchant = () => {
    setSelectedMerchantId?.("");
    setSearchQuery("");
    setSelectedMerchant(undefined);
  };

  useEffect(() => {
    if (selectedMerchantId !== "") handelSelectMerchant();
  }, [selectedMerchantId]);
  useEffect(() => {
    if (searchQuery !== "") fetchMerchants();
  }, [searchQuery]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <CardV3 classNames=" w-full flex flex-col  justify-between items-center ">
        <div className="flex items-center justify-between border-b-2 w-full lg:py-4 lg:px-4 p-2">
          <div className="flex items-center gap-2">
            <span className="text-xl font-bold capitalize w-14">
              {t("from")}
            </span>
            <div className="flex flex-col flex-1 ">
              <span className="font-bold text-sm lg:text-base">
                {(i18n.language == "ar" ? name_ar ?? name : name) ?? ""}
              </span>
              <span className="text-gray-600 text-xs">#{id}</span>
            </div>
          </div>
          <div className="flex-initial "></div>
          <div className="flex-initial h-full items-center flex">
            <Badge {...merchantBadge} isRounded={true} />
          </div>
        </div>
        <div className="relative flex items-center justify-between w-full lg:py-4 lg:px-4 p-2">
          <div className="absolute -top-5  rounded-full border-2 bg-black flex items-center justify-center w-9 h-9 z-30">
            <ChevronDown color="text-white" />
          </div>
          {selectedMerchant ? (
            <>
              <div className="flex items-center gap-2">
                <span className="text-xl font-bold capitalize w-14">
                  {t("to")}
                </span>
                <div className="flex flex-col flex-1 ">
                  <span className="font-bold text-sm lg:text-base">
                    {(i18n.language == "ar"
                      ? selectedMerchant.name_ar ?? selectedMerchant.name
                      : selectedMerchant.name) ?? ""}
                  </span>
                  <span className="text-gray-600 text-xs">
                    #{selectedMerchant.id}
                  </span>
                </div>
              </div>
              <div className="flex-initial "></div>
              <div
                className="flex-initial h-full items-center flex"
                onClick={removeSelectedMerchant}
              >
                <XIcon />
              </div>
            </>
          ) : (
            <div className=" w-full flex flex-col">
              <div className="relative w-full flex ">
                <span className="text-xl font-bold capitalize w-14">
                  {t("to")}
                </span>
                <SearchIcon />
                <input
                  className="flex-initial flex-grow capitalize   border-0 outline-none placeholder:text-gray-300 [direction:rtl]:text-right [direction:ltr]:text-left"
                  placeholder={t("selectNewMerchant")}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          )}
        </div>
      </CardV3>
      <div className="group ">
        {!selectedMerchant && searchQuery ? (
          merchants?.length ? (
            merchants.map((el: any, index: number) => {
              return (
                <div className="py-2 " key={index}>
                  <Card
                    classNames="lg:py-4 lg:px-4 p-2 w-full cursor-pointer"
                    onClick={() => setSelectedMerchantId(el.value)}
                  >
                    <div className="flex flex-row items-center gap-2 ">
                      <MerchantIcon containerClassNames="pr-4" />
                      <div className="flex flex-col flex-1">
                        <span className="font-bold text-sm lg:text-base">
                          {el?.display}
                        </span>
                        <span className="text-gray-600 text-xs">
                          #{el?.value}
                        </span>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })
          ) : (
            <span className="flex justify-center group-focus-within:block mt-2 text-xs text-gray-400">
              {t("noData")}
            </span>
          )
        ) : null}
      </div>
    </>
  );
};
