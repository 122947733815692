import React from "react";
import {
  FC,
  useState,
  createContext,
  Context,
  ReactNode,
  useEffect,
} from "react";
import SwitchMerchant from "@lib/components/modals/switch-merchant";
import SwitchClient from "@lib/components/modals/switch-client";
import Storage from "@lib/utils/storage";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { applyTheme } from "@lib/utils/themes";

interface Client {
  merchant_hub_link?: string;
  id: string;
  name: string;
  logo: string;
  vendor_id: string;
  platform: string;
  primary_color: string;
  secondary_color: string;
  menu_color: string;
  export_data_type: string;
}
interface User {
  id: string;
  email: string;
  mobile: string;
  name: string;
}
interface Merchant {
  id: string;
  name: string;
  name_ar: string;
}
interface Auth {
  user: User;
  merchant: Merchant;
  client: Client;
  role: "owner" | "accountant" | "cashier";
}

const initialUserState: User = {
  id: "",
  name: "",
  mobile: "",
  email: "",
};
const initialMerchantState: Merchant = {
  id: "",
  name: "",
  name_ar: "",
};
const initialClientState: Client = {
  id: "",
  name: "",
  logo: "",
  vendor_id: "",
  platform: "",
  primary_color: "black",
  secondary_color: "#e7e7e7",
  menu_color: "#F2F2F7",
  export_data_type: "nearpay",
};

const initialAuth: Auth = {
  user: initialUserState,
  merchant: initialMerchantState,
  client: initialClientState,
  role: "owner",
};

type Props = {
  children: ReactNode;
};

export type UserContextType = {
  isLoggedIn: () => boolean;
  addToken: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
  toggleSwitchAccount: () => void;
  auth: Auth;
};
const UserContext: Context<UserContextType | null> =
  createContext<UserContextType | null>(null);

export const UserContextProvider: FC<Props> = ({ children }: Props) => {
  const [refreshAccess, setRefreshAccess] = useState(false);
  const [isSwitchAccountOpen, setIsSwitchAccountOpen] = useState(false);
  const ldClient = useLDClient();
  const [auth, setAuth] = useState<Auth>(initialAuth);

  const toggleSwitchAccount = () =>
    setIsSwitchAccountOpen((prevState: boolean) => !prevState);

  const getTokens = (): { accessToken: string; refreshToken: string } => {
    return {
      accessToken:
        Storage.get("access_token") || Storage.get("accessToken") || "",
      refreshToken:
        Storage.get("refresh_token") || Storage.get("refreshToken") || "",
    };
  };

  const decodeJWT = (jwt: string): Auth => {
    let token = jwt.split(".")[1];
    if (!token) throw "Token not found";
    let base64 = token.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload)?.data as Auth;
  };

  const startIntercom = (admin: any) => {
    (window as any).Intercom?.("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_KEY as string,
      name: admin.name,
      email: admin.email,
      created_at: new Date(admin.created_at).getTime(),
      user_hash: admin.hashedId,
      user_id: admin.id as string,
    });
  };
  const addToken = (accessToken: string, refreshToken: string) => {
    let token = decodeJWT(accessToken);

    Storage.set("accessToken", accessToken);
    Storage.set("refreshToken", refreshToken);
    Storage.set("auth", decodeJWT(accessToken));
    // selectAccount();
    startIntercom(token.user);
    setActiveAccount(token.client?.id, token.merchant?.id);
  };
  const setActiveAccount = (clientId: string, merchantId: string) => {
    Storage.set("clientId", clientId);
    Storage.set("merchantId", merchantId);
    setRefreshAccess((prevState: boolean) => !prevState);
    window.location.replace("/");
    // toggleSwitchAccount();
    // window.location.href = `${window.location.origin}/`;
    // window.location.reload();
  };

  const isLoggedIn = () => {
    return getTokens().accessToken.length ? true : false;
  };

  const getAuth = () => {
    let auth = Storage.get("auth");
    if (auth) {
      setAuth(auth);
      if (!auth?.role) setAuth((prev) => ({ ...prev, role: "owner" }));
    }
    return auth;
  };

  const selectAccount = (accountSwitched: boolean = false) => {
    // const { merchant, client } = getAuth();
    // console.log("hi");
    // console.log({ merchant, client });
    // // Storage.set("current_merchant", merchant.id);
    // // Storage.set("current_merchant_client", client.id);
    // // Storage.set("client_logo", client.logo);
    // // Storage.set("client_name", client.name);
    // if (accountSwitched) {
    //   toggleSwitchAccount();
    //   window.location.reload();
    // }
    // setRefreshAccess((prevState: boolean) => !prevState);
  };

  const logout = () => {
    localStorage.clear();
    (window as any).Intercom?.("shutdown");
    (window as any).Intercom?.("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_KEY as string,
    });
    setRefreshAccess((prevState) => !prevState);
  };

  useEffect(() => {
    const data: Auth = getAuth();
    // console.log({ menu_color, primary_color });
    if (data)
      applyTheme({
        "--theme-primary":
          // data.client.primary_color ??
          initialClientState.primary_color,
        "--theme-secondary":
          // data.client.secondary_color ??
          initialClientState.secondary_color,
        "--theme-menu":
          // data.client.menu_color ??
          initialClientState.menu_color,
      });
  }, [refreshAccess]);

  useEffect(() => {
    ldClient?.waitUntilReady().then(() => {
      ldClient?.identify({
        kind: "user",
        key: auth.user?.email,
        name: auth.user?.name,
        mobile: auth.user?.mobile,
      });
    });
  }, [ldClient]);
  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        addToken,
        auth,
        logout,
        toggleSwitchAccount,
      }}
    >
      {children}
      {auth?.user?.id &&
        (Storage.get("access_token") || Storage.get("accessToken")) &&
        (auth?.merchant?.id ? (
          <SwitchMerchant
            adminId={auth.user.id}
            addToken={addToken}
            isOpen={isSwitchAccountOpen}
            toggleModal={toggleSwitchAccount}
            selectMerchant={selectAccount}
          />
        ) : (
          <SwitchClient
            admin_id={auth.user.id}
            curr_client_id={auth.client.id}
            addToken={addToken}
            isOpen={isSwitchAccountOpen}
            toggleModal={toggleSwitchAccount}
            selectClient={selectAccount}
          />
        ))}
    </UserContext.Provider>
  );
};

export default UserContext;
