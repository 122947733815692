import React, { FC } from "react";
import { IconType } from "./types";

export const Checked: FC<IconType> = ({
  containerClassNames = "",
  size = 24,
  color = "text-black",
}) => {
  return (
    <div className={containerClassNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        width={size}
        height={size}
        strokeWidth="1.5"
        stroke="currentColor"
        className={"text-black " + color}
      >
        <path
          fillRule="evenodd"
          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};
