import { FC, useContext, useEffect, useState } from "react";
import {
  listAdminsApi,
  revokeAdminsAccessApi,
} from "@lib/utils/api/admins-apis";

import { AdminListItem, EmptyState, GroupedList } from "@lib";
import { useTranslation } from "react-i18next";

type Props = {
  refresh: boolean;
  merchant_id?: number;
  limit?: number;
  queryParams?: {
    [key: string]: any;
  };
};

const ListAdmins: FC<Props> = ({
  refresh,
  merchant_id,
  limit = 30,
  queryParams = {},
}: Props) => {
  const [admins, setAdmins] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [refreshAdmins, setRefreshAdmins] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const fetchAdmins = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listAdminsApi({
        page,
        limit,
        merchant_id,
        queryParams: queryParams as {
          [key: string]: any;
        },
      });
      setPages(response.pages);
      setAdmins(response.admins);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const revokeAdminsAccess = async (admin: any) => {
    try {
      await revokeAdminsAccessApi(admin.id);
      setRefreshAdmins((prev) => !prev);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAdmins();
  }, [refresh, refreshAdmins]);

  return (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchAdmins(page),
      }}
      role={"client"}
      data={admins}
      component={AdminListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      actionButtonOnClick={(e, admin) => revokeAdminsAccess(admin)}
      isLoading={isLoading}
    />
  );
};

export default ListAdmins;
