import { ar, enUS } from "date-fns/locale";

import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  DateRangePicker,
  Metric,
  Text,
  Flex,
  DateRangePickerValue,
  BarChart,
  DateRangePickerItem,
} from "@tremor/react";
import {
  getSalesChartData,
  getTerminalRegisterationsApi,
} from "@lib/utils/api/reports-apis";
import {
  calculateMonthlyTotals,
  calculateTotal,
  toArabianStandardTimeMilliseconds,
  transformData,
} from "@lib/utils/chart-utils";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { ChartItem } from "@lib/types/reports";

const MyDatePicker = ({ setValue }: any) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const today = new Date();

  return (
    <DateRangePicker
      locale={currentLocale === "ar" ? ar : enUS}
      className="max-w-md"
      onValueChange={setValue}
      placeholder={currentLocale === "ar" ? "اختيار التاريخ" : "Select range"}
      selectPlaceholder={
        currentLocale === "ar" ? "اختيار الفترة" : "Select range"
      }
      defaultValue={{ selectValue: "last7Days" }}
    >
      <DateRangePickerItem
        key="last7Days"
        value="last7Days"
        from={
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
        }
        to={today}
      >
        {currentLocale === "ar" ? "آخر 7 أيام" : "Last 7 Days"}
      </DateRangePickerItem>
      <DateRangePickerItem
        key="monthToDate"
        value="monthToDate"
        from={new Date(today.getFullYear(), today.getMonth(), 1)}
        to={today}
      >
        {currentLocale === "ar" ? "منذ بداية الشهر" : "Month to Date"}
      </DateRangePickerItem>
      <DateRangePickerItem
        key="last30Days"
        value="last30Days"
        from={
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29)
        }
        to={today}
      >
        {currentLocale === "ar" ? "آخر 30 يومًا" : "Last 30 Days"}
      </DateRangePickerItem>
      <DateRangePickerItem
        key="y"
        value="y"
        from={new Date(today.getFullYear(), 0, 1)}
        to={today}
      >
        {currentLocale === "ar" ? "منذ بداية السنة" : "Year to Date"}
      </DateRangePickerItem>
    </DateRangePicker>
  );
};
const MyTab = ({ title, total }: { title: string; total: number }) => {
  return (
    <Tab className="border-b-2">
      <Flex className="max-w-xs px-2 py-6 min-h-20 flex flex-col justify-center">
        <Text>{title}</Text>
        <Metric className="text-[24px] text-center">
          {total.toLocaleString()}
        </Metric>
      </Flex>
    </Tab>
  );
};

const MyBarchart = ({
  rangedData,
  categories,
  isYearly,
}: {
  rangedData: any[];
  categories: string[];
  isYearly?: boolean;
}) => {
  const [labledData, setLabledData] = useState<any[]>([]);

  useEffect(() => {
    const transformedData = transformData(rangedData, categories[0]);
    if (isYearly) {
      setLabledData(calculateMonthlyTotals(transformedData, categories[0]));
    } else {
      setLabledData(transformedData);
    }
  }, [rangedData]);
  return (
    <BarChart
      className="mt-14  h-96 w-full px-6"
      data={labledData}
      index="name"
      categories={categories}
      colors={["slate"]}
      valueFormatter={(number) =>
        `${new Intl.NumberFormat("us").format(number)}`
      }
      yAxisWidth={48}
    />
  );
};
export const GenericBarChart = ({ type }: { type: string }) => {
  const [range, setRange] = useState<DateRangePickerValue>();
  const [rangedData, setRangedData] = useState<ChartItem[]>([]);

  const fetchAndSetData = async (
    rangeValue: DateRangePickerValue | undefined
  ) => {
    let from, to;

    if (rangeValue) {
      from = toArabianStandardTimeMilliseconds(rangeValue.from);
      to = toArabianStandardTimeMilliseconds(rangeValue.to);
    } else {
      const today = new Date();
      const weekAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 6
      );
      from = toArabianStandardTimeMilliseconds(weekAgo);
      to = toArabianStandardTimeMilliseconds(today);
    }
    let data;
    if (type === "terminals") {
      data = await getTerminalRegisterationsApi({ from, to });
    } else {
      data = await getSalesChartData({ from, to });
    }
    setRangedData(data);
  };
  useEffect(() => {
    fetchAndSetData(range);
  }, [range]);

  const countTotal = useMemo(() => {
    return calculateTotal(rangedData, "count");
  }, [rangedData]);
  const amountTotal = useMemo(() => {
    return calculateTotal(rangedData, "amount");
  }, [rangedData]);
  return (
    <Card className="pt-3">
      <TabGroup>
        <TabList className="relative">
          {type === "terminals" ? (
            <MyTab title={t("terminalRegisterations")} total={countTotal} />
          ) : (
            <>
              <MyTab
                title={t("transactionValue")}
                total={Math.round(amountTotal / 100)}
              />

              <MyTab
                title={t("transactionCount")}
                total={Math.round(countTotal)}
              />

              <MyTab
                title={t("transactionAverage")}
                total={Math.round(
                  amountTotal ? amountTotal / 100 / countTotal : 0
                )}
              />
            </>
          )}
          <div className="w-full absolute flex justify-end top-32 ltr:right-0 rtl:left-0  z-10">
            <MyDatePicker setValue={setRange} />
          </div>
        </TabList>

        {type === "terminals" ? (
          <TabPanels>
            <TabPanel>
              <MyBarchart
                rangedData={rangedData}
                categories={["Terminal Registrations"]}
                isYearly={range?.selectValue === "y"}
              />
            </TabPanel>
          </TabPanels>
        ) : (
          <TabPanels>
            <TabPanel>
              <MyBarchart
                rangedData={rangedData}
                categories={["Transaction Value"]}
                isYearly={range?.selectValue === "y"}
              />
            </TabPanel>
            <TabPanel>
              <MyBarchart
                rangedData={rangedData}
                categories={["Transaction Count"]}
                isYearly={range?.selectValue === "y"}
              />
            </TabPanel>
            <TabPanel>
              <MyBarchart
                rangedData={rangedData}
                categories={["Transaction Average"]}
                isYearly={range?.selectValue === "y"}
              />
            </TabPanel>
          </TabPanels>
        )}
      </TabGroup>
    </Card>
  );
};
