import React, { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { Button, GroupedList, ReleaseListItem } from "@lib";
import {
  assignSDKApi,
  changeCurrentReleaseApi,
  findAppApi,
  listSDKsApi,
  unassignSDKApi,
} from "@lib/utils/api/apps-api";
import ManageAppModal from "../modals/manage-app";
import { MultiSelect } from "@lib/forms/multi-select";

type Props = {
  id: string;
};

export interface App {
  id?: string;
  name: string;
  app_name: string;
  supported_sdk_versions?: SDKVersion[];
  merchant?: {
    id: string;
    name: string;
    name_ar: string;
  };
}

export const initialAppState: App = {
  name: "",
  app_name: "",
  supported_sdk_versions: [],
};

export type Release = {
  version: string;
  id: string;
  is_force_update?: string;
  file_name: string;
  status: string;
  url: string;
};

export type SDKVersion = {
  id: string;
  releases: Release[];
  version: string;
  current_release_id?: string;
};

const ViewApp: FC<Props> = ({ id }) => {
  const [app, setApp] = useState(initialAppState);
  const [releases, setReleases] = useState<any>([]);
  const [refreshApp, setRefreshApp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [SDKs, setSDKs] = useState<any>([]);
  const [isEditAppModalOpen, setIsEditAppModalOpen] = useState(false);
  const { t } = useTranslation();

  const toggleLoading = (val?: boolean) => setIsLoading(() => val === true);

  const fetchSdkVersions = async () => {
    try {
      await listSDKsApi().then((sdkVersions) => {
        setSDKs(sdkVersions);
      });
    } catch (e) {
      console.log({ e });
    }
  };

  const mutateReleases = (data: any) => {
    return data?.supported_sdk_versions
      .map((i: any) =>
        i?.releases?.map((r: any) => ({
          ...r,
          sdk_version_id: i.id,
          current_release_id: i.current_release_id,
          target_sdk: i.version,
        }))
      )
      .flat();
  };

  const findApp = async () => {
    try {
      toggleLoading();
      const app = await findAppApi({ id: id as string });
      setApp(app);
      setReleases(mutateReleases(app));
      toggleLoading();
    } catch (e) {
      console.log(e);
      toggleLoading();
    }
  };

  const setCurrentRelease = async (
    _: Event,
    { releaseId, sdkVersionId }: any
  ) => {
    try {
      const response = await changeCurrentReleaseApi({
        release_id: releaseId,
        sdk_version_id: sdkVersionId,
        package_id: app.id as string,
      });
      setApp(response);
      setReleases(mutateReleases(response));
    } catch (e) {
      console.log({ e });
    }
  };

  const addSDK = async (sdk: string) => {
    toggleLoading(true);
    try {
      const response = await assignSDKApi({
        package_id: app.id as string,
        sdk_version_id: sdk,
      });
      setApp(response);
      setReleases(mutateReleases(response));
    } catch (e) {
      console.log({ e });
    } finally {
      toggleLoading(false);
    }
  };

  const removeSDK = async (sdk: string) => {
    toggleLoading(true);
    try {
      const response = await unassignSDKApi({
        package_id: app.id as string,
        sdk_version_id: sdk,
      });
      setApp(response);
      setReleases(mutateReleases(response));
    } catch (e) {
      console.log({ e });
    } finally {
      toggleLoading(false);
    }
  };

  const updateSDKs = (actionMeta: any) => {
    if (actionMeta.action === "remove-value") {
      removeSDK(actionMeta.removedValue.value);
    } else {
      addSDK(actionMeta.option.value);
    }
  };

  const toggleManageAppModal = () => {
    setIsEditAppModalOpen((prev) => !prev);
  };

  useEffect(() => {
    findApp();
    fetchSdkVersions();
  }, [refreshApp]);

  return (
    <div className="relative">
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col relative gap-2 ">
        <div className="lg:text-3xl text-xl font-semibold ">{t("info")}</div>
        <div className="flex justify-between my-4 ">
          {/* left  */}
          <div className="flex items-end gap-x-2">
            <div className="flex items-center justify-center p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="30"
                height="30"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM8.823 15.343l-.79 1.37a.823.823 0 1 1-1.428-.822l.589-1.016c.66-.206 1.201-.048 1.629.468zM13.21 8.66l2.423 4.194h2.141a.82.82 0 0 1 .823.822.82.82 0 0 1-.823.823h-1.19l.803 1.391a.824.824 0 0 1-1.427.823l-3.04-5.266c-.69-1.19-.198-2.383.29-2.787zm.278-3.044c.395.226.528.73.302 1.125l-3.528 6.109h2.553c.826 0 1.29.972.931 1.645h-7.48a.82.82 0 0 1-.822-.823.82.82 0 0 1 .822-.822h2.097l2.685-4.653-.838-1.456a.824.824 0 0 1 1.427-.823l.359.633.367-.633a.823.823 0 0 1 1.125-.302z" />
              </svg>
            </div>
            <div className="flex flex-col justify-center w-36 sm:w-full max-w-xs sm:max-w-sm lg:max-w-lg ">
              <div className="text-sm truncate">{app?.app_name}</div>
              <div className="font-bold lg:text-lg text-base truncate">
                {app?.name}
              </div>
            </div>
            <div className="flex flex-col justify-center w-36 sm:w-full max-w-xs sm:max-w-sm lg:max-w-lg mb-1 ">
              {/* <div className="text-sm truncate">{t("merchant") as string}</div> */}
              <div className=" truncate bg-sky-100 text-sky-700 text-sm rounded-full px-2">
                {app.merchant?.name}
              </div>
            </div>
            {/* <div className="flex flex-col justify-start max-w-xs  3xl:max-w-md">
              <div className="text-sm truncate">{t("merchant") as string}</div>
              <div className="font-semibold truncate bg-sky-100 text-sky-700 text-sm rounded-full px-2"></div>
            </div> */}
          </div>
          <div className="flex flex-col lg:flex-row items-center gap-4 justify-around lg:flex-initial lg:mt-0 ">
            <Button
              label={t("edit") as string}
              onClick={() => setIsEditAppModalOpen(true)}
              className={"px-2 py-1"}
              colorScheme={"black"}
            />
          </div>
        </div>

        <MultiSelect
          data={SDKs}
          displayKey="version"
          valueKey="id"
          onChange={(_, actionMeta) => updateSDKs(actionMeta)}
          label="supported SDKs"
          values={app.supported_sdk_versions}
        />

        {(app?.supported_sdk_versions?.length as number) > 0 ? (
          <GroupedList
            role={"client"}
            data={releases}
            actionButtonOnClick={setCurrentRelease}
            groupBy={{
              key: "target_sdk",
              isDate: false,
            }}
            isLoading={isLoading}
          >
            <ReleaseListItem
              role="client"
              onClick={() => {}}
              releases={releases}
              actionButtonOnClick={setCurrentRelease}
            />
          </GroupedList>
        ) : undefined}
        <ManageAppModal
          isOpen={isEditAppModalOpen}
          app={app as any}
          setApp={setApp}
          setRefresh={setRefreshApp}
          toggleModal={toggleManageAppModal}
          sdks={SDKs}
        />
      </div>
    </div>
  );
};

export default ViewApp;
