import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Content from "@lib/layout/content";
import { EmptyState, GroupedList, ReconciliationListItem } from "@lib";
import { listReconciliationsApi } from "@lib/utils/api/reconciliations-apis";
import DownloadReconciliationsButton from "@lib/components/shared/download-reconciliations-btn";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import { dateRange } from "@lib/types/date";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import ReconciliationsTable from "@lib/tables/reconciliations-table";

type Props = {};

const Reconciliations: FC<Props> = ({}) => {
  const [refreshList, setRefreshList] = useState(false);
  const { t, i18n } = useTranslation();
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@reconciliationDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@reconciliationQueryParams"
  );

  return (
    <Content
      title={t("reconciliations")}
      isLoadingContainerTransparent={true}
      actionBtns={<DownloadReconciliationsButton queryParams={queryParams} />}
      queryBuilder={
        <QueryBuilder
          type={"reconciliations"}
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          setDateRange={
            setDateRange as React.Dispatch<React.SetStateAction<dateRange>>
          }
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      }
    >
      <ReconciliationsTable
        refresh={refreshList}
        setRefresh={setRefreshList}
        queryParams={queryParams}
      />
    </Content>
  );
};

export default Reconciliations;
