import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { policyType } from "@lib/types/policy";
import UserContext, { UserContextType } from "src/contexts/UserContext";

type Props = {
  onClick?: (value: string) => void;
  type?: string;
  disabled?: boolean;
};

export const PolicyType: FC<Props> = ({ onClick, type, disabled }) => {
  const { auth } = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();
  return (
    <div className="flex gap-x-2 items-center justify-evenly rounded-md border border-gray-300 p-1 text-center  text-xs font-bold">
      {Object.keys(policyType).map((key) => {
        if (auth.merchant?.id && key == "merchant") return;
        return (
          <button
            key={key}
            onClick={() => {
              onClick?.(key);
            }}
            className={`${
              key === type && "bg-black text-white"
            } disabled:opacity-50 w-1/3 py-2.5 rounded-md capitalize `}
            disabled={key == "terminal" || disabled}
          >
            {t(policyType[key as keyof typeof policyType])}
          </button>
        );
      })}
    </div>
  );
};
