import React, { FC, useEffect, useState } from "react";
import {
  getShortenedTerminalsApi,
  submitPullRequestApi,
} from "@lib/utils/api/terminals-apis";
import { Button, EmptyState, GroupedList, Input } from "@lib";
import { useTranslation } from "react-i18next";
import { IError, NiceError } from "@lib/nice-errors";
import { ShortenedTerminalListItem } from "@lib/list-item/terminal-list";

export type Props = {
  merchant_id?: string;
  close: () => void;
};

const PullTerminalsList: FC<Props> = ({ merchant_id, close }: Props) => {
  let debounceFn: number | any;

  const [terminals, setTerminals] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pullOutError, setPullOutError] = useState<IError | null>(null);
  const [reason, setReason] = useState("");
  const { t } = useTranslation();

  const fetchTerminals = async ({
    page = 1,
    search,
  }: {
    page?: number;
    search?: string;
  }) => {
    try {
      setIsLoading(true);
      const response = await getShortenedTerminalsApi({
        page,
        limit: 5,
        search,
      });

      setPages(response.pages);
      setTerminals(response.terminals);
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const handleReasonChange = (e: any) => {
    if (e.trim().length != 0) {
      setReason(e);
    } else {
      setReason("");
    }
  };

  const savePullTerminals = async () => {
    try {
      setIsLoading(true);
      await submitPullRequestApi({
        terminalIds,
        reason,
        merchant_id,
      });
      close();
    } catch (e: any) {
      console.log({ e });
      setPullOutError(e);
      setIsLoading(false);
    }
  };

  const [terminalIds, setTerminalIds] = useState<string[]>([]);

  useEffect(() => {
    fetchTerminals({});
  }, []);

  return (
    <>
      <p className="font-bold mb-3">{t("searchByTid") as string}</p>
      <input
        type="search"
        onChange={(e) => {
          clearTimeout(debounceFn);
          let dummySearch = "";
          if (e.target.value.trim().length != 0) {
            dummySearch = e.target.value.trim();
          }
          debounceFn = setTimeout(() => {
            setIsLoading(true);
            fetchTerminals({ search: dummySearch });
            setIsLoading(false);
          }, 1000);
        }}
        className="peer w-full h-10 flex items-center placeholder:flex placeholder:items-center placeholder:text-gray-400 placeholder:text-xs rounded-lg py-1 mb-1 border-gray-300 invalid:border-red-500 invalid:ring-red-500"
      />
      {terminals.length ? (
        <>
          <GroupedList
            pages={{
              total: pages,
              selectPage: (page: number) => fetchTerminals({ page: page }),
            }}
            role={"client"}
            data={terminals}
            groupBy={{
              key: "created_at",
              isDate: true,
            }}
            component={(props) => (
              <ShortenedTerminalListItem
                {...props}
                setTerminalIds={setTerminalIds}
                terminalIds={terminalIds}
              />
            )}
            isLoading={isLoading}
          />
          <Input
            type="text"
            name="reasonForPullingTerminals"
            onChange={(e) => handleReasonChange(e)}
            value={reason}
            label={t("reasonForPullingTerminals") as string}
          />
          {pullOutError && <NiceError error={pullOutError.error} />}
          <Button
            className={"px-2 py-1 mt-4"}
            label={t("save") as string}
            onClick={savePullTerminals}
            colorScheme="black"
            isRounded={true}
            isDisabled={reason.length === 0 || terminalIds.length === 0}
          />
        </>
      ) : (
        <>
          <EmptyState title={t("itemNotFound")} />
        </>
      )}
    </>
  );
};

export default PullTerminalsList;
