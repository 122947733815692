import Api, { List } from ".";
import { dateRange } from "@lib/types/date";
import { ReconciliationResponse } from "@lib/types/reconciliations";
import { trackPromise } from "react-promise-tracker";
interface Reconciliations extends List {
  merchant_id?: string;
  user_id?: string;
  terminal_id?: string;
  queryParams?: { [key: string]: any };
  dateRange?: dateRange;
}

interface Reconcile {
  id: string;
}

interface NewReconcile {
  terminal_ids: string[];
}

interface ReconciliationDownload {
  dateRange?: dateRange;
  queryParams?: { [key: string]: any };
}
export const findReconiliationApi = async ({ id }: Reconcile) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reconciliations/" + id,
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listReconciliationsApi = async ({
  limit = 30,
  page = 1,
  merchant_id,
  user_id,
  terminal_id,
  queryParams = {},
  dateRange = {},
}: Reconciliations) => {
  // let encodedQueryParams = btoa(JSON.stringify(queryParams));
  //to encode also arabic charachter
  let encodedQueryParams = btoa(
    unescape(encodeURIComponent(JSON.stringify(queryParams)))
  );
  let encodeddateRange = btoa(JSON.stringify(dateRange));
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reconciliations/",
        params: {
          limit,
          page,
          merchant_id,
          user_id,
          terminal_id,
          queryParams: encodedQueryParams,
          dateRange: encodeddateRange,
        },
      })
    );
    return response.data as {
      pages: any;
      receipts: ReconciliationResponse;
    };
  } catch (e: any) {
    throw e;
  }
};

export const reconcileApi = async ({
  terminal_id,
  merchant_id,
}: {
  terminal_id: string;
  merchant_id?: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "reconcile",
        data: {
          terminal_id,
          merchant_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const downloadReconciliationsApi = async ({
  queryParams = {},
  dateRange,
}: ReconciliationDownload) => {
  try {
    let encodedQueryParams = btoa(
      unescape(encodeURIComponent(JSON.stringify(queryParams)))
    );
    let encodeddateRange = btoa(JSON.stringify(dateRange));
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reconciliations/export",
        params: {
          queryParams: encodedQueryParams,
          dateRange: encodeddateRange,
        },
      })
    );
    return response.data as ReconciliationResponse[];
  } catch (e: any) {
    throw e;
  }
};
