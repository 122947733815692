export const ExportTransactionType = {
  nearpay: {
    id: "Id",
    client_id: "Client Id",
    "merchant.name.arabic": "Arabic Merchant Name ",
    "merchant.name.english": "English Merchant Name",
    terminal_id: "terminal_id",
    system_trace_audit_number: "STAN",
    retrieval_reference_number: "RRN",
    merchant_id: "merchant_id",
    pan: "PAN",
    "card_scheme.name.english": "Schema Name",
    card_expiration: "card_expiration",
    created_at: "created_at",
    pos_software_version_number: "pos_software_version_number",
    "approval_code.value": "Approval Code",
    action_code: "RespCode",
    "status_message.english": "Status",
    reconciliation_id: "Reconciliation Id",
    "amount_authorized.value": "amount_authorized",
    application_cryptogram: "application_cryptogram",
    application_identifier: "application_identifier",
    customer_reference_number: "Customer Reference Number",
    card_scheme_sponsor: "card_scheme_sponsor",
    cardholader_verfication_result: "cardholader_verfication_result",
    "currency.english": "currency",
    entry_mode: "entry_mode",
    is_refunded: "is_refunded",
    is_approved: "is_approved",
    is_reversed: "is_reversed",
    kernel_id: "kernel_id",
    qr_code: "qr_code",
    tid: "tid",
    "verification_method.english": "verification_method",
    type: "type",
  },
  geidea: {
    customer_reference_number: "Ref No",
    start_time: "Req Time",
    tid: "Terminal ID",
    system_trace_audit_number: "STAN",
    retrieval_reference_number: "RRN",
    merchant_id: "Merchant Id",
    "merchant.name.arabic": "Arabic Merchant Name ",
    "merchant.name.english": "English Merchant Name",
    pan: "Card No",
    "card_scheme.name.english": "Schema Name",
    card_expiration: "Expiry Date",
    created_at: "Resp Date",
    pos_software_version_number: "Version",
    "approval_code.value": "Auth Code",
    action_code: "RespCode",
    "status_message.english": "Status",
    reconciliation_id: "ReconId",
  },
  tuma: {
    id: "TRANSACTION ID",
    CPD: "CPD",
    acquirer: "ACQUIRER NAME",
    acquirer_institution_identification_code: "Acquirer BIN",
    "terminal.host_related_data.merchant_name.english": "RETAILER NAME",
    merchant_bank: "MERCHANT BANK ACCOUNT",
    "terminal.card_acceptor_terminal_id": "TERMINALID",
    terminal_location: "TERMINAL LOCATION",
    merchant_id: "RETAILER ID",
    transaction_type: "TRANSACTION TYPE",
    amount_authorized: "TRANAMOUNT ",
    "TOTAL_MSC_RATE ": "TOTAL MSC RATE",
    "LCY_AMOUNT_DUE_MERCHANT ": "LCY AMOUNT DUE MERCHANT",
    "terminal.currency_code": "TRANSACTION CURRENCY CODE",
    ISSUERBANK: "ISSUERBANK",
    pan: "MASKEDPAN",
    created_at: "TRANSACTION DATETIME",
    country_code: "ISSCOUNTRY CODE",
    ACQUIRERREFERENCENUMBER: "ACQUIRERREFERENCENUMBER",
    action_code: "APPROVAL CODE",
    DOCNO: "DOCNO",
    ISSFIID: "ISSFIID",
    SECTOR_DESCRIPTION: "SECTOR DESCRIPTION",
    MEB_BATCH_NUMBER: "MEB BATCH NUMBER",
    "terminal.host_related_data.merchant_category_code": "MCC CODE",
    retrieval_reference_number: "ISSUER RRN",
    system_trace_audit_number: "TRANNUMBER",
  },
};

export const ExportReconcilationType = {
  nearpay: {
    id: { key: "ID", defaultValue: "" },
    client_id: { key: "Client ID", defaultValue: "" },
    merchant_id: { key: "Merchant ID", defaultValue: "" },
    "merchant.name.english": { key: "Merchant Name", defaultValue: "" },
    terminal_id: { key: "Terminal ID", defaultValue: "" },
    user_id: { key: "User ID", defaultValue: "" },
    "currency.english": { key: "Currency", defaultValue: "" },
    device_id: { key: "Device ID", defaultValue: "" },
    created_at: { key: "Created Date", defaultValue: "" },
    action_code: { key: "Action Code", defaultValue: "" },
    system_trace_audit_number: { key: "STAN", defaultValue: "" },
    card_acceptor_terminal_id: { key: "TID", defaultValue: "" },
    time: { key: "Time", defaultValue: "" },
    "is_balanced.value": { key: "Is Balanced", defaultValue: "" },
    pos_software_version_number: {
      key: "Pos Software Version Number",
      defaultValue: "",
    },
    "schemes.name.label.english": { key: "Schema Name", defaultValue: "" },
    "schemes.pos.debit.count": { key: "POS Debit", defaultValue: "" },
    "schemes.pos.debit.total": { key: "POS TOT.Debit", defaultValue: "" },
    "schemes.pos.credit.count": { key: "POS Credit", defaultValue: "" },
    "schemes.host.debit.count": { key: "HOST Debit", defaultValue: "" },
    "schemes.host.debit.total": { key: "HOST TOT.Debit", defaultValue: "" },
    "schemes.host.credit.count": { key: "HOST Credit", defaultValue: "" },
    status: { key: "Status", defaultValue: "" },
  },
  geidea: {
    card_acceptor_terminal_id: { key: "Terminal ID", defaultValue: "" },
    created_at: { key: "Date", defaultValue: "" },
    "merchant.name.english": { key: "Merchant Name", defaultValue: "" },
    action_code: { key: "RespCode", defaultValue: "" },
    "schemes.name.label.english": { key: "Schema Name", defaultValue: "" },
    "schemes.pos.debit.count": { key: "POS Debit", defaultValue: "" },
    "schemes.pos.debit.total": { key: "POS TOT.Debit", defaultValue: "" },
    "schemes.pos.credit.count": { key: "POS Credit", defaultValue: "" },
    post_auth: { key: "Pos Auth", defaultValue: "0" },
    "schemes.host.debit.count": { key: "HOST Debit", defaultValue: "" },
    "schemes.host.debit.total": { key: "HOST TOT.Debit", defaultValue: "" },
    "schemes.host.credit.count": { key: "HOST Credit", defaultValue: "" },
    host_auth: { key: "Host Auth", defaultValue: "0" },
    status: { key: "Status", defaultValue: "" },
    system_trace_audit_number: { key: "ReconId", defaultValue: "" },
  },
};
