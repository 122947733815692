import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import Content from "@lib/layout/content";
import {
  EmptyState,
  GroupedList,
  ReconciliationListItem,
  TerminalListItem,
  TransactionListItem,
} from "@lib";
import { DeviceListItem } from "@lib/list-item/device-list";
import { findEmployeeApi } from "@lib/utils/api/employees-apis";
import { listReconciliationsApi } from "@lib/utils/api/reconciliations-apis";
import {
  listTransactionsApi,
  TransactionsResponse,
} from "@lib/utils/api/transactions-apis";
import { listTerminalsApi } from "@lib/utils/api/terminals-apis";
import ListTransactions from "@lib/components/transactions/list";
import ListReconciliations from "@lib/components/reconciliations/list";
import ListTerminals from "@lib/components/terminals/list";

type Props = {};

const initialDeviceState = {
  brand: "",
  model: "",
  is_active: false,
  id: "",
  created_at: "",
};

const Employee: FC<Props> = ({}) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState<any>({});
  const [transactions, setTransactions] = useState<TransactionsResponse[]>([]);
  const [transactionsTotal, setTransactionsTotal] = useState(0);
  const [reconciliationsTotal, setReconciliationsTotal] = useState(0);
  const [terminalsTotal, setTerminalsTotal] = useState(0);
  const [device, setDevice] = useState(initialDeviceState);
  const navigate = useNavigate();
  const { auth } = useContext(UserContext) as UserContextType;
  const fetchEmployee = async () => {
    try {
      setIsLoading(true);
      const employee = await findEmployeeApi({ id: id as string });
      await fetchReconciliations();
      await fetchTransactions();
      await fetchTerminals();
      setEmployee(employee);
      setDevice({
        brand: employee.device?.device_info?.brand,
        model: employee.device?.device_info?.model,
        id: employee.device?.id,
        is_active: employee.device?.is_active,
        created_at: employee.device?.created_at,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  const fetchTransactions = async (page: number = 1) => {
    try {
      setIsLoading(true);
      const { transactions, pages } = await listTransactionsApi({
        user_id: id,
        page,
        limit: 7,
      });
      setTransactions(transactions);
      setTransactionsTotal(pages);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const fetchReconciliations = async (page: number = 1) => {
    try {
      setIsLoading(true);
      const { receipts, pages } = await listReconciliationsApi({
        user_id: id,
        page,
        limit: 7,
      });
      setReconciliationsTotal(pages);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const fetchTerminals = async (page: number = 1) => {
    try {
      setIsLoading(true);
      const { terminals, pages } = await listTerminalsApi({
        user_id: id,
        page,
        limit: 4,
      });
      setTerminalsTotal(pages);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToTerminal = (terminal: any) => navigate("/terminals/" + terminal.id);
  const goToReconciliation = (reconcile: any) =>
    navigate("/reconciliations/" + reconcile.id);
  const goToTransaction = (transaction: any) =>
    navigate("/transactions/" + transaction.uuid);

  useEffect(() => {
    fetchEmployee();
  }, []);

  return (
    <Content
      title={employee?.name}
      subTitle={employee?.mobile}
      isLoading={isLoading}
      isLoadingContainerTransparent={false}
      hasBackButton={true}
    >
      <div className="flex-col w-full mb-4">
        <span className="lg:text-2xl font-bold ">{t("device") as string}</span>
        <div className="flex-1 ">
          {employee?.device ? (
            <DeviceListItem role={auth?.role} item={employee.device} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
      </div>
      <div className="lg:flex items-top justify-between gap-2">
        <div className="flex-1">
          <span className="lg:text-2xl font-bold ">
            {t("terminals") as string}
          </span>

          <ListTerminals limit={5} user_id={id} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 justify-between gap-2 mt-4">
        <div className="">
          <span className="lg:text-2xl font-bold ">
            {t("transactions") as string}
          </span>
          <ListTransactions user_id={id} limit={7} />
        </div>
        <div className="flex-1">
          <span className="lg:text-2xl font-bold ">
            {t("reconciliations") as string}
          </span>
          <ListReconciliations user_id={id} limit={7} />
        </div>
      </div>
    </Content>
  );
};

export default Employee;
