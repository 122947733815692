import { TableHead, TableHeaderCell, TableRow } from "@tremor/react";
import { t } from "i18next";
import React from "react";

export const ReconciliationsHeader = () => {
  return (
    <TableHead className="border-b !sticky top-0">
      <TableRow className="propagate-header bg-white">
        <TableHeaderCell className="rtl:text-right">
          {t("status")}
        </TableHeaderCell>
        <TableHeaderCell className="text-right">{t("amount")}</TableHeaderCell>
        <TableHeaderCell className="text-center">{t("count")}</TableHeaderCell>

        <TableHeaderCell className="rtl:text-right">STAN</TableHeaderCell>

        <TableHeaderCell className="rtl:text-right">
          {t("date")}
        </TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">
          {t("merchant")}
        </TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">
          {t("terminal")}
        </TableHeaderCell>
        <TableHeaderCell className="rtl:text-right">
          {t("user")}
        </TableHeaderCell>
      </TableRow>
    </TableHead>
  );
};
