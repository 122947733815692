import Api, { refreshApi } from ".";
import { trackPromise } from "react-promise-tracker";

interface ProtectedType {
  recaptchaToken: string;
}
interface Login extends ProtectedType {
  email: string;
  password: string;
}

interface RestPassword extends ProtectedType {
  email: string;
}
interface UpdatePassword extends ProtectedType {
  password_hash: string;
  password: string;
}

interface UpdateMobileType extends ProtectedType {
  admin_id: string;
  mobile: string;
}

interface LoginMobile {
  mobile: string;
}

interface VerifyLoginMobile {
  mobile: string;
  otp: string;
  client_id: string;
}

interface LoginMobileResponse {
  message: string;
  isOtpSent: boolean;
  otp: string;
}

interface VerifyLoginMobileResponse {
  access_token: string;
  refresh_token: string;
}

export const loginApi = async ({
  email = "",
  password,
  recaptchaToken,
}: Login) => {
  try {
    const response = await trackPromise(
      Api({
        headers: { recaptchaToken },
        method: "post",
        url: "login/v2",
        data: {
          email: email.toLowerCase(),
          password,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    console.log("login error", { e });
    throw e;
  }
};

export const refreshAccessTokenApi = async (refreshToken: string) => {
  const response = await trackPromise(
    refreshApi({
      method: "post",
      url: "refresh-access-token",
      data: {
        refreshToken,
      },
    })
  );
  return await response.data;
};

export const getUserByHashApi = async ({
  passwordHash,
}: {
  passwordHash: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/get-user-by-hash",
        params: {
          password_hash: passwordHash,
        },
      })
    );
    return response.data as { email: string };
  } catch (e) {
    throw e;
  }
};

export const resetPasswordApi = async ({
  email = "",
  recaptchaToken,
}: RestPassword) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        headers: { recaptchaToken },
        url: "/reset-password/send-mail",
        data: {
          email: email.toLowerCase(),
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const updatePasswordApi = async ({
  password_hash,
  password,
  recaptchaToken,
}: UpdatePassword) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "/reset-password/update",
        headers: { recaptchaToken },
        data: {
          password_hash,
          password,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const updateMobileApi = async ({
  admin_id,
  mobile,
  recaptchaToken,
}: UpdateMobileType) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: "/admin/mobile",
        headers: { recaptchaToken },
        data: {
          admin_id,
          mobile,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const loginMobileApi = async ({ mobile }: LoginMobile) => {
  try {
    const response = await Api({
      method: "POST",
      url: "/login",
      data: {
        mobile,
      },
    });
    return response.data as LoginMobileResponse;
  } catch (e) {
    console.log({ e });
    throw e;
  }
};

export const verifyLoginMobileApi = async ({
  mobile,
  otp,
  client_id,
}: VerifyLoginMobile) => {
  try {
    const response = await Api({
      method: "POST",
      url: "/login/verify",
      data: {
        mobile,
        otp,
        client_id,
      },
    });
    return response.data as VerifyLoginMobileResponse;
  } catch (e) {
    console.log({ e });
    throw e;
  }
};
