import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../forms";
import { UserIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";
import { UserListItemType } from "../types/user";

export const UserListItem: FC<UserListItemType> = ({
  role,
  onClick,
  title,
  actionButtonOnClick,
  item,
}) => {
  const { t, i18n } = useTranslation();
  const userBadge: BadgeType = item?.is_active
    ? { label: t("active") }
    : { label: t("notActive"), color: "red" };
  const user = JSON.parse(localStorage.getItem("@user") as string);

  return (
    <div className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 p-2 w-full group" onClick={onClick}>
        <div className="flex flex-row items-center">
          <UserIcon
            containerClassNames={i18n.language == "ar" ? "pl-4" : "pr-4"}
          />
          <div className="flex flex-col flex-1">
            <div className="flex flex-row gap-2 lg:my-1">
              <span className="font-bold text-sm lg:text-base">
                {item?.name}
              </span>
              <div className="hidden lg:flex">
                <Badge {...userBadge} isRounded={true} size="sm" />
              </div>
            </div>
            <span
              className={
                i18n.language == "ar"
                  ? "number-place-holder text-gray-600 text-xs text-right"
                  : "text-gray-600 text-xs "
              }
            >
              {item?.mobile}
            </span>

            {item?.email && (
              <span className={"text-gray-600 text-xs "}>{item?.email}</span>
            )}
          </div>
          {(user?.id !== item.id && actionButtonOnClick) ||
          actionButtonOnClick?.name === "UnAssignTerminal" ? (
            <div className="flex-initial">
              <Button
                label={t("revokeAccess") as string}
                isRounded={false}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  actionButtonOnClick(e, item)
                }
                colorScheme="red"
                size="sm"
                className="px-4 py-1 text-xs  "
              />
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};
