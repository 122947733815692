import React, { FC, ReactElement } from "react";
import { Card } from "../shared";

interface EmptyStateType {
  title?: string;
  componentTitle?: string;
  subTitle?: string;
  component?: ReactElement;
  isList?: boolean;
}

export const EmptyState: FC<EmptyStateType> = ({
  title,
  componentTitle,
  subTitle,
  component,
  isList,
}) => {
  return (
    <div>
      {componentTitle ? (
        <span className="text-lg font-bold text-gray-600">
          {componentTitle}
        </span>
      ) : null}
      <Card classNames="py-2 lg:py-3 lg:px-4 w-full border-dashed bg-gray-100 flex flex-col items-center justify-center">
        {component ? (
          component
        ) : (
          <>
            <span className="block font-bold text-sm lg:text-md mt-2">
              {title}
            </span>
            <span className="block text-muted text-xs mb-1">{subTitle}</span>
          </>
        )}
      </Card>
    </div>
  );
};
