import { FC, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "@lib/layout/content";
import {
  MerchantResponse,
  MerchantStatus,
  updateMerchantApi,
} from "@lib/utils/api/merchants-apis";
import { Button } from "@lib";
import ViewMerchant from "@lib/components/merchants/view";

type Props = {};
const initialMerchantState: Partial<MerchantResponse> = {
  name: "",
  id: "",
  merchant_bank_id: "",
  created_at: "",
  manual_reconcile: false,
};
const Merchant: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [merchant, setMerchant] =
    useState<Partial<MerchantResponse>>(initialMerchantState);

  // const toggleMerchantStatus = (isActive: boolean) => {
  //   return isActive ? (
  //     <Button
  //       label="Disable"
  //       className={"px-2 py-1"}
  //       colorScheme="red"
  //       onClick={() => {
  //         updateMerchantApi({
  //           id: id as string,
  //           status: MerchantStatus.deactive,
  //         });
  //       }}
  //     />
  //   ) : (
  //     <Button
  //       className={"px-2 py-1"}
  //       label="Enable"
  //       colorScheme="default"
  //       onClick={() =>
  //         updateMerchantApi({ id: id as string, status: MerchantStatus.active })
  //       }
  //     />
  //   );
  // };

  return (
    <Content isLoading={isLoading} hasBackButton>
      <ViewMerchant
        id={id as string}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        merchant={merchant}
        setMerchant={setMerchant}
      />
    </Content>
  );
};

export default Merchant;
