import React, { useState } from "react";
import { FC, ReactNode } from "react";
import { RouterNavLink } from "../utils/router/routes";
import Header from "./header";
import Navbar from "./navbar";
import Footer from "./footer";
import { Menu } from "@lib/icons/menu";
type Props = {
  children: ReactNode;
  navlinks: RouterNavLink[];
};
const Container: FC<Props> = ({ children, navlinks }: Props) => {
  const [isNavbarOpen, setisNavbarOpen] = useState(false);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="w-screen h-screen bg-white py-0 flex overflow-hidden ">
        <div>
          <Navbar
            navlinks={navlinks}
            isOpen={isNavbarOpen}
            setIsOpen={setisNavbarOpen}
          />
        </div>

        <div className="w-full">
          <main className="w-full relative">
            <div className="absolute top-2 rtl:right-2 ltr:left-2 z-10">
              <button
                onClick={() => {
                  setisNavbarOpen(true);
                }}
                className=" bg-black rounded-md cursor-pointer sm:hidden block"
              >
                <Menu color="white" />
              </button>
            </div>

            {/* <LoadingSpinner isLoading={promiseInProgress} /> */}
            {children}
          </main>

          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default Container;
