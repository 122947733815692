export const times = {
  "00": "12:00 AM",
  "01": "01:00 AM",
  "02": "02:00 AM",
  "03": "03:00 AM",
  "04": "04:00 AM",
  "05": "05:00 AM",
  "06": "06:00 AM",
  "07": "07:00 AM",
  "08": "08:00 AM",
  "09": "09:00 AM",
  "10": "10:00 AM",
  "11": "11:00 AM",
  "12": "12:00 PM",
  "13": "01:00 PM",
  "14": "02:00 PM",
  "15": "03:00 PM",
  "16": "04:00 PM",
  "17": "05:00 PM",
  "18": "06:00 PM",
  "19": "07:00 PM",
  "20": "08:00 PM",
  "21": "09:00 PM",
  "22": "10:00 PM",
  "23": "11:00 PM",
};

export const policyType = {
  client: "allTerminals",
  merchant: "allMerchantTerminal",
  terminal: "oneTerminal",
};
interface PolicyItem {
  id: string;
  type: keyof typeof policyType;
  time: keyof typeof times;
  relation_id: string;
  created_at: string;
}

export interface PoliciesListItemType {
  role: "admin" | "client";
  title?: string;
  onClick?: (item?: any) => void;
  item: PolicyItem;
}
