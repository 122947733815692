import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Content from "@lib/layout/content";
import { EmptyState, GroupedList, TransactionListItem } from "@lib";
import { listTransactionsApi } from "@lib/utils/api/transactions-apis";
import DownloadTransactionsButton from "@lib/components/shared/download-transactions-btn";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import { dateRange } from "@lib/types/date";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import TransactionsTable from "@lib/tables/transactions-table";

type Props = {};

const Transactions: FC<Props> = ({}) => {
  const [refreshList, setRefreshList] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@transactionsDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@transactionsQueryParams"
  );

  return (
    <Content
      title={t("transactions") as string}
      isLoadingContainerTransparent={true}
      actionBtns={
        <DownloadTransactionsButton
          transactions={transactions}
          dateRange={dateRange}
          queryParams={queryParams}
        />
      }
      queryBuilder={
        <div className="flex flex-col gap-2 ">
          <QueryBuilder
            type="transactions"
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            setDateRange={
              setDateRange as React.Dispatch<SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </div>
      }
    >
      <TransactionsTable
        refresh={refreshList}
        setTransactionsForDownload={setTransactions}
        dateRange={dateRange as dateRange}
        queryParams={queryParams}
      />
    </Content>
  );
};

export default Transactions;
