import React, { FC, ReactNode } from "react";

import ReactSelect from "react-select";

type Item = any;

interface MultiProps {
  data: Array<any>;
  displayKey?: string;
  valueKey?: string;
  onChange: (e: string[], actionMeta: any) => void;
  label: string | ReactNode;
  values?: any[];
  disabled?: boolean;
  errors?: Array<string>;
  placeholder?: string;
  isSearchable?: boolean;
}

export const MultiSelect: FC<MultiProps> = ({
  data = [],
  displayKey = "name",
  valueKey = "id",
  onChange,
  label,
  disabled = false,
  values = [],
  isSearchable,
}: MultiProps) => {
  values = values.map((i: any) => ({
    label: i[displayKey],
    value: i[valueKey],
  }));
  data = data.map((i: any) => ({ label: i[displayKey], value: i[valueKey] }));
  const handleChange = (values: any, actionMeta: any) =>
    onChange(
      values.map((i: any) => i.value),
      actionMeta
    );

  return (
    <div className="mb-2 text-left">
      <label className={`block text-sm font-medium text-gray-700`}>
        {label}
      </label>
      <div className="mt-1">
        <ReactSelect
          isDisabled={disabled}
          isMulti
          options={data}
          value={values}
          className={`shadow-sm`}
          onChange={handleChange}
          isSearchable={isSearchable}
        />
      </div>
    </div>
  );
};
