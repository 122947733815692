import Api from ".";

export const getCredintialsApi = async () => {
  try {
    const response = await Api({
      method: "get",
      url: `/credentials`,
    });

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateKey = async ({ public_key }: any) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/credentials`,
      data: {
        public_key,
      },
    });

    return response.data;
  } catch (e) {
    throw e;
  }
};
export const generateApikey = async () => {
  try {
    const response = await Api({
      method: "patch",
      url: `/generate-apikey`,
    });

    return response.data;
  } catch (e) {
    throw e;
  }
};
