import { FC } from "react";
import { NavLink } from "react-router-dom";
import { AppStore } from "@lib/icons";
import { Card } from "@lib";
import React from "react";
export interface ClientPackage {
  id: string;
  name: string;
  app_name: string;
  type: "sdk" | "app";
  sdk_version: string;
  sdk_name: string;
  release_version: string;
  release_name: string;
}
type Props = {
  client_package: ClientPackage;
};
export const AppItem: FC<Props> = ({ client_package }) => {
  return (
    <NavLink to={"/apps/" + client_package.id}>
      <span className="text-lg font-bold text-gray-600">App</span>
      <Card classNames="!px-4 !py-4 text-gray-700 ">
        <div className="flex items-center justify-between gap-x-2">
          <div className="flex items-center justify-between gap-x-2">
            <AppStore containerClassNames="!p-0" size={30} />
            <div>
              <h4 className="font-semibold">{client_package?.app_name}</h4>
              <p className="text-sm">{client_package?.name}</p>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center gap-x-1">
            {client_package.type == "sdk" ? (
              <>
                <span className="font-semibold text-sm">sdk version</span>
                <small className="font-semibold bg-gray-200 rounded-full px-3 py-0.5 text-xs">
                  {client_package.sdk_version
                    ? `${client_package.sdk_name ?? ""} (${
                        client_package?.sdk_version
                      })`
                    : "missing data"}
                </small>
              </>
            ) : (
              <>
                <span className="font-semibold text-sm">version</span>
                <small className="font-semibold bg-gray-200 rounded-full px-3 py-0.5 text-xs">
                  {client_package.release_version
                    ? `${client_package.release_name ?? ""} (${
                        client_package.release_version
                      })`
                    : "missing data"}
                </small>
              </>
            )}
          </div>
        </div>
        {client_package.type == "sdk" && client_package.release_version && (
          <>
            <hr className="h-0.5 -mx-4 bg-gray-300 my-3" />
            <div className="flex justify-between items-center ">
              <h4 className="text-sm font-semibold">payment plugin version</h4>
              <small className="font-semibold bg-gray-200 rounded-full px-3 py-0.5 text-xs">
                {`${client_package.release_name} (${client_package.release_version})`}
              </small>
            </div>
          </>
        )}
      </Card>
    </NavLink>
  );
};
