import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Input, Button } from "@lib/forms";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { Errors } from "@lib";
import { loginApi, refreshAccessTokenApi } from "@lib/utils/api/auth-apis";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../../assets/payment-schemes.svg";
import ConfigContext from "../contexts/ConfigContext";
import loginChecker, { LoginCheckerResult } from "../utils/login-checker";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

const Login: FC<{}> = ({}) => {
  const [email, setEmail] = useState(
    process.env.REACT_APP_TESTING_EMAIL
      ? process.env.REACT_APP_TESTING_EMAIL
      : ""
  );
  const [password, setPassword] = useState(
    process.env.REACT_APP_TESTING_PASSWORD
      ? process.env.REACT_APP_TESTING_PASSWORD
      : ""
  );
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [loginErrors, setLoginErrors] = useState<any>({});
  const { t, i18n } = useTranslation();
  const { addToken, toggleSwitchAccount } = useContext(
    UserContext
  ) as UserContextType;
  const { allowedOnboarding } = useContext(ConfigContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const onVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    onVerify();
  }, []);

  function signUpHandler(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    navigate("/signup");
  }
  const { promiseInProgress: isLoading } = usePromiseTracker();

  const login = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();
    try {
      if (!executeRecaptcha)
        return setLoginErrors({
          error: {
            english: "Recaptcha token is not valid any more !",
            arabic: "رمز Recaptcha غير صالح",
          },
        });
      await trackPromise(executeRecaptcha()).then(async (token) => {
        setRecaptchaToken(token);
        setLoginErrors({});
        let response = await loginApi({
          email,
          password,
          recaptchaToken: token as string,
        });
        const navigateLink: LoginCheckerResult = loginChecker({
          response,
          addToken,
        });

        navigate(navigateLink.link, { state: navigateLink.state });
        // if (navigateLink.action) toggleModal();
      });
    } catch (error: any) {
      // if (error.message == "The response parameter is invalid or malformed.") {
      //   setLoginErrors({
      //     error: {
      //       english:
      //         "Invalid recaptcha token. Make sure you are using trusted browser",
      //       arabic:
      //         "رمز recaptcha غير صالح. تأكد من أنك تستخدم متصفحًا موثوقًا",
      //     },
      //   });
      // } else if (error?.message) setLoginErrors(error.message);
      // else
      setLoginErrors(error.error.body);
    }
  };

  return (
    <div
      style={{ padding: "20px" }}
      className=" sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="flex flex-1 flex-col justify-center ">
            <span className="text-5xl font-bold">{t("login")}</span>
            <div className="mb-4 mt-2">
              <Errors
                errors={
                  loginErrors.error
                    ? [
                        i18n.language == "ar"
                          ? loginErrors.error.arabic
                          : loginErrors.error.english,
                      ]
                    : loginErrors?.email || loginErrors?.password
                }
              />
            </div>
            <Input
              name="email"
              value={email}
              type="email"
              label={t("email")}
              errors={loginErrors?.email}
              onChange={setEmail}
            />
            <Input
              name="password"
              value={password}
              type="password"
              label={t("password")}
              errors={loginErrors?.password}
              onChange={setPassword}
            />
            <GoogleReCaptcha onVerify={onVerify} />
            <div id="test-login-button">
              <Button
                label={t("login") as string}
                onClick={login}
                // classNames="w-full py-2 px-4 mt-4"
                className={"px-2 py-2 my-2"}
                colorScheme="black"
                isRounded={false}
              />
            </div>
            <div className="pt-4 text-center flex flex-row items-center justify-between">
              <Link
                to={"/reset-password"}
                className="text-sm md:text-md text-black font-bold block flex-1"
              >
                {t("resetPassword")}
              </Link>

              <div className="flex-1">
                {allowedOnboarding ? (
                  <Button
                    label={"Signup"}
                    onClick={signUpHandler}
                    className={"px-2 py-1"}
                    classNames="border border-black"
                    isRounded={false}
                  />
                ) : null}
              </div>
            </div>
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default Login;
