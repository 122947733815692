import Datepicker from "react-tailwindcss-datepicker";
import React, { useEffect, useState } from "react";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";

type Props = {
  setDateRange: any;
};

const DatePickerUI = ({ setDateRange }: Props) => {
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
    endDate: new Date(),
  });
  let test = new Date(value?.startDate ? value.startDate : 0).getTime();

  useEffect(() => {
    setDateRange({
      startDate: value?.startDate
        ? new Date(value.startDate).getTime()
        : undefined,
      endDate: value?.endDate ? new Date(value.endDate).getTime() : undefined,
    });
  }, [value]);

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      let from = new Date(newValue?.startDate).getTime();
      let to = new Date(newValue?.endDate).getTime();
      setValue(newValue);
    //   setDateRange({
    //     startDate: from,
    //     endDate: to,
    //   });
    } else {
      setDateRange({ startDate: undefined, endDate: undefined });
    }
  };
  return <Datepicker value={value} onChange={handleValueChange}></Datepicker>;
};

export default DatePickerUI;
