import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown, Filter, Remove, SearchIcon } from "@lib/icons";
import { listQueryParamsApi } from "@lib/utils/api/query-builder-apis";
import DateRangePicker from "./date-picker";
import LoadingSpinner from "@lib/layout/loading-spinner";

type Props = {
  item: any;
  queryParam: {
    [key: string]: any;
  };
  addQueryParam: any;
  deleteQueryParam: any;
  amountRange?: any;
  setAmountRange: any;
  onChangeDate?: any;
  dateRange?: any;
};

export const FilterType: FC<Props> = ({
  item,
  queryParam,
  addQueryParam,
  deleteQueryParam,
  amountRange,
  setAmountRange,
  onChangeDate,
  dateRange,
}: Props) => {
  const { t } = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData] = useState<any>({});
  const [searchValue, setSearchValue] = useState("");
  const listData = async (key: string, value: string) => {
    try {
      let response = await listQueryParamsApi(key, value);
      return response;
    } catch (e) {}
  };
  let debounceFn: number | any;
  const selected = () => {
    return (
      <div>
        {queryParam?.value?.length ? (
          <>
            <span>{t("selected") as string}</span>
            {queryParam?.value?.map((value: string, index: number) => {
              return (
                <div
                  key={index}
                  className=" flex justify-between items-center px-2 py-0.5 mb-2 bg-black border border-gray-300 rounded-lg cursor-pointer text-white"
                >
                  <h6 className="text-xs">
                    {t(queryParam.valueDisplay[value])}
                  </h6>
                  <span onClick={() => deleteQueryParam(item.key, value)}>
                    <Remove
                      size={18}
                      color="text-white"
                      containerClassNames="!p-1"
                    ></Remove>
                  </span>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  switch (item.type) {
    case "checkbox":
      return item.options.map((el: any, index: number) => {
        return (
          <div
            key={index}
            className="flex gap-3 items-center  pb-1.5 font-semibold"
          >
            <input
              type="checkbox"
              id={"key" in el ? el.key : undefined}
              value={el.value}
              checked={
                queryParam?.value?.find((i: any) => i === el.value)
                  ? true
                  : false
              }
              onChange={(e) =>
                e.target.checked
                  ? addQueryParam(
                      item.key,
                      e.target.value,
                      item.operator,
                      el.display
                    )
                  : deleteQueryParam(item.key, el.value)
              }
              className="w-4 h-4 text-gray-800 bg-gray-300 border-gray-300 rounded !ring-transparent checked:!ring-gray-800  cursor-pointer"
            />
            <label
              htmlFor={"key" in el ? el.key : undefined}
              className="cursor-pointer"
            >
              {t(el.key)}
            </label>
          </div>
        );
      });

    case "select":
      return (
        <div>
          {selected()}
          <div className=" group pb-2">
            <div className="relative flex items-center">
              <input
                type="search"
                pattern={item?.pattern}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder={t(item?.placeholder)}
                className="peer w-full flex items-center  placeholder:flex placeholder:items-center placeholder:text-gray-400 placeholder:text-xs rounded-lg py-1 mb-1 border-gray-300 invalid:border-red-500 invalid:ring-red-500"
              />
              <SearchIcon
                size={18}
                containerClassNames="absolute rtl:left-0 ltr:right-0 top-0 hidden peer-placeholder-shown:inline"
                color="text-gray-400"
              />
            </div>

            <ul className=" peer-checked:block max-h-28 space-y-2 mb-2  overflow-x-hidden transition-all">
              {item.options
                .filter((it: any) =>
                  searchValue
                    ? it.display
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    : true
                )
                .map((el: any, index: number) => {
                  return !queryParam?.value?.find(
                    (i: string) => i == el.value
                  ) ? (
                    <li
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        addQueryParam(
                          item.key,
                          el.value,
                          item.operator,
                          el.display
                        );
                      }}
                      className=" flex justify-between items-center text-sm px-2  m-2 bg-gray-200  hover:bg-gray-300 border border-gray-300 rounded-lg cursor-pointer"
                    >
                      <h6>{t(el.display)}</h6>
                      <Filter size={20} containerClassNames="!p-1"></Filter>
                    </li>
                  ) : null;
                })}
            </ul>
          </div>
        </div>
      );

    case "search":
      return (
        <div className="group mb-2">
          {selected()}
          <div className="relative flex items-center">
            <input
              type="search"
              pattern={item?.pattern}
              onChange={(e) => {
                clearTimeout(debounceFn);
                if (
                  !e.target.checkValidity() ||
                  e.target.value.trim().length == 0
                ) {
                  setData({ [item.searchKey]: [] });
                  return;
                } else {
                  debounceFn = setTimeout(() => {
                    setLoadingSearch(true);
                    listData(item?.searchKey, e.target.value.trim()).then(
                      (res: any) => {
                        setData({ [item.searchKey]: res });
                        setLoadingSearch(false);
                      }
                    );
                  }, 1000);
                }
              }}
              placeholder={t(item?.placeholder)}
              className="peer w-full flex items-center  placeholder:flex placeholder:items-center placeholder:text-gray-400 placeholder:text-xs rounded-lg py-1 mb-1 border-gray-300 invalid:border-red-500 invalid:ring-red-500"
            />
            <SearchIcon
              size={18}
              containerClassNames="absolute rtl:left-0 ltr:right-0 top-0 hidden peer-placeholder-shown:inline"
              color="text-gray-400"
            />
          </div>

          <ul
            className={
              "max-h-28 space-y-2 mb-2 text-center  overflow-x-hidden transition-all relative"
            }
          >
            <LoadingSpinner isLoading={loadingSearch} />
            {!data[item.searchKey]?.length ? (
              <li className=" hidden group-focus-within:block mt-2 text-gray-400">
                {t("noData")}
              </li>
            ) : (
              ""
            )}

            {data[item.searchKey]?.map((el: any, index: number) => {
              return !queryParam?.value?.find((i: string) => i == el.value) ? (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    addQueryParam(
                      item.key,
                      el.value,
                      item.operator,
                      el.display
                    );
                  }}
                  className=" flex justify-between items-center px-2  m-2 bg-gray-200  hover:bg-gray-300 border border-gray-300 rounded-lg cursor-pointer"
                >
                  <h6 className="text-xs truncate [direction:ltr] rtl:text-end">
                    {el.display}
                  </h6>
                  <Filter size={20} containerClassNames="!p-1"></Filter>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      );

    case "input":
      return (
        <div className="group">
          {selected()}
          <input
            type="text"
            pattern={"pattern" in item ? item.pattern : undefined}
            maxLength={"length" in item ? item?.length : undefined}
            placeholder={
              item?.placeholder ? `${t("ex")} ${item?.placeholder}` : ""
            }
            className="w-full rounded-lg py-1 mb-2 border-gray-300  invalid:ring-red-500 invalid:border-red-500 placeholder:text-sm placeholder:opacity-50"
            onKeyUp={(e) => {
              if (!e.currentTarget.value.match(e.currentTarget.pattern)) return;
              if (e.key === "Enter") {
                addQueryParam(
                  item.key,
                  e.currentTarget.value,
                  item.operator,
                  e.currentTarget.value
                );
                e.currentTarget.value = "";
              }
            }}
          />
        </div>
      );

    case "range":
      return (
        <div>
          <label htmlFor="from" className="text-sm text-gray-500">
            {t("from")}{" "}
            <small className="text-gray-700">
              {queryParam?.value[0].slice(0, -2)}
            </small>
          </label>
          <input
            type="text"
            pattern={item?.pattern}
            value={amountRange?.from}
            onChange={(e) => {
              setAmountRange((prev: any) => ({
                ...prev,
                from: e.target?.value,
              }));
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                document
                  .querySelector("input[name='to']")
                  ?.closest("input")
                  ?.focus();
              }
            }}
            className="w-full rounded-lg py-0.5 mb-2 border-gray-200  invalid:ring-red-500 invalid:border-red-500 placeholder:text-sm placeholder:opacity-50"
          />
          <label htmlFor="to" className="text-sm text-gray-500">
            {t("to")}{" "}
            <small className="text-gray-700">
              {queryParam?.value[1].slice(0, -2)}
            </small>
          </label>
          <input
            name="to"
            type="text"
            pattern={item?.pattern}
            value={amountRange.to}
            onChange={(e) => {
              setAmountRange((prev: any) => ({
                ...prev,
                to: e.target.value,
              }));
            }}
            onKeyUp={(e) => {
              if (
                !e.currentTarget.checkValidity() ||
                e.currentTarget.value == "" ||
                !/^[0-9]+$/.test(amountRange.from) ||
                Number(amountRange.from) >= Number(e.currentTarget.value)
              )
                return;
              if (e.key === "Enter") {
                addQueryParam(item.key, amountRange, item.operator);
              }
            }}
            className="w-full rounded-lg py-0.5 mb-2 border-gray-200  invalid:ring-red-500 invalid:border-red-500 placeholder:text-sm placeholder:opacity-50"
          />
        </div>
      );

    case "date":
      return (
        <form>
          <div className=" w-full mb-2">
            <DateRangePicker onChange={onChangeDate} dateRange={dateRange} />
          </div>
        </form>
      );

    default:
      return "";
  }
};
