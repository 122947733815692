import { t } from "i18next";
import moment from "moment";
import React, {
  ElementType,
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { Paginator } from "./paginator";
import IsFullBtn from "@lib/components/shared/is-full-button";
import LoadingSpinner from "@lib/layout/loading-spinner";
import { EmptyState } from "@lib";

interface GroupedListType {
  data: { [key: string]: any }[];
  role: "admin" | "client";
  onItemClick?: (item?: any) => void;
  hideKey?: boolean;
  pages?: {
    total: number;
    selectPage: (page: number) => void;
  };
  groupBy: {
    key: string;
    order?: "asc" | "desc" | string;
    isDate: boolean;
  };
  jsx?: (item: any) => ReactNode;
  component?: ElementType;
  actionButtonOnClick?: (e: Event, item: any) => void;
  listType?: "transactions" | "reconciliations";
  isLoading: boolean;
}

const groupBy = function (xs: any, key: string, isDate: boolean = true) {
  xs = xs.filter((el: any) => el != undefined);
  xs = orderByCreatedAt(xs).map((i: any) => ({
    ...i,
    grouped_key: isDate ? moment(i[key]).format("YYYY-MM-DD") : String(i[key]),
  }));

  return xs.reduce((rv: any, x: any) => {
    (rv[x["grouped_key"]] = rv[x["grouped_key"]] || []).push(x);
    return rv;
  }, {});
};

const orderByCreatedAt = (list: any) => {
  return list.sort((a: any, b: any) => {
    const keyA = new Date(a.created_at),
      keyB = new Date(b.created_at);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });
};

const orderBy = (order: "asc" | "desc" | string, obj: any) => {
  if (order == "desc") {
    return Object.keys(obj).sort().reverse();
  } else if (order == "asc") {
    return Object.keys(obj).sort();
  } else {
    return Object.keys(obj).reverse();
  }
};

export const GroupedList: FC<PropsWithChildren<GroupedListType>> = ({
  data,
  role,
  onItemClick,
  pages = {
    total: 1,
    selectPage: (_: number) => {},
  },
  groupBy: { key, order = "desc", isDate },
  component: Component,
  children,
  hideKey = false,
  actionButtonOnClick,
  listType,
  isLoading,
}) => {
  const list = groupBy(data, key, isDate);
  const [isFull, setIsFull] = useState(false);

  return (
    <div className="relative h-full px-2">
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : data.length ? (
        <div className="flex flex-col relative pb-4">
          <IsFullBtn
            listType={listType}
            setIsFull={setIsFull}
            isFull={isFull}
          />
          {orderBy(order, list).map((key: string, index: number) => {
            return (
              <div key={index} className={"w-full block flex-1"}>
                {!hideKey && (
                  <span className="font-bold lg:text-lg text-sm text-gray-500 block mt-2">
                    {isDate ? moment.utc(key).format("YYYY-MM-DD") : key}
                  </span>
                )}
                <div className="flex flex-col">
                  {list[key].map((i: any, index: number) => {
                    return Component ? (
                      <Component
                        key={index}
                        role={role}
                        item={i}
                        actionButtonOnClick={actionButtonOnClick}
                        onClick={onItemClick ? () => onItemClick(i) : undefined}
                        isFull={isFull}
                        index={index}
                      />
                    ) : children ? (
                      <div key={index}>
                        {React.cloneElement(children as ReactElement, {
                          item: i,
                        })}
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyState title={t("itemNotFound") as string} />
      )}
      {pages && pages.total > 1 ? (
        <div className="w-full flex justify-end items-end mt-2">
          <Paginator
            pages={pages.total}
            selectPage={pages.selectPage as (page: number) => void}
          />
        </div>
      ) : null}
    </div>
  );
};

interface GroupedListV2Type {
  data: { [key: string]: any }[];
  role: "owner" | "accountant" | "cashier";
  onItemClick?: (item?: any) => void;
  hideKey?: boolean;
  pages?: {
    total: number;
    selectPage: (page: number) => void;
  };
  groupBy: {
    key: string;
    order?: "asc" | "desc";
    isDate: boolean;
  };
  jsx?: (item: any) => ReactNode;
  component?: ElementType;
  actionButtonOnClick?: (e: Event, item: any) => void;
  listType?: "transactions" | "reconciliations";
}

export const GroupedListV2: FC<PropsWithChildren<GroupedListV2Type>> = ({
  data,
  role,
  onItemClick,
  pages = {
    total: 1,
    selectPage: (_: number) => {},
  },
  groupBy: { key, order = "desc", isDate },
  component: Component,
  children,
  hideKey = false,
  actionButtonOnClick,
  listType,
}) => {
  const list = groupBy(data, key, isDate);
  const [isFull, setIsFull] = useState(false);
  return (
    <div className="flex flex-col">
      <IsFullBtn listType={listType} setIsFull={setIsFull} isFull={isFull} />
      {orderBy(order, list).map((key: string, index: number) => {
        return (
          <div key={index} className={"w-full block flex-1"}>
            {/* {!hideKey && (
              <span className="font-bold lg:text-lg text-sm text-gray-500 block mt-2">
                {isDate ? moment.utc(key).format("YYYY-MM-DD") : key}
              </span>
            )} */}
            <div className="flex flex-col">
              {list[key].map((i: any, index: number) => {
                return Component ? (
                  <Component
                    key={index}
                    role={role}
                    item={i}
                    actionButtonOnClick={actionButtonOnClick}
                    onClick={onItemClick ? () => onItemClick(i) : undefined}
                    isFull={isFull}
                  />
                ) : children ? (
                  <div key={index}>
                    {React.cloneElement(children as ReactElement, { item: i })}
                  </div>
                ) : null;
              })}
            </div>
          </div>
        );
      })}
      {pages && pages.total > 1 ? (
        <div className="w-full flex justify-end items-end ">
          <Paginator
            pages={pages.total}
            selectPage={pages.selectPage as (page: number) => void}
          />
        </div>
      ) : null}
    </div>
  );
};
