import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import Content from "@lib/layout/content";
import { EmptyState, GroupedList, UserListItem } from "@lib";
import {
  listEmployeesApi,
  revokeEmployeeAccessApi,
} from "@lib/utils/api/employees-apis";
import { dateRange } from "@lib/types/date";
import QueryBuilder from "@lib/components/query-builder/query-builder";

type Props = {};

const Employees: FC<Props> = ({}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);

  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@userDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@userQueryParams"
  );
  const fetchEmployees = async (page: number = 1) => {
    try {
      setIsLoading(true);
      const { users, pages } = await listEmployeesApi({
        page,
        queryParams: queryParams as { [key: string]: any },
        dateRange: dateRange as {},
      });
      setUsers(users);
      setTotal(pages);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  const employeeRevokeAccess = async (id: number) => {
    let revokeResponse = await revokeEmployeeAccessApi({ id });
    setRefresh((prev) => !prev);
  };

  const goToEmployee = (employee: any) => navigate("/employees/" + employee.id);

  useEffect(() => {
    fetchEmployees();
  }, [refresh]);

  return (
    <Content
      title={t("employees")}
      isLoadingContainerTransparent={true}
      queryBuilder={
        <QueryBuilder
          type={"users"}
          refreshList={refresh}
          setRefreshList={setRefresh}
          setDateRange={
            setDateRange as React.Dispatch<React.SetStateAction<dateRange>>
          }
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      }
    >
      <GroupedList
        role="client"
        component={UserListItem}
        actionButtonOnClick={(e, item) => employeeRevokeAccess(item.id)}
        data={users}
        onItemClick={goToEmployee}
        pages={{
          selectPage: (page) => fetchEmployees(page),
          total,
        }}
        groupBy={{
          isDate: true,
          key: "created_at",
        }}
        isLoading={isLoading}
      />
    </Content>
  );
};

export default Employees;
