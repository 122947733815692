import Api, { List } from ".";
import { dateRange } from "@lib/types/date";

interface ListEmployees extends List {
  queryParams?: { [key: string]: any };
  dateRange?: dateRange;
}

interface FindEmployee {
  id: string;
}

interface NewEmployee {
  name: string;
  email: string;
  mobile: string;
  merchantId: string;
}

export interface EmployeeResponse {
  id: string;
  name: string;
  mobile: string;
  email: string;
  is_active: boolean;
  created_at: string;
}

export const listEmployeesApi = async ({
  limit = 20,
  page = 1,
  queryParams = {},
  dateRange = {},
}: ListEmployees) => {
  let encodedQueryParams = btoa(
    unescape(encodeURIComponent(JSON.stringify(queryParams)))
  );
  let encodeddateRange = btoa(JSON.stringify(dateRange));
  try {
    const response = await Api({
      method: "GET",
      url: "/users",
      params: {
        limit,
        page,
        queryParams: encodedQueryParams,
        dateRange: encodeddateRange,
      },
    });
    return response.data;
  } catch (e) {
    console.log({ e });
    throw e;
  }
};

export const findEmployeeApi = async ({ id }: FindEmployee) => {
  try {
    const response = await Api({
      method: "GET",
      url: "/users/" + id,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const revokeEmployeeAccessApi = async ({ id }: { id: number }) => {
  try {
    const response = await Api({
      method: "PUT",
      url: "/employees/" + id + "/revoke",
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const findEmployeeByMobileApi = async ({
  mobile,
}: {
  mobile: string;
}) => {
  try {
    const response = await Api({
      method: "get",
      url: "/users/mobile/" + mobile,
    });
    return response.data as {
      users: EmployeeResponse[];
    };
  } catch (e) {
    throw e;
  }
};

export const createEmployeeApi = async ({
  name,
  email,
  mobile,
  merchantId,
}: NewEmployee) => {
  try {
    const response = await Api({
      method: "post",
      url: "users/",
      data: {
        name,
        mobile: "+966" + mobile,
        email,
        merchant_id: merchantId,
      },
    });
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};
