import { FC, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../../custom-hooks/UsePreservedState";
import Content from "@lib/layout/content";
import { EmptyState, GroupedList, TerminalListItem } from "@lib";
import {
  listTerminalsApi,
  TerminalResponse,
} from "@lib/utils/api/terminals-apis";
import { dateRange } from "@lib/types/date";
import QueryBuilder from "@lib/components/query-builder/query-builder";
import DownloadTerminalsButton from "@lib/components/shared/download-terminals-btn";
import TerminalsTable from "@lib/tables/terminals-table";

type Props = {};

const Terminals: FC<Props> = ({}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(false);

  const [terminals, setTerminals] = useState<TerminalResponse[]>([]);
  const [total, setTotal] = useState(0);
  const { t, i18n } = useTranslation();
  const { auth } = useContext(UserContext) as UserContextType;
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@terminalsQueryParams"
  );
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@terminalsDateRange"
  );
  const fetchTerminals = async (page: number = 1) => {
    try {
      const user = auth.user;
      setIsLoading(true);
      const { terminals, pages } = await listTerminalsApi({
        page,
        user_id: user.id,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange: dateRange as {},
      });
      setTerminals(terminals);
      setIsLoading(false);
      setTotal(pages);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const goToTerminal = (terminal: any) => navigate("/terminals/" + terminal.id);

  useEffect(() => {
    fetchTerminals();
  }, [refreshList]);

  return (
    <Content
      title={t("terminals")}
      actionBtns={
        <DownloadTerminalsButton
          terminals={terminals}
          queryParams={queryParams}
        />
      }
      queryBuilder={
        <QueryBuilder
          type="terminals"
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          setDateRange={
            setDateRange as React.Dispatch<SetStateAction<dateRange>>
          }
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      }
    >
      <TerminalsTable
        refresh={refreshList}
        setRefresh={setRefreshList}
        dateRange={dateRange as dateRange}
        queryParams={queryParams}
      />
    </Content>
  );
};

export default Terminals;
