import Api, { List } from ".";
// import Merchant from "../../pages/Merchant";
import { dateRange } from "@lib/types/date";
import { trackPromise } from "react-promise-tracker";

export enum MerchantStatus {
  active = "active",
  deactive = "deactive",
}

export interface MerchantResponse {
  id: string;
  name: string;
  name_ar: string;
  merchant_bank_id: string;
  reference_id: string;
  status: "active" | "deactive";
  created_at: string;
  client: {
    id: string;
    name: string;
  };
  manual_reconcile: boolean;
}
interface Merchants extends List {
  queryParams?: { [key: string]: any };
  dateRange?: dateRange;
}

interface Merchant {
  id?: string;
  name?: string;
  card_acceptor_id_code: string;
  card_acceptor_name_and_location: string;
  merchant_category_code: string;
  receipt_address: string;
}

interface UpdateMerchant {
  id: string;
  status?: MerchantStatus;
  name?: string;
  card_acceptor_id_code?: string;
  card_acceptor_name_and_location?: string;
  merchant_category_code?: string;
  receipt_address?: string;
}
export interface MerchantStatistics {
  terminals_count?: number;
  transactions_count?: number;
  reconciliations_count?: number;
}

export const listMerchantsApi = async ({
  limit = 30,
  page = 1,
  queryParams = {},
}: Merchants) => {
  // let encodedQueryParams = btoa(JSON.stringify(queryParams));
  //to encode arabic charachter
  let encodedQueryParams = btoa(
    unescape(encodeURIComponent(JSON.stringify(queryParams)))
  );

  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "merchants/",
        params: {
          limit,
          page,
          queryParams: encodedQueryParams,
          // dateRange,
        },
      })
    );
    return response.data as { pages: any; merchants: MerchantResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findMerchantApi = async ({ id }: { id: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "merchants/" + id,
      })
    );
    return response.data as MerchantResponse;
  } catch (e: any) {
    throw e;
  }
};

export const updateManualReconcileApi = async ({
  id,
  manual_reconcile,
}: {
  id: string;
  manual_reconcile: boolean | undefined;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "merchants/manual-reconcile/" + id,
        data: {
          manual_reconcile,
        },
      })
    );
    return response.data as MerchantResponse;
  } catch (e: any) {
    throw e;
  }
};

export const newMerchantApi = async ({
  name,
  card_acceptor_id_code,
  card_acceptor_name_and_location,
  merchant_category_code,
  receipt_address,
}: Merchant) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "merchants/",
        data: {
          name,
          card_acceptor_id_code,
          card_acceptor_name_and_location,
          merchant_category_code,
          receipt_address,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const updateMerchantApi = async ({
  id,
  status,
  name,
  card_acceptor_id_code,
  card_acceptor_name_and_location,
  merchant_category_code,
  receipt_address,
}: UpdateMerchant) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "merchants/" + id,
        data: {
          status,
          name,
          card_acceptor_id_code,
          card_acceptor_name_and_location,
          merchant_category_code,
          receipt_address,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getMerchantsNames = async ({
  merchant_ids,
}: {
  merchant_ids: string[];
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "merchantsNames/",
        data: {
          merchant_ids: merchant_ids,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const mergeMerchantsApi = async ({
  source_merchant,
  destination_merchant,
}: {
  source_merchant: string;
  destination_merchant: string;
}) => {
  try {
    const response = await Api({
      method: "Put",
      url: "merchants/merge",
      data: {
        source_merchant,
        destination_merchant,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const merchantStatisticsApi = async (id: string) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "merchants/statistics/" + id,
      })
    );
    return response.data as MerchantStatistics;
  } catch (e: any) {
    throw e;
  }
};

export const getTokenApi = async ({
  merchant_id,
  admin_id,
}: {
  merchant_id?: string;
  admin_id?: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/admin/token",
        headers: {
          admin_id: admin_id as string,
          new_merchant_id: merchant_id as string,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listAccessableMerchantsApi = async ({
  admin_id,
  page = 1,
  limit = 10,
  name,
}: {
  admin_id?: string;
  page?: number;
  limit?: number;
  name?: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "/merchants/list",
        headers: { admin_id: admin_id as string },
        params: {
          page,
          limit,
          name,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};
